import React, { Component } from 'react';
import './styles/RegPart1.css';
import { BrowserRouter as Router, Redirect, Route, Link, histoy, withRouter } from 'react-router-dom';
import App from '../App.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextInput from './Fields/TextInput.js';
import OnlyPlaceHolderTextInput from './Fields/OnlyPlaceHolderTextInput.js';
import DatePicker from './Fields/DatePicker.js';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Farms from './Farms.js';
import './styles/AddNewFarm.css';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {sendLog} from './LogTest.js';


class AddNewFarm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exit_button_modal: false,
      farm_submit_hint: false,

      pistachioType_and_treeBase_modal: false,
      modal_0_color: 'gray',
      modal_1_color: 'gray',
      modal_2_color: 'gray',
      modal_3_color: 'gray',
      modal_4_color: 'gray',
      modal_5_color: 'gray',

      area_unit: '',
      farm_name_: '',
      area: '',
      overall_pistachio_type: [],
      tree_base: '',
      farm_location: '',
      plantig_date: '',
      first_graft_date: '',
      products_per_year: '',


      row_count: '',  // tedade radif 
      trees_count_in_row: '', // derakht number in row
      distance_between_rows: '', // fasele beine radif ha
      row_lenght_change: '',

      male_tree_count: '',

      weed_type: '',
      weed_killer_name: '',
      weed_killer_density: '',
      weed_killer_usage_time: '',
      weed_killer_usage_per_year: '',
      planting_method: '',
      Trees_overall_health: '',
      Trees_overall_color: '',

      gamooz_show_or_hide: 'none',
      weed_show_or_hide: 'none',

      gamooz_disease_modal: false,
      gamooz_disease_condition: '',
      gamooz_disease_condition_yes_overall: '',
      gamooz_disease_condition_yes_over_year: '',

      weed_condition: '',
      weed_condition_yes_quant: '',
      weed_controll_method: '',
      form_is_done: false
    };

  }

  componentDidMount () {
    this.setState({ farm_submit_hint: true })
  }


  open_gamooz_disease_modal = () => {
    this.setState({ gamooz_disease_modal: true })
  }


  close_gamooz_disease_modal = () => {
    this.setState({ gamooz_disease_modal: false })
  }

  UnitOnChange = (newValue) => {
    this.setState({
      area_unit: newValue.value,
    })
  };

  plantingMethod_onChange = (newValue) => {
    
    this.setState({
      planting_method: newValue.label,
    })
  };

  TreesOverallHealth_onChange = (newValue) => {
    
    this.setState({
      Trees_overall_color: newValue.label,
    })
  };

  TreesOverallColor_onChange = (newValue) => {
    
    this.setState({
      Trees_overall_health: newValue.label,
    })
  };


  gamoozDiseaseCondition_onChange = (newValue) => {
    
    this.setState({
      gamooz_disease_condition: newValue.label,
    });
    if (newValue.label == "دارد") {
      this.setState({ gamooz_show_or_hide: 'block' })
    }
    if (newValue.label == "نمی دانم") {
      this.setState({ gamooz_show_or_hide: 'none' })
    }
    if (newValue.label == "ندارد") {
      this.setState({ gamooz_show_or_hide: 'none' })
    }
  };


  gamoozDiseaseCondition_yes_overall_onChange = (newValue) => {
    
    this.setState({
      gamooz_disease_condition_yes_overall: newValue.label,
    })
  };


  gamoozDiseaseCondition_yes_over_year_onChange = (newValue) => {
    
    this.setState({
      gamooz_disease_condition_yes_over_year: newValue,
    })
  };


  weed_condition_onChange = (newValue) => {
    
    this.setState({
      weed_condition: newValue.label,
    })
    if (newValue.label == 'معمولا دارد') {
      this.setState({ weed_show_or_hide: 'block' })
    }
    if (newValue.label == 'معمولا ندارد') {
      this.setState({ weed_show_or_hide: 'none' })
    }
  };


  weed_condition_yes_quant_onChange = (newValue) => {
    
    this.setState({
      weed_condition_yes_quant: newValue.label,
    })
  };


  weed_controll_method_onChnage = (newValue) => {
    
    this.setState({
      weed_controll_method: newValue.label,
    })
  };


  handleOverallPistachioType = (newValue) => {
    if(newValue != null) {
    var p = [];
    for (var i = 0; i < newValue.length; i++) {
      p.push(newValue[i].label);


    }
    localStorage.setItem('overall_pistachio_type', p);
  }
  };


  handleTreeBaseChange = (newValue) => {
    if(newValue != null) {
    var p = [];
    for (var i = 0; i < newValue.length; i++) {
      p.push(newValue[i].label);


    }
    localStorage.setItem('tree_base', p);
  }
  };


  onAreaChage = value => {
    this.setState({
      area: value
    });

  };

  onLocationChage = value => {
    this.setState({
      farm_location: value
    });

  };

  onPlantingDateChange = value => {
    this.setState({
      plantig_date: value
    });

  };

  onFirstGraftDateChange = value => {
    this.setState({
      first_graft_date: value
    });

  };

  onRowCountChange = value => {
    this.setState({
      row_count: value
    });

  };

  onTreesDistanceInRow = value => {
    this.setState({
      trees_count_in_row: value
    });

  };

  onDistanceBetweenRows = value => {
    this.setState({
      distance_between_rows: value
    });

  };

  onRowLenghtChange = value => {
    this.setState({
      row_lenght_change: value
    });

  };

  onMaleTreeCountChange = value => {
    this.setState({
      male_tree_count: value
    });

  };

  WeedTypeOnChange = value => {
    this.setState({
      weed_type: value
    });

  };

  WeedKillerOnChange = value => {
    this.setState({
      weed_killer_name: value
    });

  };

  WeedKillerDensityOnChange = value => {
    this.setState({
      weed_killer_density: value
    });

  };

  WeedKillerUsageTimeOnChange = value => {
    this.setState({
      weed_killer_usage_time: value
    });

  };

  onFarmNameChage = value => {
    this.setState({
      farm_name_: value
    });

  };



  WeedKillerUsagePerYearOnChange = value => {
    this.setState({
      weed_killer_usage_per_year: value
    });

  };


  open_weed_condition_modal = () => {
    this.setState({ weed_condition_modal: true });
  }

  close_weed_condition_modal = () => {
    this.setState({ weed_condition_modal: false });
  }
  open_weed_killer_modal = () => {
    this.setState({ weed_killer_modal: true });
  }

  close_weed_killer_modal = () => {
    this.setState({ weed_killer_modal: false });
  }
  onFarmRegister = (element, next) => {

    if (this.state.farm_name_ == '' || this.state.farm_name_ == null || this.state.distance_between_rows == '' || this.state.distance_between_rows == '0'
      || this.state.distance_between_rows == null || this.state.distance_between_rows == 0 || this.state.male_tree_count == '' || this.state.male_tree_count == '0'
      || this.state.male_tree_count == null || this.state.male_tree_count == 0) {
      setTimeout(() => {
        next(false, 'مجددا تلاش نمایید!');
      }, 600)

      this.setState({ exit_button_modal: true })
    }

    else {

      const axios = require('axios');
      axios.post(' https://api.pestekaran.com/api/farm',
        {

          api_token: localStorage.getItem('userTOKEN'),
          area: this.state.area,
          area_unit: this.state.area_unit,

          rows_counts: this.state.row_count,
          distance_between_tree_rows: this.state.trees_count_in_row,
          dist_between_trees: this.state.distance_between_rows,
          rows_length: this.state.row_lenght_change,

          male_trees: this.state.male_tree_count,
          farm_name: this.state.farm_name_,
          overall_pistachio_type: localStorage.getItem('overall_pistachio_type'),
          tree_base: localStorage.getItem('tree_base'),
          farm_address: this.state.farm_location,
          plantig_date: this.state.plantig_date,
          first_graft_date: this.state.first_graft_date,
          products_per_year: this.state.products_per_year,
          weed_type: this.state.weed_type,
          weed_killer_name: this.state.weed_killer_name,
          weed_killer_density: this.state.weed_killer_density,
          weed_killer_usage_time: this.state.weed_killer_usage_time,
          weed_killer_usage_per_year: this.state.weed_killer_usage_per_year,
          planting_method: this.state.planting_method,
          Trees_overall_health: this.state.Trees_overall_health,
          Trees_overall_color: this.state.Trees_overall_color,
          gamooz_disease_condition: this.state.gamooz_disease_condition,
          gamooz_disease_condition_yes_overall: this.state.gamooz_disease_condition_yes_overall,
          gamooz_disease_condition_yes_over_year: this.state.gamooz_disease_condition_yes_over_year,
          weed_condition: this.state.weed_condition,
          weed_condition_yes_quant: this.state.weed_condition_yes_quant,
          weed_controll_method: this.state.weed_controll_method
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {

          localStorage.setItem('current_editting_farm', response.data.id);
          localStorage.setItem('current_editting_farm_name', response.data.name);
          setTimeout(() => {
            next();
          }, 600)
          toast.configure({
            position: "top-left",
            rtl: true,
            hideProgressBar: true,
            toastClassName: 'success-notifier-toast',
          });

          toast("اطلاعات عمومی شما ثبت شد. لطفا بقیه بخش ها را کامل نمایید.");

          this.setState({ form_is_done: true })
        })
        .catch((error) => {
          next(false, 'مجددا تلاش نمایید!');
          sendLog(error.response);
        })
        .finally(function () {
          // always executed
        });
    }
  }







  handleChange = (newValue, actionMeta) => {

    var ff = [];
    for (let i = 0; i <= 8; i++) {
      if (newValue[i] != undefined) {
        ff.push(newValue[i].label);
      }
    }
    alert(ff);


  };



  render() {
    const areaUnit = [
      { value: '2', label: 'متر مربع' },
      { value: '3', label: ' قصب' },
      { value: '1', label: 'هکتار' },
    ];

    const plantingMethod = [
      { value: '1', label: ' کاشت مستقیم بذر در زمین اصلی' },
      { value: '2', label: ' نهال پاکتی' },
      { value: '3', label: 'نهال گلبرگی' },
    ];

    const treeTypes = [
      { value: '1', label: 'اوحدی' },
      { value: '2', label: ' احمد آقایی' },
      { value: '3', label: 'اکبری' },
      { value: '4', label: 'کله قوچی' },
      { value: '5', label: 'عباسعلی دامغان' },
      { value: '6', label: 'بادامی سفید فیض آباد' },
      { value: '7', label: 'شاه پسند' },
      { value: '8', label: 'قزوینی' },
      { value: '9', label: 'مخلوط' },

    ];
    const treeBase = [
      { value: '1', label: 'بادامی ریز' },
      { value: '2', label: 'قزوینی' },
      { value: '3', label: 'سرخس' },
      { value: '4', label: 'سفید پسته' },
      { value: '5', label: 'UCB1 بذری' },
      { value: '6', label: ' UCB1 کشت بافتی' },
      { value: '7', label: 'سایر' },
    ];

    const TreesOverallHealth = [
      { value: '1', label: ' عالی' },
      { value: '2', label: 'خوب' },
      { value: '3', label: 'متوسط' },
      { value: '4', label: ' بد' }
    ];

    const TreesOverallColor = [
      { value: '1', label: ' عادی' },
      { value: '2', label: 'سیاه' },
      { value: '3', label: 'بنفش' },
      { value: '4', label: ' سیاه و بنفش' }
    ];

    const gamoozDiseaseCondition = [
      { value: '1', label: ' ندارد' },
      { value: '2', label: 'دارد' },
      { value: '3', label: 'نمی دانم' }
    ];

    const gamoozDiseaseConditionYesOverall = [
      { value: '1', label: ' کم' },
      { value: '2', label: ' متوسط' },
      { value: '3', label: 'زیاد' }
    ];


    const gamoozDiseaseConditionYesOverYear = [
      { value: '1', label: ' کم' },
      { value: '2', label: ' متوسط' },
      { value: '3', label: 'زیاد' }
    ];


    const weedCondition = [
      { value: '1', label: 'معمولا دارد' },
      { value: '2', label: 'معمولا ندارد' }
    ];

    const weedConditionYesQuant = [
      { value: '1', label: ' کم' },
      { value: '2', label: ' متوسط' },
      { value: '3', label: 'زیاد' }
    ];


    const weedControllMethod = [
      { value: '1', label: 'معمولا سریع تیلر می شود' },
      { value: '2', label: ' معمولا بعد از همه گیر شدن باغ تیلر می شود' },
      { value: '3', label: 'از علف کش استفاده می شود' }
    ];

    if (this.state.form_is_done == false) {

      return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>


          <div style={{ // ***************************************** header ***********************************************************************************
            position: 'absolute',
            backgroundColor: '#007bffa8',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            width: '160vw',
            height: '80vw',
            borderRadius: '80vw / 40vw',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            left: '-32%',
            top: '-20%'
          }}
          >
          </div>


          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
              افزودن باغ
              </div>
          </div>

          <Link to="/Farms" style={{ color: '#FFF' }}>
            <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
            </div>
          </Link>

          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Slide down>
              <div style={{ // ***************************************** Top card *****************************************************************************
                boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                display: 'flex',
                backgroundColor: '#1D75FA',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 8,
                width: '80vw',
                height: '20vh',
                marginTop: '9vh',
                borderRadius: 40,
                position: 'relative'
              }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                      <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                    </div>
                    <div style={{ marginRight: 8 }}>
                      {this.state.farms_count}
                    </div>
                  </div>

                </div>


                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                  <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                  لطفا اطلاعات عمومی باغ خود را به دقت پر نمایید.
                    </div>
                </div>
              </div>
            </Slide>
          </div>
          <div style={{ overflow: 'hidden', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 32, height: '70vh', marginTop: '30vh' }} ref={(el) => { this.messagesEnd = el; }}>
            <div style={{ width: '100%' }}>
              <TextInput inputName='نام باغ:' onChange={this.onFarmNameChage} style={{ marginBottom: 16 }} />
              <div style={{ marginBottom: 32, textAlign: 'right' }}>
                <div>
                  انتخاب واحد مساحت:
                    </div>
                <Select
                  className=""
                  onChange={this.UnitOnChange}
                  style={{ textAlign: 'center', height: 35 }}
                  options={areaUnit}
                  placeholder='واحد'
                />
              </div>

              <TextInput inputName='مساحت باغ:' onChange={this.onAreaChage} />
              <TextInput inputName='آدرس:' onChange={this.onLocationChage} InputPlaceHolder='کرمان، رفسنجان، ...' />
              <TextInput  inputName='سال کاشت درختان:' onChange={this.onPlantingDateChange} InputPlaceHolder='ّبرای مثال 1360' />
              <TextInput  inputName='سال اولین پیوند:'  onChange={this.onFirstGraftDateChange} InputPlaceHolder='ّبرای مثال 1370' />
              <TextInput  inputName='میزان محصول سالیانه:'  onChange={ (value) => { this.setState({ products_per_year: value }) }} InputPlaceHolder='ّبرای مثال 500 (کیلوگرم در هکتار)' />
              


              <div className="diseaseItems" onClick={() => { this.setState({ pistachioType_and_treeBase_modal: true }) }}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_0_color }} />رقم غالب و پایه کشت شده</div>
              <Modal visible={this.state.pistachioType_and_treeBase_modal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => { this.setState({ pistachioType_and_treeBase_modal: false }) }}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.setState({ pistachioType_and_treeBase_modal: false })} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}> رقم غالب و پایه کشت شده:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>

                  <div style={{ marginBottom: 32, textAlign: 'right' }}>
                <div>
                  رقم غالب پسته (می توانید چند گزینه انتخاب کنید):
                    </div>
                <CreatableSelect
                  isClearable
                  className=""
                  onChange={this.handleOverallPistachioType}
                  style={{ textAlign: 'center', height: 35 }}
                  options={treeTypes}
                  placeholder=" وارد نمایید"
                  isMulti
                />
              </div>

              <div style={{height: 24}}></div>

              <div style={{ marginBottom: 32, textAlign: 'right' }}>
                <div>
                  پایه کشت شده (می توانید به دلخواه بنویسید):
                    </div>
                <CreatableSelect
                  isClearable
                  className=""
                  onChange={this.handleTreeBaseChange}
                  style={{ textAlign: 'center', height: 35 }}
                  options={treeBase}
                  placeholder="وارد نمایید"
                  isMulti
                />
              </div>

                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} 
                       onClick={() => { this.setState({ modal_0_color: 'rgb(29, 117, 250)', pistachioType_and_treeBase_modal: false  })}}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>

                </div>
              </Modal>





{/* NO. 1  ************************/}
              <div className="diseaseItems" onClick={() => { this.setState({ planting_method_modal: true }) }}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_1_color }} />شیوه کاشت</div>
              <Modal visible={this.state.planting_method_modal} width="90%" height="90%" effect="fadeInUp" onClickAway={() => { this.setState({ planting_method_modal: false }) }}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.setState({ planting_method_modal: false })} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>شیوه کاشت:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        روش کاشت:
                    </div>
                      <Select
                        className=""
                        onChange={this.plantingMethod_onChange}
                        style={{ textAlign: 'center', height: 35 }}
                        options={plantingMethod}
                        placeholder='انتخاب کنید'
                      />
                    </div>

                    <TextInput  inputName='تعداد ردیف در باغ:' onChange={this.onRowCountChange} InputPlaceHolder='' />
                    <TextInput  inputName='فاصله درختان روی ردیف:' onChange={this.onDistanceBetweenRows} InputPlaceHolder='3 متر' />
                    <TextInput  inputName='فاصله بین ردیف درختان:' onChange={this.onTreesDistanceInRow} InputPlaceHolder='5 متر' />
                    <TextInput  inputName='طول ردیف:' onChange={this.onRowLenghtChange} InputPlaceHolder='30 متر' />
                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} 
                       onClick={() => { this.setState({ modal_1_color: 'rgb(29, 117, 250)', planting_method_modal: false  })}}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>

                </div>
              </Modal>


{/* NO. 2  ************************/}
              <div className="diseaseItems" onClick={() => { this.setState({ farm_specific_details_modal: true }) }}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_2_color }} />اطلاعات تخصصی باغ</div>
              <Modal visible={this.state.farm_specific_details_modal} width="90%" height="70%" effect="fadeInUp" onClickAway={() => { this.setState({ farm_specific_details_modal: false }) }}>
                <div className="modal-overlay">


                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.setState({ farm_specific_details_modal: false })} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>اطلاعات تخصصی باغ:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>
                  <div style={{ height: '80%', padding: 16 }}>
                    <TextInput  inputName='تعداد درختان نر در باغ:' onChange={this.onMaleTreeCountChange} InputPlaceHolder='' />


                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        وضعیت عمومی درختان:
                    </div>
                      <Select
                        className=""
                        onChange={this.TreesOverallHealth_onChange}
                        style={{ textAlign: 'center', height: 35 }}
                        options={TreesOverallHealth}
                        placeholder='انتخاب کنید'
                      />
                    </div>

                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        رنگ اغلب تنه درختان:
                    </div>
                      <Select
                        className=""
                        onChange={this.TreesOverallColor_onChange}
                        style={{ textAlign: 'center', height: 35 }}
                        options={TreesOverallColor}
                        placeholder='انتخاب کنید'
                      />
                    </div>
                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} 
                       onClick={() => { this.setState({ modal_2_color: 'rgb(29, 117, 250)', farm_specific_details_modal: false });  }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>


{/* NO. 3  ************************/}
              <div className="diseaseItems" onClick={this.open_gamooz_disease_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_3_color }} />بیماری گموز (شیره سیاه)</div>
              <Modal visible={this.state.gamooz_disease_modal} width="90%" height="70%" effect="fadeInUp" onClickAway={this.close_gamooz_disease_modal}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.close_gamooz_disease_modal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>بیماری گموز:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        وضعیت بیماری گموز:
                    </div>
                      <Select
                        className=""
                        onChange={this.gamoozDiseaseCondition_onChange}
                        style={{ textAlign: 'center', height: 35 }}
                        options={gamoozDiseaseCondition}
                        placeholder='انتخاب کنید'
                      />
                    </div>



                    <div style={{ display: this.state.gamooz_show_or_hide }}>
                      <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                        <div>
                          تعداد کل درختان خشک شده در باغ:
                    </div>
                        <Select
                          className=""
                          onChange={this.gamoozDiseaseCondition_yes_overall_onChange}
                          style={{ textAlign: 'center', height: 35 }}
                          options={gamoozDiseaseConditionYesOverall}
                          placeholder='انتخاب کنید'
                        />
                      </div>


                      <TextInput  inputName='تعداد کل درختان خشک شده در سال:' onChange={this.gamoozDiseaseCondition_yes_over_year_onChange} InputPlaceHolder='30' />

                    </div>
                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} 
                       onClick={ () => { this.setState({ modal_3_color: 'rgb(29, 117, 250)' }); this.close_gamooz_disease_modal()} }>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>

                </div>
              </Modal>



{/* NO. 4  ************************/}
              <div className="diseaseItems" onClick={this.open_weed_condition_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_4_color }} />وضعیت علف هرز</div>
              <Modal visible={this.state.weed_condition_modal} width="90%" height="80%" effect="fadeInUp" onClickAway={this.close_weed_condition_modal}>
                <div className="modal-overlay">
                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.close_weed_condition_modal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>علف هرز:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>
                  <div style={{ height: '80%', padding: 16 }}>
                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        وضعیت علف هرز باغ:
                    </div>
                      <Select
                        className=""
                        onChange={this.weed_condition_onChange}
                        style={{ textAlign: 'center', height: 35 }}
                        options={weedCondition}
                        placeholder='انتخاب کنید'
                      />
                    </div>

                    <div style={{ display: this.state.weed_show_or_hide }}>
                      <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                        <div>مقدار علف هرز:</div>
                        <Select
                          style={{ textAlign: 'center', height: 35 }}
                          onChange={this.weed_condition_yes_quant_onChange}
                          options={weedConditionYesQuant}
                          placeholder='انتخاب کنید'
                        />
                      </div>

                      <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                        <div>چگونگی کنترل علف هرز:</div>
                        <Select
                          style={{ textAlign: 'center', height: 35 }}
                          onChange={this.weed_controll_method_onChnage}
                          options={weedControllMethod}
                          placeholder='انتخاب کنید'
                        />
                      </div>

                      <TextInput inputName='انواع علف هرز:' onChange={this.WeedTypeOnChange} InputPlaceHolder='' />
                    </div>

                  </div>
                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} 
                       onClick={ () => { this.setState({ modal_4_color: 'rgb(29, 117, 250)' }); this.close_weed_condition_modal() }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>

{/* NO. 5  ************************/}
              <div className="diseaseItems" onClick={this.open_weed_killer_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_5_color }} /> علف کش</div>
              <Modal visible={this.state.weed_killer_modal} width="90%" height="80%" effect="fadeInUp" onClickAway={this.close_weed_killer_modal}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.close_weed_killer_modal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>در صورت استفاده از علف کش:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <TextInput inputName='نام علف کش:' onChange={this.WeedKillerOnChange} InputPlaceHolder='' />
                    <TextInput inputName='غلظت علف کش در هزار:' onChange={this.WeedKillerDensityOnChange} InputPlaceHolder='' />
                    <TextInput inputName='زمان استفاده از علف کش:' onChange={this.WeedKillerUsageTimeOnChange} InputPlaceHolder='' />
                    <TextInput inputName='تعداد کاربرد علف کش در سال' onChange={this.WeedKillerUsagePerYearOnChange} InputPlaceHolder='' />
                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} 
                       onClick={ () => { this.setState({ modal_5_color: 'rgb(29, 117, 250)' }); this.close_weed_killer_modal()}}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>

              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                zIndex: 0,
                marginTop: '32px',
                marginBottom: '64px',
              }}>
                <AwesomeButtonProgress
                  size='large'
                  type='primary'
                  releaseDelay={2000}
                  loadingLabel='در حال پردازش'
                  resultLabel='ثبت شد!'
                  onPress={this.onFarmRegister}
                >
                  ثبت
    </AwesomeButtonProgress>
              </div>


              <Modal visible={this.state.exit_button_modal} width="80%" height="50%" effect="fadeInDown" onClickAway={() => { this.setState({ exit_button_modal: false }) }}>
                <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><CloseRoundedIcon onClick={() => { this.setState({ exit_button_modal: false }) }} style={{ color: 'red' }} /></div>

                  <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>

                    <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                      لطفا موارد زیر را کامل نمایید:
                                  </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                      <ul style={{ textAlign: 'right' }}>
                        {(this.state.farm_name_ == '' || this.state.farm_name_ == null) && <li>نام باغ نمی تواند خالی باشد!</li>}
                        {(this.state.distance_between_rows == '' || this.state.distance_between_rows == '0' || this.state.distance_between_rows == 0
                          || this.state.distance_between_rows == null) && <li>  فاصله درختان روی دریف را در قسمت شیوه کاشت وارد نمایید.</li>}
                        {(this.state.male_tree_count == '' || this.state.male_tree_count == '0' || this.state.male_tree_count == null || this.state.male_tree_count == 0) &&
                          <li>  تعداد درختان نر در اطلاعات تخصصی باغ را وارد نمایید.</li>}

                      </ul>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                      <div
                        onClick={() => { this.setState({ exit_button_modal: false }) }} style={{ color: 'red' }}
                        style={{
                          width: '40%',
                          height: '50%',
                          boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                          backgroundColor: '#0857c3',
                          border: '1px solid rgb(3, 77, 179)',
                          borderRadius: 40,
                          color: 'white',
                          padding: '12px 16px',
                          fontSize: 12,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        ادامه </div>
                    </div>


                  </div>


                </div>

              </Modal>






              <Modal visible={this.state.farm_submit_hint} width="80%" height="50%" effect="fadeInDown" onClickAway={() => { this.setState({ farm_submit_hint: false }) }}>
                <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><CloseRoundedIcon onClick={() => { this.setState({ farm_submit_hint: false }) }} style={{ color: 'red' }} /></div>

                  <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>

                    <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                      توجه:
                                  </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                      <ul style={{ textAlign: 'right' }}>
                        <li>
                        باغ به قطعه ای اطلاق می شود که از نظر کلیه خصوصیات از جمله رقم پسته، دور آبیاری، سن درختان، مدیریت آب و کود، سابقه کوددهی، نوع خاک، طول ردیف درختان، فاصله بین ردیف ها و ... یکسان و یکنواخت باشد.
                        </li>
                      </ul>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                      <div
                        onClick={() => { this.setState({ farm_submit_hint: false }) }} style={{ color: 'red' }}
                        style={{
                          width: '40%',
                          height: '50%',
                          boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                          backgroundColor: '#0857c3',
                          border: '1px solid rgb(3, 77, 179)',
                          borderRadius: 40,
                          color: 'white',
                          padding: '12px 16px',
                          fontSize: 12,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        ادامه </div>
                    </div>


                  </div>


                </div>

              </Modal>



            </div>


          </div>
        </div>
      );
    }
    else if (this.state.form_is_done) {
      return (<Redirect to={{
        pathname: '/FarmsEdit',
        state: {
          name: localStorage.getItem('current_editting_farm_name'),
          farm_id: localStorage.getItem('current_editting_farm')
        }
      }} />);
    }
  }
}

export default AddNewFarm;