import React, { Component } from 'react';
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TextInput from '../Fields/TextInput.js';
import AcUnitRoundedIcon from '@material-ui/icons/AcUnitRounded';
import DatPickerEditable from '../Fields/DatPickerEditable.js';
import Loading from '../Loading.js';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import {sendLog} from '../LogTest.js';

export default class WateringEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {

            validation_modal_isOpen: false,
            form_is_completely_empty: false,

            modal_1_color: 'gray',
            modal_2_color: 'gray',
            modal_3_color: 'gray',
            modal_4_color: 'gray',
            modal_5_color: 'gray',
            modal_6_color: 'gray',
            modal_7_color: 'gray',
            modal_8_color: 'gray',
            modal_9_color: 'gray',
            modal_10_color: 'gray',
            modal_11_color: 'gray',
            modal_12_color: 'gray',
            modal_13_color: 'gray',
            modal_14_color: 'gray',
            modal_15_color: 'gray',
            modal_16_color: 'gray',
            modal_17_color: 'gray',
            modal_18_color: 'gray',
            modal_19_color: 'gray',
            modal_20_color: 'gray',
            modal_21_color: 'gray',


            show_image_upload_button: 'none',
            show_image_edit_button: 'block',

            water_source_display: 'none',
            well_name: '',

            water_source_modal: false,
            well_dong_count_modal: false,
            water_debi_volume_modal: false,
            water_share_count_modal: false,
            water_source_quality_modal: false,
            routin_watering_start_date_modal: false,
            routin_watering_end_date_modal: false,
            each_time_watered_area_modal: false,
            watering_duration_in_days_modal: false,
            watering_duration_in_days_234_modal: false,
            watering_method_234_details_modal: false,
            water_dropper_type_234_modal: false,
            routin_234_watering_start_date_modal: false,
            routin_234_watering_end_date_modal: false,
            each_time_watered_area_234_modal: false,
            watering_method_pvc_details_modal: false,
            watering_duration_in_days_PVC_modal: false,
            PVC_paye_derakhtan_poshte_modal: false,
            routin_PVC_watering_start_date_modal: false,
            routin_PVC_watering_end_date_modal: false,
            each_time_PVC_watered_area_modal: false,
            winter_watering_count: false,
            winter_watering_method: false,
            each_time_winter_watered_area_modal: false,
            gharghabi_watering_area_unit_modal: false,
            gharghabi_watering_area_eache_time_modaal: false,
            routin_PVC_watering_watered_area_modal: false,
            winter_watering_area_unit: false,
            winter_watering_time_modal: false,


            water_source: '',
            well_depth: '',
            well_dong_count: '',
            water_debi_volume: '',
            water_share_count: '',
            water_source_quality: '',
            watering_method: '',
            watering_method_explained: '',


            gharghabi_watering_duration_in_days: '',
            gharghabi_water_goes_by_tree_body: '',
            gharghabi_watering_band_margin: '',
            gharghabi_routin_watering_start_date: '',
            gharghabi_routin_watering_end_date: '',
            gharghabi_watered_area_unit: '',
            gharghabi_watered_area: '',

            gharghabi_watered_area_is_not_explicit: false,
            gharghabi_watering_band_width: '',
            gharghabi_watering_row_length: '',
            gharghabi_watering_row_count: '',

            gharghabi_watering_time_lenght_unit: '',
            gharghabi_watering_time_lenght: '',


            watering_duration_in_days_234: '',
            system_type: '',
            water_pipes_distance_from_trees: '',
            water_pipes_depth_in_soil_water_drops_under: '',
            water_drops_distance_from_each_other: '',
            water_drops_debi: '',
            water_dropper_type_234: '',
            routin_234_watering_start_date: '',
            routin_234_watering_end_date: '',
            each_time_watered_area_234_unit: '',
            each_time_watered_area_234: '',
            each_time_watered_time_length_unit_234: '',
            each_time_watered_time_length_234: '',



            watering_method_pvc_type: '',
            watering_method_pvc_pipe_diametere: '',
            watering_method_pvc_pipe_depth: '',
            watering_method_pvc_pipe_valves_distance: '',
            watering_method_pvc_pipe_valves_diameter: '',
            watering_method_pvc_pipe_distance_from_trees: '',
            watering_duration_in_days_PVC: '',
            PVC_paye_derakhtan_poshte: '',
            PVC_distance_from_trees_operation: '',
            routin_PVC_watering_start_date: '',
            routin_PVC_watering_end_date: '',
            each_time_PVC_watered_area_unit: '',
            each_time_PVC_watered_area: '',

            each_time_PVC_watered_is_not_explicit: false,
            pvc_farm_row_count: '',
            pvc_trees_distance_on_row: '',
            trees_distance: '',
            pvc_trees_row_length: '',
            watered_trees_row_count: '',

            each_time_PVC_watered_time_lenghth_unit: '',
            each_time_PVC_watered_time_lenghth: '',



            gharghabi_winter_watering_count: '',
            gharghabi_winter_watering_method: '',
            each_time_winter_watered_area_unit: '',
            each_time_winter_watered_area: '',
            each_time_winter_watered_band_width: '',
            each_time_winter_watered_rows_lenght: '',
            each_time_winter_watered_rows_count: '',
            each_time_winter_watered_time_length_unit: '',
            each_time_winter_watered_time_length: '',


            image_selected: false,
            upload_modal: false,
            uploader_volume: 0,

            isGharghabi: false,
            isWater_drop_and_bubbler: false,
            isPVC: false,
            showPVC: 'none',
            showWater_drop_and_bubbler: 'none',
            showGharghabi: 'none',
            water_dropper_type_image: '',
            water_dropper_type_image_url: '',
            PVC_paye_derakhtan_poshte_image: '',
            PVC_paye_derakhtan_poshte_image_url: '',

            form_is_done: false,
        }
    }


    mapAreaUnit = (value) => {
        if (value == '1') { return ('هکتار') }
        if (value == '2') { return ('متر مربع') }
        if (value == '3') { return ('قصب') }
    }

    unMapAreaUnit = (value) => {
        if (value == '1' || value == 'هکتار') { return ('1') }
        if (value == '2' || value == 'متر مربع') { return ('2') }
        if (value == '3' || value == 'قصب') { return ('3') }
    }

    mapArea = (unit, value) => {
        if (unit == '1') { return (value) }
        if (unit == '2') { return (value * 10000) }
        if (unit == '3') { return (value * 400) }
    }


    mapTime = (value) => {
        if (value == '1') { return ('ساعت') }
        if (value == '2') { return ('دقیقه') }
    }

    unMapTime = (value) => {
        if (value == 'ساعت' || value == '1') { return ('1') }
        if (value == 'دقیقه' || value == '2') { return ('2') }
    }

    mapTimeLength = (timeUnit, timeLength) => {

        if (timeUnit == '1') { return (timeLength / 3600) }
        if (timeUnit == '2') { return (timeLength / 60) }
    }


    componentDidMount() {
        const axios = require('axios');
        axios.get('https://api.pestekaran.com/api/farms',
            {

                params: {
                    api_token: localStorage.getItem('userTOKEN'),
                    id: parseInt(localStorage.getItem('current_editting_farm')),
                }
                ,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json'
                }
            })
            .then((response) => {
                console.log(response.data.length);

                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].id === parseInt(localStorage.getItem('current_editting_farm'))) {
                        console.log(response.data[i]);
                        this.setState({
                            water_source: response.data[i].irrigation.water_source,
                            well_depth: response.data[i].irrigation.well_depth,
                            well_name: response.data[i].irrigation.well_name,
                            well_dong_count: response.data[i].irrigation.well_dong_count,
                            water_debi_volume: response.data[i].irrigation.water_debi_volume,
                            water_share_count: response.data[i].irrigation.water_share_count,
                            water_source_quality: response.data[i].irrigation.water_source_quality,
                            watering_method: response.data[i].irrigation.watering_method,
                            watering_method_explained: response.data[i].irrigation.watering_method_explained,


                            gharghabi_watering_duration_in_days: response.data[i].irrigation.gharghabi_method.gharghabi_watering_duration_in_days,
                            gharghabi_water_goes_by_tree_body: response.data[i].irrigation.gharghabi_method.gharghabi_water_goes_by_tree_body,
                            gharghabi_watering_band_margin: response.data[i].irrigation.gharghabi_method.gharghabi_watering_band_margin,
                            gharghabi_routin_watering_start_date: response.data[i].irrigation.gharghabi_method.gharghabi_routin_watering_start_date,
                            gharghabi_routin_watering_end_date: response.data[i].irrigation.gharghabi_method.gharghabi_routin_watering_end_date,
                            //gharghabi_watered_area_unit: this.mapAreaUnit(response.data[i].irrigation.gharghabi_method.gharghabi_watered_area_unit),
                            //gharghabi_watered_area: response.data[i].irrigation.gharghabi_method.gharghabi_watered_area,

                            gharghabi_watered_area_is_not_explicit: false,
                            gharghabi_watering_band_width: response.data[i].irrigation.gharghabi_method.gharghabi_watered_area_is_not_explicit.gharghabi_watering_band_width,
                            gharghabi_watering_row_length: response.data[i].irrigation.gharghabi_method.gharghabi_watered_area_is_not_explicit.gharghabi_watering_row_length,
                            gharghabi_watering_row_count: response.data[i].irrigation.gharghabi_method.gharghabi_watered_area_is_not_explicit.gharghabi_watering_row_count,

                            gharghabi_watering_time_lenght_unit: this.mapTime(response.data[i].irrigation.gharghabi_method.gharghabi_watering_time_lenght_unit),
                            gharghabi_watering_time_lenght: this.mapTimeLength(response.data[i].irrigation.gharghabi_method.gharghabi_watering_time_lenght_unit, response.data[i].irrigation.gharghabi_method.gharghabi_watering_time_lenght),

                            system_type: response.data[i].irrigation.method_234.system_type,
                            water_pipes_distance_from_trees: response.data[i].irrigation.method_234.water_pipes_distance_from_trees,
                            water_drops_distance_from_each_other: response.data[i].irrigation.method_234.water_drops_distance_from_each_other,
                            water_drops_debi: response.data[i].irrigation.method_234.water_drops_debi,
                            watering_duration_in_days_234: response.data[i].irrigation.method_234.watering_duration_in_days_234,
                            water_dropper_type_234: response.data[i].irrigation.method_234.water_dropper_type_234,
                            routin_234_watering_start_date: response.data[i].irrigation.method_234.routin_234_watering_start_date,
                            routin_234_watering_end_date: response.data[i].irrigation.method_234.routin_234_watering_end_date,
                            each_time_watered_area_234_unit: this.mapAreaUnit(response.data[i].irrigation.method_234.each_time_watered_area_234_unit),
                            each_time_watered_area_234: (response.data[i].irrigation.method_234.each_time_watered_area_234),
                            each_time_watered_time_length_unit_234: this.mapTime(response.data[i].irrigation.method_234.each_time_watered_time_length_unit_234),
                            each_time_watered_time_length_234: this.mapTimeLength(response.data[i].irrigation.method_234.each_time_watered_time_length_unit_234, response.data[i].irrigation.method_234.each_time_watered_time_length_234),



                            watering_method_pvc_type: response.data[i].irrigation.pvc_method.watering_method_pvc_type,
                            watering_method_pvc_pipe_diametere: response.data[i].irrigation.pvc_method.watering_method_pvc_pipe_diametere,
                            watering_method_pvc_pipe_depth: response.data[i].irrigation.pvc_method.watering_method_pvc_pipe_depth,
                            watering_method_pvc_pipe_valves_distance: response.data[i].irrigation.pvc_method.watering_method_pvc_pipe_valves_distance,
                            watering_method_pvc_pipe_valves_diameter: response.data[i].irrigation.pvc_method.watering_method_pvc_pipe_valves_diameter,
                            watering_method_pvc_pipe_distance_from_trees: response.data[i].irrigation.pvc_method.watering_method_pvc_pipe_distance_from_trees,
                            watering_duration_in_days_PVC: response.data[i].irrigation.pvc_method.watering_duration_in_days_PVC,
                            PVC_paye_derakhtan_poshte: response.data[i].irrigation.pvc_method.PVC_paye_derakhtan_poshte,
                            PVC_distance_from_trees_operation: response.data[i].irrigation.pvc_method.PVC_distance_from_trees_operation,
                            routin_PVC_watering_start_date: response.data[i].irrigation.pvc_method.routin_PVC_watering_start_date,
                            routin_PVC_watering_end_date: response.data[i].irrigation.pvc_method.routin_PVC_watering_end_date,
                            //each_time_PVC_watered_area_unit: this.mapAreaUnit(response.data[i].irrigation.pvc_method.each_time_PVC_watered_area_unit),
                            //each_time_PVC_watered_area: response.data[i].irrigation.pvc_method.each_time_PVC_watered_area,

                            each_time_PVC_watered_is_not_explicit: false,
                            pvc_farm_row_count: response.data[i].irrigation.pvc_method.pvc_farm_row_count,
                            pvc_trees_distance_on_row: response.data[i].irrigation.pvc_method.pvc_trees_distance_on_row,
                            trees_distance: response.data[i].irrigation.pvc_method.each_time_PVC_watered_is_not_explicit.trees_distance,
                            pvc_trees_row_length: response.data[i].irrigation.pvc_method.each_time_PVC_watered_is_not_explicit.pvc_trees_row_length,
                            watered_trees_row_count: response.data[i].irrigation.pvc_method.each_time_PVC_watered_is_not_explicit.watered_trees_row_count,

                            each_time_PVC_watered_time_lenghth_unit: this.mapTime(response.data[i].irrigation.pvc_method.each_time_PVC_watered_time_lenghth_unit),
                            each_time_PVC_watered_time_lenghth: this.mapTimeLength(response.data[i].irrigation.pvc_method.each_time_PVC_watered_time_lenghth_unit, response.data[i].irrigation.pvc_method.each_time_PVC_watered_time_lenghth),



                            gharghabi_winter_watering_count: response.data[i].irrigation.winter_watering.gharghabi_winter_watering_count,
                            gharghabi_winter_watering_method: response.data[i].irrigation.winter_watering.gharghabi_winter_watering_method,
                            //each_time_winter_watered_area_unit: this.mapAreaUnit(response.data[i].irrigation.winter_watering.each_time_winter_watered_area_unit),
                            //each_time_winter_watered_area: response.data[i].irrigation.winter_watering.each_time_winter_watered_area,
                            each_time_winter_watered_band_width: response.data[i].irrigation.winter_watering.each_time_winter_watered_band_width,
                            each_time_winter_watered_rows_lenght: response.data[i].irrigation.winter_watering.each_time_winter_watered_rows_lenght,
                            each_time_winter_watered_rows_count: response.data[i].irrigation.winter_watering.each_time_winter_watered_rows_count,
                            each_time_winter_watered_time_length_unit: this.mapTime(response.data[i].irrigation.winter_watering.each_time_winter_watered_time_length_unit),
                            each_time_winter_watered_time_length: this.mapTimeLength(response.data[i].irrigation.winter_watering.each_time_winter_watered_time_length_unit, response.data[i].irrigation.winter_watering.each_time_winter_watered_time_length),



                            image_selected: false,

                            isGharghabi: false,
                            isWater_drop_and_bubbler: false,
                            isPVC: false,
                            showPVC: 'none',
                            showWater_drop_and_bubbler: 'none',
                            showGharghabi: 'none',
                            water_dropper_type_image: response.data[i].irrigation.method_234.water_dropper_type_image_id,
                            PVC_paye_derakhtan_poshte_image: response.data[i].irrigation.pvc_method.PVC_paye_derakhtan_poshte_image_id,

                            form_is_done: false,
                        }, () => {

                            this.setState({
                                loaded: true,
                            })
                        });

                    }
                }

                // Subitmes Completion Status:
                //modal 1:
                if (this.state.water_source != null ||
                    this.state.well_depth != null ||
                    this.state.well_name != null ||
                    this.state.well_dong_count != null ||
                    this.state.water_debi_volume != null ||
                    this.state.water_share_count != null) {
                    this.setState({ modal_1_color: 'rgb(29, 117, 250)' })
                }
                //modal 2:
                if (this.state.water_source_quality != null) {
                    this.setState({ modal_2_color: 'rgb(29, 117, 250)' })
                }
                //modal 3:
                if (this.state.watering_method != null ||
                    this.state.watering_method_explained != null) {
                    this.setState({ modal_3_color: 'rgb(29, 117, 250)' })
                }
                //modal 4:
                if (this.state.gharghabi_watering_duration_in_days != null ||
                    this.state.gharghabi_water_goes_by_tree_body != null ||
                    this.state.gharghabi_watering_band_margin != null) {
                    this.setState({ modal_4_color: 'rgb(29, 117, 250)' })
                }
                //modal 5:
                if (this.state.gharghabi_routin_watering_start_date != null ||
                    this.state.gharghabi_routin_watering_end_date != null) {
                    this.setState({ modal_5_color: 'rgb(29, 117, 250)' })
                }
                //modal 6:
                if (//this.state.gharghabi_watered_area_unit != null ||
                    this.state.gharghabi_watered_area != null ||
                    // this.state.gharghabi_watered_area_is_not_explicit != null ||
                    this.state.gharghabi_watering_row_length != null ||
                    this.state.gharghabi_watering_row_count != null) {
                    this.setState({ modal_6_color: 'rgb(29, 117, 250)' })
                }
                //modal 7:
                if (this.state.gharghabi_watering_time_lenght_unit != null ||
                    this.state.gharghabi_watering_time_lenght != null) {
                    this.setState({ modal_7_color: 'rgb(29, 117, 250)' })
                }
                //modal 8:
                if (this.state.watering_duration_in_days_234 != null ||
                    this.state.water_pipes_depth_in_soil_water_drops_under != null) {
                    this.setState({ modal_8_color: 'rgb(29, 117, 250)' })
                }
                //modal 9:
                if (this.state.system_type != null ||
                    this.state.water_pipes_distance_from_trees != null ||
                    this.state.water_drops_distance_from_each_other != null ||
                    this.state.water_drops_debi != null) {
                    this.setState({ modal_9_color: 'rgb(29, 117, 250)' })
                }
                //modal 10:
                if (this.state.water_dropper_type_234 != null ||
                    this.state.water_dropper_type_image != null) {
                    this.setState({ modal_10_color: 'rgb(29, 117, 250)' })
                }
                //modal 11:
                if (this.state.routin_234_watering_start_date != null ||
                    this.state.routin_234_watering_end_date != null) {
                    this.setState({ modal_11_color: 'rgb(29, 117, 250)' })
                }
                //modal 12:
                if (this.state.each_time_watered_area_234_unit != null ||
                    this.state.each_time_watered_area_234 != null ||
                    this.state.each_time_watered_time_length_unit_234 != null ||
                    this.state.each_time_watered_time_length_234 != null) {
                    this.setState({ modal_12_color: 'rgb(29, 117, 250)' })
                }
                //modal 13:
                if (this.state.watering_method_pvc_pipe_diametere != null ||
                    this.state.watering_method_pvc_pipe_depth != null ||
                    this.state.watering_method_pvc_pipe_valves_distance != null ||
                    this.state.watering_method_pvc_pipe_valves_diameter != null ||
                    this.state.watering_method_pvc_pipe_distance_from_trees != null) {
                    this.setState({ modal_13_color: 'rgb(29, 117, 250)' })
                }
                //modal 14:
                if (this.state.watering_method_pvc_type != null ||
                    this.state.watering_duration_in_days_PVC != null) {
                    this.setState({ modal_14_color: 'rgb(29, 117, 250)' })
                }
                //modal 15:
                if (this.state.PVC_paye_derakhtan_poshte != null ||
                    this.state.PVC_distance_from_trees_operation != null ||
                    this.state.PVC_paye_derakhtan_poshte_image != null) {
                    this.setState({ modal_15_color: 'rgb(29, 117, 250)' })
                }
                //modal 16:
                if (this.state.routin_PVC_watering_start_date != null ||
                    this.state.routin_PVC_watering_end_date != null) {
                    this.setState({ modal_16_color: 'rgb(29, 117, 250)' })
                }
                //modal 17:
                if (this.state.each_time_PVC_watered_area_unit != null ||
                    this.state.each_time_PVC_watered_area != null ||
                    this.state.each_time_PVC_watered_is_not_explicit != null ||
                    this.state.watered_trees_row_count != null) {
                    this.setState({ modal_17_color: 'rgb(29, 117, 250)' })
                }
                //modal 18:
                if (this.state.each_time_PVC_watered_time_lenghth_unit != null ||
                    this.state.each_time_PVC_watered_time_lenghth != null) {
                    this.setState({ modal_18_color: 'rgb(29, 117, 250)' })
                }
                //modal 19:
                if (this.state.gharghabi_winter_watering_count != null ||
                    this.state.gharghabi_winter_watering_method != null) {
                    this.setState({ modal_19_color: 'rgb(29, 117, 250)' })
                }
                //modal 20:
                if (this.state.each_time_winter_watered_area_unit != null ||
                    this.state.each_time_winter_watered_area != null ||
                    this.state.each_time_winter_watered_band_width != null ||
                    this.state.each_time_winter_watered_rows_lenght != null ||
                    this.state.each_time_winter_watered_rows_count != null) {
                    this.setState({ modal_20_color: 'rgb(29, 117, 250)' })
                }
                //modal 21:
                if (this.state.each_time_winter_watered_time_length_unit != null ||
                    this.state.each_time_winter_watered_time_length != null) {
                    this.setState({ modal_21_color: 'rgb(29, 117, 250)' })
                }
                this.setState({ loaded: true })
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

                switch (this.state.watering_method) {
                    case 'غرقابی':
                        this.gharghabiMethodIsChosen(); this.setState({ isGharghabi: true, isWater_drop_and_bubbler: false, isPVC: false });
                        if (this.state.water_pipe_depth_show_hide_state == true) { this.setState({ water_pipe_depth_show_hide: 'block' }) }
                        if (this.state.water_pipe_depth_show_hide_state == false) { this.setState({ water_pipe_depth_show_hide: 'none' }) }
                        if (this.state.isGharghabi == true) { this.setState({ showGharghabi: 'block', showWater_drop_and_bubbler: 'none', showPVC: 'none' }) }
                        if (this.state.isWater_drop_and_bubbler == true) { this.setState({ showWater_drop_and_bubbler: 'block', showGharghabi: 'none', showPVC: 'none' }) }
                        if (this.state.isPVC == true) { this.setState({ showPVC: 'block', showWater_drop_and_bubbler: 'none', showGharghabi: 'none' }) }
                        this.setState({ watering_method_modal: false, modal_3_color: 'rgb(29, 117, 250)' })
                        break;
                    case 'قطره ای سطحی':
                        this.Two_and_four_MethodChosen(); this.setState({ isWater_drop_and_bubbler: true, isGharghabi: false, isPVC: false, water_pipe_depth_show_hide_state: false })
                        if (this.state.water_pipe_depth_show_hide_state == true) { this.setState({ water_pipe_depth_show_hide: 'block' }) }
                        if (this.state.water_pipe_depth_show_hide_state == false) { this.setState({ water_pipe_depth_show_hide: 'none' }) }
                        if (this.state.isGharghabi == true) { this.setState({ showGharghabi: 'block', showWater_drop_and_bubbler: 'none', showPVC: 'none' }) }
                        if (this.state.isWater_drop_and_bubbler == true) { this.setState({ showWater_drop_and_bubbler: 'block', showGharghabi: 'none', showPVC: 'none' }) }
                        if (this.state.isPVC == true) { this.setState({ showPVC: 'block', showWater_drop_and_bubbler: 'none', showGharghabi: 'none' }) }
                        this.setState({ watering_method_modal: false, modal_3_color: 'rgb(29, 117, 250)' })
                        break;
                    case 'بابلر':
                        this.Two_and_four_MethodChosen(); this.setState({ isWater_drop_and_bubbler: true, isGharghabi: false, isPVC: false, water_pipe_depth_show_hide_state: false })
                        if (this.state.water_pipe_depth_show_hide_state == true) { this.setState({ water_pipe_depth_show_hide: 'block' }) }
                        if (this.state.water_pipe_depth_show_hide_state == false) { this.setState({ water_pipe_depth_show_hide: 'none' }) }
                        if (this.state.isGharghabi == true) { this.setState({ showGharghabi: 'block', showWater_drop_and_bubbler: 'none', showPVC: 'none' }) }
                        if (this.state.isWater_drop_and_bubbler == true) { this.setState({ showWater_drop_and_bubbler: 'block', showGharghabi: 'none', showPVC: 'none' }) }
                        if (this.state.isPVC == true) { this.setState({ showPVC: 'block', showWater_drop_and_bubbler: 'none', showGharghabi: 'none' }) }
                        this.setState({ watering_method_modal: false, modal_3_color: 'rgb(29, 117, 250)' })
                        break;
                    case 'زیر سطحی با لوله های پی وی سی':
                        this.pvcIsChosen(); this.setState({ isPVC: true, isWater_drop_and_bubbler: false, isGharghabi: false, })
                        if (this.state.water_pipe_depth_show_hide_state == true) { this.setState({ water_pipe_depth_show_hide: 'block' }) }
                        if (this.state.water_pipe_depth_show_hide_state == false) { this.setState({ water_pipe_depth_show_hide: 'none' }) }
                        if (this.state.isGharghabi == true) { this.setState({ showGharghabi: 'block', showWater_drop_and_bubbler: 'none', showPVC: 'none' }) }
                        if (this.state.isWater_drop_and_bubbler == true) { this.setState({ showWater_drop_and_bubbler: 'block', showGharghabi: 'none', showPVC: 'none' }) }
                        if (this.state.isPVC == true) { this.setState({ showPVC: 'block', showWater_drop_and_bubbler: 'none', showGharghabi: 'none' }) }
                        this.setState({ watering_method_modal: false, modal_3_color: 'rgb(29, 117, 250)' })
                        break;
                    case 'قطره ای زیر سطحی':
                        this.third_MethodChosen(); this.setState({ isWater_drop_and_bubbler: true, isGharghabi: false, isPVC: false, water_pipe_depth_show_hide_state: true })
                        if (this.state.water_pipe_depth_show_hide_state == true) { this.setState({ water_pipe_depth_show_hide: 'block' }) }
                        if (this.state.water_pipe_depth_show_hide_state == false) { this.setState({ water_pipe_depth_show_hide: 'none' }) }
                        if (this.state.isGharghabi == true) { this.setState({ showGharghabi: 'block', showWater_drop_and_bubbler: 'none', showPVC: 'none' }) }
                        if (this.state.isWater_drop_and_bubbler == true) { this.setState({ showWater_drop_and_bubbler: 'block', showGharghabi: 'none', showPVC: 'none' }) }
                        if (this.state.isPVC == true) { this.setState({ showPVC: 'block', showWater_drop_and_bubbler: 'none', showGharghabi: 'none' }) }
                        this.setState({ watering_method_modal: false, modal_3_color: 'rgb(29, 117, 250)' })
                        break;
                }

                // always executed
            });
    }



    closeModal = () => {
        this.setState({
            water_source_modal: false,
            well_dong_count_modal: false,
            water_debi_volume_modal: false,
            water_share_count_modal: false,
            water_source_quality_modal: false,
            routin_watering_start_date_modal: false,
            routin_watering_end_date_modal: false,
            each_time_watered_area_modal: false,
            watering_duration_in_days_modal: false,
            watering_duration_in_days_234_modal: false,
            watering_method_234_details_modal: false,
            water_dropper_type_234_modal: false,
            routin_234_watering_start_date_modal: false,
            routin_234_watering_end_date_modal: false,
            each_time_watered_area_234_modal: false,
            watering_method_pvc_details_modal: false,
            watering_duration_in_days_PVC_modal: false,
            PVC_paye_derakhtan_poshte_modal: false,
            routin_PVC_watering_start_date_modal: false,
            routin_PVC_watering_end_date_modal: false,
            each_time_PVC_watered_area_modal: false,
            winter_watering_count: false,
            winter_watering_method: false,
            each_time_winter_watered_area_modal: false,
            gharghabi_watering_area_unit_modal: false,
            gharghabi_watering_area_eache_time_modaal: false,
            routin_PVC_watering_watered_area_modal: false,
            winter_watering_area_unit: false,
            winter_watering_time_modal: false,


        })

    }

    handle_PVC_paye_derakhtan_poshte_ImageSelect = (event) => {
        this.setState({ image_selected: true, upload_modal: true });
        console.log((event.target.files[0].size) / 1024);
        localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
        var formData = new FormData();
        var imagefile = document.querySelector('#file');
        formData.append("file", event.target.files[0]);
        formData.append('api_token', localStorage.getItem('userTOKEN'));
        const config = {
            onUploadProgress: progressEvent => {
                var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
                console.log(x);
                this.setState({ uploader_volume: x })
            }
        }

        axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
                console.log(response.data);
                this.setState({ PVC_paye_derakhtan_poshte_image: response.data.id });



                toast.configure({
                    position: "top-left",
                    rtl: true,

                    hideProgressBar: true,
                    toastClassName: 'img-uploaded-success-notifier-toast',
                });

                toast("تصویر شما با موفقیت بارگذاری شد!");
                this.setState({ image_selected: false, upload_modal: false });


            })

    }



    handle_water_dropper_type_ImageSelect = (event) => {
        this.setState({ image_selected: true, upload_modal: true });
        console.log((event.target.files[0].size) / 1024);
        localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
        var formData = new FormData();
        var imagefile = document.querySelector('#file');
        formData.append("file", event.target.files[0]);
        formData.append('api_token', localStorage.getItem('userTOKEN'));
        const config = {
            onUploadProgress: progressEvent => {
                var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
                console.log(x);
                this.setState({ uploader_volume: x })
            }
        }

        axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                console.log(response);
                console.log(response.data);
                this.setState({ water_dropper_type_image: response.data.id });

                toast.configure({
                    position: "top-left",
                    rtl: true,

                    hideProgressBar: true,
                    toastClassName: 'img-uploaded-success-notifier-toast',
                });

                toast("تصویر شما با موفقیت بارگذاری شد!");
                this.setState({ image_selected: false, upload_modal: false });


            })

    }

    on_farm_irrigation_submit = (element, next) => {

        if (this.state.watering_method == null || this.state.watering_method == '') {
            this.setState({ form_is_completely_empty: true })
            setTimeout(() => {
                next(false, 'خطا!');
            }, 600)
        }
        else {
            let gharghabi_is_undone, method_234_is_undone, PVC_is_undone, winterWatering_is_undone, water_debi_volume_is_empty, validation_should_popup = false;




            {

                // Water Debi:  
                if (this.state.water_debi_volume == null || this.state.water_debi_volume == '') {
                    water_debi_volume_is_empty = true;
                }
                // Winter:

                if (this.state.gharghabi_winter_watering_count == null ||
                    this.state.gharghabi_winter_watering_count == '' ||
                    this.state.each_time_winter_watered_band_width == null ||
                    this.state.each_time_winter_watered_band_width == '' ||
                    this.state.each_time_winter_watered_rows_lenght == null ||
                    this.state.each_time_winter_watered_rows_lenght == '' ||
                    this.state.each_time_winter_watered_rows_count == null ||
                    this.state.each_time_winter_watered_rows_count == '' ||
                    this.state.each_time_winter_watered_time_length_unit == null ||
                    this.state.each_time_winter_watered_time_length_unit == '' ||
                    this.state.each_time_winter_watered_time_length == null ||
                    this.state.each_time_winter_watered_time_length == '') {
                    winterWatering_is_undone = true;
                    console.log('winterWatering_is_undone: ', winterWatering_is_undone)
                }
                // PVC:
                if (this.state.routin_PVC_watering_end_date == null ||
                    this.state.routin_PVC_watering_end_date == '' ||
                    this.state.routin_PVC_watering_start_date == null ||
                    this.state.routin_PVC_watering_start_date == '' ||
                    this.state.watering_duration_in_days_PVC == null ||
                    this.state.watering_duration_in_days_PVC == '' ||
                    this.state.trees_distance == null ||
                    this.state.trees_distance == '' ||
                    this.state.pvc_trees_row_length == null ||
                    this.state.pvc_trees_row_length == '' ||
                    this.state.watered_trees_row_count == null ||
                    this.state.watered_trees_row_count == '' ||
                    this.state.each_time_PVC_watered_time_lenghth_unit == null ||
                    this.state.each_time_PVC_watered_time_lenghth_unit == '' ||
                    this.state.each_time_PVC_watered_time_lenghth == null ||
                    this.state.each_time_PVC_watered_time_lenghth == '') {
                    PVC_is_undone = true;
                    console.log('PVC_is_undone: ', PVC_is_undone)
                }
                // 234:
                if (this.state.routin_234_watering_start_date == null ||
                    this.state.routin_234_watering_start_date == '' ||
                    this.state.routin_234_watering_end_date == null ||
                    this.state.routin_234_watering_end_date == '' ||
                    this.state.watering_duration_in_days_234 == null ||
                    this.state.watering_duration_in_days_234 == '' ||
                    this.state.each_time_watered_area_234_unit == null ||
                    this.state.each_time_watered_area_234_unit == '' ||
                    this.state.each_time_watered_area_234 == null ||
                    this.state.each_time_watered_area_234 == '' ||
                    this.state.each_time_watered_time_length_unit_234 == null ||
                    this.state.each_time_watered_time_length_unit_234 == '' ||
                    this.state.each_time_watered_time_length_234 == null ||
                    this.state.each_time_watered_time_length_234 == '') {
                    method_234_is_undone = true;
                    console.log('method_234_is_undone: ', method_234_is_undone)
                }
                // Gharghabi: 
                if (this.state.gharghabi_watering_band_width == null ||
                    this.state.gharghabi_watering_row_length == null ||
                    this.state.gharghabi_watering_row_count == null ||
                    this.state.gharghabi_watering_duration_in_days == null ||
                    this.state.gharghabi_watering_band_width == '' ||
                    this.state.gharghabi_watering_row_length == '' ||
                    this.state.gharghabi_watering_row_count == '' ||
                    this.state.gharghabi_watering_duration_in_days == '' ||
                    this.state.gharghabi_watering_time_lenght_unit == null ||
                    this.state.gharghabi_watering_time_lenght_unit == '' ||
                    this.state.gharghabi_watering_time_lenght == null ||
                    this.state.gharghabi_watering_time_lenght == '' ||
                    this.state.gharghabi_routin_watering_start_date == null ||
                    this.state.gharghabi_routin_watering_start_date == '' ||
                    this.state.gharghabi_routin_watering_end_date == null ||
                    this.state.gharghabi_routin_watering_end_date == '') {
                    gharghabi_is_undone = true;
                    console.log('gharghabi_is_undone: ', gharghabi_is_undone)
                }


                if (water_debi_volume_is_empty || winterWatering_is_undone) {
                    validation_should_popup = true;
                    console.log('two state')
                }

                if (gharghabi_is_undone && method_234_is_undone && PVC_is_undone) {
                    console.log('three state')
                    validation_should_popup = true;

                }
            }

            if (validation_should_popup) {
                this.setState({ validation_modal_isOpen: true })
                setTimeout(() => {
                    next(false, 'خطا!');
                }, 600)
            }

            else {
                var data = {
                    water_source: this.state.water_source,
                    well_depth: this.state.well_depth,
                    well_name: this.state.well_name,
                    well_dong_count: this.state.well_dong_count,
                    water_debi_volume: this.state.water_debi_volume,
                    water_share_count: this.state.water_share_count,
                    water_source_quality: this.state.water_source_quality,
                    watering_method: this.state.watering_method,
                    watering_method_explained: this.state.watering_method_explained,

                    gharghabi_method: {
                        gharghabi_watering_duration_in_days: this.state.gharghabi_watering_duration_in_days,
                        gharghabi_water_goes_by_tree_body: this.state.gharghabi_water_goes_by_tree_body,
                        gharghabi_watering_band_margin: this.state.gharghabi_watering_band_margin,
                        gharghabi_routin_watering_start_date: this.state.gharghabi_routin_watering_start_date,
                        gharghabi_routin_watering_end_date: this.state.gharghabi_routin_watering_end_date,
                        //gharghabi_watered_area_unit: this.state.gharghabi_watered_area_unit,
                        //gharghabi_watered_area: this.state.gharghabi_watered_area,
                        gharghabi_watered_area_is_not_explicit: {
                            gharghabi_watering_band_width: this.state.gharghabi_watering_band_width,
                            gharghabi_watering_row_length: this.state.gharghabi_watering_row_length,
                            gharghabi_watering_row_count: this.state.gharghabi_watering_row_count,
                        },
                        gharghabi_watering_time_lenght_unit: this.unMapTime(this.state.gharghabi_watering_time_lenght_unit),
                        gharghabi_watering_time_lenght: this.state.gharghabi_watering_time_lenght,
                    },
                    method_234: {

                        system_type: this.state.system_type,
                        water_pipes_distance_from_trees: this.state.water_pipes_distance_from_trees,
                        water_pipes_depth_in_soil_water_drops_under: this.state.water_pipes_depth_in_soil_water_drops_under,
                        water_drops_distance_from_each_other: this.state.water_drops_distance_from_each_other,
                        water_drops_debi: this.state.water_drops_debi,

                        water_dropper_type_image_id: this.state.water_dropper_type_image,
                        watering_duration_in_days_234: this.state.watering_duration_in_days_234,
                        water_dropper_type_234: this.state.water_dropper_type_234,
                        routin_234_watering_start_date: this.state.routin_234_watering_start_date,
                        routin_234_watering_end_date: this.state.routin_234_watering_end_date,
                        each_time_watered_area_234_unit: this.unMapAreaUnit(this.state.each_time_watered_area_234_unit),
                        each_time_watered_area_234: this.state.each_time_watered_area_234,
                        each_time_watered_time_length_unit_234: this.unMapTime(this.state.each_time_watered_time_length_unit_234),
                        each_time_watered_time_length_234: this.state.each_time_watered_time_length_234,

                    },

                    pvc_method: {
                        watering_method_pvc_type: this.state.watering_method_pvc_type,
                        watering_method_pvc_pipe_diametere: this.state.watering_method_pvc_pipe_diametere,
                        watering_method_pvc_pipe_depth: this.state.watering_method_pvc_pipe_depth,
                        watering_method_pvc_pipe_valves_distance: this.state.watering_method_pvc_pipe_valves_distance,
                        watering_method_pvc_pipe_valves_diameter: this.state.watering_method_pvc_pipe_valves_diameter,
                        watering_method_pvc_pipe_distance_from_trees: this.state.watering_method_pvc_pipe_distance_from_trees,
                        watering_duration_in_days_PVC: this.state.watering_duration_in_days_PVC,
                        PVC_paye_derakhtan_poshte: this.state.PVC_paye_derakhtan_poshte,
                        PVC_distance_from_trees_operation: this.state.PVC_distance_from_trees_operation,
                        routin_PVC_watering_start_date: this.state.routin_PVC_watering_start_date,
                        routin_PVC_watering_end_date: this.state.routin_PVC_watering_end_date,
                        //each_time_PVC_watered_area_unit: this.state.each_time_PVC_watered_area_unit,
                        //each_time_PVC_watered_area: this.state.each_time_PVC_watered_area,
                        PVC_paye_derakhtan_poshte_image_id: this.state.PVC_paye_derakhtan_poshte_image,
                        pvc_farm_row_count: this.state.pvc_farm_row_count,
                        pvc_trees_distance_on_row: this.state.pvc_trees_distance_on_row,
                        each_time_PVC_watered_is_not_explicit: {
                            trees_distance: this.state.trees_distance,
                            pvc_trees_row_length: this.state.pvc_trees_row_length,
                            watered_trees_row_count: this.state.watered_trees_row_count,
                        },
                        each_time_PVC_watered_time_lenghth_unit: this.unMapTime(this.state.each_time_PVC_watered_time_lenghth_unit),
                        each_time_PVC_watered_time_lenghth: this.state.each_time_PVC_watered_time_lenghth,
                    },

                    winter_watering: {
                        gharghabi_winter_watering_count: this.state.gharghabi_winter_watering_count,
                        gharghabi_winter_watering_method: this.state.gharghabi_winter_watering_method,
                        //each_time_winter_watered_area_unit: this.state.each_time_winter_watered_area_unit,
                        //each_time_winter_watered_area: this.state.each_time_winter_watered_area,
                        each_time_winter_watered_band_width: this.state.each_time_winter_watered_band_width,
                        each_time_winter_watered_rows_lenght: this.state.each_time_winter_watered_rows_lenght,
                        each_time_winter_watered_rows_count: this.state.each_time_winter_watered_rows_count,
                        each_time_winter_watered_time_length_unit: this.unMapTime(this.state.each_time_winter_watered_time_length_unit),
                        each_time_winter_watered_time_length: this.state.each_time_winter_watered_time_length,
                    }
                }

                if (localStorage.getItem('current_editting_farm') == '') {
                    toast.configure({
                        position: "top-left",
                        rtl: true,
                        hideProgressBar: true,
                        toastClassName: 'error-notifier-toast',
                    });

                    toast("ابتدا اطلاعات عمومی باغتان را پر نمایید.");
                }
                else {
                    console.log(data)
                    const axios = require('axios');
                    axios.put('https://api.pestekaran.com/api/irrigation/' + localStorage.getItem('current_editting_farm'),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Accept': 'application/json'
                            },

                            api_token: localStorage.getItem('userTOKEN'),
                            farm_id: localStorage.getItem('current_editting_farm'),
                            data: data,


                        })
                        .then((response) => {
                            console.log(response);
                            next();
                            toast.configure({
                                position: "top-left",
                                rtl: true,
                                hideProgressBar: true,
                                toastClassName: 'success-notifier-toast',
                            });

                            toast("اصلاعات آبیاری باغ شما با موفقیت ویرایش شد.");
                            this.setState({ form_is_done: true })

                        })
                        .catch((error) => {
                            next(false, 'مجددا تلاش نمایید!');
                            sendLog(error.response);
                        })
                        .finally(function () {
                            // always executed
                        });
                }
            }
        }
    }


    pvcIsChosen = () => {
        this.setState({

            gharghabi_watering_duration_in_days: null,
            gharghabi_water_goes_by_tree_body: null,
            gharghabi_watering_band_margin: null,
            gharghabi_routin_watering_start_date: null,
            gharghabi_routin_watering_end_date: null,
            gharghabi_watered_area_unit: null,
            gharghabi_watered_area: null,
            gharghabi_watering_band_width: null,
            gharghabi_watering_row_length: null,
            gharghabi_watering_row_count: null,
            gharghabi_watering_time_lenght_unit: null,
            gharghabi_watering_time_lenght: null,
            system_type: null,
            water_pipes_distance_from_trees: null,
            water_pipes_depth_in_soil_water_drops_under: null,
            water_drops_distance_from_each_other: null,
            water_drops_debi: null,
            // water_dropper_type_image: null,
            watering_duration_in_days_234: null,
            water_dropper_type_234: null,
            routin_234_watering_start_date: null,
            routin_234_watering_end_date: null,
            each_time_watered_area_234_unit: null,
            each_time_watered_area_234: null,
            each_time_watered_time_length_unit_234: null,
            each_time_watered_time_length_234: null,
        })
    }

    third_MethodChosen = () => {
        this.setState({
            gharghabi_watering_duration_in_days: null,
            gharghabi_water_goes_by_tree_body: null,
            gharghabi_watering_band_margin: null,
            gharghabi_routin_watering_start_date: null,
            gharghabi_routin_watering_end_date: null,
            gharghabi_watered_area_unit: null,
            gharghabi_watered_area: null,
            gharghabi_watering_band_width: null,
            gharghabi_watering_row_length: null,
            gharghabi_watering_row_count: null,
            gharghabi_watering_time_lenght_unit: null,
            gharghabi_watering_time_lenght: null,


            watering_method_pvc_type: null,
            watering_method_pvc_pipe_diametere: null,
            watering_method_pvc_pipe_depth: null,
            watering_method_pvc_pipe_valves_distance: null,
            watering_method_pvc_pipe_valves_diameter: null,
            watering_method_pvc_pipe_distance_from_trees: null,
            watering_duration_in_days_PVC: null,
            PVC_paye_derakhtan_poshte: null,
            PVC_distance_from_trees_operation: null,
            routin_PVC_watering_start_date: null,
            routin_PVC_watering_end_date: null,
            each_time_PVC_watered_area_unit: null,
            each_time_PVC_watered_area: null,
            pvc_farm_row_count: null,
            pvc_trees_distance_on_row: null,
            // PVC_paye_derakhtan_poshte_image: null,
            trees_distance: null,
            pvc_trees_row_length: null,
            watered_trees_row_count: null,
            each_time_PVC_watered_time_lenghth_unit: null,
            each_time_PVC_watered_time_lenghth: null,
        })
    }

    Two_and_four_MethodChosen = () => {
        this.setState({
            water_pipes_depth_in_soil_water_drops_under: null,
            gharghabi_watering_duration_in_days: null,
            gharghabi_water_goes_by_tree_body: null,
            gharghabi_watering_band_margin: null,
            gharghabi_routin_watering_start_date: null,
            gharghabi_routin_watering_end_date: null,
            gharghabi_watered_area_unit: null,
            gharghabi_watered_area: null,
            gharghabi_watering_band_width: null,
            gharghabi_watering_row_length: null,
            gharghabi_watering_row_count: null,
            gharghabi_watering_time_lenght_unit: null,
            gharghabi_watering_time_lenght: null,


            watering_method_pvc_type: null,
            watering_method_pvc_pipe_diametere: null,
            watering_method_pvc_pipe_depth: null,
            watering_method_pvc_pipe_valves_distance: null,
            watering_method_pvc_pipe_valves_diameter: null,
            watering_method_pvc_pipe_distance_from_trees: null,
            watering_duration_in_days_PVC: null,
            PVC_paye_derakhtan_poshte: null,
            PVC_distance_from_trees_operation: null,
            pvc_farm_row_count: null,
            pvc_trees_distance_on_row: null,
            routin_PVC_watering_start_date: null,
            routin_PVC_watering_end_date: null,
            each_time_PVC_watered_area_unit: null,
            each_time_PVC_watered_area: null,
            // PVC_paye_derakhtan_poshte_image: null,
            trees_distance: null,
            pvc_trees_row_length: null,
            watered_trees_row_count: null,
            each_time_PVC_watered_time_lenghth_unit: null,
            each_time_PVC_watered_time_lenghth: null,
        })
    }

    gharghabiMethodIsChosen = () => {

        this.setState({

            system_type: null,
            water_pipes_distance_from_trees: null,
            water_pipes_depth_in_soil_water_drops_under: null,
            water_drops_distance_from_each_other: null,
            water_drops_debi: null,
            // water_dropper_type_image: null,
            watering_duration_in_days_234: null,
            water_dropper_type_234: null,
            routin_234_watering_start_date: null,
            routin_234_watering_end_date: null,
            each_time_watered_area_234_unit: null,
            each_time_watered_area_234: null,
            each_time_watered_time_length_unit_234: null,
            each_time_watered_time_length_234: null,
            watering_method_pvc_type: null,
            watering_method_pvc_pipe_diametere: null,
            watering_method_pvc_pipe_depth: null,
            watering_method_pvc_pipe_valves_distance: null,
            watering_method_pvc_pipe_valves_diameter: null,
            watering_method_pvc_pipe_distance_from_trees: null,
            watering_duration_in_days_PVC: null,
            PVC_paye_derakhtan_poshte: null,
            PVC_distance_from_trees_operation: null,
            pvc_farm_row_count: null,
            pvc_trees_distance_on_row: null,
            routin_PVC_watering_start_date: null,
            routin_PVC_watering_end_date: null,
            each_time_PVC_watered_area_unit: null,
            each_time_PVC_watered_area: null,
            //  PVC_paye_derakhtan_poshte_image: null,
            trees_distance: null,
            pvc_trees_row_length: null,
            watered_trees_row_count: null,
            each_time_PVC_watered_time_lenghth_unit: null,
            each_time_PVC_watered_time_lenghth: null,
        })
    }

    openWater_dropper_type_234_modal = () => {
        if (this.state.water_dropper_type_image != null) {
            axios.get('https://api.pestekaran.com/api/file/' + this.state.water_dropper_type_image,
                {

                    params: {
                        api_token: localStorage.getItem('userTOKEN'),
                        id: parseInt(localStorage.getItem('current_editting_farm')),
                    }
                    ,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json'
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    this.setState({ water_dropper_type_image_url: response.data });
                    var temp = [];
                    temp[0] = this.state.water_dropper_type_image_url;
                    this.setState({ images: temp })
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
            this.setState({
                water_dropper_type_234_modal: true,
                show_image_upload_button: 'none',
                show_image_edit_button: 'block'
            });
        }
        else {
            this.setState({
                water_dropper_type_234_modal: true,
                show_image_upload_button: 'block',
                show_image_edit_button: 'none'
            });
        }
    }
    openPVC_paye_derakhtan_poshte_image = () => {
        if (this.state.PVC_paye_derakhtan_poshte_image != null) {
            axios.get('https://api.pestekaran.com/api/file/' + this.state.PVC_paye_derakhtan_poshte_image,
                {

                    params: {
                        api_token: localStorage.getItem('userTOKEN'),
                        id: parseInt(localStorage.getItem('current_editting_farm')),
                    }
                    ,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json'
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    this.setState({ PVC_paye_derakhtan_poshte_image_url: response.data });
                    var temp = [];
                    temp[0] = this.state.PVC_paye_derakhtan_poshte_image_url;
                    this.setState({ images: temp })
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
            this.setState({
                PVC_paye_derakhtan_poshte_modal: true,
                show_image_upload_button: 'none',
                show_image_edit_button: 'block'
            });
        }
        else {
            this.setState({
                PVC_paye_derakhtan_poshte_modal: true,
                show_image_upload_button: 'block',
                show_image_edit_button: 'none'
            });
        }
    }
    render() {

        if (!this.state.loaded) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loading />
                </div>
            );
        }
        else if (this.state.loaded) {



            var UploadIndicator;
            if (this.state.image_selected == true) {
                if (this.state.uploader_volume != 100) {
                    UploadIndicator =
                        <div className='circularWait' style={{ width: '15vw', height: '15vw', marginRight: 0 }}>
                            <CircularProgressbar value={this.state.uploader_volume} text={`${this.state.uploader_volume}%`} />
                        </div>;
                }

                if (this.state.uploader_volume >= 98) {
                    UploadIndicator =
                        <div className='circularWait' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '15vw', height: '15vw', marginRight: 0 }}>
                            <CheckRoundedIcon />
                            <div>انجام شد!</div>

                        </div>;
                }

            } else if (this.state.image_selected == false) {

                UploadIndicator = <div></div>;

            }

            if (!this.state.form_is_done) {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

                        <div style={{ // ***************************************** header ***********************************************************************************
                            position: 'absolute',
                            backgroundColor: '#007bffa8',
                            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                            width: '160vw',
                            height: '80vw',
                            borderRadius: '80vw / 40vw',
                            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                            left: '-32%',
                            top: '-20%'
                        }}
                        >
                        </div>


                        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                آبیاری
            </div>
                        </div>

                        <Link style={{ color: 'white' }} to={{
                            pathname: '/FarmsEdit',
                            state: {
                                name: localStorage.getItem('current_editting_farm_name'),
                                farm_id: localStorage.getItem('current_editting_farm')
                            }
                        }}>
                            <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                            </div>
                        </Link>

                        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Slide down>
                                <div style={{ // ***************************************** Top card *****************************************************************************
                                    boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                    display: 'flex',
                                    backgroundColor: '#1D75FA',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 8,
                                    width: '80vw',
                                    height: '20vh',
                                    marginTop: '9vh',
                                    borderRadius: 40,
                                    position: 'relative'
                                }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                            <div>
                                                <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                            </div>
                                            <div style={{ marginRight: 8 }}>
                                                {this.state.farms_count}
                                            </div>
                                        </div>

                                    </div>


                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                                        <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                            در این بخش می توانید اطلاعات آبیاری باغ خود را مشاهده یا ویرایش کنید.
                  </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>


                        <div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>




                            {/*Modal 1 *********************مشخصات کلی سیستم آبیاری******************************************/}
                            <div className="wateringItems" onClick={() => this.setState({ water_source_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_1_color }} />مشخصات کلی سیستم آبیاری</div>
                            <Modal visible={this.state.water_source_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.setState({ water_source_modal: false })}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={this.closeModal} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>مشخصات کلی سیستم آبیاری:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>
                                    <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                        <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                            <div>
                                                منبع آب:
                                </div>
                                            <Select
                                                className=""
                                                onChange={(newValue) => {
                                                    if (newValue.value == 2 || newValue.value == 3) {
                                                        this.setState({
                                                            water_source: newValue.label,
                                                            water_source_display: 'block',
                                                        })
                                                    }
                                                    else {
                                                        this.setState({
                                                            water_source: newValue.label,
                                                            water_source_display: 'none',
                                                        })
                                                    }
                                                }}
                                                style={{ textAlign: 'center', height: 35 }}
                                                options={[
                                                    { value: '1', label: 'قنات' },
                                                    { value: '2', label: 'چاه نیمه عمیق' },
                                                    { value: '3', label: 'چاه عمیق' },
                                                ]}
                                                placeholder={this.state.water_source}
                                            />
                                        </div>

                                        <div style={{ display: this.state.water_source_display }}>
                                            <TextInput inputName='عمق چاه:' onChange={value => { this.setState({ well_depth: value }) }} initialValue={this.state.well_depth} InputPlaceHolder='عمق چاه (متر)' />

                                            <TextInput inputName=' نام  چاه:' onChange={value => { this.setState({ well_name: value }) }} initialValue={this.state.well_name} InputPlaceHolder='' />
                                        </div>
                                        <TextInput inputName='تلمبه یا چاه چند دانگ است؟'

                                            onChange={value => { this.setState({ well_dong_count: value }) }}
                                            initialValue={this.state.well_dong_count} InputPlaceHolder='برای مثال: 6' />


                                        <TextInput inputName='میزان برداشت از منبع آب (چاه/قنات):'
                                            onChange={value => { this.setState({ water_debi_volume: value }) }}

                                            initialValue={this.state.water_debi_volume} InputPlaceHolder='5 (لیتر بر ثانیه)' />


                                        <TextInput inputName='وضعیت حقابه (تعداد سهم):'
                                            onChange={value => { this.setState({ water_share_count: value }) }}

                                            initialValue={this.state.water_share_count} InputPlaceHolder='برای مثال: 2 حبه' />


                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_1_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }} href="javascript:void(0);" onClick={() => this.setState({ water_source_modal: false })}>ثبت</a></div>
                                    </div>

                                </div>
                            </Modal>

                            {/*Modal 2 **********************************کیفیت منبع آب*****************************/}
                            <div className="wateringItems" onClick={() => this.setState({ water_source_quality_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_2_color }} />کیفیت منبع آب</div>
                            <Modal visible={this.state.water_source_quality_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.setState({ water_source_quality_modal: false })}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>کیفیت منبع آب:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                        <div style={{ margin: 5, width: "100%", textAlign: "center" }}>مطابق با راهنمای زیر انتخاب کنید، دقت نماید واحد بر حسب میکروموس بر سانتی متر است.</div>
                                        <img style={{ textAlign: 'center', marginBottom: 20 }} src={require('../FarmDetails/watersource.jpg')} width='200' height='200' />
                                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Select
                                                style={{ textAlign: 'center', height: 35 }}
                                                onChange={console.log('hereis')}
                                                isRtl={true}
                                                options={[
                                                    { value: '1', label: 'شیرین' },
                                                    { value: '2', label: 'کم شور' },
                                                    { value: '3', label: 'لب شور' },
                                                    { value: '4', label: 'شور' },
                                                    { value: '5', label: 'خیلی شور' },
                                                ]}
                                                placeholder={this.state.water_source_quality}
                                                onChange={value => {
                                                    this.setState({ water_source_quality: value.label })
                                                }}
                                            />
                                        </div>

                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_2_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            {/*Modal 3 **********************روش یا سیستم آبیاری*****************************************/}
                            <div className="wateringItems" onClick={() => this.setState({ watering_method_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_3_color }} />روش یا سیستم آبیاری</div>
                            <Modal visible={this.state.watering_method_modal} width="90%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ watering_method_modal: false })}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.setState({ watering_method_modal: false })} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>روش یا سیستم آبیاری:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>
                                    <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                        <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                            <div>
                                                روش آبیاری:
                                </div>
                                            <Select
                                                className=""
                                                onChange={(value) => {
                                                    this.setState({ watering_method: value.label })
                                                    if (value.value == 1) { this.gharghabiMethodIsChosen(); this.setState({ isGharghabi: true, isWater_drop_and_bubbler: false, isPVC: false }) }
                                                    if (value.value == 2 | value.value == 4) { this.Two_and_four_MethodChosen(); this.setState({ isWater_drop_and_bubbler: true, isGharghabi: false, isPVC: false, water_pipe_depth_show_hide_state: false }) }
                                                    if (value.value == 5) { this.pvcIsChosen(); this.setState({ isPVC: true, isWater_drop_and_bubbler: false, isGharghabi: false, }) }
                                                    if (value.value == 3) { this.third_MethodChosen(); this.setState({ isWater_drop_and_bubbler: true, isGharghabi: false, isPVC: false, water_pipe_depth_show_hide_state: true }) }
                                                }}
                                                style={{ textAlign: 'center', height: 35 }}
                                                options={[
                                                    { value: '1', label: 'غرقابی' },
                                                    { value: '2', label: 'قطره ای سطحی' },
                                                    { value: '3', label: 'قطره ای زیر سطحی' },
                                                    { value: '4', label: 'بابلر' },
                                                    { value: '5', label: 'زیر سطحی با لوله های پی وی سی' },
                                                    { value: '6', label: 'سایر' },
                                                ]}
                                                placeholder={this.state.watering_method}
                                            />
                                        </div>


                                        <textarea style={{ borderRadius: 5 }}
                                            onChange={value => {
                                                this.setState({ watering_method_explained: value.target.value })
                                            }}
                                            placeholder="در صورت انتخاب سایر توضیح دهید ..."
                                            className='modal-ex-text'
                                            value={this.state.watering_method_explained}
                                            rows="5"
                                            cols="100">
                                        </textarea>


                                    </div>

                                    <div onClick={() => {
                                        if (this.state.water_pipe_depth_show_hide_state == true) { this.setState({ water_pipe_depth_show_hide: 'block' }) }
                                        if (this.state.water_pipe_depth_show_hide_state == false) { this.setState({ water_pipe_depth_show_hide: 'none' }) }
                                        if (this.state.isGharghabi == true) { this.setState({ showGharghabi: 'block', showWater_drop_and_bubbler: 'none', showPVC: 'none' }) }
                                        if (this.state.isWater_drop_and_bubbler == true) { this.setState({ showWater_drop_and_bubbler: 'block', showGharghabi: 'none', showPVC: 'none' }) }
                                        if (this.state.isPVC == true) { this.setState({ showPVC: 'block', showWater_drop_and_bubbler: 'none', showGharghabi: 'none' }) }
                                        this.setState({ watering_method_modal: false, modal_3_color: 'rgb(29, 117, 250)' })
                                    }}
                                        style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>


                            <div style={{ display: this.state.showGharghabi/* gharghabi chosen ********************************************************************************************* */ }}>

                                {/*Modal 4 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ watering_duration_in_days_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_4_color }} />دور آبیاری</div>
                                <Modal visible={this.state.watering_duration_in_days_modal} width="95%" height="80%" effect="fadeInUp" onClickAway={() => this.setState({ watering_duration_in_days_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>دور آبیاری:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>

                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                            <TextInput inputName='دور آبیاری (روز):'

                                                onChange={value => { this.setState({ gharghabi_watering_duration_in_days: value }) }}
                                                initialValue={this.state.gharghabi_watering_duration_in_days} InputPlaceHolder='برای مثال: 30' />

                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    آب پای تنه درخت می رود:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={(value) => {
                                                        this.setState({ gharghabi_water_goes_by_tree_body: value.label })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'می رود' },
                                                        { value: '2', label: 'نمی رود' },
                                                    ]}
                                                    placeholder={this.state.gharghabi_water_goes_by_tree_body}
                                                />
                                            </div>
                                            <TextInput inputName='عرض نوار آبیاری از هر طرف درختان:'

                                                onChange={value => {
                                                    this.setState({ gharghabi_watering_band_margin: value })
                                                }}
                                                initialValue={this.state.gharghabi_watering_band_margin} InputPlaceHolder='مثال: 2 (متر)' />

                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_4_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>

                                {/*Modal 5 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ routin_watering_start_date_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_5_color }} />تاریخ آبیاری های معمول</div>
                                <Modal visible={this.state.routin_watering_start_date_modal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ routin_watering_start_date_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>تاریخ آبیاری های معمول:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>


                                            <DatPickerEditable
                                                inputName='تاریخ شروع (برای مثال 15 اسفند):'
                                                on_date_edit={value => {
                                                    this.setState({ gharghabi_routin_watering_start_date: value })
                                                }}
                                                onChange={value => {
                                                    this.setState({ gharghabi_routin_watering_start_date: value })
                                                }}
                                                initialValue={this.state.gharghabi_routin_watering_start_date} />



                                            <DatPickerEditable
                                                inputName='تاریخ پایان (برای مثال 10 مهر):'
                                                on_date_edit={value => {
                                                    this.setState({ gharghabi_routin_watering_end_date: value })
                                                }}
                                                onChange={value => {
                                                    this.setState({ gharghabi_routin_watering_end_date: value })
                                                }}
                                                initialValue={this.state.gharghabi_routin_watering_end_date} />

                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_5_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>


                                {/*Modal 6 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ each_time_watered_area_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_6_color }} />مساحت و مدت آبیاری در هر نوبت</div>
                                <Modal visible={this.state.each_time_watered_area_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.setState({ each_time_watered_area_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>مساحت و مدت آبیاری:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overflow: 'auto' }}>
                                            {/** 
                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    واحد مساحت:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ gharghabi_watered_area_unit: value.value })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'هکتار' },
                                                        { value: '2', label: 'متر مربع' },
                                                        { value: '3', label: 'قصب' },
                                                    ]}
                                                    placeholder={this.state.gharghabi_watered_area_unit}
                                                />
                                            </div>

                                            <TextInput inputName='مساحت آبیاری در هر نوبت آبیاری:'
                                                onChange={value => {
                                                    this.setState({ gharghabi_watered_area: value })
                                                }}
                                                initialValue={this.state.gharghabi_watered_area} InputPlaceHolder='برای مثال 120' />



                                            <div style={{ marginTop: 15, marginTop: 15 }}>
                                                <div style={{ marginBottom: 5, textAlign: "center", color: 'red' }}>تنها در صورتی که به طور مشخص مساحت آبیاری را نمی دانید به سوالات زیر پاسخ دهید:</div>

                                            </div>

*/}

                                            <TextInput inputName='عرض نوار آبیاری از هر طرف درختان:'
                                                onChange={value => {
                                                    console.log(value);
                                                    this.setState({ gharghabi_watered_area_is_not_explicit: true, gharghabi_watering_band_width: value });
                                                }}

                                                initialValue={this.state.gharghabi_watering_band_width} InputPlaceHolder='برای مثال 1 (متر)' />



                                            <TextInput inputName='طول ردیف ها:'
                                                onChange={value => {
                                                    this.setState({ gharghabi_watering_row_length: value });
                                                }}

                                                initialValue={this.state.gharghabi_watering_row_length} InputPlaceHolder='برای مثال 50 (متر)' />



                                            <TextInput inputName='تعداد کل ردیف ها در هر نوبت آبیاری:'
                                                onChange={value => {
                                                    this.setState({ gharghabi_watering_row_count: value });
                                                }}

                                                initialValue={this.state.gharghabi_watering_row_count} InputPlaceHolder='برای مثال 10 (ردیف)' />



                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    واحد زمان:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ gharghabi_watering_time_lenght_unit: value.value })
                                                        console.log(this.state.gharghabi_watered_area_is_not_explicit)
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'ساعت' },
                                                        { value: '2', label: 'دقیقه' },
                                                    ]}
                                                    placeholder={this.state.gharghabi_watering_time_lenght_unit}
                                                />
                                            </div>

                                            <TextInput inputName='مدت زمان:'
                                                onChange={value => {
                                                    this.setState({ gharghabi_watering_time_lenght: value })
                                                }}

                                                initialValue={(this.state.gharghabi_watering_time_lenght)} InputPlaceHolder='برای مثال 4' />


                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_6_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>


                                {/*Modal 7 ***********************************************************
                                <div className="wateringItems" onClick={() => this.setState({ gharghabi_watering_area_eache_time_modaal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_7_color }} />مدت آبیاری در هر نوبت</div>
                                <Modal visible={this.state.gharghabi_watering_area_eache_time_modaal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ gharghabi_watering_area_eache_time_modaal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>مدت آبیاری در هر نوبت:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_7_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>
****/}


                            </div>



                            <div style={{ display: this.state.showWater_drop_and_bubbler/* Water_drop_and_bubbler chosen ********************************************************************************************* */ }}>

                                {/*Modal 8 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ watering_duration_in_days_234_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_8_color }} />دور آبیاری</div>
                                <Modal visible={this.state.watering_duration_in_days_234_modal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ watering_duration_in_days_234_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>دور آبیاری:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                            <TextInput inputName='دور آبیاری (روز):'
                                                onChange={value => {
                                                    this.setState({ watering_duration_in_days_234: value })
                                                }}

                                                initialValue={this.state.watering_duration_in_days_234} InputPlaceHolder='برای مثال: 12' />

                                            <div style={{ display: this.state.water_pipe_depth_show_hide }}>
                                                <TextInput inputName='اگر سیستم آبیاری شما قطره ای زیر سطحی می باشد، عمق جایگذاری لوله ها در خاک:'
                                                    onChange={value => { this.setState({ water_pipes_depth_in_soil_water_drops_under: value }) }}

                                                    initialValue={this.state.water_pipes_depth_in_soil_water_drops_under} InputPlaceHolder='مثال: 50 (ساتی متر)' />
                                            </div>
                                        </div>

                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_8_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>                                </div>
                                </Modal>

                                {/*Modal 9 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ watering_method_234_details_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_9_color }} />مشخصات سیستم آبیاری انتخاب شده</div>
                                <Modal visible={this.state.watering_method_234_details_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.setState({ watering_method_234_details_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>مشخصات سیستم آبیاری انتخاب شده:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>


                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>


                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    نوع سیستم:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={(value) => {
                                                        this.setState({ system_type: value.label })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'یک خط لوله در هر طرف درختان' },
                                                        { value: '2', label: 'دو خط لوله در هر طرف درختان' },
                                                    ]}
                                                    placeholder={this.state.system_type}
                                                />
                                            </div>


                                            <TextInput inputName='فاصله خط لوله از درختان:'
                                                onChange={value => { this.setState({ water_pipes_distance_from_trees: value }) }}

                                                initialValue={this.state.water_pipes_distance_from_trees} InputPlaceHolder='مثال: 40 (ساتی متر)' />


                                            <TextInput inputName='فاصله قطره چکان ها:'
                                                onChange={value => { this.setState({ water_drops_distance_from_each_other: value }) }}

                                                initialValue={this.state.water_drops_distance_from_each_other} InputPlaceHolder='مثال: 80 (ساتی متر)' />

                                            <TextInput inputName='دبی هر قطره چکان:'
                                                onChange={value => { this.setState({ water_drops_debi: value }) }}

                                                initialValue={this.state.water_drops_debi} InputPlaceHolder='مثال: 4 (لیتر بر ساعت)' />


                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_9_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>


                                {/*Modal 10 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.openWater_dropper_type_234_modal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_10_color }} />نوع قطره چکان ها</div>
                                <Modal visible={this.state.water_dropper_type_234_modal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ water_dropper_type_234_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>نوع قطره چکان ها:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>

                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>
                                            <textarea
                                                style={{ borderRadius: 5 }}
                                                onChange={value => {
                                                    this.setState({ water_dropper_type_234: value.target.value })
                                                }}
                                                placeholder="توضیحات ..."
                                                value={this.state.swater_dropper_type_234}
                                                className='modal-ex-text'
                                                rows="5"
                                                cols="100">
                                            </textarea>

                                            <div style={{ display: this.state.show_image_upload_button }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                                    <label className="addImageContainer">
                                                        <div style={{ display: 'flex', width: '20%' }}>
                                                            <CropOriginalIcon style={{ color: 'white' }} />
                                                        </div>
                                                        <div style={{ width: '60%' }}>
                                                            <input type="file" id="myfiles" class="hidden-input" onChange={this.handle_water_dropper_type_ImageSelect} />
                                                            <label style={{ marginBottom: 0 }} for="myfiles" style={{ color: 'white' }}>افزودن تصویر </label>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>

                                            <div style={{ display: this.state.show_image_edit_button }}>
                                                <div className="disease-image-edit-block">
                                                    <div style={{ width: '10%' }}>
                                                        <label for="files"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                                                        <input id="files" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                                                            onChange={this.handle_water_dropper_type_ImageSelect} />
                                                    </div>

                                                    <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.water_dropper_type_image_url} />
                                                    </div>
                                                </div>
                                                <a href={this.state.water_dropper_type_image_url}>مشاهده تصویر</a>
                                            </div>


                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_10_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>                                </div>
                                </Modal>

                                {/*Modal 11 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ routin_234_watering_start_date_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_11_color }} />تاریخ آبیاری های معمول</div>
                                <Modal visible={this.state.routin_234_watering_start_date_modal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ routin_234_watering_start_date_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>تاریخ آبیاری های معمول:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>

                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                            <DatPickerEditable
                                                inputName='تاریخ شروع (برای مثال 15 اسفند):'
                                                on_date_edit={value => {
                                                    this.setState({ routin_234_watering_start_date: value })
                                                }}
                                                onChange={value => {
                                                    this.setState({ routin_234_watering_start_date: value })
                                                }}
                                                initialValue={this.state.routin_234_watering_start_date} />



                                            <DatPickerEditable
                                                inputName='تاریخ پایان (برای مثال 10 مهر):'
                                                on_date_edit={value => {
                                                    this.setState({ routin_234_watering_end_date: value })
                                                }}
                                                onChange={value => {
                                                    this.setState({ routin_234_watering_end_date: value })
                                                }}
                                                initialValue={this.state.routin_234_watering_end_date} />


                                        </div>

                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_11_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>                                </div>
                                </Modal>





                                {/*Modal 12 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ each_time_watered_area_234_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_12_color }} />مساحت و مدت آبیاری</div>
                                <Modal visible={this.state.each_time_watered_area_234_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.setState({ each_time_watered_area_234_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>مساحت و مدت آبیاری:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>


                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    واحد مساحت:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ each_time_watered_area_234_unit: value.value })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'هکتار' },
                                                        { value: '2', label: 'متر مربع' },
                                                        { value: '3', label: 'قصب' },
                                                    ]}
                                                    placeholder={this.state.each_time_watered_area_234_unit}
                                                />
                                            </div>


                                            <TextInput inputName='مساحت آبیاری در هر شیفت آبیاری:'
                                                onChange={value => {
                                                    this.setState({ each_time_watered_area_234: value });
                                                }}
                                                initialValue={this.state.each_time_watered_area_234} InputPlaceHolder='برای مثال 120' />




                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    واحد زمان:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ each_time_watered_time_length_unit_234: value.value })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'ساعت' },
                                                        { value: '2', label: 'دقیقه' },
                                                    ]}
                                                    placeholder={this.state.each_time_watered_time_length_unit_234}
                                                />
                                            </div>


                                            <TextInput inputName='مدت زمان:'
                                                onChange={value => {
                                                    this.setState({ each_time_watered_time_length_234: value });
                                                }}

                                                initialValue={(this.state.each_time_watered_time_length_234)} InputPlaceHolder='برای مثال 10 (ساعت)' />

                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_12_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>                                </div>
                                </Modal>
                            </div>


                            <div style={{ display: this.state.showPVC }} /* PVC chosen ***********************************************************************************/>

                                {/*Modal 13 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ watering_method_pvc_details_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_13_color }} />مشخصات سیستم آبیاری انتخاب شده</div>
                                <Modal visible={this.state.watering_method_pvc_details_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.setState({ watering_method_pvc_details_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>مشخصات سیستم آبیاری انتخاب شده:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>


                                            <TextInput inputName='قطر لوله ها:'
                                                onChange={value => {
                                                    this.setState({ watering_method_pvc_pipe_diametere: value })
                                                }}

                                                initialValue={this.state.watering_method_pvc_pipe_diametere} InputPlaceHolder='مثال: 110 (میلی متر)' />

                                            <TextInput inputName='عمق کارگذاری لوله:'
                                                onChange={value => {
                                                    this.setState({ watering_method_pvc_pipe_depth: value })
                                                }}

                                                initialValue={this.state.watering_method_pvc_pipe_depth} InputPlaceHolder='مثال: 50 (ساتی متر)' />

                                            <TextInput inputName='فاصله سوراخ های روی لوله:'
                                                onChange={value => {
                                                    this.setState({ watering_method_pvc_pipe_valves_distance: value })
                                                }}

                                                initialValue={this.state.watering_method_pvc_pipe_valves_distance} InputPlaceHolder='مثال: 20 (ساتی متر)' />

                                            <TextInput inputName='قطر سوراخ های روی لوله:'
                                                onChange={value => {
                                                    this.setState({ watering_method_pvc_pipe_valves_diameter: value })
                                                }}

                                                initialValue={this.state.watering_method_pvc_pipe_valves_diameter} InputPlaceHolder='مثال: 4 (میلی  لیتر)' />

                                            <TextInput inputName='فاصله لوله ها از درخت:'
                                                onChange={value => {
                                                    this.setState({ watering_method_pvc_pipe_distance_from_trees: value })
                                                }}

                                                initialValue={this.state.watering_method_pvc_pipe_distance_from_trees} InputPlaceHolder='مثال: 40 (سانتی  متر)' />


                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_13_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>

                                {/*Modal 14 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ watering_duration_in_days_PVC_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_14_color }} />دور آبیاری</div>
                                <Modal visible={this.state.watering_duration_in_days_PVC_modal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ watering_duration_in_days_PVC_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>دور آبیاری:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>


                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    نوع سیستم:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ watering_method_pvc_type: value.label })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'یک طرف درختان' },
                                                        { value: '2', label: 'دو طرف درختان' },
                                                    ]}
                                                    placeholder={this.state.watering_method_pvc_type}
                                                />
                                            </div>

                                            <TextInput inputName='دور آبیاری (روز):'
                                                onChange={value => {
                                                    this.setState({ watering_duration_in_days_PVC: value })
                                                }}

                                                initialValue={this.state.watering_duration_in_days_PVC} InputPlaceHolder='برای مثال: 20' />

                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_14_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>

                                {/*Modal 15 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.openPVC_paye_derakhtan_poshte_image()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_15_color }} />پای تنه درختان پشته (خاک)</div>
                                <Modal visible={this.state.PVC_paye_derakhtan_poshte_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.setState({ PVC_paye_derakhtan_poshte_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>پای تنه درختان پشته (خاک):</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>

                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    پای تنه درختان پشته (خاک):
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ PVC_paye_derakhtan_poshte: value.label })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'وجود دارد' },
                                                        { value: '2', label: 'وجود ندارد' },
                                                    ]}
                                                    placeholder={this.state.PVC_paye_derakhtan_poshte}
                                                />
                                            </div>

                                            <TextInput inputName='عرض نوار عملیات کشت و کار از هر طرف درختان:'
                                                onChange={value => {
                                                    this.setState({ PVC_distance_from_trees_operation: value })
                                                }}

                                                initialValue={this.state.PVC_distance_from_trees_operation} InputPlaceHolder='مثال: 2 ( متر)' />






                                            <div style={{ display: this.state.show_image_upload_button }} >
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                                    <label className="addImageContainer">
                                                        <div style={{ display: 'flex', width: '20%' }}>
                                                            <CropOriginalIcon style={{ color: 'white' }} />
                                                        </div>
                                                        <div style={{ width: '60%' }}>
                                                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_PVC_paye_derakhtan_poshte_ImageSelect} />
                                                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>


                                            <div style={{ display: this.state.show_image_edit_button }}>
                                                <div className="disease-image-edit-block">
                                                    <div style={{ width: '10%' }}>
                                                        <label for="files"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                                                        <input id="files" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                                                            onChange={this.handle_PVC_paye_derakhtan_poshte_ImageSelect} />
                                                    </div>

                                                    <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.PVC_paye_derakhtan_poshte_image_url} />
                                                    </div>
                                                </div>
                                                <a href={this.state.PVC_paye_derakhtan_poshte_image_url}>مشاهده تصویر</a>
                                            </div>


                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_15_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>

                                {/*Modal 16 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ routin_PVC_watering_start_date_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_16_color }} />تاریخ آبیاری های معمول</div>
                                <Modal visible={this.state.routin_PVC_watering_start_date_modal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ routin_PVC_watering_start_date_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>تاریخ آبیاری های معمول:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                            <DatPickerEditable
                                                inputName='تاریخ شروع (برای مثال 15 اسفند):'
                                                on_date_edit={value => {
                                                    this.setState({ routin_PVC_watering_start_date: value })
                                                }}
                                                onChange={value => {
                                                    this.setState({ routin_PVC_watering_start_date: value })
                                                }}
                                                initialValue={this.state.routin_PVC_watering_start_date} />


                                            <DatPickerEditable
                                                inputName='تاریخ پایان (برای مثال 10 مهر):'
                                                on_date_edit={value => {
                                                    this.setState({ routin_PVC_watering_end_date: value })
                                                }}
                                                onChange={value => {
                                                    this.setState({ routin_PVC_watering_end_date: value })
                                                }}
                                                initialValue={this.state.routin_PVC_watering_end_date} />

                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_16_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>

                                {/*Modal 17 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ each_time_PVC_watered_area_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_17_color }} />مساحت و مدت آبیاری در هر نوبت</div>
                                <Modal visible={this.state.each_time_PVC_watered_area_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.setState({ each_time_PVC_watered_area_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>مساحت و مدت آبیاری:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overflow: 'auto' }}>
                                            {/**
                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    واحد مساحت:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ each_time_PVC_watered_area_unit: value.value })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'هکتار' },
                                                        { value: '2', label: 'متر مربع' },
                                                        { value: '3', label: 'قصب' },
                                                    ]}
                                                    placeholder={this.state.each_time_PVC_watered_area_unit}
                                                />
                                            </div>

                                            <TextInput inputName='مساحت آبیاری در هر نوبت آبیاری:'
                                                onChange={value => {
                                                    this.setState({ each_time_PVC_watered_area: value })
                                                }}
                                                initialValue={this.state.each_time_PVC_watered_area} InputPlaceHolder='برای مثال 120' />

                                            <div style={{ marginTop: 15 }}>
                                                <div style={{ marginBottom: 5, textAlign: "center", color: 'red' }}>در صورتی که به طور مشخص مساحت آبیاری شده را نمی دانید به سوالات زیر پاسح دهید.</div>
                                            </div>
 */}

                                            <TextInput inputName='طول ردیف ها:'
                                                onChange={value => {
                                                    this.setState({ pvc_trees_row_length: value });
                                                }}

                                                initialValue={this.state.pvc_trees_row_length} InputPlaceHolder='برای مثال 20 (متر)' />

                                            <TextInput inputName='فاصله درختان روی ردیف:'
                                                onChange={value => {
                                                    this.setState({ pvc_trees_distance_on_row: value });
                                                }}

                                                initialValue={this.state.pvc_trees_distance_on_row}
                                                InputPlaceHolder='برای مثال 3 (متر)' />

                                            <TextInput inputName='تعداد ردیف در باغ :'
                                                onChange={value => {
                                                    this.setState({ pvc_farm_row_count: value });
                                                }}

                                                initialValue={this.state.pvc_farm_row_count}
                                                InputPlaceHolder='برای مثال 25' />



                                            <TextInput inputName=' فاصله بين رديف درختان:'
                                                onChange={value => {
                                                    this.setState({ each_time_PVC_watered_is_not_explicit: true, trees_distance: value });
                                                }}

                                                initialValue={this.state.trees_distance} InputPlaceHolder='برای مثال 5 (متر)' />


                                            <TextInput inputName='تعداد رديفهاي درختان كه در هر نوبت آبياري مي شود:'
                                                onChange={value => {
                                                    this.setState({ watered_trees_row_count: value });
                                                }}

                                                initialValue={this.state.watered_trees_row_count} InputPlaceHolder='برای مثال 15 (ردیف)' />

                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    واحد زمان:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ each_time_PVC_watered_time_lenghth_unit: value.value })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'ساعت' },
                                                        { value: '2', label: 'دقیقه' },
                                                    ]}
                                                    placeholder={this.state.each_time_PVC_watered_time_lenghth_unit}
                                                />
                                            </div>

                                            <TextInput inputName='مدت زمان:'
                                                onChange={value => {
                                                    this.setState({ each_time_PVC_watered_time_lenghth: value })
                                                }}

                                                initialValue={(this.state.each_time_PVC_watered_time_lenghth)} InputPlaceHolder='برای مثال 4' />


                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_17_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>

                                {/*Modal 18 ***********************************************************
                                <div className="wateringItems" onClick={() => this.setState({ routin_PVC_watering_watered_area_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_18_color }} />مدت آبیاری در هر نوبت</div>
                                <Modal visible={this.state.routin_PVC_watering_watered_area_modal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ routin_PVC_watering_watered_area_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>مدت آبیاری در هر نوبت:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>


                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_18_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>

****/}




                            </div>

                            <hr style={{ marginTop: 32, marginBottom: 16, border: 0, height: 1, backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(121, 121, 121, 0.75), rgba(0, 0, 0, 0))' }} />

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center' }}>

                                <div style={{ width: '10%' }}></div>
                                <div style={{ width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>وضعیت آبشویی زمستانه</div>
                                    <div style={{ marginTop: 8 }}>(آب سنگین زمستانه)</div>
                                </div>
                                <div style={{ width: '10%' }}></div>

                            </div>



                            <div /*abshooooooooooooyi zemestane started ********************************************************************** */>
                                {/*Modal 19 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ winter_watering_count: true })}><AcUnitRoundedIcon style={{ marginLeft: 4, color: this.state.modal_19_color }} />تعداد و شیوه آبياري غرقابي زمستانه</div>
                                <Modal visible={this.state.winter_watering_count} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ winter_watering_count: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>تعداد و شیوه آبياري:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>

                                            <TextInput inputName='تعداد آبياري غرقابي زمستانه:'
                                                onChange={value => {
                                                    this.setState({ gharghabi_winter_watering_count: value })
                                                }}

                                                initialValue={this.state.gharghabi_winter_watering_count} InputPlaceHolder='2' />

                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    شیوه آبیاری:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ gharghabi_winter_watering_method: value.label })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'کل فاصله بین ردیف درختان آبیاری می شود' },
                                                        { value: '2', label: 'فقط نوار آبیاری دو طرف درختان آبیاری می شود' },
                                                    ]}
                                                    placeholder={this.state.gharghabi_winter_watering_method}
                                                />
                                            </div>

                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_19_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>

                                {/*Modal 20 ***************************************************************/}
                                <div className="wateringItems" onClick={() => this.setState({ each_time_winter_watered_area_modal: true })}><AcUnitRoundedIcon style={{ marginLeft: 4, color: this.state.modal_20_color }} />مساحت و مدت آبیاری در هر نوبت</div>
                                <Modal visible={this.state.each_time_winter_watered_area_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.setState({ each_time_winter_watered_area_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>مساحت و مدت آبیاری:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>
                                        <div style={{ height: '80%', padding: 16, overflow: 'auto' }}>

                                            {/** 
                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    واحد مساحت:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ each_time_winter_watered_area_unit: value.value })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'هکتار' },
                                                        { value: '2', label: 'متر مربع' },
                                                        { value: '3', label: 'قصب' },
                                                    ]}
                                                    placeholder={this.state.each_time_winter_watered_area_unit}
                                                />
                                            </div>

                                            <TextInput inputName='مساحت آبیاری در هر نوبت آبیاری:'
                                                onChange={value => {
                                                    this.setState({ each_time_winter_watered_area: value })
                                                }}
                                                initialValue={this.state.each_time_winter_watered_area} InputPlaceHolder='برای مثال 120' />


                                            <div style={{ marginTop: 15 }}>
                                                <div style={{ marginBottom: 12, textAlign: "center", color: 'red' }}>در صورتی که مساحت آبیاری را به طور مشخص نمی دانید به سوالات زیر پاسخ دهید.</div>

                                            </div>
*/}
                                            <TextInput inputName='عرض نوار آبیاری از هر طرف درختان:'
                                                onChange={value => {
                                                    this.setState({ each_time_winter_watered_band_width: value })
                                                }}

                                                initialValue={this.state.each_time_winter_watered_band_width} InputPlaceHolder='برای مثال 1 (متر)' />


                                            <TextInput inputName='طول ردیف ها:'
                                                onChange={value => {
                                                    this.setState({ each_time_winter_watered_rows_lenght: value })
                                                }}

                                                initialValue={this.state.each_time_winter_watered_rows_lenght} InputPlaceHolder='برای مثال 15 (متر)' />

                                            <TextInput inputName='تعداد رديفهاي درختان كه در هر نوبت آبياري مي شود:'
                                                onChange={value => {
                                                    this.setState({ each_time_winter_watered_rows_count: value })
                                                }}

                                                initialValue={this.state.each_time_winter_watered_rows_count} InputPlaceHolder='برای مثال ۱۰ (ردیف)' />

                                            <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                                <div>
                                                    واحد زمان:
                                </div>
                                                <Select
                                                    className=""
                                                    onChange={value => {
                                                        this.setState({ each_time_winter_watered_time_length_unit: value.value })
                                                    }}
                                                    style={{ textAlign: 'center', height: 35 }}
                                                    options={[
                                                        { value: '1', label: 'ساعت' },
                                                        { value: '2', label: 'دقیقه' },
                                                    ]}
                                                    placeholder={this.state.each_time_winter_watered_time_length_unit}
                                                />
                                            </div>


                                            <TextInput inputName='مدت زمان:'
                                                onChange={value => {
                                                    this.setState({ each_time_winter_watered_time_length: value })
                                                }}

                                                initialValue={(this.state.each_time_winter_watered_time_length)} InputPlaceHolder='برای مثال 4' />


                                        </div>
                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_20_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>

                                {/*Modal 21 ***********************************************************
                                <div className="wateringItems" onClick={() => this.setState({ winter_watering_time_modal: true })}><AcUnitRoundedIcon style={{ marginLeft: 4, color: this.state.modal_21_color }} />مدت آبیاری در هر نوبت</div>
                                <Modal visible={this.state.winter_watering_time_modal} width="95%" height="70%" effect="fadeInUp" onClickAway={() => this.setState({ winter_watering_time_modal: false })}>
                                    <div className="modal-overlay">

                                        <div className="modal-title-container">
                                            <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                            <div style={{ width: '80%', marginTop: 8 }}>مدت آبیاری در هر نوبت:</div>
                                            <div style={{ width: '10%' }}></div>
                                        </div>

                                        <div style={{ height: '80%', padding: 16, overFlow: 'auto' }}>


                                        </div>

                                        <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_21_color: 'rgb(29, 117, 250)' })
                                            }}>
                                            <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                        </div>
                                    </div>
                                </Modal>
****/}

                                {/*/ Validation Modal ***********************************************************************************/}
                                <Modal visible={this.state.form_is_completely_empty} width="80%" height="50%" effect="fadeInDown" onClickAway={() => { this.setState({ form_is_completely_empty: false }) }}>
                                    <div style={{ position: 'relative', padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', height: '10' }}><CloseRoundedIcon onClick={() => { this.setState({ form_is_completely_empty: false }) }} style={{ color: 'red' }} /></div>

                                        <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '80%', overflow: 'auto' }}>

                                            <div style={{ textAlign: 'center', marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%', padding: 8 }}>
                                                شما هیچ روش یا سیستمی برای آبیاری انتخاب نکرده اید.
                                  </div>

                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '10%' }}>
                                            <div
                                                onClick={() => { this.setState({ form_is_completely_empty: false }) }} style={{ color: 'red' }}
                                                style={{
                                                    width: '40%',
                                                    height: '100%',
                                                    boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                                                    backgroundColor: '#0857c3',
                                                    border: '1px solid rgb(3, 77, 179)',
                                                    borderRadius: 40,
                                                    color: 'white',
                                                    padding: '12px 16px',
                                                    fontSize: 12,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                ادامه </div>
                                        </div>





                                    </div>

                                </Modal>







                                <Modal visible={this.state.validation_modal_isOpen} width="90%" height="80%" effect="fadeInDown" onClickAway={() => { this.setState({ validation_modal_isOpen: false }) }}>
                                    <div style={{ position: 'relative', padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', height: '10' }}><CloseRoundedIcon onClick={() => { this.setState({ validation_modal_isOpen: false }) }} style={{ color: 'red' }} /></div>

                                        <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '102%', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>

                                            <div style={{ textAlign: 'right', marginTop: 16, marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
                                                روش آبیاری انتخاب شده شما {this.state.watering_method} است. کامل کردن موارد زیر ضروری می باشد:
                                  </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', width: '100%' }}>

                                                <ul style={{ textAlign: 'right' }}>
                                                    {/** GHARGHABI */}
                                                    {this.state.watering_method == 'غرقابی' && (this.state.gharghabi_watering_duration_in_days == null || this.state.gharghabi_watering_duration_in_days == '') &&
                                                        <li>دور آبیاری (روز)</li>
                                                    }

                                                    {this.state.watering_method == 'غرقابی' && (this.state.gharghabi_routin_watering_start_date == null || this.state.gharghabi_routin_watering_start_date == '') &&
                                                        <li>تاریخ شروع آبیاری های معمول</li>
                                                    }

                                                    {this.state.watering_method == 'غرقابی' && (this.state.gharghabi_routin_watering_end_date == null || this.state.gharghabi_routin_watering_end_date == '') &&
                                                        <li>تاریخ پایان آبیاری های معمول</li>
                                                    }

                                                    {this.state.watering_method == 'غرقابی' && (this.state.gharghabi_watering_band_width == null || this.state.gharghabi_watering_band_width == '') &&
                                                        <li>عرض نوار آبیاری از هر طرف درختان</li>
                                                    }

                                                    {this.state.watering_method == 'غرقابی' && (this.state.gharghabi_watering_row_length == null || this.state.gharghabi_watering_row_length == '') &&
                                                        <li>طول ردیف ها</li>
                                                    }

                                                    {this.state.watering_method == 'غرقابی' && (this.state.gharghabi_watering_row_count == null || this.state.gharghabi_watering_row_count == '') &&
                                                        <li>تعداد کل ردیف ها در هر نوبت آبیاری</li>
                                                    }

                                                    {this.state.watering_method == 'غرقابی' && (this.state.gharghabi_watering_time_lenght_unit == null || this.state.gharghabi_watering_time_lenght_unit == '') &&
                                                        <li>واحد زمان</li>
                                                    }

                                                    {this.state.watering_method == 'غرقابی' && (this.state.gharghabi_watering_time_lenght == null || this.state.gharghabi_watering_time_lenght == '') &&
                                                        <li>مدت زمان</li>
                                                    }

                                                    {this.state.watering_method == 'غرقابی' && (this.state.water_debi_volume == null || this.state.water_debi_volume == '') &&
                                                        <li>میزان برداشت از منبع آب</li>
                                                    }


                                                    {/** 234 */}
                                                    {(this.state.watering_method == 'قطره ای سطحی' || this.state.watering_method == 'قطره ای زیر سطحی' || this.state.watering_method == 'بابلر') && (this.state.watering_duration_in_days_234 == null || this.state.watering_duration_in_days_234 == '') &&
                                                        <li>دور آبیاری (روز)</li>
                                                    }

                                                    {(this.state.watering_method == 'قطره ای سطحی' || this.state.watering_method == 'قطره ای زیر سطحی' || this.state.watering_method == 'بابلر') && (this.state.routin_234_watering_start_date == null || this.state.routin_234_watering_start_date == '') &&
                                                        <li>تاریخ شروع آبیاری های معمول</li>
                                                    }

                                                    {(this.state.watering_method == 'قطره ای سطحی' || this.state.watering_method == 'قطره ای زیر سطحی' || this.state.watering_method == 'بابلر') && (this.state.routin_234_watering_end_date == null || this.state.routin_234_watering_end_date == '') &&
                                                        <li>تاریخ پایان آبیاری های معمول</li>
                                                    }

                                                    {(this.state.watering_method == 'قطره ای سطحی' || this.state.watering_method == 'قطره ای زیر سطحی' || this.state.watering_method == 'بابلر') && (this.state.each_time_watered_area_234_unit == null || this.state.each_time_watered_area_234_unit == '') &&
                                                        <li>واحد مساحت آبیاری</li>
                                                    }

                                                    {(this.state.watering_method == 'قطره ای سطحی' || this.state.watering_method == 'قطره ای زیر سطحی' || this.state.watering_method == 'بابلر') && (this.state.each_time_watered_area_234 == null || this.state.each_time_watered_area_234 == '') &&
                                                        <li>مساحت آبیاری</li>
                                                    }

                                                    {(this.state.watering_method == 'قطره ای سطحی' || this.state.watering_method == 'قطره ای زیر سطحی' || this.state.watering_method == 'بابلر') && (this.state.each_time_watered_time_length_unit_234 == null || this.state.each_time_watered_time_length_unit_234 == '') &&
                                                        <li>واحد زمان آبیاری</li>
                                                    }

                                                    {(this.state.watering_method == 'قطره ای سطحی' || this.state.watering_method == 'قطره ای زیر سطحی' || this.state.watering_method == 'بابلر') && (this.state.each_time_watered_time_length_234 == null || this.state.each_time_watered_time_length_234 == '') &&
                                                        <li>مدت زمان</li>
                                                    }

                                                    {(this.state.watering_method == 'قطره ای سطحی' || this.state.watering_method == 'قطره ای زیر سطحی' || this.state.watering_method == 'بابلر') && (this.state.water_debi_volume == null || this.state.water_debi_volume == '') &&
                                                        <li>میزان برداشت از منبع آب</li>
                                                    }


                                                    {/** PVC */}
                                                    {this.state.watering_method == 'زیر سطحی با لوله های پی وی سی' && (this.state.watering_duration_in_days_PVC == null || this.state.watering_duration_in_days_PVC == '') &&
                                                        <li>دور آبیاری (روز)</li>
                                                    }

                                                    {this.state.watering_method == 'زیر سطحی با لوله های پی وی سی' && (this.state.routin_PVC_watering_start_date == null || this.state.routin_PVC_watering_start_date == '') &&
                                                        <li>تاریخ شروع آبیاری های معمول</li>
                                                    }

                                                    {this.state.watering_method == 'زیر سطحی با لوله های پی وی سی' && (this.state.routin_PVC_watering_end_date == null || this.state.routin_PVC_watering_end_date == '') &&
                                                        <li>تاریخ پایان آبیاری های معمول</li>
                                                    }

                                                    {this.state.watering_method == 'زیر سطحی با لوله های پی وی سی' && (this.state.trees_distance == null || this.state.trees_distance == '') &&
                                                        <li>فاصله بین ردیف درختان</li>
                                                    }

                                                    {this.state.watering_method == 'زیر سطحی با لوله های پی وی سی' && (this.state.pvc_trees_row_length == null || this.state.pvc_trees_row_length == '') &&
                                                        <li>طول ردیف ها</li>
                                                    }

                                                    {this.state.watering_method == 'زیر سطحی با لوله های پی وی سی' && (this.state.watered_trees_row_count == null || this.state.watered_trees_row_count == '') &&
                                                        <li>تعداد رديفهاي درختان كه در هر نوبت آبياري مي شود</li>
                                                    }

                                                    {this.state.watering_method == 'زیر سطحی با لوله های پی وی سی' && (this.state.each_time_PVC_watered_time_lenghth_unit == null || this.state.each_time_PVC_watered_time_lenghth_unit == '') &&
                                                        <li>واحد زمان آبیاری</li>
                                                    }

                                                    {this.state.watering_method == 'زیر سطحی با لوله های پی وی سی' && (this.state.each_time_PVC_watered_time_lenghth == null || this.state.each_time_PVC_watered_time_lenghth == '') &&
                                                        <li>مدت زمان</li>
                                                    }

                                                    {this.state.watering_method == 'زیر سطحی با لوله های پی وی سی' && (this.state.water_debi_volume == null || this.state.water_debi_volume == '') &&
                                                        <li>میزان برداشت از منبع آب</li>
                                                    }

                                                </ul>

                                            </div>
                                            {(this.state.gharghabi_winter_watering_count == null || this.state.gharghabi_winter_watering_count == '' ||
                                                this.state.each_time_winter_watered_band_width == null || this.state.each_time_winter_watered_band_width == '' ||
                                                this.state.each_time_winter_watered_rows_lenght == null || this.state.each_time_winter_watered_rows_lenght == '' ||
                                                this.state.each_time_winter_watered_rows_count == null || this.state.each_time_winter_watered_rows_count == '' ||
                                                this.state.each_time_winter_watered_time_length_unit == null || this.state.each_time_winter_watered_time_length_unit == '' ||
                                                this.state.each_time_winter_watered_time_length == null || this.state.each_time_winter_watered_time_length == '') &&
                                                <div>
                                                    <div style={{ textAlign: 'right', marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
                                                        کامل کردن موارد زیر در بخش آبشویی زمستانه الزامی است:
                                                </div>

                                                    <ul style={{ textAlign: 'right' }}>
                                                        {(this.state.gharghabi_winter_watering_count == null || this.state.gharghabi_winter_watering_count == '') &&
                                                            <li>تعداد آبياري غرقابي زمستانه</li>
                                                        }

                                                        {(this.state.each_time_winter_watered_band_width == null || this.state.each_time_winter_watered_band_width == '') &&
                                                            <li>عرض نوار آبیاری از هر طرف درختان</li>
                                                        }

                                                        {(this.state.each_time_winter_watered_rows_lenght == null || this.state.each_time_winter_watered_rows_lenght == '') &&
                                                            <li>طول ردیف ها</li>
                                                        }

                                                        {(this.state.each_time_winter_watered_rows_count == null || this.state.each_time_winter_watered_rows_count == '') &&
                                                            <li>تعداد رديفهاي درختان كه در هر نوبت آبياري مي شود</li>
                                                        }

                                                        {(this.state.each_time_winter_watered_time_length_unit == null || this.state.each_time_winter_watered_time_length_unit == '') &&
                                                            <li>واحد زمان</li>
                                                        }

                                                        {(this.state.each_time_winter_watered_time_length == null || this.state.each_time_winter_watered_time_length == '') &&
                                                            <li>مدت زمان</li>
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '10%' }}>
                                            <div
                                                onClick={() => { this.setState({ validation_modal_isOpen: false }) }} style={{ color: 'red' }}
                                                style={{
                                                    width: '40%',
                                                    height: '100%',
                                                    boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                                                    backgroundColor: '#0857c3',
                                                    border: '1px solid rgb(3, 77, 179)',
                                                    borderRadius: 40,
                                                    color: 'white',
                                                    padding: '12px 16px',
                                                    fontSize: 12,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                ادامه </div>
                                        </div>





                                    </div>

                                </Modal>


                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    zIndex: 0,
                                    marginTop: '32px',
                                    marginBottom: '64px',
                                }}>
                                    <AwesomeButtonProgress
                                        size='large'
                                        type='primary'
                                        disabled={false}
                                        fakePress={false}
                                        releaseDelay={2000}
                                        loadingLabel='در حال پردازش'
                                        resultLabel='ثبت شد!'
                                        onPress={this.on_farm_irrigation_submit}
                                    >
                                        ثبت
</AwesomeButtonProgress>
                                </div>


                                <Modal visible={this.state.upload_modal} width="50%" height="20%" effect="fadeInDown">
                                    <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        {UploadIndicator}
                                    </div>
                                </Modal>

                            </div>
                        </div>


                    </div>
                );
            }

            else {
                return (<Redirect to={{
                    pathname: '/FarmsEdit',
                    state: {
                        name: localStorage.getItem('current_editting_farm_name'),
                        farm_id: localStorage.getItem('current_editting_farm')
                    }
                }} />);
            }


        }
    }
}