import React, { Component } from 'react';
import { BrowserRouter as Redirect, Route, Link, histoy, withRouter } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './styles/QuestionForm.css'
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './Loading.js';
import Select from 'react-select';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Modal from 'react-awesome-modal';
import {sendLog} from './LogTest.js';



export default class QuestionForm extends Component {
  state = {
    farms_name: [],
    loaded: false,
    question_text: null,
    farm_id: null,
    form_is_done: false,
    question_asked: false,
    log_text: '',
    farms_options_placeholder: 'انتخاب کنید'


  };


  componentDidMount() {
    if (this.props.location.state != undefined) {
      //console.log(this.props.location.state.cat_id)
    }

    if (this.props.location.state == undefined) {
      this.props.location.state = {cat_id: 8}
      //console.log(this.props.location.state.cat_id)
    }

    if (this.props.location.state.cat_id == 8) {
      this.setState({ farms_options_placeholder: 'می توانید انتخابی انجام ندهید!' })
    } 
    
    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/farms',
      {

        params: {
          api_token: localStorage.getItem('userTOKEN'),
        }
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        //console.log(response);
        localStorage.setItem('farm_list', JSON.stringify(response.data));
        var farms = JSON.parse(localStorage.getItem('farm_list'));
        for (let i = 0; i < farms.length; i++) {
          this.setState({
            farms_name: [...this.state.farms_name, {
              value: farms[i].id.toString(),
              label: farms[i].name,
            }]
          })
        }
        this.setState({ loaded: true })


      })
      .catch((error) => {
        //console.log(error);
        //console.log("code ghalate")
      })
      .finally(function () {
        // always executed
      });

  }


  handleTextChange = (e) => {
    //console.log(e.target.value);
    this.setState({
      question_text: e.target.value
    })
  };


  questionSubmit = (element, next) => {

    let final_farm_id = null;

    if (this.props.location.state == undefined) {
      this.props.location.state = {cat_id: 8}
      //console.log(this.props.location.state.cat_id)
    }

    if ( this.state.farm_id === null ) {
     final_farm_id = null;
    }

    else {
       final_farm_id = parseInt(this.state.farm_id)
    }

    if (this.state.question_text == null) {
      setTimeout(() => {
        next(false, 'خطا!');
      }, 600)
      this.setState({
        question_asked: true,
        log_text: 'لطفا توضیحات مختصری برای پرسش بنویسید.'
      })
    }
    
   else {
    const axios = require('axios');

    axios.post('https://api.pestekaran.com/api/messages',
      {

        category_id: this.props.location.state.cat_id,
        api_token: localStorage.getItem('userTOKEN'),
        text: this.state.question_text,
        farm_id: final_farm_id
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {

        //console.log(response);
        next();
        this.setState({
          question_asked: true,
          log_text: 'درخواست شما ثبت شد. تا زمان پاسخ کارشناسان شکیبا باشید.'
        })



      })
      .catch((error) => {
        next(false, 'مجددا تلاش نمایید!');
        sendLog(error.response);
        this.setState({
          question_asked: true,
          log_text: 'اتصال خود به اینترنت را بررسی کنید و مجددا تلاش نمایید.'
        })

      })
      .finally(function () {
        // always executed
      });
    }
  }


  render() {

    if (this.state.loaded) {
      return (


        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

          <div style={{ // ***************************************** header ***********************************************************************************
            position: 'absolute',
            backgroundColor: '#007bffa8',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            width: '160vw',
            height: '80vw',
            borderRadius: '80vw / 40vw',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            left: '-32%',
            top: '-20%'
          }}
          >
          </div>


          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
              پرسش
</div>
          </div>

          <Link style={{ color: 'white' }} to={{
            pathname: '/Home',
          }}>
            <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
            </div>
          </Link>

          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Slide down>
              <div style={{ // ***************************************** Top card *****************************************************************************
                boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                display: 'flex',
                backgroundColor: '#1D75FA',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 8,
                width: '80vw',
                height: '20vh',
                marginTop: '9vh',
                borderRadius: 40,
                position: 'relative'
              }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                      <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                    </div>
                    <div style={{ marginRight: 8 }}>
                      {this.state.farms_count}
                    </div>
                  </div>

                </div>


                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                  <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white'}}>
                    در این قسمت سوال خود در مورد باغتان را بپرسید.
      </div>
                </div>
              </div>
            </Slide>
          </div>


          <div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>



            <div style={{ textAlign: 'right' }}>
              <div>
                نام باغ:
                    </div>
              <Select
                className=""
                onChange={(value) => {
                  this.setState({ farm_id: value.value }, () => {
                    //console.log(parseInt(this.state.farm_id));
                    this.setState({ farm_id: value.value });
                    //console.log(parseInt(this.state.farm_id));
                  })
                }}
                style={{ textAlign: 'center', height: 35 }}
                options={this.state.farms_name}
                placeholder={this.state.farms_options_placeholder}
              />
            </div>
            <div style={{ textAlign: 'right' }}>
              <textarea
                style={{ marginTop: 32, borderRadius: 5 }}
                onChange={e => this.handleTextChange(e)}
                placeholder="سوال و توضیحات خود را در اینجا بنویسید ..."
                className='question-text'
                rows="5"
                cols="100"
              >
              </textarea>
            </div>

            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 0,
              marginTop: '32px',
              marginBottom: '64px',
            }}>
              <AwesomeButtonProgress
                size='large'
                type='primary'
                disabled={false}
                fakePress={false}
                releaseDelay={2000}
                loadingLabel='در حال پردازش'
                resultLabel='ثبت شد!'
                onPress={this.questionSubmit}
              >
                ثبت
    </AwesomeButtonProgress>
            </div>



            <Modal visible={this.state.question_asked} width="60%" height="30%" effect="fadeInDown" onClickAway={() => { }}>
              <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}></div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                  <div style={{ textAlign: 'center' }}>{this.state.log_text}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                  { this.state.question_text != null &&<Link to='/Home'><div
                    style={{
                      width: '100%',
                      height: '70%',
                      boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                      backgroundColor: '#0857c3',
                      border: '1px solid rgb(3, 77, 179)',
                      borderRadius: 40,
                      color: 'white',
                      padding: '12px 16px',
                      fontSize: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    ادامه </div></Link> }

                    { this.state.question_text == null && <Link><div
                    onClick={ () => this.setState({ question_asked: false })}
                    style={{
                      width: '100%',
                      height: '70%',
                      boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                      backgroundColor: '#0857c3',
                      border: '1px solid rgb(3, 77, 179)',
                      borderRadius: 40,
                      color: 'white',
                      padding: '12px 16px',
                      fontSize: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    ادامه </div></Link> }


                </div>
              </div>

            </Modal>



          </div>


        </div>
      );
    }
    else if (!this.state.loaded) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading />
        </div>
      );
    }
  }
}