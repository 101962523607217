
import React, { Component } from 'react';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Redirect, Route, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { throwStatement } from '@babel/types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Loading from '../Loading.js';
import DatPickerEditable from '../Fields/DatPickerEditable.js';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TextInput from '../Fields/TextInput.js';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {sendLog} from '../LogTest.js';



const customStyles = {

  overlay: { zIndex: 20000 }
};
class DiseaseEdit extends Component {
  constructor(props) {
    super(props);
    this.ghermezoo_disease_trees_count_onChange = this.ghermezoo_disease_trees_count_onChange.bind(this);
    this.state = {

      show_image_upload_button: 'none',
      show_image_edit_button: 'block',

      modal_1_color: 'gray',
      modal_2_color: 'gray',
      modal_3_color: 'gray',
      modal_4_color: 'gray',
      modal_5_color: 'gray',
      modal_6_color: 'gray',
      modal_7_color: 'gray',
      modal_8_color: 'gray',
      modal_9_color: 'gray',
      modal_10_color: 'gray',
      modal_11_color: 'gray',
      modal_12_color: 'gray',


      images: [
        'https://effigis.com/wp-content/uploads/2015/02/DigitalGlobe_WorldView2_50cm_8bit_Pansharpened_RGB_DRA_Rome_Italy_2009DEC10_8bits_sub_r_1.jpg',
      ],
      primary_farm_data: {},

      upload_modal: false,


      loaded: false,
      isOpen: false,
      photoIndex: 0,
      form_is_done: false,
      ghermezoo_disease_date: '',
      trees_yellow_leaves_date: '',
      trees_sarkhoshkidegi_date: '',
      trees_burned_leaves_edge_date: '',
      trees_bony_skin_date: '',
      trees_tanki_khooshe_date: '',
      trees_bud_fall_date: '',

      ghermezoo_disease_trees_count: '',

      trees_low_growth_rate_text: '',
      trees_are_small_text: '',
      surface_soil_hardness_text: '',
      depth_soil_hardness_text: '',
      sele_existance_text: '',
      ghermezoo_disease_text: '',
      trees_yellow_leaves_text: '',
      trees_sarkhoshkidegi_text: '',
      trees_burned_leaves_edge_text: '',
      trees_bony_skin_text: '',
      trees_tanki_khooshe_text: '',
      trees_bud_fall_text: '',


      Trees_low_growth_rate_img_id: 0,
      Trees_low_growth_rate_img_url: '',

      trees_are_small_img_id: 0,
      trees_are_small_img_url: '',

      sele_existance_img_id: 0,
      sele_existance_img_url: '',

      ghermezoo_disease_img_id: 0,
      ghermezoo_disease_img_url: '',

      trees_yellow_leaves_img_id: 0,
      trees_yellow_leaves_img_url: '',

      trees_sarkhoshkidegi_img_id: 0,
      trees_sarkhoshkidegi_img_url: '',

      trees_burned_leaves_edge_img_id: 0,
      trees_burned_leaves_edge_img_url: '',

      trees_bony_skin_img_id: 0,
      trees_bony_skin_img_url: '',

      trees_tanki_khooshe_img_id: 0,
      trees_tanki_khooshe_img_url: '',

      trees_bud_fall_img_id: 0,
      trees_bud_fall_img_url: '',



      ghermezoo_disease_lvl: '',
      trees_yellow_leaves_lvl: '',
      trees_sarkhoshkidegi_lvl: '',
      trees_burned_leaves_edge_lvl: '',
      trees_bony_skin_lvl: '',
      trees_tanki_khooshe_lvl: '',
      trees_bud_fall_lvl: '',


      uploader_volume: 0,
      image_selected: false,
      Trees_low_growth_rate_modal: false,
      surface_soil_hardness_modal: false,
      trees_are_small_modal: false,
      depth_soil_hardness_modal: false,
      sele_existance_modal: false,
      ghermezoo_disease_modal: false,
      trees_yellow_leaves_modal: false,
      trees_sarkhoshkidegi_modal: false,
      trees_burned_leaves_edge_modal: false,
      trees_bony_skin_modal: false,
      trees_tanki_khooshe_modal: false,
      trees_bud_fall_modal: false,

      trees_burned_leaves_edge_type: '',

    }
  }




  componentDidMount() {
    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/farms',
      {

        params: {
          api_token: localStorage.getItem('userTOKEN'),
          id: parseInt(localStorage.getItem('current_editting_farm')),
        }
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        console.log(response.data.length);

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].id === parseInt(localStorage.getItem('current_editting_farm'))) {
            console.log(response.data[i]);
            this.setState({ primary_farm_data: response.data[i].disease });
            this.setState({
              ghermezoo_disease_date: this.state.primary_farm_data.ghermezoo_disease.date,
              trees_yellow_leaves_date: this.state.primary_farm_data.trees_yellow_leaves.date,
              trees_sarkhoshkidegi_date: this.state.primary_farm_data.trees_sarkhoshkidegi.date,
              trees_burned_leaves_edge_date: this.state.primary_farm_data.trees_burned_leaves_edge.date,
              trees_bony_skin_date: this.state.primary_farm_data.trees_bony_skin.date,
              trees_tanki_khooshe_date: this.state.primary_farm_data.trees_tanki_khooshe.date,
              trees_bud_fall_date: this.state.primary_farm_data.trees_bud_fall.date,

              ghermezoo_disease_trees_count: this.state.primary_farm_data.ghermezoo_disease.trees_count,

              trees_low_growth_rate_text: this.state.primary_farm_data.trees_low_growth.text,
              trees_are_small_text: this.state.primary_farm_data.trees_are_small.text,
              surface_soil_hardness_text: this.state.primary_farm_data.surface_soil_hardness.text,
              depth_soil_hardness_text: this.state.primary_farm_data.depth_soil_hardness.text,
              sele_existance_text: this.state.primary_farm_data.sele_existance.text,
              ghermezoo_disease_text: this.state.primary_farm_data.ghermezoo_disease.text,
              trees_yellow_leaves_text: this.state.primary_farm_data.trees_yellow_leaves.text,
              trees_sarkhoshkidegi_text: this.state.primary_farm_data.trees_sarkhoshkidegi.text,
              trees_burned_leaves_edge_text: this.state.primary_farm_data.trees_burned_leaves_edge.text,
              trees_bony_skin_text: this.state.primary_farm_data.trees_bony_skin.text,
              trees_tanki_khooshe_text: this.state.primary_farm_data.trees_tanki_khooshe.text,
              trees_bud_fall_text: this.state.primary_farm_data.trees_bud_fall.text,


              Trees_low_growth_rate_img_id: this.state.primary_farm_data.trees_low_growth.img_id,
              trees_are_small_img_id: this.state.primary_farm_data.trees_are_small.img_id,
              sele_existance_img_id: this.state.primary_farm_data.sele_existance.img_id,
              ghermezoo_disease_img_id: this.state.primary_farm_data.ghermezoo_disease.img_id,
              trees_yellow_leaves_img_id: this.state.primary_farm_data.trees_yellow_leaves.img_id,
              trees_sarkhoshkidegi_img_id: this.state.primary_farm_data.trees_sarkhoshkidegi.img_id,
              trees_burned_leaves_edge_img_id: this.state.primary_farm_data.trees_burned_leaves_edge.img_id,
              trees_bony_skin_img_id: this.state.primary_farm_data.trees_bony_skin.img_id,
              trees_tanki_khooshe_img_id: this.state.primary_farm_data.trees_tanki_khooshe.img_id,
              trees_bud_fall_img_id: this.state.primary_farm_data.trees_bud_fall.img_id,



              ghermezoo_disease_lvl: this.state.primary_farm_data.ghermezoo_disease.level,
              trees_yellow_leaves_lvl: this.state.primary_farm_data.trees_yellow_leaves.level,
              trees_sarkhoshkidegi_lvl: this.state.primary_farm_data.trees_sarkhoshkidegi.level,
              trees_burned_leaves_edge_lvl: this.state.primary_farm_data.trees_burned_leaves_edge.level,
              trees_bony_skin_lvl: this.state.primary_farm_data.trees_bony_skin.level,
              trees_tanki_khooshe_lvl: this.state.primary_farm_data.trees_tanki_khooshe.level,
              trees_bud_fall_lvl: this.state.primary_farm_data.trees_bud_fall.level,


              uploader_volume: 0,
              image_selected: false,
              Trees_low_growth_rate_modal: false,
              surface_soil_hardness_modal: false,
              trees_are_small_modal: false,
              depth_soil_hardness_modal: false,
              sele_existance_modal: false,
              ghermezoo_disease_modal: false,
              trees_yellow_leaves_modal: false,
              trees_sarkhoshkidegi_modal: false,
              trees_burned_leaves_edge_modal: false,
              trees_bony_skin_modal: false,
              trees_tanki_khooshe_modal: false,
              trees_bud_fall_modal: false,

              trees_burned_leaves_edge_type: this.state.primary_farm_data.trees_burned_leaves_edge.type,
            }, () => {
              // Checking for each Modal Completion:
              if (this.state.trees_low_growth_rate_text != null || this.state.Trees_low_growth_rate_img_id != null) {
                this.setState({ modal_1_color: 'rgb(29, 117, 250)' })
              }
              if (this.state.trees_are_small_text != null || this.state.trees_are_small_img_id != null) {
                this.setState({ modal_2_color: 'rgb(29, 117, 250)' })
              }
              if (this.state.surface_soil_hardness_text) {
                this.setState({ modal_3_color: 'rgb(29, 117, 250)' })
              }
              if (this.state.depth_soil_hardness_text) {
                this.setState({ modal_4_color: 'rgb(29, 117, 250)' })
              }
              if (this.state.sele_existance_text != null || this.state.sele_existance_img_id != null) {
                this.setState({ modal_5_color: 'rgb(29, 117, 250)' })
              }
              if (this.state.ghermezoo_disease_date != null ||
                this.state.ghermezoo_disease_trees_count != null ||
                this.state.ghermezoo_disease_text != null ||
                this.state.ghermezoo_disease_img_id != null ||
                this.state.ghermezoo_disease_lvl != null) {
                this.setState({ modal_6_color: 'rgb(29, 117, 250)' })
              }
              if (this.state.trees_yellow_leaves_date != null ||
                this.state.trees_yellow_leaves_text != null ||
                this.state.trees_yellow_leaves_img_id != null ||
                this.state.trees_yellow_leaves_lvl != null) {
                this.setState({ modal_7_color: 'rgb(29, 117, 250)' })
              }

              if (this.state.trees_sarkhoshkidegi_date != null ||
                this.state.trees_sarkhoshkidegi_text != null ||
                this.state.trees_sarkhoshkidegi_img_id != null ||
                this.state.trees_sarkhoshkidegi_lvl != null) {
                this.setState({ modal_8_color: 'rgb(29, 117, 250)' })
              }

              if (this.state.trees_sarkhoshkidegi_date != null ||
                this.state.trees_sarkhoshkidegi_text != null ||
                this.state.trees_sarkhoshkidegi_img_id != null ||
                this.state.trees_sarkhoshkidegi_lvl != null) {
                this.setState({ modal_8_color: 'rgb(29, 117, 250)' })
              }

              if (this.state.trees_burned_leaves_edge_date != null ||
                this.state.trees_burned_leaves_edge_text != null ||
                this.state.trees_burned_leaves_edge_img_id != null ||
                this.state.trees_burned_leaves_edge_lvl != null ||
                this.state.trees_burned_leaves_edge_type != null) {
                this.setState({ modal_9_color: 'rgb(29, 117, 250)' })
              }

              if (this.state.trees_bony_skin_date != null ||
                this.state.trees_bony_skin_text != null ||
                this.state.trees_bony_skin_img_id != null ||
                this.state.trees_bony_skin_lvl != null) {
                this.setState({ modal_10_color: 'rgb(29, 117, 250)' })
              }

              if (this.state.trees_tanki_khooshe_date != null ||
                this.state.trees_tanki_khooshe_text != null ||
                this.state.trees_tanki_khooshe_img_id != null ||
                this.state.trees_tanki_khooshe_lvl != null) {
                this.setState({ modal_11_color: 'rgb(29, 117, 250)' })
              }
              if (this.state.trees_bud_fall_date != null ||
                this.state.trees_bud_fall_text != null ||
                this.state.trees_bud_fall_img_id != null ||
                this.state.trees_bud_fall_lvl != null) {
                this.setState({ modal_12_color: 'rgb(29, 117, 250)' })
              }

            });
            console.log(this.state.primary_farm_data);
          }
        }
        this.setState({ loaded: true })
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }






  onFarmRegister = (element, next) => {
    var data = {
      trees_low_growth: {
        img_id: this.state.Trees_low_growth_rate_img_id,
        text: this.state.trees_low_growth_rate_text,
      },
      trees_are_small: {
        img_id: this.state.trees_are_small_img_id,
        text: this.state.trees_are_small_text,
      },
      surface_soil_hardness: {
        text: this.state.surface_soil_hardness_text,
      },
      depth_soil_hardness: {
        text: this.state.depth_soil_hardness_text,
      },
      sele_existance: {
        text: this.state.sele_existance_text,
        img_id: this.state.sele_existance_img_id,
      },
      ghermezoo_disease: {
        level: this.state.ghermezoo_disease_lvl,
        img_id: this.state.ghermezoo_disease_img_id,
        date: this.state.ghermezoo_disease_date,
        text: this.state.ghermezoo_disease_text,
        trees_count: this.state.ghermezoo_disease_trees_count,
      },
      trees_yellow_leaves: {
        level: this.state.trees_yellow_leaves_lvl,
        img_id: this.state.trees_yellow_leaves_img_id,
        date: this.state.trees_yellow_leaves_date,
        text: this.state.trees_yellow_leaves_text,
      },
      trees_sarkhoshkidegi: {
        level: this.state.trees_sarkhoshkidegi_lvl,
        img_id: this.state.trees_sarkhoshkidegi_img_id,
        date: this.state.trees_sarkhoshkidegi_date,
        text: this.state.trees_sarkhoshkidegi_text,
      },
      trees_burned_leaves_edge: {
        level: this.state.trees_burned_leaves_edge_lvl,
        img_id: this.state.trees_burned_leaves_edge_img_id,
        date: this.state.trees_burned_leaves_edge_date,
        text: this.state.trees_burned_leaves_edge_text,
        type: this.state.trees_burned_leaves_edge_type,
      },
      trees_bony_skin: {
        level: this.state.trees_bony_skin_lvl,
        img_id: this.state.trees_bony_skin_img_id,
        date: this.state.trees_bony_skin_date,
        text: this.state.trees_bony_skin_text,
      },
      trees_tanki_khooshe: {
        level: this.state.trees_tanki_khooshe_lvl,
        img_id: this.state.trees_tanki_khooshe_img_id,
        date: this.state.trees_tanki_khooshe_date,
        text: this.state.trees_tanki_khooshe_text,
      },
      trees_bud_fall: {
        level: this.state.trees_bud_fall_lvl,
        img_id: this.state.trees_bud_fall_img_id,
        date: this.state.trees_bud_fall_date,
        text: this.state.trees_bud_fall_text,
      },

    }
    console.log(data)
    const axios = require('axios');
    axios.put('https://api.pestekaran.com/api/disease/' + localStorage.getItem('current_editting_farm'),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        },

        api_token: localStorage.getItem('userTOKEN'),
        data: data,


      })
      .then((response) => {

        console.log(response)
        localStorage.setItem('current_editting_farm', response.data.farm_id);
        next();
        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'success-notifier-toast',
        });

        toast("اطلاعات عارضه باغ شما ثبت شد. لطفا بقیه بخش ها را کامل نمایید.");

        this.setState({ form_is_done: true })
      })
      .catch((error) => {
        next(false, 'مجددا تلاش نمایید!');
        sendLog(error.response);
      })
      .finally(function () {
        // always executed
      });
  }

  handleImageTrees_low_growth_rateSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ Trees_low_growth_rate_img_id: response.data.id });
        console.log(response);

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");

        this.setState({ image_selected: false, upload_modal: false });


      })

  }

  handleImagetrees_are_smallSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log('i reached')
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        console.log(response)
        this.setState({ trees_are_small_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }

  handle_sele_existance_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {

        this.setState({ sele_existance_img_id: response.data.id });


        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }

  handle_ghermezoo_disease_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {

        this.setState({ ghermezoo_disease_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_yellow_leaves_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ trees_yellow_leaves_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_sarkhoshkidegi_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ trees_sarkhoshkidegi_img_id: response.data.id });


        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_burned_leaves_edge_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {

        this.setState({ trees_burned_leaves_edge_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_bony_skin_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {

        this.setState({ trees_bony_skin_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_tanki_khooshe_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ trees_tanki_khooshe_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }

  handle_trees_bud_fall_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {

        this.setState({ trees_bud_fall_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }



  openTrees_low_growth_rateModal() {
    if (this.state.Trees_low_growth_rate_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.Trees_low_growth_rate_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ Trees_low_growth_rate_img_url: response.data });
          var temp = [];
          temp[0] = this.state.Trees_low_growth_rate_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
      this.setState({
        Trees_low_growth_rate_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        Trees_low_growth_rate_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  opensurface_soil_hardnessModal() {
    this.setState({
      surface_soil_hardness_modal: true,
    });
  }

  opentrees_are_smallModal() {
    if (this.state.trees_are_small_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.trees_are_small_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ trees_are_small_img_url: response.data });
          var temp = [];
          temp[0] = this.state.trees_are_small_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
      this.setState({
        trees_are_small_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        trees_are_small_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  opendepth_soil_hardnessModal() {
    this.setState({
      depth_soil_hardness_modal: true,
    });
  }

  opensele_existanceModal() {
    if (this.state.sele_existance_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.sele_existance_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ sele_existance_img_url: response.data });
          var temp = [];
          temp[0] = this.state.sele_existance_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
      this.setState({
        sele_existance_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        sele_existance_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  openghermezoo_diseaseModal() {
    if (this.state.ghermezoo_disease_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.ghermezoo_disease_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ ghermezoo_disease_img_url: response.data });
          var temp = [];
          temp[0] = this.state.ghermezoo_disease_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
      this.setState({
        ghermezoo_disease_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        ghermezoo_disease_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  opentrees_yellow_leavesModal() {
    if (this.state.trees_yellow_leaves_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.trees_yellow_leaves_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ trees_yellow_leaves_img_url: response.data });
          var temp = [];
          temp[0] = this.state.trees_yellow_leaves_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
      this.setState({
        trees_yellow_leaves_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        trees_yellow_leaves_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  opentrees_sarkhoshkidegiModal() {
    if (this.state.trees_sarkhoshkidegi_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.trees_sarkhoshkidegi_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ trees_sarkhoshkidegi_img_url: response.data });
          var temp = [];
          temp[0] = this.state.trees_sarkhoshkidegi_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
      this.setState({
        trees_sarkhoshkidegi_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        trees_sarkhoshkidegi_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  opentrees_burned_leaves_edgeModal() {
    if (this.state.trees_burned_leaves_edge_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.trees_burned_leaves_edge_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ trees_burned_leaves_edge_img_url: response.data });
          var temp = [];
          temp[0] = this.state.trees_burned_leaves_edge_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
      this.setState({
        trees_burned_leaves_edge_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        trees_burned_leaves_edge_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  opentrees_bony_skinModal() {
    if (this.state.trees_bony_skin_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.trees_bony_skin_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ trees_bony_skin_img_url: response.data });
          var temp = [];
          temp[0] = this.state.trees_bony_skin_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
      this.setState({
        trees_bony_skin_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        trees_bony_skin_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  opentrees_tanki_khoosheModal() {
    if (this.state.trees_tanki_khooshe_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.trees_tanki_khooshe_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ trees_tanki_khooshe_img_url: response.data });
          var temp = [];
          temp[0] = this.state.trees_tanki_khooshe_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
      this.setState({
        trees_tanki_khooshe_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        trees_tanki_khooshe_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  opentrees_bud_fallModal() {
    if (this.state.trees_tanki_khooshe_img_id != null) {
      axios.get('https://api.pestekaran.com/api/file/' + this.state.trees_bud_fall_img_id,
        {

          params: {
            api_token: localStorage.getItem('userTOKEN'),
            id: parseInt(localStorage.getItem('current_editting_farm')),
          }
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ trees_bud_fall_img_url: response.data });
          var temp = [];
          temp[0] = this.state.trees_bud_fall_img_url;
          this.setState({ images: temp })
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });

      this.setState({
        trees_bud_fall_modal: true,
        show_image_upload_button: 'none',
        show_image_edit_button: 'block'
      });
    }
    else {
      this.setState({
        trees_bud_fall_modal: true,
        show_image_upload_button: 'block',
        show_image_edit_button: 'none'
      });
    }
  }

  set_ghermezoo_disease_lvl = (newValue) => {

    this.setState({ ghermezoo_disease_lvl: newValue.label });
  }

  set_trees_yellow_leaves_lvl = (newValue) => {

    this.setState({ trees_yellow_leaves_lvl: newValue.label });
  }

  set_trees_sarkhoshkidegi_lvl = (newValue) => {

    this.setState({ trees_sarkhoshkidegi_lvl: newValue.label });
  }

  set_trees_burned_leaves_edge_lvl = (newValue) => {

    this.setState({ trees_burned_leaves_edge_lvl: newValue.label });
  }

  set_trees_bony_skin_lvl = (newValue) => {
    this.setState({ trees_bony_skin_lvl: newValue.label });
  }

  set_trees_tanki_khooshe_lvl = (newValue) => {

    this.setState({ trees_tanki_khooshe_lvl: newValue.label });
  }

  set_trees_bud_fall_lvl = (newValue) => {

    this.setState({ trees_bud_fall_lvl: newValue.label });
  }

  trees_low_growth_rate_text_change = (value) => {
    this.setState({ trees_low_growth_rate_text: value.target.value });
  }

  trees_are_small_text_change = (value) => {
    this.setState({ trees_are_small_text: value.target.value });
  }

  surface_soil_hardness_text_change = (value) => {
    this.setState({ surface_soil_hardness_text: value.target.value });
  }

  depth_soil_hardness_text_change = (value) => {
    this.setState({ depth_soil_hardness_text: value.target.value });
  }

  sele_existance_text_change = (value) => {
    this.setState({ sele_existance_text: value.target.value });
  }

  ghermezoo_disease_text_change = (value) => {
    this.setState({ ghermezoo_disease_text: value.target.value });
  }

  trees_yellow_leaves_text_change = (value) => {
    this.setState({ trees_yellow_leaves_text: value.target.value });
  }

  trees_sarkhoshkidegi_text_change = (value) => {
    this.setState({ trees_sarkhoshkidegi_text: value.target.value });
  }

  trees_burned_leaves_edge_text_change = (value) => {
    this.setState({ trees_burned_leaves_edge_text: value.target.value });
  }

  trees_bony_skin_text_change = (value) => {
    this.setState({ trees_bony_skin_text: value.target.value });
  }

  trees_tanki_khooshe_text_change = (value) => {
    this.setState({ trees_tanki_khooshe_text: value.target.value });
  }

  trees_bud_fall_text_change = (value) => {
    this.setState({ trees_bud_fall_text: value.target.value });
  }





  // Fields Dats start from here:

  ghermezoo_disease_date_change = (date) => {
    this.setState({ ghermezoo_disease_date: date })
  }

  trees_yellow_leaves_date_change = (date) => {
    this.setState({ trees_yellow_leaves_date: date })
  }

  trees_sarkhoshkidegi_date_change = (date) => {
    this.setState({ trees_sarkhoshkidegi_date: date })
  }

  trees_burned_leaves_edge_date_change = (date) => {
    this.setState({ trees_burned_leaves_edge_date: date })
  }

  trees_bony_skin_date_change = (date) => {
    this.setState({ trees_bony_skin_date: date })
  }

  trees_tanki_khooshe_date_change = (date) => {
    this.setState({ trees_tanki_khooshe_date: date })
  }

  trees_bud_fall_date_Change = (date) => {
    this.setState({ trees_bud_fall_date: date })
  }


  // Here starts types (only two):

  trees_burned_leaves_edge_type_change = (newValue) => {
    this.setState({ trees_burned_leaves_edge_type: newValue.label });
  }

  ghermezoo_disease_trees_count_onChange = (event) => {
    this.setState({ ghermezoo_disease_trees_count: event });
    console.log(this.state.ghermezoo_disease_trees_count);
  }


  closeModal() {
    this.setState({
      Trees_low_growth_rate_modal: false,
      surface_soil_hardness_modal: false,
      trees_are_small_modal: false,
      depth_soil_hardness_modal: false,
      sele_existance_modal: false,
      ghermezoo_disease_modal: false,
      trees_yellow_leaves_modal: false,
      trees_sarkhoshkidegi_modal: false,
      trees_burned_leaves_edge_modal: false,
      trees_bony_skin_modal: false,
      trees_tanki_khooshe_modal: false,
      trees_bud_fall_modal: false
    });
  }
  render() {

    if (!this.state.loaded) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading />
        </div>
      );
    }
    else if (this.state.loaded) {
      if (!this.state.form_is_done) {
        var UploadIndicator;
        if (this.state.image_selected == true) {

          UploadIndicator = <div className='circularWait'><CircularProgressbar value={this.state.uploader_volume} text={`${this.state.uploader_volume}%`} /></div>;

        } else if (this.state.image_selected == false) {

          UploadIndicator = <div></div>;

        }

        const trees_burned_leaves_edge_options = [
          { value: '1', label: 'حاشیه سوختگی زرد رنگ' },
          { value: '2', label: 'حاشیه سوختگی قهوه ای' },
          { value: '3', label: 'سایر' },
        ];

        const ghermezoo_disease = [
          { value: '1', label: 'کم' },
          { value: '2', label: 'متوسط' },
          { value: '3', label: 'زیاد' },
        ];
        return (
          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

            <div style={{ // ***************************************** header ***********************************************************************************
              position: 'absolute',
              backgroundColor: '#007bffa8',
              boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
              width: '160vw',
              height: '80vw',
              borderRadius: '80vw / 40vw',
              boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
              left: '-32%',
              top: '-20%'
            }}
            >
            </div>


            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                ویرایش عارضه ها {this.state.farm_name}
              </div>
            </div>

            <Link style={{ color: 'white' }} to={{
              pathname: '/FarmsEdit',
              state: {
                name: localStorage.getItem('current_editting_farm_name'),
                farm_id: localStorage.getItem('current_editting_farm')
              }
            }}>
              <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
              </div>
            </Link>

            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Slide down>
                <div style={{ // ***************************************** Top card *****************************************************************************
                  boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                  display: 'flex',
                  backgroundColor: '#1D75FA',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 8,
                  width: '80vw',
                  height: '20vh',
                  marginTop: '9vh',
                  borderRadius: 40,
                  position: 'relative'
                }}>

                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                      <div>
                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ marginRight: 8 }}>
                        {this.state.farms_count}
                      </div>
                    </div>

                  </div>


                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                    <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                      در این بخش می توانید اطلاعات عارضه های موجود در باغ خود را مشاهده یا ویرایش کنید.
                    </div>
                  </div>
                </div>
              </Slide>
            </div>


            <div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>





              {/* Modal 1 ***********************************/}
              <div className="diseaseItems" onClick={() => this.openTrees_low_growth_rateModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_1_color }} />رشد کم درختان (رشد کم شاخه ها)</div>
              <Modal visible={this.state.Trees_low_growth_rate_modal} width="80%" height="80%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>رشد کم درختان:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>


                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.trees_low_growth_rate_text_change} value={this.state.trees_low_growth_rate_text} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>


                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handleImageTrees_low_growth_rateSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handleImageTrees_low_growth_rateSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.Trees_low_growth_rate_img_url} />
                        </div>
                      </div>
                    </div>

                    <div>
                      {this.state.iseOpen && (

                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.Trees_low_growth_rate_img_url}
                          onCloseRequest={() => this.setState({ iseOpen: false })}
                        />
                      )}
                    </div>
                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_1_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>



              {/* Modal 2 ***********************************/}
              <div className="diseaseItems" onClick={() => this.opentrees_are_smallModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_2_color }} />کوچکی درختان با توجه به سنشان</div>
              <Modal visible={this.state.trees_are_small_modal} width="80%" height="80%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>کوچکی درختان با توجه به سنشان:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>
                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.trees_are_small_text_change} value={this.state.trees_are_small_text} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>

                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handleImagetrees_are_smallSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files1"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files1" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handleImagetrees_are_smallSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.trees_are_small_img_url} />
                        </div>



                      </div>
                    </div>
                    <div>
                      {this.state.isOpen && (
                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.images[this.state.photoIndex]}
                          nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                          prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_2_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>

                </div>
              </Modal>




              {/* Modal 3 ***********************************/}
              <div className="diseaseItems" onClick={() => this.opensurface_soil_hardnessModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_3_color }} />سختی و سفتی خاک سطحی</div>
              <Modal visible={this.state.surface_soil_hardness_modal} width="80%" height="70%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>سختی و سفتی خاک سطحی:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>
                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.surface_soil_hardness_text_change} value={this.state.surface_soil_hardness_text} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>
                  </div>
                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_3_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>



              <div className="diseaseItems" onClick={() => this.opendepth_soil_hardnessModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_4_color }} />سختی و سفتی خاک عمقی</div>
              <Modal visible={this.state.depth_soil_hardness_modal} width="80%" height="70%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>سختی و سفتی خاک عمقی:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>
                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.depth_soil_hardness_text_change} value={this.state.depth_soil_hardness_text} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>
                  </div>
                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_4_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>



              {/* Modal 5 ***********************************/}
              <div className="diseaseItems" onClick={() => this.opensele_existanceModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_5_color }} />وجود سله در سطح خاک</div>
              <Modal visible={this.state.sele_existance_modal} width="80%" height="80%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>وجود سله در سطح خاک:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.sele_existance_text_change} value={this.state.sele_existance_text} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>

                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_sele_existance_ImageSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files2"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files2" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handle_sele_existance_ImageSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.sele_existance_img_url} />
                        </div>



                      </div>
                    </div>
                    <div>
                      {this.state.isOpen && (
                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.images[this.state.photoIndex]}
                          nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                          prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                          }
                        />
                      )}
                    </div>

                  </div>
                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_5_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>







              {/* Modal 6 ***********************************/}
              <div className="diseaseItems" onClick={() => this.openghermezoo_diseaseModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_6_color }} />عارضه ریزبرگی یا قرمزو</div>
              <Modal visible={this.state.ghermezoo_disease_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>عارضه ریزبرگی یا قرمزو:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>

                    <TextInput inputName='توضیحات:' onChange={this.ghermezoo_disease_trees_count_onChange} InputPlaceHolder='' initialValue={this.state.ghermezoo_disease_text} />

                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        میزان عارضه:
                    </div>
                      <Select
                        className=""
                        onChange={this.set_ghermezoo_disease_lvl}
                        style={{ textAlign: 'center', height: 35 }}
                        options={ghermezoo_disease}
                        placeholder={this.state.ghermezoo_disease_lvl}
                      />
                    </div>


                    <DatPickerEditable inputName='زمان بروز:' on_date_edit={this.ghermezoo_disease_date_change} onChange={this.ghermezoo_disease_date_change} initialValue={this.state.ghermezoo_disease_date} />

                    <TextInput inputName='تعداد درختان دارای عارضه در باغ:' onChange={this.ghermezoo_disease_trees_count_onChange} InputPlaceHolder='' initialValue={this.state.ghermezoo_disease_trees_count} />

                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_ghermezoo_disease_ImageSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files3"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files3" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handle_ghermezoo_disease_ImageSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.ghermezoo_disease_img_url} />
                        </div>



                      </div>
                    </div>
                    <div>
                      {this.state.isOpen && (
                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.images[this.state.photoIndex]}
                          nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                          prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                          }
                        />
                      )}
                    </div>

                  </div>
                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_6_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>


              {/* Modal 7 ***********************************/}
              <div className="diseaseItems" onClick={() => this.opentrees_yellow_leavesModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_7_color }} />عارضه زردی برگ درختان</div>
              <Modal visible={this.state.trees_yellow_leaves_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>عارضه زردی برگ درختان:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.trees_yellow_leaves_text_change} value={this.state.trees_yellow_leaves_text} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>

                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        میزان عارضه:
                    </div>
                      <Select
                        className=""
                        onChange={this.set_trees_yellow_leaves_lvl}
                        style={{ textAlign: 'center', height: 35 }}
                        options={ghermezoo_disease}
                        placeholder={this.state.trees_yellow_leaves_lvl}
                      />
                    </div>

                    <DatPickerEditable inputName='زمان بروز:' on_date_edit={this.trees_yellow_leaves_date_change} onChange={this.trees_yellow_leaves_date_change} initialValue={this.state.trees_yellow_leaves_date} />

                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_trees_yellow_leaves_ImageSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files4"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files4" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handle_trees_yellow_leaves_ImageSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.trees_yellow_leaves_img_url} />
                        </div>



                      </div>
                    </div>
                    <div>
                      {this.state.isOpen && (
                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.images[this.state.photoIndex]}
                          nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                          prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                          }
                        />
                      )}
                    </div>

                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_7_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>



              {/* Modal 8 ***********************************/}
              <div className="diseaseItems" onClick={() => this.opentrees_sarkhoshkidegiModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_8_color }} />عارضه سرخشکیدگی</div>
              <Modal visible={this.state.trees_sarkhoshkidegi_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>عارضه سرخشکیدگی:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.trees_sarkhoshkidegi_text_change} value={this.state.trees_sarkhoshkidegi_text} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>

                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        میزان عارضه:
                    </div>
                      <Select
                        className=""
                        onChange={this.set_trees_sarkhoshkidegi_lvl}
                        style={{ textAlign: 'center', height: 35 }}
                        options={ghermezoo_disease}
                        placeholder={this.state.trees_sarkhoshkidegi_lvl}
                      />
                    </div>

                    <DatPickerEditable inputName='زمان بروز:' on_date_edit={this.trees_sarkhoshkidegi_date_change} onChange={this.trees_sarkhoshkidegi_date_change} initialValue={this.state.trees_sarkhoshkidegi_date} />

                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_trees_sarkhoshkidegi_ImageSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files5"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files5" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handle_trees_sarkhoshkidegi_ImageSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.trees_sarkhoshkidegi_img_url} />
                        </div>



                      </div>
                    </div>

                    <div>
                      {this.state.isOpen && (
                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.images[this.state.photoIndex]}
                          nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                          prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                          }
                        />
                      )}
                    </div>

                  </div>
                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_8_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>

              {/* Modal 9 ***********************************/}
              <div className="diseaseItems" onClick={() => this.opentrees_burned_leaves_edgeModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_9_color }} />حاشیه سوختگی برگ</div>
              <Modal visible={this.state.trees_burned_leaves_edge_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>حاشیه سوختگی برگ:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <TextInput inputName='توضیحات:' onChange={this.trees_burned_leaves_edge_text_change} InputPlaceHolder='' initialValue={this.state.trees_bud_fall_text} />

                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        میزان عارضه:
                    </div>
                      <Select
                        className=""
                        onChange={this.set_trees_burned_leaves_edge_lvl}
                        style={{ textAlign: 'center', height: 35 }}
                        options={ghermezoo_disease}
                        placeholder={this.state.trees_burned_leaves_edge_lvl}
                      />
                    </div>

                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        نوع:
                    </div>
                      <Select
                        className=""
                        onChange={this.trees_burned_leaves_edge_type_change}
                        style={{ textAlign: 'center', height: 35 }}
                        options={trees_burned_leaves_edge_options}
                        placeholder={this.state.trees_burned_leaves_edge_type}
                      />
                    </div>

                    <DatPickerEditable inputName='زمان بروز:' on_date_edit={this.trees_burned_leaves_edge_date_change} onChange={this.trees_burned_leaves_edge_date_change} initialValue={this.state.trees_burned_leaves_edge_date} />

                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_trees_burned_leaves_edge_ImageSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files6"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files6" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handle_trees_burned_leaves_edge_ImageSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.trees_burned_leaves_edge_img_url} />
                        </div>



                      </div>
                    </div>

                    <div>
                      {this.state.isOpen && (
                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.images[this.state.photoIndex]}
                          nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                          prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                          }
                        />
                      )}
                    </div>

                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_9_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>


              {/* Modal 10 ***********************************/}
              <div className="diseaseItems" onClick={() => this.opentrees_bony_skinModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_10_color }} />لکه پوست استخوانی</div>
              <Modal visible={this.state.trees_bony_skin_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>لکه پوست استخوانی:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.trees_bony_skin_text_change} value={this.state.trees_bony_skin_text} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>

                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        میزان عارضه:
                    </div>
                      <Select
                        className=""
                        onChange={this.set_trees_bony_skin_lvl}
                        style={{ textAlign: 'center', height: 35 }}
                        options={ghermezoo_disease}
                        placeholder={this.state.trees_bud_fall_lvl}
                      />
                    </div>

                    <DatPickerEditable inputName='زمان بروز:' on_date_edit={this.trees_bony_skin_date_change} onChange={this.trees_bony_skin_date_change} initialValue={this.state.trees_bony_skin_date} />

                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_trees_bony_skin_ImageSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files7"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files7" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handle_trees_bony_skin_ImageSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.trees_bony_skin_img_url} />
                        </div>



                      </div>
                    </div>


                    <div>
                      {this.state.isOpen && (
                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.images[this.state.photoIndex]}
                          nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                          prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                          }
                        />
                      )}
                    </div>


                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_10_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>



              {/* Modal 11 ***********************************/}
              <div className="diseaseItems" onClick={() => this.opentrees_tanki_khoosheModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_11_color }} />تنکی خوشه</div>
              <Modal visible={this.state.trees_tanki_khooshe_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>تنکی خوشه:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.trees_tanki_khooshe_text_change} value={this.state.trees_tanki_khooshe_text} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>

                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        میزان عارضه:
                    </div>
                      <Select
                        className=""
                        onChange={this.set_trees_tanki_khooshe_lvl}
                        style={{ textAlign: 'center', height: 35 }}
                        options={ghermezoo_disease}
                        placeholder={this.state.trees_tanki_khooshe_lvl}
                      />
                    </div>

                    <DatPickerEditable inputName='زمان بروز:' on_date_edit={this.trees_tanki_khooshe_date_change} onChange={this.trees_tanki_khooshe_date_change} initialValue={this.state.trees_tanki_khooshe_date} />

                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_trees_tanki_khooshe_ImageSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files8"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files8" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handle_trees_tanki_khooshe_ImageSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.trees_tanki_khooshe_img_url} />
                        </div>



                      </div>
                    </div>

                    <div>
                      {this.state.isOpen && (
                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.trees_tanki_khooshe_img_url}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                        />
                      )}
                    </div>

                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_11_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>


              {/* Modal 12 ***********************************/}
              <div className="diseaseItems" onClick={() => this.opentrees_bud_fallModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_12_color }} />ریزش جوانه ها ی گل</div>
              <Modal visible={this.state.trees_bud_fall_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                <div className="modal-overlay">

                  <div className="modal-title-container">
                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                    <div style={{ width: '80%', marginTop: 8 }}>ریزش جوانه ها ی گل:</div>
                    <div style={{ width: '10%' }}></div>
                  </div>

                  <div style={{ height: '80%', padding: 16 }}>
                    <textarea style={{ borderRadius: 5 }} onChange={this.trees_bud_fall_text_change} placeholder="توضیحات ..." value={this.state.trees_bud_fall_text} className='modal-ex-text' rows="5" cols="100"></textarea>

                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                      <div>
                        میزان عارضه:
                    </div>
                      <Select
                        className=""
                        onChange={this.set_trees_bud_fall_lvl}
                        style={{ textAlign: 'center', height: 35 }}
                        options={ghermezoo_disease}
                        placeholder={this.state.trees_bud_fall_lvl}
                      />
                    </div>

                    <DatPickerEditable inputName='زمان بروز:' on_date_edit={this.trees_bud_fall_date_Change} onChange={this.trees_bud_fall_date_Change} initialValue={this.state.trees_bud_fall_date} />


                    <div style={{ display: this.state.show_image_upload_button }} >
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <label className="addImageContainer">
                          <div style={{ display: 'flex', width: '20%' }}>
                            <CropOriginalIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ width: '60%' }}>
                            <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_trees_bud_fall_ImageSelect} />
                            <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                          </div>

                        </label>
                      </div>
                    </div>



                    <div style={{ display: this.state.show_image_edit_button }}>
                      <div className="disease-image-edit-block">
                        <div style={{ width: '10%' }}>
                          <label for="files9"><EditRoundedIcon style={{ margin: 16, color: 'gray' }} /></label>
                          <input id="files9" style={{ display: 'none' }} className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
                            onChange={this.handle_trees_bud_fall_ImageSelect} />
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                          <img className="avatar" onClick={() => this.setState({ iseOpen: true })} src={this.state.trees_bud_fall_img_url} />
                        </div>



                      </div>
                    </div>
                    <div>
                      {this.state.isOpen && (
                        <Lightbox
                          reactModalStyle={customStyles}
                          mainSrc={this.state.images[this.state.photoIndex]}
                          nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                          prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                          onCloseRequest={() => this.setState({ isOpen: false })}
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                          }
                        />
                      )}
                    </div>

                  </div>

                  <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => { this.closeModal(); this.setState({ modal_12_color: 'rgb(29, 117, 250)' }); }}>
                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                  </div>
                </div>
              </Modal>


              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                zIndex: 0,
                marginTop: '32px',
                marginBottom: '64px',
              }}>
                <AwesomeButtonProgress
                  size='large'
                  type='primary'
                  disabled={false}
                  fakePress={false}
                  releaseDelay={2000}
                  loadingLabel='در حال پردازش'
                  resultLabel='ثبت شد!'
                  onPress={this.onFarmRegister}
                >
                  ثبت
    </AwesomeButtonProgress>
              </div>
            </div>

            <Modal visible={this.state.upload_modal} width="50%" height="20%" effect="fadeInDown">
              <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {UploadIndicator}
              </div>
            </Modal>

          </div>
        );
      }
      else if (this.state.form_is_done) {
        return (<Redirect to={{
          pathname: '/FarmsEdit',
          state: {
            name: localStorage.getItem('current_editting_farm_name'),
            farm_id: localStorage.getItem('current_editting_farm')
          }
        }} />);
      }
    }

  }
}

export default DiseaseEdit;