
import React, { Component } from 'react';
import './Disease.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Redirect, Route, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { throwStatement } from '@babel/types';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TextInput from '../Fields/TextInput.js';
import {sendLog} from '../LogTest.js';

class Disease extends Component {
  constructor(props) {
    super(props);
    this.ghermezoo_disease_trees_count_onChange = this.ghermezoo_disease_trees_count_onChange.bind(this);
    this.state = {
      upload_modal: false,

      modal_1_color: 'gray',
      modal_2_color: 'gray',
      modal_3_color: 'gray',
      modal_4_color: 'gray',
      modal_5_color: 'gray',
      modal_6_color: 'gray',
      modal_7_color: 'gray',
      modal_8_color: 'gray',
      modal_9_color: 'gray',
      modal_10_color: 'gray',
      modal_11_color: 'gray',
      modal_12_color: 'gray',

      form_is_done: false,
      ghermezoo_disease_date: '',
      trees_yellow_leaves_date: '',
      trees_sarkhoshkidegi_date: '',
      trees_burned_leaves_edge_date: '',
      trees_bony_skin_date: '',
      trees_tanki_khooshe_date: '',
      trees_bud_fall_date: '',

      ghermezoo_disease_trees_count: '',

      trees_low_growth_rate_text: '',
      trees_are_small_text: '',
      surface_soil_hardness_text: '',
      depth_soil_hardness_text: '',
      sele_existance_text: '',
      ghermezoo_disease_text: '',
      trees_yellow_leaves_text: '',
      trees_sarkhoshkidegi_text: '',
      trees_burned_leaves_edge_text: '',
      trees_bony_skin_text: '',
      trees_tanki_khooshe_text: '',
      trees_bud_fall_text: '',


      Trees_low_growth_rate_img_id: '',
      trees_are_small_img_id: '',
      sele_existance_img_id: '',
      ghermezoo_disease_img_id: '',
      trees_yellow_leaves_img_id: '',
      trees_sarkhoshkidegi_img_id: '',
      trees_burned_leaves_edge_img_id: '',
      trees_bony_skin_img_id: '',
      trees_tanki_khooshe_img_id: '',
      trees_bud_fall_img_id: '',


      ghermezoo_disease_lvl: '',
      trees_yellow_leaves_lvl: '',
      trees_sarkhoshkidegi_lvl: '',
      trees_burned_leaves_edge_lvl: '',
      trees_bony_skin_lvl: '',
      trees_tanki_khooshe_lvl: '',
      trees_bud_fall_lvl: '',

      uploader_volume: 0,
      image_selected: false,
      Trees_low_growth_rate_modal: false,
      surface_soil_hardness_modal: false,
      trees_are_small_modal: false,
      depth_soil_hardness_modal: false,
      sele_existance_modal: false,
      ghermezoo_disease_modal: false,
      trees_yellow_leaves_modal: false,
      trees_sarkhoshkidegi_modal: false,
      trees_burned_leaves_edge_modal: false,
      trees_bony_skin_modal: false,
      trees_tanki_khooshe_modal: false,
      trees_bud_fall_modal: false,

      trees_burned_leaves_edge_type: '',

    }
  }


  onFarmRegister = (element, next) => {

    var data = {
      trees_low_growth: {
        img_id: this.state.Trees_low_growth_rate_img_id,
        text: this.state.trees_low_growth_rate_text,
      },
      trees_are_small: {
        img_id: this.state.trees_are_small_img_id,
        text: this.state.trees_are_small_text,
      },
      surface_soil_hardness: {
        text: this.state.surface_soil_hardness_text,
      },
      depth_soil_hardness: {
        text: this.state.depth_soil_hardness_text,
      },
      sele_existance: {
        text: this.state.sele_existance_text,
        img_id: this.state.sele_existance_img_id,
      },
      ghermezoo_disease: {
        level: this.state.ghermezoo_disease_lvl,
        img_id: this.state.ghermezoo_disease_img_id,
        date: this.state.ghermezoo_disease_date,
        text: this.state.ghermezoo_disease_text,
        trees_count: this.state.ghermezoo_disease_trees_count,
      },
      trees_yellow_leaves: {
        level: this.state.trees_yellow_leaves_lvl,
        img_id: this.state.trees_yellow_leaves_img_id,
        date: this.state.trees_yellow_leaves_date,
        text: this.state.trees_yellow_leaves_text,
      },
      trees_sarkhoshkidegi: {
        level: this.state.trees_sarkhoshkidegi_lvl,
        img_id: this.state.trees_sarkhoshkidegi_img_id,
        date: this.state.trees_sarkhoshkidegi_date,
        text: this.state.trees_sarkhoshkidegi_text,
      },
      trees_burned_leaves_edge: {
        level: this.state.trees_burned_leaves_edge_lvl,
        img_id: this.state.trees_burned_leaves_edge_img_id,
        date: this.state.trees_burned_leaves_edge_date,
        text: this.state.trees_burned_leaves_edge_text,
        type: this.state.trees_burned_leaves_edge_type,
      },
      trees_bony_skin: {
        level: this.state.trees_bony_skin_lvl,
        img_id: this.state.trees_bony_skin_img_id,
        date: this.state.trees_bony_skin_date,
        text: this.state.trees_bony_skin_text,
      },
      trees_tanki_khooshe: {
        level: this.state.trees_tanki_khooshe_lvl,
        img_id: this.state.trees_tanki_khooshe_img_id,
        date: this.state.trees_tanki_khooshe_date,
        text: this.state.trees_tanki_khooshe_text,
      },
      trees_bud_fall: {
        level: this.state.trees_bud_fall_lvl,
        img_id: this.state.trees_bud_fall_img_id,
        date: this.state.trees_bud_fall_date,
        text: this.state.trees_bud_fall_text,
      },

    }
    //console.log(data)
    const axios = require('axios');
    axios.post(' https://api.pestekaran.com/api/disease',
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        },

        api_token: localStorage.getItem('userTOKEN'),
        farm_id: localStorage.getItem('current_editting_farm'),
        data: data,


      })
      .then((response) => {
        //console.log(response)
        localStorage.setItem('current_editting_farm', response.data.farm_id);
        next();
        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'success-notifier-toast',
        });

        toast("اطلاعات عارضه باغ شما ثبت شد. لطفا بقیه بخش ها را کامل نمایید.");

        this.setState({ form_is_done: true })
      })
      .catch((error) => {
        next(false, 'مجددا تلاش نمایید!');
        sendLog(error.response);
      })
      .finally(function () {
        // always executed
      });
  }

  handleImageTrees_low_growth_rateSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {

        this.setState({ Trees_low_growth_rate_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");

        this.setState({ image_selected: false, upload_modal: false });


      })

  }

  handleImagetrees_are_smallSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log('i reached')
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ trees_are_small_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }

  handle_sele_existance_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {

        this.setState({ sele_existance_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }

  handle_ghermezoo_disease_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {

        this.setState({ ghermezoo_disease_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_yellow_leaves_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ trees_yellow_leaves_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_sarkhoshkidegi_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {

        this.setState({ trees_sarkhoshkidegi_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_burned_leaves_edge_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ trees_burned_leaves_edge_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_bony_skin_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ trees_bony_skin_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  handle_trees_tanki_khooshe_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ trees_tanki_khooshe_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }

  handle_trees_bud_fall_ImageSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ trees_bud_fall_img_id: response.data.id });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }



  openTrees_low_growth_rateModal() {
    this.setState({
      Trees_low_growth_rate_modal: true,
    });
  }

  opensurface_soil_hardnessModal() {
    this.setState({
      surface_soil_hardness_modal: true,
    });
  }

  opentrees_are_smallModal() {
    this.setState({
      trees_are_small_modal: true,
    });
  }

  opendepth_soil_hardnessModal() {
    this.setState({
      depth_soil_hardness_modal: true,
    });
  }

  opensele_existanceModal() {
    this.setState({
      sele_existance_modal: true,
    });
  }

  openghermezoo_diseaseModal() {
    this.setState({
      ghermezoo_disease_modal: true,
    });
  }

  opentrees_yellow_leavesModal() {
    this.setState({
      trees_yellow_leaves_modal: true,
    });
  }

  opentrees_sarkhoshkidegiModal() {
    this.setState({
      trees_sarkhoshkidegi_modal: true,
    });
  }

  opentrees_burned_leaves_edgeModal() {
    this.setState({
      trees_burned_leaves_edge_modal: true,
    });
  }

  opentrees_bony_skinModal() {
    this.setState({
      trees_bony_skin_modal: true,
    });
  }

  opentrees_tanki_khoosheModal() {
    this.setState({
      trees_tanki_khooshe_modal: true,
    });
  }

  opentrees_bud_fallModal() {
    this.setState({
      trees_bud_fall_modal: true,
    });
  }

  set_ghermezoo_disease_lvl = (newValue) => {
    this.setState({ ghermezoo_disease_lvl: newValue.label });
  }

  set_trees_yellow_leaves_lvl = (newValue) => {
    this.setState({ trees_yellow_leaves_lvl: newValue.label });
  }

  set_trees_sarkhoshkidegi_lvl = (newValue) => {

    this.setState({ trees_sarkhoshkidegi_lvl: newValue.label });
  }

  set_trees_burned_leaves_edge_lvl = (newValue) => {
    this.setState({ trees_burned_leaves_edge_lvl: newValue.label });
  }

  set_trees_bony_skin_lvl = (newValue) => {
    this.setState({ trees_bony_skin_lvl: newValue.label });
  }

  set_trees_tanki_khooshe_lvl = (newValue) => {

    this.setState({ trees_tanki_khooshe_lvl: newValue.label });
  }

  set_trees_bud_fall_lvl = (newValue) => {
    this.setState({ trees_bud_fall_lvl: newValue.label });
  }

  trees_low_growth_rate_text_change = (value) => {
    this.setState({ trees_low_growth_rate_text: value.target.value });
  }

  trees_are_small_text_change = (value) => {
    this.setState({ trees_are_small_text: value.target.value });
  }

  surface_soil_hardness_text_change = (value) => {
    this.setState({ surface_soil_hardness_text: value.target.value });
  }

  depth_soil_hardness_text_change = (value) => {
    this.setState({ depth_soil_hardness_text: value.target.value });
  }

  sele_existance_text_change = (value) => {
    this.setState({ sele_existance_text: value.target.value });
  }

  ghermezoo_disease_text_change = (value) => {
    this.setState({ ghermezoo_disease_text: value.target.value });
  }

  trees_yellow_leaves_text_change = (value) => {
    this.setState({ trees_yellow_leaves_text: value.target.value });
  }

  trees_sarkhoshkidegi_text_change = (value) => {
    this.setState({ trees_sarkhoshkidegi_text: value.target.value });
  }

  trees_burned_leaves_edge_text_change = (value) => {
    this.setState({ trees_burned_leaves_edge_text: value.target.value });
  }

  trees_bony_skin_text_change = (value) => {
    this.setState({ trees_bony_skin_text: value.target.value });
  }

  trees_tanki_khooshe_text_change = (value) => {
    this.setState({ trees_tanki_khooshe_text: value.target.value });
  }

  trees_bud_fall_text_change = (value) => {
    this.setState({ trees_bud_fall_text: value.target.value });
  }





  // Fields Dats start from here:

  ghermezoo_disease_date_change = (date) => {
    this.setState({ ghermezoo_disease_date: date })
  }

  trees_yellow_leaves_date_change = (date) => {
    this.setState({ trees_yellow_leaves_date: date })
  }

  trees_sarkhoshkidegi_date_change = (date) => {
    this.setState({ trees_sarkhoshkidegi_date: date })
  }

  trees_burned_leaves_edge_date_change = (date) => {
    this.setState({ trees_burned_leaves_edge_date: date })
  }

  trees_bony_skin_date_change = (date) => {
    this.setState({ trees_bony_skin_date: date })
  }

  trees_tanki_khooshe_date_change = (date) => {
    this.setState({ trees_tanki_khooshe_date: date })
  }

  trees_bud_fall_date_Change = (date) => {
    this.setState({ trees_bud_fall_date: date })
  }


  // Here starts types (only two):

  trees_burned_leaves_edge_type_change = (newValue) => {
    this.setState({ trees_burned_leaves_edge_type: newValue.label });
  }

  ghermezoo_disease_trees_count_onChange = (event) => {
    this.setState({ ghermezoo_disease_trees_count: event });
  }


  closeModal() {
    this.setState({
      Trees_low_growth_rate_modal: false,
      surface_soil_hardness_modal: false,
      trees_are_small_modal: false,
      depth_soil_hardness_modal: false,
      sele_existance_modal: false,
      ghermezoo_disease_modal: false,
      trees_yellow_leaves_modal: false,
      trees_sarkhoshkidegi_modal: false,
      trees_burned_leaves_edge_modal: false,
      trees_bony_skin_modal: false,
      trees_tanki_khooshe_modal: false,
      trees_bud_fall_modal: false
    });
  }
  render() {


    if (!this.state.form_is_done) {
      var UploadIndicator;
      if (this.state.image_selected == true) {

        UploadIndicator = <div className='circularWait' style={{ width: '15vw', height: '15vw', marginRight: 0 }}><CircularProgressbar value={this.state.uploader_volume} text={`${this.state.uploader_volume}%`} /></div>;

      } else if (this.state.image_selected == false) {

        UploadIndicator = <div></div>;

      }

      const trees_burned_leaves_edge_options = [
        { value: '1', label: 'حاشیه سوختگی زرد رنگ' },
        { value: '2', label: 'حاشیه سوختگی قهوه ای' },
        { value: '3', label: 'سایر' },
      ];

      const ghermezoo_disease = [
        { value: '1', label: 'کم' },
        { value: '2', label: 'متوسط' },
        { value: '3', label: 'زیاد' },
      ];
      return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

          <div style={{ // ***************************************** header ***********************************************************************************
            position: 'absolute',
            backgroundColor: '#007bffa8',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            width: '160vw',
            height: '80vw',
            borderRadius: '80vw / 40vw',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            left: '-32%',
            top: '-20%'
          }}
          >
          </div>


          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
              عارضه ها
              </div>
          </div>

          <Link style={{ color: 'white' }} to={{
            pathname: '/FarmsEdit',
            state: {
              name: localStorage.getItem('current_editting_farm_name'),
              farm_id: localStorage.getItem('current_editting_farm')
            }
          }}>
            <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
            </div>
          </Link>

          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Slide down>
              <div style={{ // ***************************************** Top card *****************************************************************************
                boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                display: 'flex',
                backgroundColor: '#1D75FA',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 8,
                width: '80vw',
                height: '20vh',
                marginTop: '9vh',
                borderRadius: 40,
                position: 'relative'
              }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                      <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                    </div>
                    <div style={{ marginRight: 8 }}>
                      {this.state.farms_count}
                    </div>
                  </div>

                </div>


                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                  <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white'}}>
                  در این بخش با دقت کامل اطلاعات عارضه های باغ خود را ثبت کنید.
                    </div>
                </div>
              </div>
            </Slide>
          </div>


          <div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>






            <div className="diseaseItems" onClick={() => this.openTrees_low_growth_rateModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_1_color }} />رشد کم درختان (رشد کم شاخه ها)</div>
            <Modal visible={this.state.Trees_low_growth_rate_modal} width="80%" height="80%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>رشد کم درختان:</div>
                  <div style={{ width: '10%' }}></div>
                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.trees_low_growth_rate_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles" class="hidden-input" onChange={this.handleImageTrees_low_growth_rateSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>
                    </label>
                  </div>
                </div>

                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_1_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>
              </div>
            </Modal>




            <div className="diseaseItems" onClick={() => this.opentrees_are_smallModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_2_color }} />کوچکی درختان با توجه به سنشان</div>
            <Modal visible={this.state.trees_are_small_modal} width="80%" height="80%" effect="fadeInUp" onClickAway={() => this.closeModal()}>

              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>کوچکی درختان:</div>
                  <div style={{ width: '10%' }}></div>

                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.trees_are_small_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles1" class="hidden-input" onChange={this.handleImagetrees_are_smallSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>

                    </label>
                  </div>

                </div>


                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_2_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>


              </div>
            </Modal>





            <div className="diseaseItems" onClick={() => this.opensurface_soil_hardnessModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_3_color }} />سختی و سفتی خاک سطحی</div>
            <Modal visible={this.state.surface_soil_hardness_modal} width="80%" height="80%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>سختی و سفتی خاک سطحی:</div>
                  <div style={{ width: '10%' }}></div>
                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.surface_soil_hardness_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>
                </div>

                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_3_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>

              </div>
            </Modal>



            <div className="diseaseItems" onClick={() => this.opendepth_soil_hardnessModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_4_color }} />سختی و سفتی خاک عمقی</div>
            <Modal visible={this.state.depth_soil_hardness_modal} width="80%" height="80%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>سختی و سفتی خاک عمقی:</div>
                  <div style={{ width: '10%' }}></div>
                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.depth_soil_hardness_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>
                </div>

                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_4_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>

              </div>
            </Modal>



            <div className="diseaseItems" onClick={() => this.opensele_existanceModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_5_color }} />وجود سله در سطح خاک</div>
            <Modal visible={this.state.sele_existance_modal} width="80%" height="80%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>وجود سله در سطح خاک:</div>
                  <div style={{ width: '10%' }}></div>
                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.sele_existance_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles2" class="hidden-input" onChange={this.handle_sele_existance_ImageSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles2" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>

                    </label>
                  </div>
                </div>

                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_5_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>
              </div>
            </Modal>







            <div className="diseaseItems" onClick={() => this.openghermezoo_diseaseModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_6_color }} />عارضه ریزبرگی یا قرمزو</div>
            <Modal visible={this.state.ghermezoo_disease_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>عارضه ریزبرگی یا قرمزو:</div>
                  <div style={{ width: '10%' }}></div>
                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.ghermezoo_disease_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>

                  <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                    <div>
                      میزان عارضه:
                    </div>
                    <Select
                      className=""
                      onChange={this.set_ghermezoo_disease_lvl}
                      style={{ textAlign: 'center', height: 35 }}
                      options={ghermezoo_disease}
                      placeholder='انتخاب کنید'
                    />
                  </div>

                  <DatePicker inputName='زمان بروز:' onChange={this.ghermezoo_disease_date_change} />

                  <TextInput inputName=' تعداد درختان دارای عارضه در باغ:' onChange={this.ghermezoo_disease_trees_count_onChange} InputPlaceHolder='' />

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles3" class="hidden-input" onChange={this.handle_ghermezoo_disease_ImageSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles3" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>

                    </label>
                  </div>

                </div>

                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_6_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>
              </div>
            </Modal>



            <div className="diseaseItems" onClick={() => this.opentrees_yellow_leavesModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_7_color }} />عارضه زردی برگ درختان</div>
            <Modal visible={this.state.trees_yellow_leaves_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>عارضه زردی برگ درختان:</div>
                  <div style={{ width: '10%' }}></div>
                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.trees_yellow_leaves_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>


                  <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                    <div>
                      میزان عارضه:
                    </div>
                    <Select
                      className=""
                      onChange={this.set_trees_yellow_leaves_lvl}
                      style={{ textAlign: 'center', height: 35 }}
                      options={ghermezoo_disease}
                      placeholder='انتخاب کنید'
                    />
                  </div>

                  <DatePicker inputName='زمان بروز:' onChange={this.trees_yellow_leaves_date_change} />

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles4" class="hidden-input" onChange={this.handle_trees_yellow_leaves_ImageSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles4" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>

                    </label>
                  </div>
                </div>

                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_7_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>
              </div>
            </Modal>




            <div className="diseaseItems" onClick={() => this.opentrees_sarkhoshkidegiModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_8_color }} />عارضه سرخشکیدگی</div>
            <Modal visible={this.state.trees_sarkhoshkidegi_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>عارضه سرخشکیدگی:</div>
                  <div style={{ width: '10%' }}></div>
                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.trees_sarkhoshkidegi_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>


                  <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                    <div>
                      میزان عارضه:
                    </div>
                    <Select
                      className=""
                      onChange={this.set_trees_sarkhoshkidegi_lvl}
                      style={{ textAlign: 'center', height: 35 }}
                      options={ghermezoo_disease}
                      placeholder='انتخاب کنید'
                    />
                  </div>


                  <DatePicker inputName='زمان بروز:' onChange={this.trees_sarkhoshkidegi_date_change} />


                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles5" class="hidden-input" onChange={this.handle_trees_sarkhoshkidegi_ImageSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles5" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>

                    </label>
                  </div>
                </div>

                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_8_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>
              </div>
            </Modal>


            <div className="diseaseItems" onClick={() => this.opentrees_burned_leaves_edgeModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_9_color }} />حاشیه سوختگی برگ</div>
            <Modal visible={this.state.trees_burned_leaves_edge_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>حاشیه سوختگی برگ:</div>
                  <div style={{ width: '10%' }}></div>
                </div>


                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.trees_burned_leaves_edge_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>


                  <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                    <div>
                      میزان عارضه:
                    </div>
                    <Select
                      className=""
                      onChange={this.set_trees_burned_leaves_edge_lvl}
                      style={{ textAlign: 'center', height: 35 }}
                      options={ghermezoo_disease}
                      placeholder='انتخاب کنید'
                    />
                  </div>


                  <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                    <div>
                      نوع:
                    </div>
                    <Select
                      className=""
                      onChange={this.trees_burned_leaves_edge_type_change}
                      style={{ textAlign: 'center', height: 35 }}
                      options={trees_burned_leaves_edge_options}
                      placeholder='انتخاب کنید'
                    />
                  </div>


                  <DatePicker inputName='زمان بروز:' onChange={this.trees_burned_leaves_edge_date_change} />


                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles6" class="hidden-input" onChange={this.handle_trees_burned_leaves_edge_ImageSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles6" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>

                    </label>
                  </div>

                </div>
                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_9_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>
              </div>
            </Modal>



            <div className="diseaseItems" onClick={() => this.opentrees_bony_skinModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_10_color }} />لکه پوست استخوانی</div>
            <Modal visible={this.state.trees_bony_skin_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>لکه پوست استخوانی:</div>
                  <div style={{ width: '10%' }}></div>
                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.trees_bony_skin_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>


                  <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                    <div>
                      میزان عارضه:
                    </div>
                    <Select
                      className=""
                      onChange={this.set_trees_bony_skin_lvl}
                      style={{ textAlign: 'center', height: 35 }}
                      options={ghermezoo_disease}
                      placeholder='انتخاب کنید'
                    />
                  </div>




                  <DatePicker inputName='زمان بروز:' onChange={this.trees_bony_skin_date_change} />



                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles7" class="hidden-input" onChange={this.handle_trees_bony_skin_ImageSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles7" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>

                    </label>
                  </div>


                </div>
                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_10_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>
              </div>
            </Modal>



            <div className="diseaseItems" onClick={() => this.opentrees_tanki_khoosheModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_11_color }} />تنکی خوشه</div>
            <Modal visible={this.state.trees_tanki_khooshe_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>تنکی خوشه:</div>
                  <div style={{ width: '10%' }}></div>
                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.trees_tanki_khooshe_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>


                  <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                    <div>
                      میزان عارضه:
                    </div>
                    <Select
                      className=""
                      onChange={this.set_trees_tanki_khooshe_lvl}
                      style={{ textAlign: 'center', height: 35 }}
                      options={ghermezoo_disease}
                      placeholder='انتخاب کنید'
                    />
                  </div>



                  <DatePicker inputName='زمان بروز:' onChange={this.trees_tanki_khooshe_date_change} />


                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles8" class="hidden-input" onChange={this.handle_trees_tanki_khooshe_ImageSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles8" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>

                    </label>
                  </div>
                </div>


                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_11_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>
              </div>
            </Modal>



            <div className="diseaseItems" onClick={() => this.opentrees_bud_fallModal()}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_12_color }} />ریزش جوانه ها ی گل</div>
            <Modal visible={this.state.trees_bud_fall_modal} width="95%" height="95%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
              <div className="modal-overlay">

                <div className="modal-title-container">
                  <div style={{ width: '10%' }}><CloseRoundedIcon onClick={ () => this.closeModal()} /></div>
                  <div style={{ width: '80%', marginTop: 8 }}>ریزش جوانه ها ی گل:</div>
                  <div style={{ width: '10%' }}></div>

                </div>

                <div style={{ height: '80%', padding: 16 }}>
                  <textarea style={{ borderRadius: 5 }} onChange={this.trees_bud_fall_text_change} placeholder="توضیحات ..." className='modal-ex-text' rows="5" cols="100"></textarea>


                  <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                    <div>
                      میزان عارضه:
                    </div>
                    <Select
                      className=""
                      onChange={this.set_trees_bud_fall_lvl}
                      style={{ textAlign: 'center', height: 35 }}
                      options={ghermezoo_disease}
                      placeholder='انتخاب کنید'
                    />
                  </div>

                  <DatePicker inputName='زمان بروز:' onChange={this.trees_bud_fall_date_Change} />

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%' }}>
                        <input type="file" id="myfiles9" class="hidden-input" onChange={this.handle_trees_bud_fall_ImageSelect} />
                        <label style={{ marginBottom: 0 }} for="myfiles9" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>

                    </label>
                  </div>

                </div>


                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} onClick={() => {this.closeModal(); this.setState({ modal_12_color: 'rgb(29, 117, 250)' });}}>
                  <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                </div>
              </div>
            </Modal>

            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 0,
              marginTop: '32px',
              marginBottom: '64px',
            }}>
              <AwesomeButtonProgress
                size='large'
                type='primary'
                disabled={false}
                fakePress={false}
                releaseDelay={2000}
                loadingLabel='در حال پردازش'
                resultLabel='ثبت شد!'
                onPress={this.onFarmRegister}
              >
                ثبت
    </AwesomeButtonProgress>
            </div>





          </div>

          <Modal visible={this.state.upload_modal} width="50%" height="20%" effect="fadeInDown">
            <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {UploadIndicator}
            </div>
          </Modal>

        </div>
      );
    }
    else if (this.state.form_is_done) {
      return (<Redirect to={{
        pathname: '/FarmsEdit',
        state: {
          name: localStorage.getItem('current_editting_farm_name'),
          farm_id: localStorage.getItem('current_editting_farm')
        }
      }} />);
    }


  }
}

export default Disease;