
import React, { Component } from 'react';
import './CorrectionOperation.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/creatable';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Loading from '../Loading.js';


export default class ChemicalFertilizerOAF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      form_is_Ready: false,

      last_year_color: '#36F1CD',
      last_year_check: 'block',
      last_year_cross: 'none',
      last_year_rout: 'CFOAFlastYearEdit',


      last_two_year_color: '#36F1CD',
      last_two_year_check: 'block',
      last_two_year_cross: 'none',
      last_two_year_rout: 'CFOAFlastTwoYearEdit',

    }
  }

  onSubmit = (element, next) => {
    next();
    toast.configure({
      position: "top-left",
      rtl: true,
      hideProgressBar: true,
      toastClassName: 'error-notifier-toast',
    });

    toast("ثبت شد");

    this.setState({ form_is_Ready: true })
  }



  componentDidMount = () => {

    this.setState({ loaded: true })
    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/farms',
      {

        params: {
          api_token: localStorage.getItem('userTOKEN'),
          id: parseInt(localStorage.getItem('current_editting_farm')),
        }
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        //console.log(response.data.length);

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].id === parseInt(localStorage.getItem('current_editting_farm'))) {

            if (response.data[i].fertilizerHistory.animalOnChemical.last_year == null) {
              this.setState({
                last_year_color: '#CE2D4F',
                last_year_check: 'none',
                last_year_cross: 'blcok',
                last_year_rout: 'CFOAFlastYear',
              })
            }
            if (response.data[i].fertilizerHistory.animalOnChemical.last_two_year == null) {
              this.setState({
                last_two_year_color: '#CE2D4F',
                last_two_year_check: 'none',
                last_two_year_cross: 'block',
                last_two_year_rout: 'CFOAFlastTwoYear',
              })
            }

          }
        }
        this.setState({ loaded: false })
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  render() {

    if (!this.state.form_is_Ready) {
      const JDate = require('jalali-date');
      const jdate = new JDate;
      if (this.state.loaded) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Loading />
          </div>
        );
      }
      else if (!this.state.loaded) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

          <div style={{ // ***************************************** header ***********************************************************************************
            position: 'absolute',
            backgroundColor: '#007bffa8',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            width: '160vw',
            height: '80vw',
            borderRadius: '80vw / 40vw',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            left: '-32%',
            top: '-20%'
          }}
          >
          </div>


          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
              کود حیوانی
          </div>
          </div>

          <Link style={{ color: 'white' }} to={{
            pathname: '/FarmsEdit',
            state: {
              name: localStorage.getItem('current_editting_farm_name'),
              farm_id: localStorage.getItem('current_editting_farm')
            }
          }}>
            <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
            </div>
          </Link>

          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Slide down>
              <div style={{ // ***************************************** Top card *****************************************************************************
                boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                display: 'flex',
                backgroundColor: '#1D75FA',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 8,
                width: '80vw',
                height: '20vh',
                marginTop: '9vh',
                borderRadius: 40,
                position: 'relative'
              }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                      <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                    </div>
                    <div style={{ marginRight: 8 }}>
                      {this.state.farms_count}
                    </div>
                  </div>

                </div>


                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                  <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white'}}>
                  در این بخش با دقت کامل اطلاعات کوددهی باغ خود را ثبت کنید.
                </div>
                </div>
              </div>
            </Slide>
          </div>


          <div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>

            <div className="frame">
              <Link to={this.state.last_year_rout} style={{ color: 'black' }}>
                <div style={{ position: 'relative', backgroundColor: this.state.last_year_color }} className="general-farm-info">
                  <div>
                    <CheckRoundedIcon style={{ display: this.state.last_year_check }} />
                  </div>
                  <div>
                    <ClearRoundedIcon style={{ display: this.state.last_year_cross }} />
                  </div>
                  <div style={{ width: '80%' }} className="correctionFormNotation">کاربرد کود شیمیایی روی کود حیوانی در سال{(jdate.getFullYear()) - 1}</div>
                </div>
              </Link>

            </div>


            <div className="frame">
              <Link to={this.state.last_two_year_rout} style={{ color: 'black' }}>
                <div style={{ position: 'relative', backgroundColor: this.state.last_two_year_color }} className="general-farm-info">
                  <div>
                    <CheckRoundedIcon style={{ display: this.state.last_two_year_check }} />
                  </div>
                  <div>
                    <ClearRoundedIcon style={{ display: this.state.last_two_year_cross }} />
                  </div>
                  <div style={{ width: '80%' }} className="correctionFormNotation">کاربرد کود شیمیایی روی کود حیوانی در سال{(jdate.getFullYear()) - 2}</div>
                </div>
              </Link>

            </div>

            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 0,
              marginTop: '32px',
              marginBottom: '64px',
            }}>
              <AwesomeButtonProgress
                size='large'
                type='primary'
                disabled={false}
                fakePress={false}
                releaseDelay={2000}
                loadingLabel='در حال پردازش'
                resultLabel='ثبت شد!'
                onPress={this.onSubmit}
              >
                ثبت
    </AwesomeButtonProgress>
            </div>

          </div>


        </div>
      );
    }
  }
    else if (this.state.form_is_Ready) {
      return (<Redirect to={{
        pathname: '/FarmsEdit',
        state: {
          name: localStorage.getItem('current_editting_farm_name'),
          farm_id: localStorage.getItem('current_editting_farm')
        }
      }} />);
    }
  }
}