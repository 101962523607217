import React, { Component } from 'react';
import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import LanguageIcon from '@material-ui/icons/Language';
import GroupIcon from '@material-ui/icons/Group';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import HelpIcon from '@material-ui/icons/Help';
import EmailIcon from '@material-ui/icons/Email';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import AddCommentIcon from '@material-ui/icons/AddComment';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import Message from './Message.js';

import QuestionForm from './QuestionForm.js';


const bottomNavigatorStyle = {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      padding: 3,
      backgroundColor: '#f7f7f7'
};

class LabelBottomNavigation extends Component {

  state = {
    value: 4,
    pathMap: [
      '/Home',
      '/QuestionForm',
      '/Message',
      '/AboutUs',
    ]
  };

  componentWillReceiveProps(newProps) {
    const {pathname} = newProps.location;
    const {pathMap} = this.state;

    const value = pathMap.indexOf(pathname);

    if (value > -1) {
      this.setState({
        value
      });
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {value, pathMap} = this.state;

    return (
      <BottomNavigation
      hideTabBar={true}
        value={value}
        onChange={this.handleChange}
        showLabels
        style={bottomNavigatorStyle}
        selected={{color: 'black'}}
      >
                <BottomNavigationAction style={{textDecoration: 'none'}} exact label="خانه" icon={<HomeRoundedIcon />} component={Link} to={pathMap[0]} />
                <BottomNavigationAction style={{textDecoration: 'none'}} label="پرسش" icon={<AddCommentIcon />} component={Link} to={pathMap[1]} />
                <BottomNavigationAction style={{textDecoration: 'none'}} label="پیام ها" icon={<EmailIcon />} component={Link} to={pathMap[2]} />
                <BottomNavigationAction style={{textDecoration: 'none'}} label="درباره ما" icon={<PersonPinCircleIcon />} component={Link} to={pathMap[3]} />



      </BottomNavigation>
    );
  }
}
export default withRouter(LabelBottomNavigation);
