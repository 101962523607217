import React, { Component } from 'react';
import { MetroSpinner } from "react-spinners-kit";
import './styles/Home.css';
import 'react-chat-widget/lib/styles.css';



  
export default class Loading extends Component {

  render() {

    return (
        <div>
            <div className='loader-container'>
                <div className='loader-spinner'><MetroSpinner size={60} color="white" loading={true} /></div>
                <div className='loader-text'>در حال پردازش ...</div>
            </div>
        </div>
    );
            }
}
