

export function sendLog  (errorMSG)  {
    if ( localStorage.getItem('user_credentials') != null ) {
    const axios = require('axios');

    axios.post('https://applogs.pestekaran.com/errors',
      {
          username: JSON.parse(localStorage.getItem('user_credentials')).name + ' ' + JSON.parse(localStorage.getItem('user_credentials')).id,
          error_description: JSON.stringify(errorMSG, undefined, 4)
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        ////console.log(response)
      })
      .catch((error) => {
        ////console.log(error)
      })
      .finally(function () {
        // always executed
      });
    }
  }
