
import React, { Component } from 'react';
import './styles/Pest.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BrowserRouter as Route, Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextInput from './Fields/TextInput.js';
import DatePicker from './Fields/DatePicker.js';
import './styles/AddNewFarm.css';
import Select from 'react-select';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Modal from 'react-awesome-modal';
import {sendLog} from './LogTest.js';



class PestFirstLevel extends Component {
  constructor() {
    super();
    this.state={
      image_selected: false,
      pest_identified_image: '',
      pest_unidentified_image: '',
      general_pest_image: '',
      pest_identified: 'none',
      pest_unidentified: 'none',
      farms_name: [],
      form_is_done: false,
    }
    
  }

  componentDidMount() {
    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/farms',
        {

            params: {
                api_token: localStorage.getItem('userTOKEN'),
            }
            ,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            //console.log(response);
            for (let i = 0; i < response.data.length; i++) {
                this.setState({
                    farms_name: [...this.state.farms_name, {
                        value: response.data[i].id,
                        label: response.data[i].name,
                    }]
                })
}
//console.log(this.state.farms_name);


})
.catch((error) => {
//console.log(error);
//console.log("code ghalate")
})
.finally(function () {
// always executed
}); 

}
pest_identified_image_change = (event) => {
  this.setState({ image_selected: true, upload_modal: true });
  //console.log((event.target.files[0].size) / 1024);
  localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
  var formData = new FormData();
  var imagefile = document.querySelector('#file');
  formData.append("file", event.target.files[0]);
  formData.append('api_token', localStorage.getItem('userTOKEN'));
  const config = {
    onUploadProgress: progressEvent => {
      var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
      //console.log(x);
      this.setState({ uploader_volume: x })
    }
  }

  axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
    }
  })
    .then((response) => {
//console.log(response.data.data);
      this.setState({ pest_identified_image: response.data.data });
      //console.log('22: ' + this.state.pest_identified_image)

      toast.configure({
        position: "top-left",
        rtl: true,

        hideProgressBar: true,
        toastClassName: 'img-uploaded-success-notifier-toast',
      });

      toast("تصویر شما با موفقیت بارگذاری شد!");
      this.setState({ image_selected: false, upload_modal: false });


    })

}

  general_pest_image_change = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
//console.log(response.data.data);
        this.setState({ general_pest_image: response.data.data });
        //console.log('11: ' + this.state.general_pest_image)

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }

  pest_unidentified_image_change = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
//console.log(response.data.data);
        this.setState({ pest_unidentified_image: response.data.data });

        toast.configure({
          position: "top-left",
          rtl: true,

          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })

  }


  on_submit = (element, next) => {


    if (this.state.pest_identified == 'block') {
      var q =  'باغ دچار آفت یا خسارت:  ' + this.state.address + '<br>' + 'مشکلات باغ از نظر آفات (تاریخچه آفات):  ' + this.state.pest_issues_discription 
      + '<br>' + 'عکس کلی از آفات:' + '<br>' + '<br>' + '<img class="chatimg" src="' + (this.state.general_pest_image) + '">' 
      + '<br>' + '<br>'
      + '<br>' + 'آفت توسط باغدار شناسایی شده است:  ' + this.state.pest_identified_by_user 
      + '<br>' + 'نام آفت شاسایی شده:  ' + this.state.pest_identified_by_user_name
      + '<br>' + 'عکس آفت شناسایی شده توسط باغدار:' + '<br>' + '<br>' + '<img class="chatimg" src="' + (this.state.pest_identified_image) + '">';
    }

    if (this.state.pest_unidentified == 'block') {
      var q =  'باغ دچار آفت یا خسارت:  ' + this.state.address + '<br>' + 'مشکلات باغ از نظر آفات (تاریخچه آفات):  ' + this.state.pest_issues_discription 
      + '<br>' + 'عکس کلی از آفات:' + '<br>' + '<br>' + '<img class="chatimg" src="' + (this.state.general_pest_image) + '">' 
      + '<br>' + '<br>'
      + '<br>' + 'آفت توسط باغدار شناسایی شده است:  ' + this.state.pest_identified_by_user 
      + '<br>' + 'آفت یا خسارت روی چه قسمتی از گیاه دیده شده:  ' + this.state.pest_unidentified_which_plant_area
      + '<br>' + 'عکس آفت ارسال شده توسط باغدار:' + '<br>' + '<br>' + '<img class="chatimg" src="' + (this.state.pest_unidentified_image) + '">';
    }



          axios.post('https://api.pestekaran.com/api/messages',
      {

        category_id: 63,
        api_token: localStorage.getItem('userTOKEN'),
        text: q,
        farm_id: this.state.Pest_farm_id
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {

        //console.log(response);
        axios.post('https://api.pestekaran.com/api/pest',
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          },
  
          api_token: localStorage.getItem('userTOKEN'),
          farm_id: this.state.Pest_farm_id,
          data: {
            General: {
              farm_name: this.state.address,
              pest_history: this.state.pest_issues_discription,
              general_pest_img_url: this.state.general_pest_image,
              pest_identified_by_gardener: this.state.pest_identified_by_user,
              pest_name_identified_by_gardener: this.state.pest_identified_by_user_name,
              pest_img_url_identified_by_gardener: this.state.pest_identified_image,
              exposed_unidentified_pest_targeted_part: this.state.pest_unidentified_which_plant_area,
              pest_img_url_unidentified_by_gardener: this.state.pest_unidentified_image
            }
          },
  
  
        })
        .then((response) => {
          next();
          toast.configure({
            position: "top-left",
            rtl: true,
            hideProgressBar: true,
            toastClassName: 'success-notifier-toast',
          });
  
          toast("درخواست شما ثبت شد. تا زمان پاسخ کارشناسان شکیبا باشید.");
  
          this.setState({ form_is_done: true })
        })
        .catch((error) => {
          next(false, 'مجددا تلاش نمایید!');
          sendLog(error.response);        
        })
        .finally(function () {
          // always executed
        });

      })
      .catch((error) => {
        next(false, 'مجددا تلاش نمایید!');
        this.setState({
          question_asked: true,
          log_text: 'اتصال خود به اینترنت را بررسی کنید و مجددا تلاش نمایید.'
        })

      })
      .finally(function () {
        // always executed
      });


// post for pest ******************************************************************************





  }

  render() {
    var UploadIndicator;
    if (this.state.image_selected == true) {

      UploadIndicator = <div className='circularWait' style={{ width: '15vw', height: '15vw', marginRight: 0 }}><CircularProgressbar value={this.state.uploader_volume} text={`${this.state.uploader_volume}%`} /></div>;

    } else if (this.state.image_selected == false) {

      UploadIndicator = <div></div>;

    }
    
    if (!this.state.form_is_done) {

    return (
<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
        <div style={{ // ***************************************** header ***********************************************************************************
          position: 'absolute',
          backgroundColor: '#007bffa8',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          width: '160vw',
          height: '80vw',
          borderRadius: '80vw / 40vw',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          left: '-32%',
          top: '-20%'
        }}
        >
        </div>


        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
            راهنمای کنترل آفات
                </div>
        </div>

        <Link to="/Pest" style={{ color: '#FFF' }}>
          <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
          </div>
        </Link>

        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Slide down>
            <div style={{ // ***************************************** Top card *****************************************************************************
              boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
              display: 'flex',
              backgroundColor: '#1D75FA',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 8,
              width: '80vw',
              height: '20vh',
              marginTop: '9vh',
              borderRadius: 40,
              position: 'relative'
            }}>

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                  </div>
                </div>

              </div>


              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                  لطفا سوالات زیر را برای پاسخ بهتر از سمت کارشناسان به دقت پاسخ دهید.
                      </div>
              </div>
            </div>
          </Slide>
        </div>


        <div style={{ padding: 0, overflow: 'hidden', overflowY: 'auto', height: '70vh', marginTop: '30vh' }}>


        <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', alignItems: 'flex-start', marginBottom: 16, padding: 16}}>

        <div style={{ textAlign: 'right', marginBottom: 32 }}>
                <div style={{ marginTop: 32 }}>
                    باغ دچار آفت یا خسارت:
                    </div>
                <Select
                    className=""
                    onChange={ (value) => { this.setState({ address:  value.label, Pest_farm_id: parseInt(value.value) }) } }
                    style={{ textAlign: 'center', height: 35 }}
                    options={this.state.farms_name}
                    placeholder='باغ'
                />
            </div>

                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <textarea
                    onChange={ (e) => { this.setState({ pest_issues_discription: e.target.value }) } }
                    style={{ borderRadius: 5}}
                    placeholder="مشکل یا مشکلات موجود در باغ از نظر آفات چیست؟ (تاریخچه آفات در باغ را هم توضیح هید)"
                    rows="5"
                    cols="100">
                </textarea>
                </div>


                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 16, marginBottom: 16 }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%', textAlign: 'center' }}>
                        <input type="file" id="myfiles" class="hidden-input" onChange={this.general_pest_image_change} />
                        <label style={{ marginBottom: 0 }} for="myfiles" style={{ color: 'white' }}>افزودن تصویر </label>
                      </div>
                    </label>
                  </div>

                  <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                    <div>
                      آیا آفت موجود که باعث خسارت شده را می شناسید؟
                    </div>
                    <Select
                      className=""
                      onChange={ (value) => {
                        this.setState({ pest_identified_by_user: value.label })
                        if (value.value == 1) { this.setState({ pest_identified: 'block', pest_unidentified: 'none'  }) }
                        if (value.value == 2) { this.setState({ pest_identified: 'none', pest_unidentified: 'block' }) }
                      }}
                      style={{ textAlign: 'center', height: 35 }}
                      options={[
                        { value: '1', label: 'بله' },
                        { value: '2', label: 'خیر' }
                      ]}
                      placeholder='انتخاب کنید'
                    />
                  </div>



<div style={{display: this.state.pest_identified, width: '100%'}}>
                  <TextInput inputName='نام آفت شناسایی شده:' onChange={value => { this.setState({ pest_identified_by_user_name: value }) }} InputPlaceHolder='پسیل (شیره خشک)' />

                  <div style={{  width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%', textAlign: 'center' }}>
                        <input type="file" id="myfiles1" class="hidden-input" onChange={this.pest_identified_image_change} />
                        <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن تصویر آفت</label>
                      </div>
                    </label>
                  </div>
</div>

<div style={{display: this.state.pest_unidentified}}>
                  <TextInput inputName='آفت یا خسارت روی چه قسمتی از گیاه است؟' onChange={value => { this.setState({ pest_unidentified_which_plant_area: value }) }} InputPlaceHolder='قسمتی از گیاه که آفت زده ...' />


                  <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <textarea
                    onChange={ (e) => { this.setState({ pest_unidentified_issue_discription: e.target.value }) } }
                    style={{ borderRadius: 5}}
                    placeholder="آفت یا خسارت چه مشکلی به وجود آورده است؟"
                    rows="5"
                    cols="100">
                </textarea>
                </div>



                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 16 }}>
                    <label className="addImageContainer">
                      <div style={{ display: 'flex', width: '20%' }}>
                        <CropOriginalIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ width: '60%', textAlign: 'center' }}>
                        <input type="file" id="myfiles2" class="hidden-input" onChange={this.pest_unidentified_image_change} />
                        <label style={{ marginBottom: 0 }} for="myfiles2" style={{ color: 'white' }}>افزودن تصویر آفت</label>
                      </div>
                    </label>
                  </div>
</div>




<div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 0,
              marginTop: '32px',
              marginBottom: '64px',
            }}>
              <AwesomeButtonProgress
                size='large'
                type='primary'
                disabled={false}
                fakePress={false}
                releaseDelay={2000}
                loadingLabel='در حال پردازش'
                resultLabel='ثبت شد!'
                onPress={this.on_submit}
              >
                ثبت
    </AwesomeButtonProgress>
            </div>


</div>


</div>
<Modal visible={this.state.upload_modal} width="50%" height="20%" effect="fadeInDown">
            <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {UploadIndicator}
            </div>
          </Modal>
</div>

);
          }
          else if (this.state.form_is_done) {
            return (<Redirect to='/Home' />);
          }

}
}

export default PestFirstLevel;