import React, { Component } from 'react';
import '../styles/OnlyPlaceHolderTextInput.css';

class TextInput extends Component {
    constructor(props) {
        super(props);
        this.changetextInputvalue = this.changetextInputvalue.bind(this);
        this.state = { textInputvalue: this.props.initialValue };
        
      }
      changetextInputvalue (event) {
        const inputText = event.target.value;
        this.setState({textInputvalue: event.target.value});
        this.props.onChange(inputText);
      }
    render() {
        return (
            <div className='inputContainerC'>
                <div className="inputContainerChildPlaceHolder">
                    <input className="effect-1" type="text" placeholder={this.props.inputName} value={this.state.textInputvalue} onChange={this.changetextInputvalue}/>
                    <span className="focus-border"></span>
                </div>
            </div>
        );
    }
}
export default TextInput;