import firebase from 'firebase';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyBEG10NWmllI6oG58kmFRMTqfTtBQgC-m4",
    authDomain: "fir-test-d528c.firebaseapp.com",
    databaseURL: "https://fir-test-d528c.firebaseio.com",
    projectId: "fir-test-d528c",
    storageBucket: "fir-test-d528c.appspot.com",
    messagingSenderId: "32592993272",
    appId: "1:32592993272:web:cdfe00e079cc1f2bbd940d",
    measurementId: "G-47WDF524VX"
  });
  firebase.analytics();
}

export const askForPermissioToReceiveNotifications = async () => {
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      localStorage.setItem('fcm_token', token);
      
      return token;
    } catch (error) {
      console.error(error);
    }
  }