
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import TextInput from '../Fields/TextInput.js';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';

export default class RegionWise extends Component {

  constructor(props) {
    super(props);
    this.state = {
        farmed: 'none',
      address: '',
      uploader_volume: number,
      image_selected: false,

    };
  }
  componentDidMount() {

  }
  handleTextChange = (e) => {
    console.log(e.target.value);
    this.setState({
      address: e.target.value
    })
  };
  questionSubmit = (element, next) => {
    var temp_text
    if (this.state.farmed == 'none') {
         temp_text = ' استان:  ' + this.state.province + '<br>' + ' شهر:  ' + this.state.city + '<br>' + 'آدرس:  ' + this.state.address + '<br>' + 'سابقه کاشت پسته:  ' + this.state.farming_bg;
    }
    if (this.state.farmed == 'block') {
         temp_text = ' استان:  ' + this.state.province + '<br>' + ' شهر:  ' + this.state.city + '<br>' + 'آدرس:  ' + this.state.address + '<br>' + 'سابقه کاشت پسته:  ' + this.state.farming_bg + '<br>' + 'سن باغ:  ' + this.state.farm_age + '<br>' + 'وضعیت باغ:  ' + this.state.farm_satatus + '<br>' + 'وضعیت محصول دهی:  ' + this.state.harvest_status;
    }
    const axios = require('axios');

    axios.post('https://api.pestekaran.com/api/messages',
      {

        category_id: 57,
        api_token: localStorage.getItem('userTOKEN'),
        text: temp_text,
        farm_id: ''
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {

        console.log(response);
        next();
        this.setState({
          question_asked: true,
          log_text: 'درخواست شما ثبت شد. تا زمان پاسخ کارشناسان شکیبا باشید.'
        })



      })
      .catch((error) => {
        next(false, 'مجددا تلاش نمایید!');
        this.setState({
          question_asked: true,
          log_text: 'اتصال خود به اینترنت را بررسی کنید و مجددا تلاش نمایید.'
        })

      })
      .finally(function () {
        // always executed
      });
  }






  render() {


    return (
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
        <div style={{ // ***************************************** header ***********************************************************************************
          position: 'absolute',
          backgroundColor: '#007bffa8',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          width: '160vw',
          height: '80vw',
          borderRadius: '80vw / 40vw',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          left: '-32%',
          top: '-20%'
        }}
        >
        </div>


        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
             بررسی اقلیمی یا آب و هوایی
                </div>
        </div>

        <Link to="/Pistachioable" style={{ color: '#FFF' }}>
          <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
          </div>
        </Link>

        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Slide down>
            <div style={{ // ***************************************** Top card *****************************************************************************
              boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
              display: 'flex',
              backgroundColor: '#1D75FA',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 8,
              width: '80vw',
              height: '20vh',
              marginTop: '9vh',
              borderRadius: 40,
              position: 'relative'
            }}>

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                  </div>
                  <div style={{ marginRight: 8 }}>
                    {this.state.farms_count}
                  </div>
                </div>

              </div>


              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                  برای بررسی اقلیمی یا آب و هوایی توسط کارشناسان، فرم زیر را پر بفرمایید.
                      </div>
              </div>
            </div>
          </Slide>
        </div>


        <div style={{ overflow: 'hidden', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 32, height: '70vh', marginTop: '30vh' }} ref={(el) => { this.messagesEnd = el; }}>
          <div style={{width: '100%'}}>

          <TextInput inputName='استان:' onChange={ (value) => { this.setState({ province: value }) }} InputPlaceHolder='کرمان' />

          <TextInput inputName='شهر:' onChange={ (value) => { this.setState({ city: value }) }}  InputPlaceHolder='رفسنجان'/>


            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 16}}>
            <textarea
              onChange={e => {
                this.setState({address: e.target.value})
              }}
              placeholder="آدرس دقیق خود را بنویسید."
              rows="5"
              cols="100">
            </textarea>
            </div>

              <div style={{ marginBottom: 32, textAlign: 'right' }}>
              <div>
                آیا سابقه پسته کاری در منطقه وجود دارد؟
                    </div>
              <Select
                className=""
                onChange={ (value) => {
                  if (value.value == 1) { this.setState({ farmed: 'block' })}
                  if (value.value == 2) { this.setState({ farmed: 'none' })}
                  this.setState({ farming_bg: value.label })
                }}
                style={{ textAlign: 'center', height: 35 }}
                options={[
                  { value: '1', label: 'بله' },
                  { value: '2', label: 'خیر' },
                ]}
                placeholder='سابقه کاشت'
              />
            </div>

<div style={{ display: this.state.farmed }}>
<TextInput inputName='باغ شما چند ساله است؟' onChange={ (value) => { this.setState({ farm_age: value }) }}  InputPlaceHolder='برای مثال: 5'/>

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 16}}>
            <textarea
              onChange={e => {
                  this.setState({farm_satatus: e.target.value})
                }}
              placeholder="وضعیت باغ:"
              rows="5"
              cols="100">
            </textarea>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 16}}>
            <textarea
              onChange={e => {
                this.setState({harvest_status: e.target.value})
              }}
              placeholder="وضعیت محصول دهی:"
              rows="5"
              cols="100">
            </textarea>
            </div>

</div>
            <div style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  zIndex: 0,
                  marginTop: '64px',
                  marginBottom: '64px',
                }}>
              <AwesomeButtonProgress
                size='large'
                type='primary'
                disabled={false}
                fakePress={false}
                releaseDelay={2000}
                loadingLabel='در حال پردازش'
                resultLabel='ثبت شد!'
                onPress={this.questionSubmit}
              >
                ثبت
    </AwesomeButtonProgress>
            </div>

          </div>
        </div>



      </div>
    );
  }
}

/*
{(element, next) => {
        console.log("What's up bro...")

        setTimeout(() => {
          next(false, 'خطا!')
        }, 600)
      }}
*/

