import React from 'react';
import Select from 'react-select';
import '../styles/Input.css';
const options = [
  { value: 'chocolate', label: 'انتخاب 1' },
  { value: 'strawberry', label: 'انتخاب 2' },
  { value: 'vanilla', label: 'Vanilla' },
];

export default class PersianDatePicker extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <div className='inputContainer'>
<div className='fieldName'>نام:</div>
<div className="col-9">
      <Select
        onfocus="blur()"
        placeholder="انتخاب کنید" 
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
      />
      </div>
</div>
    );
  }
}
