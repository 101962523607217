
import React, { Component } from 'react';
import './styles/CodeConfirmation.css';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import App from '../App.js';
import RegPart1 from './RegPart1.js'
import Countdown from 'react-countdown-now';
import { IndexedDB } from 'react-indexed-db';
import { useIndexedDB } from 'react-indexed-db';
import { AccessDB } from 'react-indexed-db';
import Dexie from 'dexie';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import {sendLog} from './LogTest.js';

class CodeConfirmation extends Component {
    constructor(props) {
        super(props);
        this.changeVerificationCode = this.changeVerificationCode.bind(this);
        this.sendCodeConfirm = this.sendCodeConfirm.bind(this);
        this.state = {smsCode: '', showConfirmButton: false, apiToken: '', id: ''};
        
      }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }
      
      componentDidMount() {
        this.scrollToBottom();
      }
      
      componentDidUpdate() {
        this.scrollToBottom();
      }
      changeVerificationCode (event) {
        this.setState({smsCode: event.target.value});
        //console.log((event.target.value).length);
            
         if ((event.target.value).length == 4) { 
          //console.log((event.target.value));
        const axios = require('axios');
        axios.post('https://api.pestekaran.com/api/sms/auth', 
        {
          
            phone_number: localStorage.getItem('phone_number'),
            sms: event.target.value
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          //console.log(response);
          localStorage.setItem('user_credentials', JSON.stringify(response.data.data));
          if(response.data.error == 0) {

              this.setState({showConfirmButton: true, apiToken: response.data.data.api_token, id: response.data.data.id});
              localStorage.setItem('userID', response.data.data.id);
              localStorage.setItem('userTOKEN', response.data.data.api_token);
          }
          else {console.log("")}
        })
        .catch((error) => {
          sendLog(error.response);
        })
        .finally(function () {
          // always executed
        }); 
      

    }
  }
    changeButtonState = () => {
this.setState({showConfirmButton: true});
    }
      sendCodeConfirm = () => { 

      }


  render() {

    const Completionist = () => <span>کد ارسالی منقضی شد. دوباره تلاش نمایید.</span>;
    if (this.state.showConfirmButton) {
      var myButton =  <Link to="/RegPart1" style={{color: '#FFF'}}><button onClick={this.sendCodeConfirm} className="regCodeConfirmButton">تایید کد </button></Link>
    }
    return (
      <TouchScrollable>
        <React.Fragment>
      <div className='codeConfirmBg1' ref={(el) => { this.messagesEnd = el; }}>
        <div className="codeInputContainer">
            <input type="tel" placeholder="کد ارسال شده" className="phoneInput" value={this.state.smsCode} onChange={this.changeVerificationCode}/>
          </div>

        {myButton} 
     <div className='countDown'>
      <Countdown zeroPadTime={1} date={Date.now() + 90000}>
         
          </Countdown>
          </div>
      </div>
      
        </React.Fragment>
        </TouchScrollable>
    );
  }
}

export default CodeConfirmation;
export var db;