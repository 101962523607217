
import React, { Component } from 'react';
import './SubmitNewFarm.css';
import axios from 'axios';
import 'react-circular-progressbar/dist/styles.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'; import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Redirect, Link } from 'react-router-dom';
import Loading from '../Loading.js';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';


class FarmsEdit extends Component {
  constructor() {
    super();
    this.state = {
      form_is_ready: false,
      farm_name: '',
      loaded: false,
      general_details_status_color: '#36F1CD',
      correctiveOperation_status_color: '#36F1CD',
      disease_status_color: '#36F1CD',
      fertilizerHistory_status_color: '#36F1CD',
      irrigation_status_color: '#36F1CD',

      general_details_status_check: 'unset',
      general_details_status_cross: 'none',
      correctiveOperation_status_check: 'unset',
      correctiveOperation_status_cross: 'none',
      disease_status_check: 'unset',
      disease_status_cross: 'none',
      fertilizerHistory_status_check: 'unset',
      fertilizerHistory_status_cross: 'none',
      irrigation_status_check: 'unset',
      irrigation_status_cross: 'none',

      general_details_navigation: '/GeneralFarmDetails',
      correctiveOperation_navigation: '/CorrectionOperationEdit',
      disease_navigation: '/DiseaseEdit',
      fertilizerHistory_navigation: '/FertilizerBackgroundEdit',
      irrigation_navigation: '/WateringEdit',



    }
  }




  componentDidMount() {

    localStorage.setItem('current_editting_farm_name', this.props.location.state.name)
    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/farms',
      {

        params: {
          api_token: localStorage.getItem('userTOKEN'),
          id: parseInt(localStorage.getItem('current_editting_farm')),
        }
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        console.log(response.data.length);

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].id === parseInt(localStorage.getItem('current_editting_farm'))) {

            if (response.data[i].general_details == null) {
              this.setState({
                general_details_status_color: '#CE2D4F',
                general_details_status_check: 'none',
                general_details_status_cross: 'unset'
              })

            }
            if (response.data[i].correctiveOperation == null) {
              this.setState({
                correctiveOperation_status_color: '#CE2D4F',
                correctiveOperation_status_check: 'none',
                correctiveOperation_status_cross: 'unset',
                correctiveOperation_navigation: '/CorrectionOperation'
              })

            }
            if (response.data[i].disease == null) {
              this.setState({
                disease_status_color: '#CE2D4F',
                disease_status_check: 'none',
                disease_status_cross: 'unset',
                disease_navigation: '/Disease'
              })

            }
            if (response.data[i].fertilizerHistory.animal_fertilizer == null ||
              response.data[i].fertilizerHistory.chemical == null ||
              response.data[i].fertilizerHistory.spraying == null ||
              response.data[i].fertilizerHistory.animalOnChemical.last_year == null ||
              response.data[i].fertilizerHistory.animalOnChemical.last_two_year == null) {
              this.setState({
                fertilizerHistory_status_color: '#CE2D4F',
                fertilizerHistory_status_check: 'none',
                fertilizerHistory_status_cross: 'unset',
                fertilizerHistory_navigation: '/FertilizerBackground'
              })

            }
            if (response.data[i].irrigation == null) {
              this.setState({
                irrigation_status_color: '#CE2D4F',
                irrigation_status_check: 'none',
                irrigation_status_cross: 'unset',
                irrigation_navigation: '/Watering'
              })

            }

            this.setState({ farm_name: response.data[i].name });

          }
        }
        this.setState({ loaded: true })
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

  }



  onSubmit = () => {
    if (localStorage.getItem('current_farm_id') == '') {
      toast.configure({
        position: "top-left",
        rtl: true,
        hideProgressBar: true,
        toastClassName: 'error-notifier-toast',
      });

      toast("ابتدا اطلاعات عمومی باغتان را پر نمایید.");
    }
    else {
      toast.configure({
        position: "top-left",
        rtl: true,
        hideProgressBar: true,
        toastClassName: 'error-notifier-toast',
      });

      toast("باغ شما با موفقیت ثبت شد");

      this.setState({ form_is_ready: true })
    }
  }
  render() {

    //console.log(this.props.location.state.farm_id);
    if (!this.state.loaded) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading />
        </div>
      );
    }
    else if (this.state.loaded) {


      if (!this.state.form_is_ready) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

            <div style={{ // ***************************************** header ***********************************************************************************
              position: 'absolute',
              backgroundColor: '#007bffa8',
              boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
              width: '160vw',
              height: '80vw',
              borderRadius: '80vw / 40vw',
              boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
              left: '-32%',
              top: '-20%'
            }}
            >
            </div>


            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                ویرایش باغ {localStorage.getItem('current_editting_farm_name')}
              </div>
            </div>

            <Link to="/Farms" style={{ color: '#FFF' }}>
              <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
              </div>
            </Link>

            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Slide down>
                <div style={{ // ***************************************** Top card *****************************************************************************
                  boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                  display: 'flex',
                  backgroundColor: '#1D75FA',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 8,
                  width: '80vw',
                  height: '20vh',
                  marginTop: '9vh',
                  borderRadius: 40,
                  position: 'relative'
                }}>

                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                      <div>
                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                      </div>
                      <div style={{ marginRight: 8 }}>
                        {this.state.farms_count}
                      </div>
                    </div>

                  </div>


                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                    <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white'}}>
                      در این بخش می توانید اطلاعات مختلف باغ مورد نظر خود را مشاهده یا ویرایش کنید.
                    </div>
                  </div>
                </div>
              </Slide>
            </div>

            <div style={{ padding: 0, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>



              <div className="frame" style={{ marginTop: '3vh' }}>

                <Link to='/GeneralFarmDetails' style={{ color: 'black' }}>
                  <div style={{ position: 'relative', backgroundColor: this.state.general_details_status_color }} className="general-farm-info">
                    <div>
                      <CheckRoundedIcon style={{ display: this.state.general_details_status_check }} />
                    </div>
                    <div>
                      <ClearRoundedIcon style={{ display: this.state.general_details_status_cross }} />
                    </div>
                    <div style={{ width: '80%' }} className="correctionFormNotation">اطلاعات عمومی باغ</div>
                  </div>
                </Link>

              </div>


              <div className="frame">

                <Link to={this.state.disease_navigation} style={{ color: 'black' }}>
                  <div style={{ position: 'relative', backgroundColor: this.state.disease_status_color }} className="general-farm-info">
                    <div>
                      <CheckRoundedIcon style={{ display: this.state.disease_status_check }} />
                    </div>
                    <div>
                      <ClearRoundedIcon style={{ display: this.state.disease_status_cross }} />
                    </div>
                    <div style={{ width: '80%' }} className="correctionFormNotation">عارضه های موجود در باغ</div>
                  </div>
                </Link>

              </div>




              <div className="frame">

                <Link to={this.state.irrigation_navigation} style={{ color: 'black' }}>
                  <div style={{ position: 'relative', backgroundColor: this.state.irrigation_status_color }} className="general-farm-info">
                    <div>
                      <CheckRoundedIcon style={{ display: this.state.irrigation_status_check }} />
                    </div>
                    <div>
                      <ClearRoundedIcon style={{ display: this.state.irrigation_status_cross }} />
                    </div>
                    <div style={{ width: '80%' }} className="correctionFormNotation">آبیاری</div>
                  </div>
                </Link>

              </div>


              <div className="frame">

                <Link to={this.state.correctiveOperation_navigation} style={{ color: 'black' }}>
                  <div style={{ position: 'relative', backgroundColor: this.state.correctiveOperation_status_color }} className="general-farm-info">
                    <div>
                      <CheckRoundedIcon style={{ display: this.state.correctiveOperation_status_check }} />
                    </div>
                    <div>
                      <ClearRoundedIcon style={{ display: this.state.correctiveOperation_status_cross }} />
                    </div>
                    <div style={{ width: '80%' }} className="correctionFormNotation">عملیات اصلاحی</div>
                  </div>
                </Link>

              </div>


              <div className="frame" style={{ marginBottom: '10vh' }}>

                <Link to='/FertilizerBackground' style={{ color: 'black' }}>
                  <div style={{ position: 'relative', backgroundColor: this.state.fertilizerHistory_status_color }} className="general-farm-info">
                    <div>
                      <CheckRoundedIcon style={{ display: this.state.fertilizerHistory_status_check }} />
                    </div>
                    <div>
                      <ClearRoundedIcon style={{ display: this.state.fertilizerHistory_status_cross }} />
                    </div>
                    <div style={{ width: '80%' }} className="correctionFormNotation">سابقه کوددهی</div>
                  </div>
                </Link>

              </div>



            </div>



          </div>
        );
      }
      else if (this.state.form_is_ready) {
        return (<Redirect to='/Farms' />);
      }


    }


  }



}

export default FarmsEdit;