import React, { Component } from 'react';
import '../styles/DatePicker.css';
import Modal from 'react-awesome-modal';
import DatePicker from './DatePicker.js';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

export default class DatPickerEditable extends Component {
    constructor(props) {
        super(props);
        this.changetextInputvalue = this.changetextInputvalue.bind(this);
        this.state = { textInputvalue: this.props.initialValue, dateEdit: false };

    }
    onFinalDateChange = value => {
        localStorage.setItem('first_date', value);
        this.props.onChange(value);
    };

    changetextInputvalue(event) {
        const inputText = event.target.value;
        this.setState({ textInputvalue: event.target.value });
        this.props.onChange(inputText);
    }
    render() {
        return (
            <div className='inputContainerC' style={{marginBottom: 32}}>
                <div className='fieldName'>{this.props.inputName}</div>
                <div className="inputContainerChild" style={{width: '91%'}}>
                    <input
                        type="text" placeholder=""
                        value={this.state.textInputvalue}
                        onChange={this.changetextInputvalue}
                        readOnly
                    />
                    <div style={{ color: 'gray', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} onClick={() => this.setState({ dateEdit: true })}>
                        <div><EditRoundedIcon /></div>
                        <div style={{fontSize: 12, marginRight: 4}}>ویرایش</div>    
                    </div>
                </div>


                <Modal visible={this.state.dateEdit} width="80%" height="45%" effect="fadeInUp" onClickAway={() => this.setState({ dateEdit: false })}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%', padding: 8 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', padding: '5px' }}><CloseRoundedIcon onClick={() => { this.setState({ dateEdit: false }) }} style={{ color: 'red' }} /></div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
               
               
                <div style={{ marginTop: 5, marginRight: 8, fontSize: 12, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%', justifyContent: 'center' }}>
                            <DatePicker inputName='ویرایش تاریخ:' onChange={this.onFinalDateChange} /> 
                                                           </div>


                                                           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                          <div
                            onClick={() => { this.setState({ dateEdit: false, textInputvalue: localStorage.getItem('first_date') }); }}
                            style={{
                              width: '40%',
                              height: '40%',
                              boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                              backgroundColor: '#0857c3',
                              border: '1px solid rgb(3, 77, 179)',
                              borderRadius: 40,
                              color: 'white',
                              padding: '12px 16px',
                              fontSize: 12,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                            تایید </div>


                          <div
                            onClick={() => { this.setState({ dateEdit: false }) }} 
                            style={{
                              width: '40%',
                              height: '40%',
                              boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                              backgroundColor: '#0857c3',
                              border: '1px solid rgb(3, 77, 179)',
                              borderRadius: 40,
                              color: 'white',
                              padding: '12px 16px',
                              fontSize: 12,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                            بازگشت </div>
                        </div>

                            </div>
                    </div>
                </Modal>

            </div>
        );
    }
}