
import React, { Component } from 'react';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BrowserRouter as Route, Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';




class Pistachioable extends Component {
  constructor() {
    super();
    
  }
componentDidMount () {
  localStorage.setItem('back_from_requests', true)
}
  render() {
    
    return (
<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
        <div style={{ // ***************************************** header ***********************************************************************************
          position: 'absolute',
          backgroundColor: '#007bffa8',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          width: '160vw',
          height: '80vw',
          borderRadius: '80vw / 40vw',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          left: '-32%',
          top: '-20%'
        }}
        >
        </div>


        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
            امکان سنجی کشت
                </div>
        </div>

        <Link to="/Home" style={{ color: '#FFF' }}>
          <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
          </div>
        </Link>

        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Slide down>
            <div style={{ // ***************************************** Top card *****************************************************************************
              boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
              display: 'flex',
              backgroundColor: '#1D75FA',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 8,
              width: '80vw',
              height: '20vh',
              marginTop: '9vh',
              borderRadius: 40,
              position: 'relative'
            }}>

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                  </div>
                </div>

              </div>


              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

              <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: '#dee2e6' }}>
                جهت امکان سنجی کشت پسته در مناطق جدید، بررسی مورد نیاز خود را انتخاب کنید.
                </div>
              </div>
            </div>
          </Slide>
        </div>


        <div style={{ padding: 0, overflow: 'hidden', overflowY: 'auto', height: '70vh', marginTop: '30vh' }}>



        
          
        <Link to={{ pathname: '/RegionWise', state: { cat_id: 57 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
          
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#EF476F' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 16, color: '#EF476F' }}>بررسی اقلیمی یا آب‌ و هوایی</h6>
                </Slide>
              </div>
            </div>
            
          </div></Link>

          <Link to={{ pathname: '/WaterWise', state: { cat_id: 58 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
          
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: 'rgb(38, 84, 124)' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 16, color: 'rgb(38, 84, 124)' }}>بررسی آب‌</h6>
                </Slide>
              </div>
            </div>
            
          </div></Link>

          
          <Link to={{ pathname: '/SoilWise', state: { cat_id: 59 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
          
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: 'rgb(86, 31, 55)' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 16, color: 'rgb(86, 31, 55)' }}>بررسی خاک</h6>
                </Slide>
              </div>
            </div>
            
          </div></Link>





</div>

</div>

);

}
}

export default Pistachioable;