import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import './styles/simpleList.css';
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    marginBottom: 100,
    marginRight: 10,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SimpleList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List className="listContainer" component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <div>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
          </div>
          <Link style={{ color: 'black' }} to="/Appointment">
            <div>
              <ListItemText primary="درخواست ملاقات حضوری" />
            </div>
          </Link>
        </ListItem>
        <Divider />
        <ListItem className="list" button>
          <div>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
          </div>
          <Link style={{ color: 'black' }} to="/FarmVisit">
            <div>
              <ListItemText style={{ fontFamily: "vazir" }} primary="درخواست بازدید از باغ" />
            </div>
          </Link>
        </ListItem>
        <Divider />
        <ListItem className="list" button>
          <div>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
          </div>
          <Link style={{ color: 'black' }} to="/Pistachioable">

            <div>
              <ListItemText primary="بررسی امکان کشت پسته در مناطق جدید" />
            </div>
          </Link>
        </ListItem>
        <Divider />
        <ListItem className="list" button>
          <div>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
          </div>
          <Link style={{ color: 'black' }} to="/Blog">
            <div>
              <ListItemText primary="مطالب عمومی" />
            </div>
          </Link>
        </ListItem>
      </List>
    </div>
  );
}