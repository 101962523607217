
import React, { Component } from 'react';
import './styles/RegPart1.css';
import { BrowserRouter as Router, Route, Link, histoy, withRouter, Redirect } from 'react-router-dom';
import App from '../App.js';
import JobPicker from './Fields/JobPicker.js';
import EducationPicker from './Fields/EducationPicker.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import TextInput from './Fields/TextInput.js';
import DatePicker from './Fields/DatePicker.js';
import './styles/AddNewFarm.css';
import Select from 'react-select';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {sendLog} from './LogTest.js';
import TouchAppRoundedIcon from '@material-ui/icons/TouchAppRounded';
import TermsAndConditions from './TermsAndConditions.js';

class RegPart1 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      exit_button_modal: false,
      name: null,
      lName: '',
      finalDate: '',
      education_lvl_value: '',
      major: '',
      job_type: '',
      farming_history: '',
      location: '',
      extra_explaination: '',
      user_exist: false,
      conditions_modal: false
    };

  }

  setConditionsModalState = () => {
    this.setState({ conditions_modal: false })
  }

  profileUpdate = (element, next) => {

    if (this.state.name == null || this.state.name == '' || this.state.finalDate == null || this.state.finalDate == '') {
      //console.log('Fuccccccccccccccccccccccccccck')
      this.setState({ exit_button_modal: true });
      setTimeout(() => {
        next(false, 'مجددا تلاش نمایید!');
      }, 600)
    }
    else {
    const axios = require('axios');
    axios.put('https://api.pestekaran.com/api/profile',
      {

        id: parseInt(localStorage.getItem('userID')),
        name: this.state.name,
        birth_day: this.state.finalDate,
        education: this.state.education_lvl_value,
        profession: this.state.job_type,
        major: this.state.major,
        farming_background: this.state.farming_history,
        resident: this.state.location,
        farm_location: this.state.extra_explaination,
        api_token: localStorage.getItem('userTOKEN')
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
        }
      })
      .then(function (response) {
        //console.log(response);
        next();
        localStorage.setItem('user_credentials', JSON.stringify(response.data));
        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });
        

        toast("اطلاعات شما ثبت شد.");
        window.location.reload(false);
      })
      .catch(function (error) {
        next(false, 'مجددا تلاش نمایید!');
        sendLog(error.response);
        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'error-notifier-toast',
        });

        toast("اتصال خود به اینترنت را بررسی نمایید، در غیر اینصورت بعدا تلاش نمایید. با تشکر!");
      })
      .finally(function () {
        // always executed
      });
    }
  }


  onNameInputChage = value => {
    this.setState({
      name: value
    });
    //console.log("I am Parent component. I got", value, "from my child.");
  };


  onLnameInputChage = value => {
    this.setState({
      lName: value
    });
    //console.log("I am Parent component. I got", value, "from my child.");
  };


  onFarmingHistoryChange = value => {
    this.setState({
      farming_history: value
    });
  };


  onExtraExplainationChange = value => {
    this.setState({
      extra_explaination: value
    });
  };


  onLocationChange = value => {
    this.setState({
      location: value
    });

  };


  onFinalDateChange = value => {
    this.setState({
      finalDate: value
    });
  };


  jobTypeChange = (newValue) => {
    //console.log(typeof(newValue.label));
    this.setState({
      job_type: newValue.label,
    })
  };


  handleEducationLevelChange = (newValue) => {
    //console.log(newValue.label);
    this.setState({
      education_lvl_value: newValue.label,
    })
  };


  onMajorInputChage = value => {
    this.setState({
      major: value
    });
  };
  

      
      componentDidMount() {
        if (JSON.parse(localStorage.getItem('user_credentials')).name != '') {this.setState({user_exist: true})}
      }
 
  render() {
var x = JSON.parse(localStorage.getItem('user_credentials'));

    const educationLevel = [ 
      {value:'1', label: 'دکتری'},
      {value:'2', label: 'فوق لیسانس'},
      {value:'3', label: 'لیسانس'},
      {value:'4', label: 'فوق دیپلم'},
      {value:'5', label: 'دیپلم'},
      {value:'6', label: 'بی سواد'},
 ];
 const jobType = [ 
  {value:'1', label: 'فقط کشاورزی'},
  {value:'2', label: 'شغل اصلي كشاورزي'},
  {value:'3', label: 'شغل غیر اصلی (دوم یا بیشتر) کشاورزی'},
];


if (!this.state.user_exist) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>


      <div style={{ // ***************************************** header ***********************************************************************************
        position: 'absolute',
        backgroundColor: '#007bffa8',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        width: '160vw',
        height: '80vw',
        borderRadius: '80vw / 40vw',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        left: '-32%',
        top: '-20%'
      }}
      >
      </div>


      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
          ثبت نام
          </div>
      </div>


      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Slide down>
              <div style={{ // ***************************************** Top card *****************************************************************************
                boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                display: 'flex',
                backgroundColor: '#1D75FA',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 8,
                width: '80vw',
                height: '20vh',
                marginTop: '9vh',
                borderRadius: 40,
                position: 'relative'
              }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                      <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                    </div>
                    <div style={{ marginRight: 8 }}>
                      
                    </div>
                  </div>

                </div>


                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                  <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white'}}>
                    لطفا اطلاعات زیر را به دقت پر نمایید.
                    </div>
                </div>
              </div>
            </Slide>
          </div>


          <Modal visible={this.state.exit_button_modal} width="70%" height="30%" effect="fadeInDown" onClickAway={() => { this.setState({ exit_button_modal: false }) }}>
                  <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><CloseRoundedIcon onClick={() => { this.setState({ exit_button_modal: false }) }} style={{ color: 'red' }} /></div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>

                      <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                        لطفا موارد زیر را کامل نمایید:
                                  </div>
                          <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                           <ul style={{ textAlign: 'right' }}>
                            { (this.state.name == '' || this.state.name == null) && <li>نام و نام خانوادگی شما نمی تواند خالی باشد!</li>}
                            { (this.state.finalDate == '' || this.state.finalDate == null) && <li>تاریخ تولد شما نمی تواند خالی باشد!</li>}
                            
                            </ul>
                          </div>

                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                        <div 
                        onClick={() => { this.setState({ exit_button_modal: false }) }} style={{ color: 'red' }}
                        style={{
                          width: '40%',
                          height: '70%',
                          boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                          backgroundColor: '#0857c3',
                          border: '1px solid rgb(3, 77, 179)',
                          borderRadius: 40,
                          color: 'white',
                          padding: '12px 16px',
                          fontSize: 12,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                           ادامه </div>
                      </div>


                    </div>


                  </div>

                </Modal>


      <div style={{ overflow: 'hidden', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 32, height: '70vh', marginTop: '30vh' }}>
      <div style={{ width: '100%' }}>
      <TextInput inputName='نام و نام خانوادگی:' onChange={this.onNameInputChage} />

          <DatePicker inputName='تاریخ تولد:' onChange={this.onFinalDateChange} />

          <div style={{ marginBottom: 32, textAlign: 'right' }}>
              <div>
              مدرک تحصیلی:
                    </div>
              <Select
                className=""
                style={{ textAlign: 'center', height: 35 }}
                onChange={this.handleEducationLevelChange}
                options={educationLevel}
                placeholder='انتخاب مدرک'
              />
            </div>

            <TextInput inputName='رشته تحصیلی:' onChange={this.onMajorInputChage} />

            <div style={{ marginBottom: 32, textAlign: 'right' }}>
              <div>
              شغل:
                    </div>
              <Select
                className=""
                style={{ textAlign: 'center', height: 35 }}
                onChange={this.jobTypeChange}
                options={jobType}
                placeholder='انتخاب شغل'
              />
            </div>

            <TextInput inputName='سابقه فعالیت (سال):' onChange={this.onFarmingHistoryChange} />

          <TextInput inputName='محل سکونت:' onChange={this.onLocationChange} />

          <TextInput inputName='توضیحات (اختیاری):' onChange={this.onExtraExplainationChange} />

            <div onClick={() => { this.setState({ conditions_modal: true }) }} style={{ marginTop: 16, width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'right', justifyContent: 'flex-start', alignItems: 'center' }}>


              <TouchAppRoundedIcon style={{ color: 'red', width: '6vh', height: '6vh' }} />

              <div style={{ textAlign: 'center' }}>
                کاربر عزیز، ثبت نام در برنامه نشان می دهد شما با تمامی قوانین و مقررات مجموعه پسته کاران موافقت نموده اید. (برای مطالعه کلیک کنید)
              </div>

            </div>


            <Modal visible={this.state.conditions_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ conditions_modal: false }) }}>

              <div style={{ height: '100%', width: '100%', zIndex: 90000, overflow: 'auto' }}>
                <TermsAndConditions sendBoolToRegPage={this.setConditionsModalState} />
              </div>
            </Modal>


          <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 0,
              marginTop: '32px',
              marginBottom: '64px',}}>
              <AwesomeButtonProgress
              size='large'
              type='primary'
              disabled={false}
              fakePress={false}
              releaseDelay={2000}
              loadingLabel='در حال پردازش'
              resultLabel='ثبت شد!'
              onPress={this.profileUpdate}
            >
              ثبت
    </AwesomeButtonProgress>
          </div>
</div>
      </div>


      </div>
    );
  }
  else if (this.state.user_exist) {
  return (
<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>


<div style={{ // ***************************************** header ***********************************************************************************
  position: 'absolute',
  backgroundColor: '#007bffa8',
  boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
  width: '160vw',
  height: '80vw',
  borderRadius: '80vw / 40vw',
  boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
  left: '-32%',
  top: '-20%'
}}
>
</div>


<div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
  <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
    پسته کاران
    </div>
</div>


<div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Slide down>
        <div style={{ // ***************************************** Top card *****************************************************************************
          boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
          display: 'flex',
          backgroundColor: '#1D75FA',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 8,
          width: '80vw',
          height: '20vh',
          marginTop: '9vh',
          borderRadius: 40,
          position: 'relative'
        }}>

          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
              </div>
              <div style={{ marginRight: 8 }}>
                
              </div>
            </div>

          </div>


          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center' }}>
            {x.name} عزیز خوش آمدید.
              </div>
          </div>
        </div>
      </Slide>
    </div>


<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>


    <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 0,
        marginTop: '32px',
        marginBottom: '64px',}}>
        <AwesomeButtonProgress
        size='large'
        type='primary'
        disabled={false}
        fakePress={false}
        releaseDelay={2000}
        loadingLabel='در حال پردازش'
        resultLabel='ثبت شد!'
        onPress={ (element, next) => {
          window.location.reload(false);
            next();

        }}
      >
        ورود
</AwesomeButtonProgress>
    </div>





</div>


</div>
  );  
  }
  
}
}

export default RegPart1;


