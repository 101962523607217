
import React, { Component } from 'react';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FertilizerBackgroundEdit from '../FarmsEdit/FertilizerBackgroundEdit';
import Loading from '../Loading.js';

class FertilizerBackground extends Component {
  constructor() {
    super();
    this.state = {
      loaded: true,
      form_is_ready: false,

      animal_fertilizer_status_color: '#36F1CD',
      animal_fertilizer_status_check: 'block',
      animal_fertilizer_status_cross: 'none',
      animal_rout: 'AnimalFertizilerEdit',

      chemical_fertilizer_status_color: '#36F1CD',
      chemical_fertilizer_status_check: 'block',
      chemical_fertilizer_status_cross: 'none',
      chemical_fertilizer_router: '/ChemicalFertilizerDuringGrowthEdit',

      spraying_fertilizer_status_color: '#36F1CD',
      spraying_fertilizer_status_check: 'block',
      spraying_fertilizer_status_cross: 'none',
      spraying_fertilizer_route: 'SprayingSolutionFertilizerEdit',

      animalOnchemical_fertilizer_status_color: '#36F1CD',
      animalOnchemical_fertilizer_status_check: 'block',
      animalOnchemical_fertilizer_status_cross: 'none',

    }

  }

  componentDidMount = () => {
    this.setState({ loaded: true })
    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/farms',
      {

        params: {
          api_token: localStorage.getItem('userTOKEN'),
          id: parseInt(localStorage.getItem('current_editting_farm')),
        }
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        //console.log(response.data.length);

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].id === parseInt(localStorage.getItem('current_editting_farm'))) {
            if (response.data[i].fertilizerHistory.animal_fertilizer == null) {
              this.setState({
                animal_fertilizer_status_color: '#CE2D4F',
                animal_fertilizer_status_check: 'none',
                animal_fertilizer_status_cross: 'block',
                animal_rout: 'AnimalFertiziler',
              })
            }
            if (response.data[i].fertilizerHistory.chemical == null) {
              this.setState({
                chemical_fertilizer_status_color: '#CE2D4F',
                chemical_fertilizer_status_check: 'none',
                chemical_fertilizer_status_cross: 'block',
                chemical_fertilizer_router: '/ChemicalFertilizerDuringGrowth',
              })
            }
            if (response.data[i].fertilizerHistory.spraying == null) {
              this.setState({
                spraying_fertilizer_status_color: '#CE2D4F',
                spraying_fertilizer_status_check: 'none',
                spraying_fertilizer_status_cross: 'block',
                spraying_fertilizer_route: '/SprayingSolutionFertilizer',
              })
            }
            if (response.data[i].fertilizerHistory.animalOnChemical.last_year == null || response.data[i].fertilizerHistory.animalOnChemical.last_two_year == null) {
              this.setState({
                animalOnchemical_fertilizer_status_color: '#CE2D4F',
                animalOnchemical_fertilizer_status_check: 'none',
                animalOnchemical_fertilizer_status_cross: 'block',
              })
            }

          }
        }
        this.setState({ loaded: false })
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  onSubmit = (element, next) => {
    toast.configure({
      position: "top-left",
      rtl: true,
      hideProgressBar: true,
      toastClassName: 'error-notifier-toast',
      });
         
     toast("ثبت شد");   
next();
this.setState({form_is_ready: true})
  }
  render() {
if (!this.state.loaded) {
if (!this.state.form_is_ready) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

      <div style={{ // ***************************************** header ***********************************************************************************
          position: 'absolute',
          backgroundColor: '#007bffa8',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          width: '160vw',
          height: '80vw',
          borderRadius: '80vw / 40vw',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          left: '-32%',
          top: '-20%'
      }}
      >
      </div>


      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
              سابقه کوددهی
      </div>
      </div>

      <Link style={{ color: 'white' }} to={{
          pathname: '/FarmsEdit',
          state: {
              name: localStorage.getItem('current_editting_farm_name'),
              farm_id: localStorage.getItem('current_editting_farm')
          }
      }}>
          <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
          </div>
      </Link>

      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Slide down>
              <div style={{ // ***************************************** Top card *****************************************************************************
                  boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                  display: 'flex',
                  backgroundColor: '#1D75FA',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 8,
                  width: '80vw',
                  height: '20vh',
                  marginTop: '9vh',
                  borderRadius: 40,
                  position: 'relative'
              }}>

                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                          <div>
                              <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                          </div>
                          <div style={{ marginRight: 8 }}>
                              {this.state.farms_count}
                          </div>
                      </div>

                  </div>


                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                      <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white'}}>
                      در این بخش با دقت کامل اطلاعات عارضه های باغ خود را ثبت کنید.
            </div>
                  </div>
              </div>
          </Slide>
      </div>


      <div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>







<div className="frame">

<Link to={this.state.animal_rout} style={{ color: 'black' }}>
  <div style={{ position: 'relative', backgroundColor: this.state.animal_fertilizer_status_color }} className="general-farm-info">
    <div>
      <CheckRoundedIcon style={{ display: this.state.animal_fertilizer_status_check }} />
    </div>
    <div>
      <ClearRoundedIcon style={{ display: this.state.animal_fertilizer_status_cross }} />
    </div>
    <div style={{ width: '80%' }} className="correctionFormNotation">کود حیوانی</div>
  </div>
</Link>

</div>

<div className="frame">
<Link to='/ChemicalFertilizerOAF' style={{ color: 'black' }}>
  <div style={{ position: 'relative', backgroundColor: this.state.animalOnchemical_fertilizer_status_color }} className="general-farm-info">
    <div>
      <CheckRoundedIcon style={{ display: this.state.animalOnchemical_fertilizer_status_check }} />
    </div>
    <div>
      <ClearRoundedIcon style={{ display: this.state.animalOnchemical_fertilizer_status_cross }} />
    </div>
    <div style={{ width: '80%' }} className="correctionFormNotation">کود شیمیایی روی کود حیوانی</div>
  </div>
</Link>

</div>


<div className="frame">
<Link to={this.state.chemical_fertilizer_router} style={{ color: 'black' }}>
  <div style={{ position: 'relative', backgroundColor: this.state.chemical_fertilizer_status_color }} className="general-farm-info">
    <div>
      <CheckRoundedIcon style={{ display: this.state.chemical_fertilizer_status_check }} />
    </div>
    <div>
      <ClearRoundedIcon style={{ display: this.state.chemical_fertilizer_status_cross }} />
    </div>
    <div style={{ width: '80%' }} className="correctionFormNotation">
      <div>کود شیمیایی در طول فصل رشد</div>
      <div>(کود آبیاری و سرپاش)</div>
      </div>
  </div>
</Link>

</div>


<div className="frame">
<Link to={this.state.spraying_fertilizer_route} style={{ color: 'black' }}>
  <div style={{ position: 'relative', backgroundColor: this.state.spraying_fertilizer_status_color }} className="general-farm-info">
    <div>
      <CheckRoundedIcon style={{ display: this.state.spraying_fertilizer_status_check }} />
    </div>
    <div>
      <ClearRoundedIcon style={{ display: this.state.spraying_fertilizer_status_cross }} />
    </div>
    <div style={{ width: '80%' }} className="correctionFormNotation">محلول پاشی کودها</div>
  </div>
</Link>

</div>





<div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      zIndex: 0,
      marginTop: '32px',
      marginBottom: '64px',
    }}>
      <AwesomeButtonProgress
        size='large'
        type='primary'
        disabled={false}
        fakePress={false}
        releaseDelay={2000}
        loadingLabel='در حال پردازش'
        resultLabel='ثبت شد!'
        onPress={this.onSubmit}
      >
        ثبت
</AwesomeButtonProgress>
    </div>


</div>



      </div>
    );
}
else if(this.state.form_is_ready) {
  return(<Redirect to={{
      pathname: '/FarmsEdit',
      state: {
        name: localStorage.getItem('current_editting_farm_name'),
        farm_id: localStorage.getItem('current_editting_farm')
      }
    }} />);
}
  }
  else if (this.state.loaded) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loading />
      </div>
    );
  }
}
}

export default FertilizerBackground;