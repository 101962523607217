
import React, { Component } from 'react';
import './CorrectionOperation.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/creatable';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TextInput from '../Fields/TextInput.js';
import AcUnitRoundedIcon from '@material-ui/icons/AcUnitRounded';
import {sendLog} from '../LogTest.js';

export default class AnimalFertiziler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_1_color: 'gray',
            modal_2_color: 'gray',
            modal_3_color: 'gray',
            modal_4_color: 'gray',

            animal_fertilizer_usage_frequency: '',
            animal_fertilizer_usage_date: '',


            animal_fertilizer_usage_method_modal: false,

            animal_fertilizer_usage_method: '',
            animal_fertilizer_usage_ton_per_hectare: '',
            animal_fertilizer_usage_depo_or_direct: '',
            animal_fertilizer_usage_effects_explanation: '',

            
            animal_fertilizer_type_modal: false,

            animal_fertilizer_type: '',
            animal_fertilizer_type_others_explanation: '',
            animal_fertilizer_mix_explanation: '',
            chemical_fertilizer_on_animal_fertilizer: '',


            animal_fertilizer_best_choice_modal: false,

            animal_fertilizer_best_choice: '',
            animal_fertilizer_best_choice_others_explanation: '',
            animal_fertilizer_best_choice_mix_explanation: '',



            uploader_volume: number,
            image_selected: false,
        }
    }

    on_farm_fertilizer_submit = (element, next) => {

    var animal_fertilizer =  {
        animal_fertilizer_usage_frequency: this.state.animal_fertilizer_usage_frequency,
        animal_fertilizer_usage_date: this.state.animal_fertilizer_usage_date,



        animal_fertilizer_usage_method: {
        animal_fertilizer_usage_method: this.state.animal_fertilizer_usage_method,
        animal_fertilizer_usage_ton_per_hectare: this.state.animal_fertilizer_usage_ton_per_hectare,
        animal_fertilizer_usage_depo_or_direct: this.state.animal_fertilizer_usage_depo_or_direct,
        animal_fertilizer_usage_effects_explanation: this.state.animal_fertilizer_usage_effects_explanation,
        },
        
        animal_fertilizer_type: {
        animal_fertilizer_type: this.state.animal_fertilizer_type,
        animal_fertilizer_type_others_explanation: this.state.animal_fertilizer_type_others_explanation,
        animal_fertilizer_mix_explanation: this.state.animal_fertilizer_mix_explanation,
        chemical_fertilizer_on_animal_fertilizer: this.state.chemical_fertilizer_on_animal_fertilizer,
        },

        animal_fertilizer_best_choice: {
            animal_fertilizer_best_choice: this.state.animal_fertilizer_best_choice,
            animal_fertilizer_best_choice_others_explanation: this.state.animal_fertilizer_best_choice_others_explanation,
            animal_fertilizer_best_choice_mix_explanation: this.state.animal_fertilizer_best_choice_mix_explanation,
        }
        };


localStorage.setItem('animal_fertilizer', JSON.stringify(animal_fertilizer));

        
          
                const axios = require('axios');
                axios.post('https://api.pestekaran.com/api/fertilizer/animal', 
                {
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json'
                  },
                  
                  api_token: localStorage.getItem('userTOKEN'),
                  farm_id: localStorage.getItem('current_editting_farm'),
                  data: animal_fertilizer,
                  
          
                })
                .then((response) => {
                  //console.log(response);
                  next();
                    toast.configure({
                        position: "top-left",
                        rtl: true,
                        hideProgressBar: true,
                        toastClassName: 'success-notifier-toast',
                        });
                           
                       toast("اصلاعات آبیاری باغ شما با موفقیت ثبت شد.");  
                       this.setState({form_is_done: true}) 
            
                })
                .catch((error) => {
                    next(false, 'مجددا تلاش نمایید!');
                    sendLog(error.response);
                  })
                .finally(function () {
                  // always executed
                }); 
              }


              closeModal = () => {

                this.setState({
                    animal_fertilizer_best_choice_modal: false,
                    animal_fertilizer_type_modal: false,
                    animal_fertilizer_usage_method_modal: false,
                    animal_fertilizer_usage_modal: false
                })

              }

    render() {

        return(


<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

<div style={{ // ***************************************** header ***********************************************************************************
    position: 'absolute',
    backgroundColor: '#007bffa8',
    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
    width: '160vw',
    height: '80vw',
    borderRadius: '80vw / 40vw',
    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
    left: '-32%',
    top: '-20%'
}}
>
</div>


<div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
    کود حیوانی
</div>
</div>

<Link style={{ color: 'white' }} to={{
    pathname: '/FertilizerBackground',
    state: {
        name: localStorage.getItem('current_editting_farm_name'),
        farm_id: localStorage.getItem('current_editting_farm')
    }
}}>
    <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
    </div>
</Link>

<div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
    <Slide down>
        <div style={{ // ***************************************** Top card *****************************************************************************
            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
            display: 'flex',
            backgroundColor: '#1D75FA',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 8,
            width: '80vw',
            height: '20vh',
            marginTop: '9vh',
            borderRadius: 40,
            position: 'relative'
        }}>

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                    </div>
                    <div style={{ marginRight: 8 }}>
                        {this.state.farms_count}
                    </div>
                </div>

            </div>


            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center' }}>
                در این بخش با دقت کامل اطلاعات کوددهی باغ خود را ثبت کنید.
      </div>
            </div>
        </div>
    </Slide>
</div>


<div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>

                    <div className="wateringItems" onClick={() => this.setState({animal_fertilizer_usage_modal: true})}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_1_color }} />کاربرد کود حیوانی</div>
                    <Modal visible={this.state.animal_fertilizer_usage_modal} width="95%" height="80%" effect="fadeInUp" onClickAway={() => this.setState({ animal_fertilizer_usage_modal: false })}>
                        <div className="modal-overlay">

                            <div className="modal-title-container">
                                <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                <div style={{ width: '80%', marginTop: 8 }}>کاربرد کود حیوانی:</div>
                                <div style={{ width: '10%' }}></div>
                            </div>

                            <div style={{ height: '80%', padding: 16 }}>


                                <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                    <div>
                                        معمولا هر چندسال یکبار از کود حیوانی استفاده می کنید؟
                                </div>
                                    <Select
                                        className=""
                                        onChange={value => {
                                            this.setState({ animal_fertilizer_usage_frequency: value.label })
                                        }}
                                        style={{ textAlign: 'center', height: 35 }}
                                        options={[
                                            { value: '1', label: '1' },
                                            { value: '2', label: '2' },
                                            { value: '3', label: '3' },
                                            { value: '4', label: '4' },
                                            { value: '5', label: '5' },
                                            { value: '6', label: 'بیشتر' },
                                        ]}
                                        placeholder='انتخاب کنید'
                                    />
                                </div>

                                <TextInput inputName='زمان استفاده:'
                                    onChange={value => {
                                        this.setState({ animal_fertilizer_usage_date: value})
                                    }}
                                    InputPlaceHolder='برای مثال دی، بهمن' />
                            </div>

                            <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} 
                                 onClick={() => { this.closeModal();
                                                  this.setState({ modal_1_color: 'rgb(29, 117, 250)' }) }}>
                                <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                            </div>
                        </div>
                    </Modal>

                <div className="wateringItems" onClick={() => this.setState({animal_fertilizer_usage_method_modal: true})}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_2_color }} />روش کاربرد کود حیوانی در باغ</div>
                    <Modal visible={this.state.animal_fertilizer_usage_method_modal} width="95%" height="80%" effect="fadeInUp" onClickAway={() => this.setState({ animal_fertilizer_usage_method_modal: false })}>
                        <div className="modal-overlay">

                            <div className="modal-title-container">
                                <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                <div style={{ width: '80%', marginTop: 8 }}>روش کاربرد کود:</div>
                                <div style={{ width: '10%' }}></div>
                            </div>
                            <div style={{ height: '80%', padding: 16, overflow: 'auto' }}>
                                <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                    <div>
                                        روش کاربرد کود حیوانی در باغ:
                                </div>
                                    <Select
                                        className=""
                                        onChange={(value) => {
                                            this.setState({ animal_fertilizer_usage_method: value.label })
                                        }}
                                        style={{ textAlign: 'center', height: 35 }}
                                        options={[
                                            { value: '1', label: 'چالکود' },
                                            { value: '2', label: 'سطحی' },
                                            { value: '3', label: 'هر دو' },
                                        ]}
                                        placeholder='انتخاب کنید'
                                    />
                                </div>


                                <TextInput inputName='مقدار معمول کاربرد کود حیوانی:'
                                    onChange={value => {
                                        this.setState({ animal_fertilizer_usage_ton_per_hectare: value })
                                    }}
                                    InputPlaceHolder='برای مثال: 20 (تن در هکتار)' />



                                <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                    <div>
                                        آیا کود حیوانی را اصطلاحا دپو کرده و می پوسانید یا مستقیم استفاده میکنید؟
                                </div>
                                    <Select
                                        className=""
                                        onChange={(value) => {
                                            this.setState({ animal_fertilizer_usage_depo_or_direct: value.label })
                                        }}
                                        style={{ textAlign: 'center', height: 35 }}
                                        options={[
                                            { value: '1', label: 'دپو' },
                                            { value: '2', label: 'مستقیم' },
                                        ]}
                                        placeholder='انتخاب کنید'
                                    />
                                </div>


                                <textarea
                                    style={{ borderRadius: 5 }}
                                    onChange={value => {
                                        this.setState({ animal_fertilizer_usage_effects_explanation: value.target.value })
                                    }}
                                    placeholder="به نظر شما اثرات کلی کود حیوانی چگونه بوده است؟"
                                    className='modal-ex-text'
                                    rows="5"
                                    cols="100">
                                </textarea>
                            </div>
                            <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}                                  
                                 onClick={() => { this.closeModal();
                                                  this.setState({ modal_2_color: 'rgb(29, 117, 250)' }) }}>
                                <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                            </div>
                        </div>
                    </Modal>

                <div className="wateringItems" onClick={() => this.setState({animal_fertilizer_type_modal: true})}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_3_color }} />نوع کود حیوانی</div>
                    <Modal visible={this.state.animal_fertilizer_type_modal} width="95%" height="85%" effect="fadeInUp" onClickAway={() => this.setState({ animal_fertilizer_type_modal: false })}>
                        <div className="modal-overlay">

                            <div className="modal-title-container">
                                <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                <div style={{ width: '80%', marginTop: 8 }}>نوع کود حیوانی:</div>
                                <div style={{ width: '10%' }}></div>
                            </div>
                            <div style={{ height: '80%', padding: 16, overflow: 'auto' }}>


                                <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                    <div>
                                        معمولا از چه نوع کود حیوانی استفاده می کنید؟
                                </div>
                                    <Select
                                        className=""
                                        onChange={(value) => {
                                            this.setState({ animal_fertilizer_type: value.label })
                                        }}
                                        style={{ textAlign: 'center', height: 35 }}
                                        options={[
                                            { value: '1', label: 'گاوی' },
                                            { value: '2', label: 'گوسفندی' },
                                            { value: '3', label: 'مرغی' },
                                            { value: '4', label: 'ماهی' },
                                            { value: '5', label: 'مخلوط' },
                                            { value: '6', label: 'سایر' },
                                        ]}
                                        placeholder='انتخاب کنید'
                                    />
                                </div>

                                <textarea
                                    style={{ borderRadius: 5 }}
                                    onChange={value => {
                                        this.setState({ animal_fertilizer_type_others_explanation: value.target.value })
                                    }}
                                    placeholder="در صورت انتخاب سایر توضیح دهید ..."
                                    className='modal-ex-text'
                                    rows="5"
                                    cols="100">
                                </textarea>

                                <textarea
                                    style={{ borderRadius: 5 }}
                                    onChange={value => {
                                        this.setState({ animal_fertilizer_mix_explanation: value.target.value })
                                    }}
                                    placeholder="در صورت استفاده از کود مخلوط، نوع مخلوط و نسبت آن را بنویسید."
                                    className='modal-ex-text'
                                    rows="5"
                                    cols="100">
                                </textarea>


                                <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                    <div>
                                        معمولا کود شیمیایی روی کود حیوانی به کار می رود؟
                                </div>
                                    <Select
                                        className=""
                                        onChange={(value) => {
                                            this.setState({ chemical_fertilizer_on_animal_fertilizer: value.label })
                                        }}
                                        style={{ textAlign: 'center', height: 35 }}
                                        options={[
                                            { value: '1', label: 'بله' },
                                            { value: '2', label: 'خیر' },
                                        ]}
                                        placeholder='انتخاب کنید'
                                    />
                                </div>

                            </div>
                            <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}                                 
                                 onClick={() => { this.closeModal();
                                                  this.setState({ modal_3_color: 'rgb(29, 117, 250)' }) }}>
                                <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                            </div>
                        </div>
                    </Modal>

                <div className="wateringItems" onClick={() => this.setState({animal_fertilizer_best_choice_modal: true})}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_4_color }} />اثرات کود حیوانی</div>
                    <Modal visible={this.state.animal_fertilizer_best_choice_modal} width="95%" height="80%" effect="fadeInUp" onClickAway={() => this.setState({ animal_fertilizer_best_choice_modal: false })}>
                        <div className="modal-overlay">

                            <div className="modal-title-container">
                                <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                <div style={{ width: '80%', marginTop: 8 }}>اثرات کود حیوانی:</div>
                                <div style={{ width: '10%' }}></div>
                            </div>

                            <div style={{ height: '80%', padding: 16, overflow: 'auto' }}>

                                <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                    <div>
                                        به نظر شما در باغتان اثر کدام کود حیوانی بهتر بوده است؟
                                </div>
                                    <Select
                                        className=""
                                        onChange={(value) => {
                                            this.setState({ animal_fertilizer_best_choice: value.label })
                                        }}
                                        style={{ textAlign: 'center', height: 35 }}
                                        options={[
                                            { value: '1', label: 'گاوی' },
                                            { value: '2', label: 'گوسفندی' },
                                            { value: '3', label: 'مرغی' },
                                            { value: '4', label: 'ماهی' },
                                            { value: '5', label: 'سایر' },
                                        ]}
                                        placeholder='انتخاب کنید'
                                    />
                                </div>


                                <textarea
                                    style={{ borderRadius: 5 }}
                                    onChange={value => {
                                        this.setState({ animal_fertilizer_best_choice_others_explanation: value.target.value })
                                    }}
                                    placeholder="در صورت انتخاب سایر توضیح دهید ..."
                                    className='modal-ex-text'
                                    rows="5"
                                    cols="100">
                                </textarea>

                                <textarea
                                    style={{ borderRadius: 5 }}
                                    onChange={value => {
                                        this.setState({ animal_fertilizer_best_choice_mix_explanation: value.target.value })
                                    }}
                                    placeholder="در صورت استفاده از کود مخلوط، نوع مخلوط و نسبت آن را بنویسید."
                                    className='modal-ex-text'
                                    rows="5"
                                    cols="100">
                                </textarea>
                            </div>
                            <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }} 
                                 onClick={() => { this.closeModal();
                                                  this.setState({ modal_4_color: 'rgb(29, 117, 250)' }) }}>
                                <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                            </div>
                        </div>
                    </Modal>


                    <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 0,
              marginTop: '32px',
              marginBottom: '64px',
            }}>
              <AwesomeButtonProgress
                size='large'
                type='primary'
                disabled={false}
                fakePress={false}
                releaseDelay={2000}
                loadingLabel='در حال پردازش'
                resultLabel='ثبت شد!'
                onPress={this.on_farm_fertilizer_submit}
              >
                ثبت
    </AwesomeButtonProgress>
            </div>


        </div>


            </div>
        );
    }
}