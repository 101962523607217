import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import TextInput from '../Fields/TextInput.js';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { CircularProgressbar } from 'react-circular-progressbar';
import Modal from 'react-awesome-modal';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import {sendLog} from '../LogTest.js';

import {
  AwesomeButton,
  AwesomeButtonProgress,
  AwesomeButtonSocial,
} from 'react-awesome-button';
import AwesomeButtonStyles from 'react-awesome-button/src/styles/styles.scss'

export default class Zanjare extends Component {

  constructor(props) {
    super(props);
    
    this.state = {

      is_pest_identified_by_user: null,
      pest_img: null,
      exposed_pest_black_holes: null,

      form_is_done: false,
      question_text: ' ',
      farms_name: [],
      show_newAddressChosen: 'none',
      newAddressChosen: false,

      pest_engaged_visible: 'none',
      pest_not_engaged_visible: 'none',
      image_selected: false,
      upload_modal: false,
      uploader_volume: 0

    };
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  questionSubmit = (element, next) => {

    const axios = require('axios');
    axios.post('https://api.pestekaran.com/api/pest',
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        },

        api_token: localStorage.getItem('userTOKEN'),
        farm_id: localStorage.getItem('pested_farm_id'),
        msg_id: parseInt(localStorage.getItem('pest_modal_msg_id')),
        data: {
          zanjare: {
            is_pest_identified_by_user: this.state.pest_identified_by_user,
            pest_img: this.state.pest_img,
            exposed_pest_black_holes: this.state.exposed_pest_black_holes,
          }
        },


      })
      .then((response) => {
        next();
        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'success-notifier-toast',
        });

        toast("درخواست شما ثبت شد. تا زمان پاسخ کارشناسان شکیبا باشید.");

        this.setState({ form_is_done: true })
      })
      .catch((error) => {
                next(false, 'مجددا تلاش نمایید!');
        sendLog(error.response);
      })
      .finally(function () {
        // always executed
      });

  }


  handleImageTrees_low_growth_rateSelect = (event) => {
    this.setState({ image_selected: true, upload_modal: true });
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        this.setState({ uploader_volume: x })
      }
    }

    axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        this.setState({ pest_img: response.data.data });

        toast.configure({
          position: "top-left",
          rtl: true,
          zIndex: 9999999999999999,
          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");

        this.setState({ image_selected: false, upload_modal: false });


      })

  }




  render() {

    var UploadIndicator;
    if (this.state.image_selected == true) {
      if (this.state.uploader_volume != 100) {
        UploadIndicator =
          <div className='circularWait' style={{ width: '15vw', height: '15vw', marginRight: 0 }}>
            <CircularProgressbar value={this.state.uploader_volume} text={`${this.state.uploader_volume}%`} />
          </div>;
      }

      if (this.state.uploader_volume >= 98) {
        UploadIndicator =
          <div className='circularWait' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '15vw', height: '15vw', marginRight: 0 }}>
            <CheckRoundedIcon />
            <div>انجام شد!</div>

          </div>;
      }

    } else if (this.state.image_selected == false) {

      UploadIndicator = <div></div>;

    }

    if (!this.state.form_is_done) {

      return (


        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>



          <div style={{ overflow: 'hidden', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 32, height: '70vh', marginTop: '30vh' }} ref={(el) => { this.messagesEnd = el; }}>
            <div style={{ width: '100%' }} ref={(el) => { this.messagesEnd = el; }}>


              <div style={{ marginBottom: 32, textAlign: 'right' }}>
                <div>
                  آیا آفت را میشناسید؟
                    </div>
                <Select
                  className=""
                  onChange={
                    (value) => {
                      this.setState({ is_pest_identified_by_user: value.label })
                      if (value.value == 1) {
                        this.setState({
                          pest_engaged_visible: 'block',
                          pest_not_engaged_visible: 'none',
                        })
                      }

                      if (value.value == 2) {
                        this.setState({
                          pest_engaged_visible: 'none',
                          pest_not_engaged_visible: 'block',
                        })
                      }
                    }
                  }

                  style={{ textAlign: 'center', height: 35 }}
                  options={[
                    { value: '1', label: 'بله' },
                    { value: '2', label: 'خیر' },
                  ]}
                  placeholder='انتخاب کنید'
                />
              </div>



              <div style={{ display: this.state.pest_engaged_visible }}>
                <div style={{ textAlign: 'right', marginBottom: 16 }}>افزودن تصویر از حشره یا خسارت:</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                  <label className="addImageContainer">
                    <div style={{ display: 'flex', width: '20%' }}>
                      <CropOriginalIcon style={{ color: 'white' }} />
                    </div>
                    <div style={{ width: '60%', textAlign: 'center' }}>
                      <input type="file" id="myfilesz" class="hidden-input" onChange={this.handleImageTrees_low_growth_rateSelect} />
                      <label style={{ marginBottom: 0 }} for="myfilesz" style={{ color: 'white' }}>افزودن تصویر </label>
                    </div>
                  </label>
                </div>
              </div>

              <div style={{ display: this.state.pest_not_engaged_visible }}>
                <div style={{ marginBottom: 32, textAlign: 'right' }}>
                  <div>
                    آیا لکه های سیاه در اواخر فروردین روی خوشه و سرشاخه ها مشاهده می شود؟
                    </div>
                  <Select
                    className=""
                    onChange={
                      (value) => {
                        this.setState({ exposed_pest_black_holes: value.label })
                      }
                    }
                    style={{ textAlign: 'center', height: 35 }}
                    options={[
                      { value: '1', label: 'بله' },
                      { value: '2', label: 'خیر' },
                    ]}
                    placeholder='انتخاب کنید'
                  />
                </div>
              </div>





              <div className="frame" style={{ zIndex: 0, marginTop: 64, marginBottom: 64 }}>
                <AwesomeButtonProgress
                  size='large'
                  type='primary'
                  disabled={false}
                  fakePress={false}
                  releaseDelay={2000}
                  loadingLabel='در حال پردازش'
                  resultLabel='ثبت شد!'
                  onPress={this.questionSubmit}
                >
                  ثبت
    </AwesomeButtonProgress>
              </div>
            </div>
          </div>


          <Modal visible={this.state.upload_modal} width="50%" height="20%" effect="fadeInDown">
            <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {UploadIndicator}
            </div>
          </Modal>


        </div>
      );
    }

    if (this.state.form_is_done) {
      return (<Redirect to='/Home' />);
    }
  }
}



