
import React, { Component } from 'react';
import './styles/SubmitNewFarm.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';


class SubmitNewFarm extends Component {
  constructor() {
    super();
    this.state ={
      form_is_ready: false,
    }
  }

  onSubmit = () => {
    if (localStorage.getItem('current_farm_id') == '') {
      toast.configure({
        position: "top-left",
        rtl: true,
        hideProgressBar: true,
        toastClassName: 'error-notifier-toast',
        });
           
       toast("ابتدا اطلاعات عمومی باغتان را پر نمایید."); 
    }
    else {
    toast.configure({
      position: "top-left",
      rtl: true,
      hideProgressBar: true,
      toastClassName: 'error-notifier-toast',
      });
         
     toast("باغ شما با موفقیت ثبت شد");   

this.setState({form_is_ready: true})
    }
  }
  render() {


    if (!this.state.form_is_ready) {
    return (
      <div>


<AppBar position="sticky">
          <Toolbar className="headerBackButton">
            <Typography variant="h6">
                افزودن باغ
            </Typography>
            <div>
              <Link to="/Home" style={{ color: '#FFF' }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
            </div>
          </Toolbar>
        </AppBar>

        <div className='submitNewFarmContainer'>



<div className="frame">

    <div className="centerAdd">
            <div className="correctionFormNotation">برای افزودن باغ تمامی قسمت های زیر باید به درستی پر شود.</div>
  </div>

</div>


<div className="frame">

    <Link to='/AddNewFarm' style={{color: 'black'}}><div className="general-farm-info"><div className="correctionFormNotation">اطلاعات عمومی باغ</div></div></Link>

</div>


<div className="frame">

    <Link to='/Disease' style={{color: 'black'}}><div className="disease-farm-info"><div className="correctionFormNotation">عارضه های موجود در باغ</div></div></Link>

</div>




<div className="frame">

<Link to='/Watering' style={{color: 'black'}}><div className="watering-farm-info"><div className="correctionFormNotation">آبیاری</div></div></Link>

</div>


<div className="frame">

    <div className="correction-farm-info">
    <Link to='/CorrectionOperation' style={{color: 'black'}}><div className="correctionFormNotation">عملیات اصلاحی</div></Link>
  </div>

</div>


<div className="frame">

    <div className="fertilizer-farm-info">
    <Link to='/FertilizerBackground' style={{color: 'black'}}><div className="correctionFormNotation">سابقه کوددهی</div></Link>
  </div>

</div>


<div style={{marginBottom: 40}} className="frame">

<button className="regSubmitButton" onClick={this.onSubmit}>ثبت باغ</button>


</div>
</div>



      </div>
    );
    }
    else if(this.state.form_is_ready) {
      return(<Redirect to='/Farms' />);
    }
  }
}

export default SubmitNewFarm;