
import React, { Component } from 'react';
import './CorrectionOperation.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/creatable';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TextInput from '../Fields/TextInput.js';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import {sendLog} from '../LogTest.js';

export default class Watering extends Component {
    constructor(props) {
        super(props);
        this.state = {

            modal_1_color: 'gray',
            modal_2_color: 'gray',
            modal_3_color: 'gray',
            modal_4_color: 'gray',

            sand_modal: false,


            corrective_operations_untill_present: [],
            extra_corrective_operations: '',
            sand_usage_count: '',
            sand_usage_years: '',
            sand_usage_volume: '',
            sand_usage_is_experimented: '',
            sand_usage_effects_explanation: '',


            plaster_modal: false,

            plaster_usage_count: '',
            plaster_usage_years: '',
            plaster_usage_volume: '',
            plaster_usage_is_experimented: '',
            plaster_usage_effects_explanation: '',

            soil_shuffle_modal: false,

            soil_shuffle_options: '',
            soil_shuffle_years_after_cultivation: '',
            soil_shuffle_options_others_explanation: '',
            soil_shuffle_effects: '',


            soil_shuffle_options_display: 'none',

            uploader_volume: 0,
            image_selected: false,
            upload_modal: false,
        }
    }


    correction_operation_farm_submit = (element, next) => {
        var data = {
            corrective_operations_untill_present: localStorage.getItem('corrective_operations_untill_present'),
            extra_corrective_operations: this.state.extra_corrective_operations,
            sand_usage_count: this.state.sand_usage_count,
            sand_usage_years: this.state.sand_usage_years,
            sand_usage_volume: this.state.sand_usage_volume,
            sand_usage_is_experimented: this.state.sand_usage_is_experimented,
            sand_usage_effects_explanation: this.state.sand_usage_effects_explanation,
            sand_file_url: this.state.sand_file_url,


            plaster_usage_count: this.state.plaster_usage_count,
            plaster_usage_years: this.state.plaster_usage_years,
            plaster_usage_volume: this.state.plaster_usage_volume,
            plaster_usage_is_experimented: this.state.plaster_usage_is_experimented,
            plaster_usage_effects_explanation: this.state.plaster_usage_effects_explanation,
            plaster_file_url: this.state.plaster_file_url,


            soil_shuffle_options: this.state.soil_shuffle_options,
            soil_shuffle_years_after_cultivation: this.state.soil_shuffle_years_after_cultivation,
            soil_shuffle_options_others_explanation: this.state.soil_shuffle_options_others_explanation,
            soil_shuffle_effects: this.state.soil_shuffle_effects,
        };
        const axios = require('axios');
        axios.post('https://api.pestekaran.com/api/correctiveOperation',
            {

                api_token: localStorage.getItem('userTOKEN'),
                farm_id: localStorage.getItem('current_editting_farm'),
                data: data
                ,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json'
                }
            })
            .then((response) => {
                next();
                //console.log(response);
                //console.log(response.data.id);
                toast.configure({
                    position: "top-left",
                    rtl: true,
                    hideProgressBar: true,
                    toastClassName: 'success-notifier-toast',
                });

                toast("اصلاعات عملیات اصلاحی باغ شما با موفقیت ثبت شد.");

                this.setState({ form_is_done: true })
            })
            .catch((error) => {
                next(false, 'مجددا تلاش نمایید!');
                sendLog(error.response);
            })
            .finally(function () {
                // always executed
            });
    }


    closeModal = () => {
        this.setState({
            correction_operations_until_now_modal: false,
            sand_modal: false,
            plaster_modal: false,
            soil_shuffle_modal: false,
        })
    }

    handle_plaster_upload = (event) => {
        this.setState({ image_selected: true, upload_modal: true });
        //console.log((event.target.files[0].size) / 1024);
        localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
        var formData = new FormData();
        var imagefile = document.querySelector('#file');
        formData.append("file", event.target.files[0]);
        formData.append('api_token', localStorage.getItem('userTOKEN'));
        const config = {
            onUploadProgress: progressEvent => {
                var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
                //console.log(x);
                this.setState({ uploader_volume: x })
            }
        }

        axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                this.setState({ plaster_file_url: response.data.data });
                //console.log(response);

                toast.configure({
                    position: "top-left",
                    rtl: true,
                    zIndex: 9999999999999999,
                    hideProgressBar: true,
                    toastClassName: 'img-uploaded-success-notifier-toast',
                });

                toast("فایل شما با موفقیت بارگذاری شد!");

                this.setState({ image_selected: false, upload_modal: false });


            })

    }


    handle_mase_upload = (event) => {
        this.setState({ image_selected: true, upload_modal: true });
        //console.log((event.target.files[0].size) / 1024);
        localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
        var formData = new FormData();
        var imagefile = document.querySelector('#file');
        formData.append("file", event.target.files[0]);
        formData.append('api_token', localStorage.getItem('userTOKEN'));
        const config = {
            onUploadProgress: progressEvent => {
                var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
                //console.log(x);
                this.setState({ uploader_volume: x })
            }
        }

        axios.post('https://api.pestekaran.com/api/file/upload', formData, config, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                this.setState({ sand_file_url: response.data.data });
                //console.log(response);

                toast.configure({
                    position: "top-left",
                    rtl: true,
                    zIndex: 9999999999999999,
                    hideProgressBar: true,
                    toastClassName: 'img-uploaded-success-notifier-toast',
                });

                toast("فایل شما با موفقیت بارگذاری شد!");

                this.setState({ image_selected: false, upload_modal: false });


            })

    }

    render() {

        var UploadIndicator;
        if (this.state.image_selected == true) {
            if (this.state.uploader_volume <= 97) {
                UploadIndicator =
                    <div className='circularWait' style={{ width: '15vw', height: '15vw', marginRight: 0 }}>
                        <CircularProgressbar value={this.state.uploader_volume} text={`${this.state.uploader_volume}%`} />
                    </div>;
            }

            if (this.state.uploader_volume > 97) {
                UploadIndicator =
                    <div className='circularWait' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '15vw', height: '15vw', marginRight: 0 }}>
                        <CheckRoundedIcon />
                        <div>انجام شد!</div>

                    </div>;
            }

        } else if (this.state.image_selected == false) {

            UploadIndicator = <div></div>;

        }

        const corrections_operated = [
            { value: '1', label: 'کاربرد ماسه بادی' },
            { value: '2', label: 'کاربرد گچ' },
            { value: '3', label: 'تغییر سیستم آبیاری' },
            { value: '4', label: 'تغییر شیوه آبیاری' },
            { value: '5', label: 'زیر و رو کردن یا به هم زدن خاک' },
            { value: '6', label: 'سایر' },
        ];
        if (!this.state.form_is_done) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

                    <div style={{ // ***************************************** header ***********************************************************************************
                        position: 'absolute',
                        backgroundColor: '#007bffa8',
                        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                        width: '160vw',
                        height: '80vw',
                        borderRadius: '80vw / 40vw',
                        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                        left: '-32%',
                        top: '-20%'
                    }}
                    >
                    </div>


                    <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                            عملیات اصلاحی
                </div>
                    </div>

                    <Link style={{ color: 'white' }} to={{
                        pathname: '/FarmsEdit',
                        state: {
                            name: localStorage.getItem('current_editting_farm_name'),
                            farm_id: localStorage.getItem('current_editting_farm')
                        }
                    }}>
                        <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                        </div>
                    </Link>

                    <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Slide down>
                            <div style={{ // ***************************************** Top card *****************************************************************************
                                boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                display: 'flex',
                                backgroundColor: '#1D75FA',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 8,
                                width: '80vw',
                                height: '20vh',
                                marginTop: '9vh',
                                borderRadius: 40,
                                position: 'relative'
                            }}>

                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                        <div>
                                            <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                        </div>
                                        <div style={{ marginRight: 8 }}>
                                            {this.state.farms_count}
                                        </div>
                                    </div>

                                </div>


                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                                    <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                        در این بخش با دقت کامل اطلاعات عملیات اصلاحی باغ خود را ثبت کنید.
                      </div>
                                </div>
                            </div>
                        </Slide>
                    </div>


                    <div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>


                        <div className="wateringItems" onClick={() => this.setState({ correction_operations_until_now_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_1_color }} />عملیات اصلاحی انجام شده تا کنون</div>
                        <Modal visible={this.state.correction_operations_until_now_modal} width="95%" height="80%" effect="fadeInUp" onClickAway={() => this.setState({ correction_operations_until_now_modal: false })}>
                            <div className="modal-overlay">

                                <div className="modal-title-container">
                                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                    <div style={{ width: '80%', marginTop: 8 }}>عملیات اصلاحی انجام شده تا کنون:</div>
                                    <div style={{ width: '10%' }}></div>
                                </div>

                                <div style={{ height: '80%', padding: 16 }}>

                                    <div style={{ marginTop: '8px', marginBottom: 64, textAlign: 'right' }}>
                                        <div>
                                            عملیات اصلاحی انجام شده تا کنون:
                                </div>
                                        <CreatableSelect
                                            isMulti
                                            className=""
                                            onChange={(newValue) => {
                                                var p = [];
                                                for (var i = 0; i < newValue.length; i++) {
                                                    p.push(newValue[i].label);

                                                    //console.log("array content: ", p);
                                                    localStorage.setItem('corrective_operations_untill_present', p)
                                                }

                                            }}
                                            style={{ textAlign: 'center', height: 35 }}
                                            options={corrections_operated}
                                            placeholder='انتخاب کنید'
                                        />
                                    </div>

                                    <textarea
                                        style={{ borderRadius: 5 }}
                                        onChange={value => {
                                            this.setState({ extra_corrective_operations: value.target.value })
                                        }}
                                        placeholder="در صورت انتخاب سایر توضیح دهید ..."
                                        className='modal-ex-text'
                                        rows="5"
                                        cols="100">
                                    </textarea>

                                </div>


                                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_1_color: 'rgb(29, 117, 250)' })
                                            }}>
                                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                </div>
                            </div>
                        </Modal>





                        <div className="wateringItems" onClick={() => this.setState({ sand_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_2_color }} />کاربرد ماسه بادی</div>
                        <Modal visible={this.state.sand_modal} width="95%" height="80%" effect="fadeInUp" onClickAway={() => this.setState({ sand_modal: false })}>
                            <div className="modal-overlay">

                                <div className="modal-title-container">
                                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                    <div style={{ width: '80%', marginTop: 8 }}>کاربرد ماسه بادی:</div>
                                    <div style={{ width: '10%' }}></div>
                                </div>


                                <div style={{ height: '80%', padding: 16, overflow: 'auto' }}>
                                    <TextInput inputName='چند نوبت استفاده شده است؟'
                                        onChange={value => {
                                            this.setState({ sand_usage_count: value })
                                        }}
                                        InputPlaceHolder='برای مثال: 3' />

                                    <TextInput inputName='چه سال هایی استفاده شده است؟'
                                        onChange={value => {
                                            this.setState({ sand_usage_years: value })
                                        }}
                                        InputPlaceHolder='برای مثال: 94 و 95' />

                                    <TextInput inputName='چه مقدار استفاده شده است؟'
                                        onChange={value => {
                                            this.setState({ sand_usage_volume: value })
                                        }}
                                        InputPlaceHolder='چند کامیون در باغ (برای مثال: 2)' />



                                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                        <div>
                                            آیا ماسه بادی قبل از کاربرد آزمایش شده است؟
                                </div>
                                        <Select
                                            className=""
                                            onChange={(value) => {
                                                this.setState({ sand_usage_is_experimented: value.label })
                                            }}
                                            style={{ textAlign: 'center', height: 35 }}
                                            options={[
                                                { value: '1', label: 'بله' },
                                                { value: '2', label: 'خیر' },
                                            ]}
                                            placeholder='انتخاب کنید'
                                        />
                                    </div>

                                    <textarea
                                        style={{ borderRadius: 5 }}
                                        onChange={value => {
                                            this.setState({ sand_usage_effects_explanation: value.target.value })
                                        }}
                                        placeholder="از نظر شما چه تاثیراتی داشته است؟"
                                        className='modal-ex-text'
                                        rows="5"
                                        cols="100">
                                    </textarea>

                                    <div style={{ textAlign: 'right', marginBottom: 16 }}>افزودن فایل یا عکس آزمایش:</div>
                                    <div style={{ marginBottom: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                        <label className="addImageContainer">
                                            <div style={{ display: 'flex', width: '20%' }}>
                                                <CropOriginalIcon style={{ color: 'white' }} />
                                            </div>
                                            <div style={{ width: '60%', textAlign: 'center' }}>
                                                <input type="file" id="myfiles1" class="hidden-input" onChange={this.handle_mase_upload} />
                                                <label style={{ marginBottom: 0 }} for="myfiles1" style={{ color: 'white' }}>افزودن فایل  </label>
                                            </div>
                                        </label>
                                    </div>

                                </div>

                                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_2_color: 'rgb(29, 117, 250)' })
                                            }}>
                                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                </div>
                            </div>
                        </Modal>

                        <div className="wateringItems" onClick={() => this.setState({ plaster_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_3_color }} />کاربرد گچ</div>
                        <Modal visible={this.state.plaster_modal} width="95%" height="80%" effect="fadeInUp" onClickAway={() => this.setState({ plaster_modal: false })}>
                            <div className="modal-overlay">

                                <div className="modal-title-container">
                                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                    <div style={{ width: '80%', marginTop: 8 }}>کاربرد گچ:</div>
                                    <div style={{ width: '10%' }}></div>
                                </div>

                                <div style={{ height: '80%', padding: 16, overflow: 'auto' }}>
                                    <TextInput inputName='چند نوبت استفاده شده است؟'
                                        onChange={value => {
                                            this.setState({ plaster_usage_count: value })
                                        }}
                                        InputPlaceHolder='برای مثال: 3' />

                                    <TextInput inputName='چه سال هایی استفاده شده است؟'
                                        onChange={value => {
                                            this.setState({ plaster_usage_years: value })
                                        }}
                                        InputPlaceHolder='برای مثال: 94 و 95' />

<TextInput inputName='چه مقدار استفاده شده است؟'
                                        onChange={value => {
                                            this.setState({ plaster_usage_volume: value })
                                        }}
                                        InputPlaceHolder='چند کامیون در باغ (برای مثال: 2)' />


                                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                        <div>
                                            آیا ماسه بادی قبل از کاربرد آزمایش شده است؟
                                </div>
                                        <Select
                                            className=""
                                            onChange={(value) => {
                                                this.setState({ plaster_usage_is_experimented: value.label })
                                            }}
                                            style={{ textAlign: 'center', height: 35 }}
                                            options={[
                                                { value: '1', label: 'بله' },
                                                { value: '2', label: 'خیر' },
                                            ]}
                                            placeholder='انتخاب کنید'
                                        />
                                    </div>

                                    <textarea
                                        style={{ borderRadius: 5 }}
                                        onChange={value => {
                                            this.setState({ plaster_usage_effects_explanation: value.target.value })
                                        }}
                                        placeholder="از نظر شما چه تاثیراتی داشته است؟"
                                        className='modal-ex-text'
                                        rows="5"
                                        cols="100">
                                    </textarea>

                                    <div style={{ textAlign: 'right', marginBottom: 16 }}>افزودن فایل یا عکس آزمایش:</div>
                                    <div style={{ marginBottom: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                        <label className="addImageContainer">
                                            <div style={{ display: 'flex', width: '20%' }}>
                                                <CropOriginalIcon style={{ color: 'white' }} />
                                            </div>
                                            <div style={{ width: '60%', textAlign: 'center' }}>
                                                <input type="file" id="myfiles2" class="hidden-input" onChange={this.handle_plaster_upload} />
                                                <label style={{ marginBottom: 0 }} for="myfiles2" style={{ color: 'white' }}>افزودن فایل  </label>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_3_color: 'rgb(29, 117, 250)' })
                                            }}>
                                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                </div>
                            </div>
                        </Modal>

                        <div className="wateringItems" onClick={() => this.setState({ soil_shuffle_modal: true })}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_4_color }} />زیر و رو کردن یا به هم زدن خاک</div>
                        <Modal visible={this.state.soil_shuffle_modal} width="95%" height="85%" effect="fadeInUp" onClickAway={() => this.setState({ soil_shuffle_modal: false })}>
                            <div className="modal-overlay">

                                <div className="modal-title-container">
                                    <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                    <div style={{ width: '80%', marginTop: 8 }}>زیر و رو کردن یا به هم زدن خاک:</div>
                                    <div style={{ width: '10%' }}></div>
                                </div>

                                <div style={{ height: '80%', padding: 16, overflow: 'auto' }}>
                                    <div style={{ marginTop: '8px', marginBottom: 32, textAlign: 'right' }}>
                                        <div>
                                            زیر و رو کردن یا به هم زدن خاک:
                                </div>
                                        <Select
                                            className=""
                                            onChange={(value) => {
                                                if (value.value == 1 || value.label == 3) {
                                                    this.setState({ soil_shuffle_options: value.label, soil_shuffle_options_display: 'none' })
                                                }
                                                if (value.value == 2) {
                                                    this.setState({ soil_shuffle_options: value.label, soil_shuffle_options_display: 'block' })
                                                }

                                            }}
                                            style={{ textAlign: 'center', height: 35 }}
                                            options={[
                                                { value: '1', label: 'قبل از کاشت' },
                                                { value: '2', label: 'سالیان پس از کاشت' },
                                                { value: '2', label: 'سایر' },
                                            ]}
                                            placeholder='انتخاب کنید'
                                        />
                                    </div>
                                    <div style={{ display: this.state.soil_shuffle_options_display }}>
                                        <TextInput

                                            inputName='چند سال پس از کاشت؟'
                                            onChange={value => {
                                                this.setState({ soil_shuffle_years_after_cultivation: value })
                                            }}
                                            InputPlaceHolder='برای مثال: 3' /></div>


                                    <div style={{ marginTop: 15 }}>
                                        <textarea
                                            onChange={value => {
                                                this.setState({ soil_shuffle_options_others_explanation: value.target.value })
                                            }}
                                            placeholder="در صورت اتخاب سایر توضیح دهید ..."
                                            className='modal-ex-text'
                                            rows="5"
                                            cols="100">
                                        </textarea>
                                    </div>


                                    <textarea
                                        style={{ borderRadius: 5 }}
                                        onChange={value => {
                                            this.setState({ soil_shuffle_effects: value.target.value })
                                        }}
                                        placeholder="از نظر شما چه تاثیری داشته است؟"
                                        className='modal-ex-text'
                                        rows="5"
                                        cols="100">
                                    </textarea>
                                </div>


                                <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                            onClick={() => {
                                                this.closeModal();
                                                this.setState({ modal_4_color: 'rgb(29, 117, 250)' })
                                            }}>
                                    <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                </div>
                            </div>
                        </Modal>

                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            zIndex: 0,
                            marginTop: '32px',
                            marginBottom: '64px',
                        }}>
                            <AwesomeButtonProgress
                                size='large'
                                type='primary'
                                disabled={false}
                                fakePress={false}
                                releaseDelay={2000}
                                loadingLabel='در حال پردازش'
                                resultLabel='ثبت شد!'
                                onPress={this.correction_operation_farm_submit}
                            >
                                ثبت
    </AwesomeButtonProgress>





                        </div>


                        <Modal visible={this.state.upload_modal} width="50%" height="20%" effect="fadeInDown">
                            <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                {UploadIndicator}
                            </div>
                        </Modal>


                    </div>


                </div>

            );
        }

        else if (this.state.form_is_done) {
            return (<Redirect to={{
                pathname: '/FarmsEdit',
                state: {
                    name: localStorage.getItem('current_editting_farm_name'),
                    farm_id: localStorage.getItem('current_editting_farm')
                }
            }} />);
        }


    }
}