
import React, { Component } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';


class FertilizerBackgroundEdit extends Component {
  constructor() {
    super();
    this.state ={
      form_is_ready: false,
    }
    
  }

  onSubmit = () => {
    toast.configure({
      position: "top-left",
      rtl: true,
      hideProgressBar: true,
      toastClassName: 'error-notifier-toast',
      });
         
     toast("ثبت شد");   

this.setState({form_is_ready: true})
  }
  render() {

if (!this.state.form_is_ready) {
    return (
      <div>


<AppBar position="sticky">
          <Toolbar className="headerBackButton">
            <Typography variant="h6">
                سابقه کوددهی
            </Typography>
            <div>
              <Link to="/FarmsEdit" style={{ color: '#FFF' }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
            </div>
          </Toolbar>
        </AppBar>

        <div className='submitNewFarmContainer'>



<div className="frame">

    <div className="centerAdd">
            <div className="correctionFormNotation">برای افزودن باغ تمامی قسمت های زیر باید به درستی پر شود.</div>
  </div>

</div>


<div className="frame">

    <Link to='/AnimalFertizilerEdit' style={{color: 'black'}}><div className="general-farm-info"><div className="correctionFormNotation">کود حیوانی</div></div></Link>

</div>


<div className="frame">

    <Link to='/ChemicalFertilizerOAFEdit' style={{color: 'black'}}><div className="disease-farm-info"><div className="correctionFormNotation">کود شیمیایی روی کود حیوانی</div></div></Link>

</div>




<div className="frame">

<Link to='/ChemicalFertilizerDuringGrowthEdit' style={{color: 'black'}}><div className="watering-farm-info"><div className="correctionFormNotation">کود شیمیایی در طول فصل رشد (کود آبیاری و سرپاش)</div></div></Link>

</div>


<div className="frame">

    <div className="correction-farm-info">
    <Link to='/SprayingSolutionFertilizerEdit' style={{color: 'black'}}><div className="correctionFormNotation">محلول پاشی کودها</div></Link>
  </div>

</div>




<div style={{marginBottom: 40}} className="frame">

<button className="regSubmitButton" onClick={this.onSubmit}>ثبت سابقه کودهی</button>


</div>
</div>



      </div>
    );
}
else if(this.state.form_is_ready) {
  return(<Redirect to='/SubmitNewFarm' />);
}
  }
}

export default FertilizerBackgroundEdit;