import React, { Component } from 'react';
import '../styles/Input.css';

class TextInput extends Component {
    constructor(props) {
        super(props);
        this.changetextInputvalue = this.changetextInputvalue.bind(this);

        if (this.props.type != null) {
        this.state = { textInputvalue: this.props.initialValue,
                       type : this.props.type };
        }
        if (this.props.type == null) {
          this.state = { textInputvalue: this.props.initialValue,
                         type : 'text' };
          }
        
      }


      changetextInputvalue (event) {
        const dot = RegExp('.');
        const slash = RegExp('/');
        var inputText = (event.target.value);
        var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g], arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
        if(typeof inputText === 'string')
        {
          for(var i=0; i<10; i++)
          {
            inputText = inputText.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
            inputText = inputText.replace('.', '.');
            inputText = inputText.replace('.', '.');
            inputText = inputText.replace('.', '.');
            inputText = inputText.replace('/', '.');  
          }



          console.log(typeof(inputText));
        }

        this.setState({textInputvalue: event.target.value});
        this.props.onChange(inputText);
      }
    render() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: 32
            }}>
                <div style={{marginBottom: 4}}>{this.props.inputName}</div>
                <div className="">
                    <input
                        className=""
                        type={this.state.type} placeholder={this.props.InputPlaceHolder}
                        value={this.state.textInputvalue}
                        onChange={this.changetextInputvalue}
                    />
                    <span className=""></span>
                </div>
            </div>
        );
    }
}
export default TextInput;