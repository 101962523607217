
import React, { Component } from 'react';
import './styles/WateringAdvice.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';

class WateringAdvice extends Component {
  constructor(props) {
    super(props);
    this.state = {
watering_maintenance: false,
    }
    
  }


  render() {


    return (
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

      <div style={{ // ***************************************** header ***********************************************************************************
        position: 'absolute',
        backgroundColor: '#007bffa8',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        width: '160vw',
        height: '80vw',
        borderRadius: '80vw / 40vw',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        left: '-32%',
        top: '-20%'
      }}
      >
      </div>


      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
          ویرایش باغ {this.state.farm_name}
        </div>
      </div>

      <Link to="/Home" style={{ color: '#FFF' }}>
        <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
        </div>
      </Link>

      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Slide down>
          <div style={{ // ***************************************** Top card *****************************************************************************
            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
            display: 'flex',
            backgroundColor: '#1D75FA',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 8,
            width: '80vw',
            height: '20vh',
            marginTop: '9vh',
            borderRadius: 40,
            position: 'relative'
          }}>

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                  <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                </div>
                <div style={{ marginRight: 8 }}>
                  {this.state.farms_count}
                </div>
              </div>

            </div>


            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

              <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: '#dee2e6' }}>
                توصیه مورد نیاز باغ خود را انتخاب کنید.
                </div>
            </div>
          </div>
        </Slide>
      </div>

      <div style={{ padding: 0, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>




<Modal visible={this.state.watering_maintenance} width="80%" height="50%" effect="fadeInUp" onClickAway={() => this.setState({ watering_maintenance: false })}>
                            <div style={{ padding: 10, textAlign: 'center' }}>
                                <div style={{ marginTop: 15 }}>
                                    <Link to={{ pathname: '/QuestionForm', state: { cat_id: 38 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>اسید شویی</div></Link>
                                </div>

                                <div style={{ marginTop: 15 }}>
                                    <Link to={{ pathname: '/QuestionForm', state: { cat_id: 39 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>کلر زنی</div></Link>
                                </div>
                                
                                <div style={{ marginTop: 15 }}>
                                    <Link to={{ pathname: '/QuestionForm', state: { cat_id: 40 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>شست و شوی لوله ها و فیلتر ها</div></Link>
                                </div>


                                <div style={{ marginTop: 15 }}>
                                    <Link to={{ pathname: '/QuestionForm', state: { cat_id: 41 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>نگهداری از استخر های ذخیره آب</div></Link>
                                </div>


                                <div style={{ marginTop: 15 }}>
                                    <Link to={{ pathname: '/QuestionForm', state: { cat_id: 42 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>کنترل ورود ریشه علف هرز به داخل لوله های آبیاری و زیرسطحی</div></Link>
                                </div>

                                <div style={{ marginTop: 20, color: 'red' }}><a style={{color: 'red'}} href="javascript:void(0);" onClick={() => this.setState({ watering_maintenance: false })}>بازگشت</a></div>
                            </div>
                        </Modal>




          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 33 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '25%', width: "100%" }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
                <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#26547C' }} />
              </div>
              <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
                <div>
                  <Slide up>
                    <h6 style={{ fontSize: 16, color: '#26547C' }}>تعیین نسبت مناسب آب به زمین</h6>
                  </Slide>
                </div>
                <div style={{ fontSize: 13, marginLeft: 8, marginRight: 8, textAlign: 'justify' }}>مشخص نمودن مساحت مجاز باغ با توجه به مقدار و کیفیت آب و سیستم آبیاری مورد استفاده</div>
              </div>
            </div>
          </Link>


          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 34 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '25%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{width: 25, height: 25, color: '#EF476F'}} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{fontSize: 16, color: '#EF476F'}}>انتخاب بهترین روش آبیاری</h6>
                </Slide>
              </div>
              <div style={{fontSize: 13, marginLeft: 8, marginRight: 8, textAlign: 'justify'}}>انتخاب و توصیه بهترین روش آبیاری با توجه به کلیه عوامل گیاهی و آبی و خاکی باغ مورد نظر</div>
            </div>
          </div>
          </Link>



          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 35 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '35%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{width: 25, height: 25, color: '#E03616'}} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{fontSize: 16, color: '#E03616'}}>اصلاح و بهینه سازی روش آبیاری فعلی</h6>
                </Slide>
              </div>
              <div style={{fontSize: 13, marginLeft: 8, marginRight: 8, textAlign: 'justify'}}>اصلاح روش و مدیریت آبیاری باغ با ارائه راهکار های کاربردی در جهت بهبود رشد و عملکرد درختان و افزایش بهره وری مصرف آب</div>
            </div>
          </div>
          </Link>


          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 36 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '35%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{width: 25, height: 25, color: '#0CCE6B'}} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{fontSize: 16, color: '#0CCE6B'}}>بررسی امکان تغییر سیستم آبیاری</h6>
                </Slide>
              </div>
              <div style={{fontSize: 13, marginLeft: 8, marginRight: 8, textAlign: 'justify'}}>بررسی تغییر روش آبیاری از غرقابی به انواع آبیاری تحت فشار (قطره ای سطحی و زیر سطحی و ...) و کم فشار (لوله های پی وی سی سوراخدار، سیمانی و ...)</div>
            </div>
          </div>
          </Link>




          <div onClick={() => this.setState({watering_maintenance: true})} className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '35%', width: "100%", marginBottom: 40 }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{width: 25, height: 25, color: '#48392A'}} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{fontSize: 16, color: '#48392A'}}>نگهداری از سیستم آبیاری تحت فشار</h6>
                </Slide>
              </div>
              <div style={{fontSize: 13, marginLeft: 8, marginRight: 8, textAlign: 'justify'}}>ارائه اصول و عملیات بهره برداری و نگهداری از سیستم های آبیاری تحت فشار و کم فشار جهت کارکرد مناسب سیستم، افزایش طول عمر سیستم و استفاده بهینه از آب</div>
            </div>
          </div>


      </div>
      </div>
    );
  }
}

export default WateringAdvice;


/*


<div style={{height: '35%', width:"100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} className="watering-ratio">
    <Link to='/QuestionForm'><div style={{marginRight: 20, color: 'white', fontSize: 16}}>تعیین نسبت مناسب آب به زمین</div></Link>
    <div style={{marginRight: 20, color: 'white', fontSize: 14, textAlign: 'right', padding: 10}}>مشخص نمودن مساحت مجاز باغ با توجه به مقدار و کیفیت آب و سیستم آبیاری مورد استفاده</div>
</div>

<div style={{height: '35%', width:"100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} className="watering-best-method">
    <Link to='/QuestionForm'><div style={{marginRight: 20, color: 'white', fontSize: 16}}>انتخاب بهترین روش آبیاری</div></Link>
<div style={{marginRight: 20, color: 'white', fontSize: 14, textAlign: 'right', padding: 10}}>انتخاب و توصیه بهترین روش آبیاری با توجه به کلیه عوامل گیاهی و آبی و خاکی باغ مورد نظر</div>
</div>

<div style={{height: '35%', width:"100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} className="watering-optimization">
    <Link to='/QuestionForm'><div style={{marginRight: 20, color: 'white', fontSize: 16}}>اصلاح و بهینه سازی روش آبیاری فعلی</div></Link>
<div style={{marginRight: 20, color: 'white', fontSize: 14, textAlign: 'right', padding: 10}}>اصلاح روش و مدیریت آبیاری باغ با ارائه راهکار های کاربردی در جهت بهبود رشد و عملکرد درختان و افزایش بهره وری مصرف آب</div>
</div>
<!--
<div style={{height: '35%', width:"100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} className="watering-method-change">
    <Link to='/QuestionForm'><div style={{marginRight: 20, color: 'white', fontSize: 16}}>بررسی امکان تغییر سیستم آبیاری</div></Link>
<div style={{marginRight: 20, color: 'white', fontSize: 14, textAlign: 'right', padding: 10}}>بررسی تغییر روش آبیاری از غرقابی به انواع آبیاری تحت فشار (قطره ای سطحی و زیر سطحی و ...) و کم فشار (لوله های پی وی سی سوراخدار، سیمانی و ...)</div>
</div>


<div onClick={() => this.setState({watering_maintenance: true})} style={{height: '35%', width:"100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} className="watering-maintenance">
    <div style={{marginRight: 20, color: 'white', fontSize: 16}}>نگهداری از سیستم آبیاری تحت فشار</div>
<div style={{marginRight: 20, color: 'white', fontSize: 14, textAlign: 'right', padding: 10}}>ارائه اصول و عملیات بهره برداری و نگهداری از سیستم های آبیاری تحت فشار و کم فشار جهت کارکرد مناسب سیستم، افزایش طول عمر سیستم و استفاده بهینه از آب</div>
</div>
*/