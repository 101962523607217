import React, { Component } from 'react';
import '../styles/DatePicker.css';
import Modal from 'react-awesome-modal';
import DatPickerEditable from './DatPickerEditable.js';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddIcon from '@material-ui/icons/Add';

export default class EditableDynamicInput extends Component {


    constructor(props) {
        super(props);
        this.changeCountNumber = this.changeCountNumber.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {

            initial_array: this.props.initialValue,
            InputCount: 2,
            count_number: 0,
            amounts: [],
            dates: [],
            final_array: [],

            amount_field_1: null,
            amount_field_2: null,
            amount_field_3: null,
            amount_field_4: null,
            amount_field_5: null,
            amount_field_6: null,
            amount_field_7: null,
            amount_field_8: null,
            amount_field_9: null,
            amount_field_10: null,

            date_field_1: null,
            date_field_2: null,
            date_field_3: null,
            date_field_4: null,
            date_field_5: null,
            date_field_6: null,
            date_field_7: null,
            date_field_8: null,
            date_field_9: null,
            date_field_10: null,


        }
    }

    componentDidMount () {
        if (this.props.initialValue != null) {
            this.setState({ count_number: this.props.initialValue.length })
            }
    }

    changeCountNumber(event) {
        if (event.target.value <= 10) {

            var inputText = (event.target.value);
            var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g], arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
            if (typeof inputText === 'string') {
                for (var i = 0; i < 10; i++) {
                    inputText = inputText.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
                }
            }

            this.setState({ count_number: event.target.value });
            var temp = [];
            for (let i = 0; i < parseInt(event.target.value); i++) {
                temp.push({ usage: null, date: null })
            }
            this.setState({ final_array: temp, initial_array: temp })
            //this.props.onChange(inputText);
        }
    }


    handleChange = (e) => {
        ////console.log('name: ', e.target.name, ' value:', e.target.value)
        let my_array = this.state.initial_array;
        switch (e.target.name) {
            case '0-amonut':
                this.setState({ amount_field_1: e.target.value })
                let temp = { usage: e.target.value, date: my_array[0].date };
                my_array[0] = temp;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
            case '1-amonut':
                this.setState({ amount_field_2: e.target.value })
                let temp1 = { usage: e.target.value, date: my_array[1].date };
                my_array[1] = temp1;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
            case '2-amonut':
                this.setState({ amount_field_3: e.target.value })
                let temp2 = { usage: e.target.value, date: my_array[2].date };
                my_array[2] = temp2;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
            case '3-amonut':
                this.setState({ amount_field_4: e.target.value })
                let temp3 = { usage: e.target.value, date: my_array[3].date };
                my_array[3] = temp3;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
            case '4-amonut':
                this.setState({ amount_field_5: e.target.value })
                let temp4 = { usage: e.target.value, date: my_array[4].date };
                my_array[4] = temp4;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
            case '5-amonut':
                this.setState({ amount_field_6: e.target.value })
                let temp5 = { usage: e.target.value, date: my_array[5].date };
                my_array[5] = temp5;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
            case '6-amonut':
                this.setState({ amount_field_7: e.target.value })
                let temp6 = { usage: e.target.value, date: my_array[6].date };
                my_array[6] = temp6;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
            case '7-amonut':
                this.setState({ amount_field_8: e.target.value })
                let temp7 = { usage: e.target.value, date: my_array[7].date };
                my_array[7] = temp7;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
            case '8-amonut':
                this.setState({ amount_field_9: e.target.value })
                let temp8 = { usage: e.target.value, date: my_array[7].date };
                my_array[8] = temp8;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
            case '9-amonut':
                this.setState({ amount_field_10: e.target.value })
                let temp9 = { usage: e.target.value, date: my_array[8].date };
                my_array[9] = temp9;
                this.setState({ initial_array: my_array })
                //console.log('this is the result:', e.target.value)
                break;
        }
        this.props.onChange(my_array);
    }

    render() {
        let options1 = [];
        for (let j = 0; j < this.state.count_number; j += 1) { options1.push(j) }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>

                <div style={{ fontSize: 14, textAlign: 'right' }}>چند نوبت در فصل استفاده می شود؟</div>
                <input type="tel" value={this.state.count_number} placeholder="5" className="phoneInput" style={{ direction: 'ltr', borderRadius: 0, marginBottom: 16 }} onChange={this.changeCountNumber} />

                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    {options1.map((i) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid gray', padding: 16, borderRadius: 20, marginTop: 16 }} key={`group-input-${i}`}>
                                <div style={{ marginBottom: 8 }}>
                                    {i == 0 && <div>نوبت اول:</div>}
                                    {i == 1 && <div>نوبت دوم:</div>}
                                    {i == 2 && <div>نوبت سوم:</div>}
                                    {i == 3 && <div>نوبت چهارم:</div>}
                                    {i == 4 && <div>نوبت پنجم:</div>}
                                    {i == 5 && <div>نوبت ششم:</div>}
                                    {i == 6 && <div>نوبت هفتم:</div>}
                                    {i == 7 && <div>نوبت هشتم:</div>}
                                    {i == 8 && <div>نوبت نهم:</div>}
                                    {i == 9 && <div>نوبت دهم:</div>}

                                </div>

                                {/**Text Field ******************************************/}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 32 }}>
                                    <div style={{ marginBottom: 4 }}>مقدار کود:</div>
                                    <div>
                                        <input
                                            className=""
                                            type="text"
                                            placeholder={this.state.initial_array[i].usage}
                                            onChange={this.handleChange}
                                            type="text"
                                            name={`${i}-amonut`}
                                        />
                                    </div>
                                </div>

                                {/**Date Field ******************************************/}


                                <DatPickerEditable
                                    inputName='زمان استفاده :'
                                    name={`${i}-date`}
                                    initialValue={this.state.initial_array[i].date}
                                    on_date_edit={value => {
                                        //console.log('name: ', i, ' value:', value);
                                        let my_array = this.state.initial_array;
                                        switch (i) {
                                            case 0:
                                                this.setState({ date_field_1: value })
                                                let temp = { usage: my_array[0].usage, date: value };
                                                my_array[0] = temp;
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 1:
                                                this.setState({ date_field_2: value })
                                                let temp1 = { usage: my_array[1].usage, date: value };
                                                my_array[1] = temp1
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 2:
                                                this.setState({ date_field_3: value })
                                                let temp2 = { usage: my_array[2].usage, date: value };
                                                my_array[2] = temp2
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 3:
                                                this.setState({ date_field_4: value })
                                                let temp3 = { usage: my_array[3].usage, date: value };
                                                my_array[3] = temp3
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 4:
                                                this.setState({ date_field_5: value })
                                                let temp4 = { usage: my_array[4].usage, date: value };
                                                my_array[4] = temp4
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 5:
                                                this.setState({ date_field_6: value })
                                                let temp5 = { usage: my_array[5].usage, date: value };
                                                my_array[5] = temp5
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 6:
                                                this.setState({ date_field_7: value })
                                                let temp6 = { usage: my_array[6].usage, date: value };
                                                my_array[6] = temp6
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 7:
                                                this.setState({ date_field_8: value })
                                                let temp7 = { usage: my_array[7].usage, date: value };
                                                my_array[7] = temp7
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 8:
                                                this.setState({ date_field_9: value })
                                                let temp8 = { usage: my_array[8].usage, date: value };
                                                my_array[8] = temp8
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 9:
                                                this.setState({ date_field_10: value })
                                                let temp9 = { usage: my_array[9].usage, date: value };
                                                my_array[9] = temp9
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                        }
                                        this.props.onChange(my_array);
                                    }}
                                    onChange={value => {
                                        //console.log('name: ', i, ' value:', value);
                                        let my_array = this.state.initial_array;
                                        switch (i) {
                                            case 0:
                                                this.setState({ date_field_1: value })
                                                let temp = { usage: my_array[0].usage, date: value };
                                                my_array[0] = temp;
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 1:
                                                this.setState({ date_field_2: value })
                                                let temp1 = { usage: my_array[1].usage, date: value };
                                                my_array[1] = temp1
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 2:
                                                this.setState({ date_field_3: value })
                                                let temp2 = { usage: my_array[2].usage, date: value };
                                                my_array[2] = temp2
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 3:
                                                this.setState({ date_field_4: value })
                                                let temp3 = { usage: my_array[3].usage, date: value };
                                                my_array[3] = temp3
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 4:
                                                this.setState({ date_field_5: value })
                                                let temp4 = { usage: my_array[4].usage, date: value };
                                                my_array[4] = temp4
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 5:
                                                this.setState({ date_field_6: value })
                                                let temp5 = { usage: my_array[5].usage, date: value };
                                                my_array[5] = temp5
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 6:
                                                this.setState({ date_field_7: value })
                                                let temp6 = { usage: my_array[6].usage, date: value };
                                                my_array[6] = temp6
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 7:
                                                this.setState({ date_field_8: value })
                                                let temp7 = { usage: my_array[7].usage, date: value };
                                                my_array[7] = temp7
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 8:
                                                this.setState({ date_field_9: value })
                                                let temp8 = { usage: my_array[8].usage, date: value };
                                                my_array[8] = temp8
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                            case 9:
                                                this.setState({ date_field_10: value })
                                                let temp9 = { usage: my_array[9].usage, date: value };
                                                my_array[9] = temp9
                                                this.setState({ initial_array: my_array })
                                                //console.log('this is the dateeeeeeeeeeee result:', value)
                                                break;
                                        }
                                        this.props.onChange(my_array);
                                    }}
                                />
                            </div>
                        )
                    })}

                </div>



            </div>);
    }
}