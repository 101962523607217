import React, { Component } from 'react';
import '../styles/DatePicker.css';

export default class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.changeDayvalue = this.changeDayvalue.bind(this);
        this.changeMonthvalue = this.changeMonthvalue.bind(this);
        this.changeYearvalue = this.changeYearvalue.bind(this);
        this.state = { 
          day: '', 
          month: '', 
          year: '',
          day_length_is_NOT_correct: false,
          month_length_is_NOT_correct: false, 
          day_limit_is_incorrect: false, 
          month_limit_is_incorrect: false,
        
      }
    }

      changeDayvalue = (event) => {

        var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g], arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
        var inputText = '';
        var inputText = event.target.value;

        if(typeof inputText === 'string')
        {
          for(var i=0; i<10; i++)
          {
            inputText = inputText.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
          }
        }
        if(inputText.length == 2) {
          if (parseInt(this.state.day) > 31 || parseInt(this.state.day) < 1) {
            this.setState({ day_limit_is_incorrect: true })
          }
          this.monthInput.focus();       
         }
        this.setState({day: inputText});
      }

      changeMonthvalue (event) {


        var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g], arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
        var inputText = '';
        var inputText = event.target.value;

        if(typeof inputText === 'string')
        {
          for(var i=0; i<10; i++)
          {
            inputText = inputText.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
          }
        }
        if(inputText.length == 2) {
          this.yearInput.focus();       
         }
        this.setState({month: inputText});

      }
      changeYearvalue (event) {

        if (this.state.year.length == 3 ) { this.setState({ year_length_is_NOT_correct: false })}

        if (this.state.day.length == 0) {
          this.setState({ day_length_is_NOT_correct: true })
        }

        if (this.state.day.length == 0) {
          this.setState({ day_length_is_NOT_correct: true })
        }


        var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g], arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
        var inputText = '';
        var inputText = event.target.value;

        if(typeof inputText === 'string')
        {
          for(var i=0; i<10; i++)
          {
            inputText = inputText.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
          }
        }
        this.setState({year: inputText});

        var temp_month = this.state.month;
        if(typeof temp_month === 'string')
        {
          for(var i=0; i<10; i++)
          {
            temp_month = temp_month.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
          }
        }
        this.setState({ month: temp_month })


        var temp_day = this.state.day;
        if(typeof temp_day === 'string')
        {
          for(var i=0; i<10; i++)
          {
            temp_day = temp_day.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
          }
        }
        this.setState({ day: temp_day })

        this.props.onChange(inputText + '/' + this.state.month + '/' + this.state.day);


      }
    render() {
        return (
          <div className='inputContainerD'>
            <div className='fieldName'>{this.props.inputName}</div>
            <div className="inputContainerChild">
              <input
                className="sub-dates-input"
                type="tel"
                placeholder="15"
                value={this.state.day}
                ref={(input) => this.dayInput = input}
                onChange={this.changeDayvalue}
                onBlur={() => {

                  let day = this.dayInput.value;

                  if (parseInt(day) < 1 || parseInt(day) > 31) { console.log('im here babe'); this.setState({ day_limit_is_incorrect: true }) }
                  else { this.setState({ day_limit_is_incorrect: false }) }

                  if (day.length == 0) { this.setState({ day_length_is_NOT_correct: true }) }
                  else { this.setState({ day_length_is_NOT_correct: false }) }
                }}
              />
              /
                    <input
                className="sub-dates-input"
                type="tel"
                placeholder="08"
                value={this.state.month}
                ref={(input) => this.monthInput = input}
                selecttextonfocus="true"
                onChange={this.changeMonthvalue}
                onBlur={ () => {
                  let month = this.monthInput.value;

                  if (parseInt(month) < 1 || parseInt(month) > 12) { console.log('im here babe'); this.setState({ month_limit_is_incorrect: true }) }
                  else { this.setState({ month_limit_is_incorrect: false }) }

                  if (month.length == 0) { this.setState({ month_length_is_NOT_correct: true }) }
                  else { this.setState({ month_length_is_NOT_correct: false }) }

                }}

              />
              /
                    <input
                className="sub-dates-input"
                type="tel"
                placeholder="1393"
                value={this.state.year}
                onChange={this.changeYearvalue}
                ref={(input) => this.yearInput = input}
                selecttextonfocus="true"
                onFocus={ () => {
                  let day = this.dayInput;
                  let month = this.monthInput;


                  if (day.length == 0) { this.setState({ day_length_is_NOT_correct: true }) }
                  if (month.length == 0) { this.setState({ month_length_is_NOT_correct: true }) }

                  if (parseInt(day) < 1 || parseInt(day) > 31) { this.setState({ day_limit_is_incorrect: true }) }
                  if (parseInt(month) < 1 || parseInt(month) > 12) { this.setState({ month_limit_is_incorrect: true }) }
                }}
                onBlur={ () => {
                  let year = this.state.year;
                  console.log('here', year.length )
                  if (year.length != 4 ) { this.setState({ year_length_is_NOT_correct: true })}
                  if (year.length == 4 ) { console.log('length is four'); this.setState({ year_length_is_NOT_correct: false })}
                }}
              />
            </div>
            <ul style={{ marginTop: 8, textAlign: 'right', fontSize: 14, color: 'red' }}>
              {this.state.day_length_is_NOT_correct && <li>روز وارد نشده است.</li>}
              {this.state.month_length_is_NOT_correct && <li>ماه وارد نشده است.</li>}
              {this.state.day_limit_is_incorrect && <li>روز باید بین ۱ تا ۳۱ باشد!</li>}
              {this.state.month_limit_is_incorrect && <li>ماه باید بین ۱ تا ۱۲ باشد!</li>}
              {this.state.year_length_is_NOT_correct && <li>سال باید چهار رقم باشد. برای مثال: ۱۳۹۹</li>}
            </ul>

          </div>
        );
    }
}

