import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import './styles/Home.css';
import './styles/Message.css';
import Profile from './Profile.js';
import LabelBottomNavigation from './BottomTab';
import SimpleList from './SimpleList.js';
import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import QuestionForm from './QuestionForm.js';
import './styles/Pest.css';
import Loading from './Loading.js';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import TelegramIcon from '@material-ui/icons/Telegram';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { MetroSpinner } from "react-spinners-kit";
import Modal from 'react-awesome-modal';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class UserNotificatin extends Component {



  constructor(props) {

    super(props);
    this.msg_deletion = this.msg_deletion.bind(this)
    this.state = {
      bgColor: 'white',
      txtColor: '#3f51b5',
      chat_box_open: false,
      ready: false,
      public_bg_color: 'transparent',
      public_font_size: 13,
      private_bg_color: '#1d75fa',
      modal_text: '',
      modal_title: '',
      notification_modal: false,
      private_font_size: 15,
      delete_button_modal: false,
      message_id: '',
      msg_available: false,
      notification_array: [],
      refresh_bool: true
    };
  }

  componentDidMount() {


    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/notifications',
      {
        params: {
          api_token: localStorage.getItem('userTOKEN')
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        //console.log(response.data);
        if (response.data.length == 0) {
          this.setState({msg_available: false})
        }
        else {
          this.setState({msg_available: true})
          let x = [];
        for (let i = 0; i < response.data.length; i++) {
          var string = response.data[i].data.text;
          var trim = 30;
          var trimmedString = string.substring(0, trim) + '...';
          var msg_id = response.data[i].id;
          x.push({ title: response.data[i].data.title, text: trimmedString, full_text: response.data[i].data.text, current_msg_id: msg_id })
        }
        this.setState({ notification_array: x.reverse() });
        localStorage.setItem('user_nitification_list', JSON.stringify(x.reverse()));
      }
        this.setState({ ready: true })


      })
      .catch(function (error) {
        //console.log(error);
      })
      .finally(function () {
        // always executed
      });



  }

  msg_deletion = () => {
    
this.setState({ refresh_bool: false })
      const axios = require('axios');
      axios.put('https://api.pestekaran.com/api/notifications/delete/' + this.state.message_id.toString(),
        {

          api_token: localStorage.getItem('userTOKEN')
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
          }
        })
        .then((response) => {
          toast.configure({
            position: "top-left",
            rtl: true,
            hideProgressBar: true,
            toastClassName: 'img-uploaded-success-notifier-toast',
          });

          toast("اعلان شما با موفقیت حذف شد.");
            //console.log(response.data);

          this.setState({ notification_modal: false, delete_button_modal: false })
        })
        .catch((error) => {
          //console.log(error)
          toast.configure({
            position: "top-left",
            rtl: true,
            hideProgressBar: true,
            toastClassName: 'error-notifier-toast',
          });

          toast("اتصال خود به اینترنت را بررسی نمایید، در غیر اینصورت بعدا تلاش نمایید. با تشکر!");
          this.setState({ notification_modal: false, delete_button_modal: false })

        })
        .finally( () => {

          axios.get('https://api.pestekaran.com/api/notifications',
      {
        params: {
          api_token: localStorage.getItem('userTOKEN')
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        //console.log(response.data);
        if (response.data.length == 0) {
          this.setState({msg_available: false})
        }
        else {
          this.setState({msg_available: true})
          let x = [];
        for (let i = 0; i < response.data.length; i++) {
          var string = response.data[i].data.text;
          var trim = 30;
          var trimmedString = string.substring(0, trim) + '...';
          var msg_id = response.data[i].id;
          x.push({ title: response.data[i].data.title, text: trimmedString, full_text: response.data[i].data.text, current_msg_id: msg_id })
        }
        this.setState({ notification_array: x.reverse() }, () => {
          this.setState({ refresh_bool: true })
        });
        //console.log(x.reverse());
        localStorage.setItem('user_nitification_list', JSON.stringify(x.reverse()));
      }
        


      })
      .catch(function (error) {
        //console.log(error);
      })
      .finally(function () {
        // always executed
      });
          // always executed
        });
    
  }



  render() {
    const Notifications = ({ my_title, my_text, my_full_text, my_msg_id }) => (
      <div className='generalMSGContainer'>

        <div className='generalItemContainer' onClick={() => { this.setState({ message_id: my_msg_id, notification_modal: true, modal_title: my_title, modal_text: my_full_text }) }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '20%' }}>

            <RemoveCircleIcon style={{ width: '50%', height: '50%', color: 'white' }} />
          </div>
          <div className='msgContent'>
            <div className='notificationTitle' style={{ color: '#e8e8e8', fontSize: 14 }}>{my_title}</div>
            <div className='notificationSubTitle' style={{ color: '#e8e8e8' }} >{my_text}</div>
          </div>
        </div>
      </div>


    )

    if (this.state.ready == false) {
      return (
        <div style={{ marginBottom: '15vh', marginTop: '3vh', padding: '16px', display: 'flex', justifyContent: 'center' }}>
          <div className='loader-container' style={{ marginTop: 0 }}>
            <div className='loader-spinner'><MetroSpinner size={60} color="white" loading={true} /></div>
            <div className='loader-text'>در حال پردازش ...</div>
          </div>
        </div>);
    }


    else {
      if(this.state.msg_available) {
      return (

        <div key={this.state.refresh_bool} style={{ marginBottom: '15vh', marginTop: '3vh', padding: '16px' }}>
          <ul className='farmULs'>
            {this.state.notification_array.map(({ title, text, full_text, current_msg_id }) => (
              <li key={title, text, full_text}>
                <Notifications my_title={title} my_text={text} my_full_text={full_text} my_msg_id={current_msg_id} />
              </li>
            ))}
          </ul>



          <Modal visible={this.state.notification_modal} width="95%" height="80%" effect="fadeInDown" onClickAway={() => { this.setState({ notification_modal: false }) }}>
            <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                <CloseRoundedIcon onClick={() => { this.setState({ notification_modal: false }) }} style={{ color: 'red' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>

                <div style={{ height: '10%', width: '100%', textAlign: 'center', fontWeight: '600', fontSize: 14 }}>
                  {this.state.modal_title}
                </div>
                <div style={{ padding: 8, height: '80%', width: '100%', overflow: 'auto', textAlign: 'justify', textAlignLast: 'center' }}>
                  {this.state.modal_text}
                </div>

                <div style={{ height: '10%', width: '100%', textAlign: 'center', fontWeight: '600', fontSize: 14 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                    <div
                      onClick={() => { 
                        this.setState({ notification_modal: false }) }}
                      style={{
                        width: '40%',
                        height: '70%',
                        boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                        backgroundColor: '#0857c3',
                        border: '1px solid rgb(3, 77, 179)',
                        borderRadius: 40,
                        color: 'white',
                        padding: '12px 16px',
                        fontSize: 12,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      بستن </div>


                    <div
                      onClick={() => { this.setState({ delete_button_modal: true }) }} style={{ color: 'red' }}
                      style={{
                        width: '40%',
                        height: '70%',
                        boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                        backgroundColor: '#0857c3',
                        border: '1px solid rgb(3, 77, 179)',
                        borderRadius: 40,
                        color: 'white',
                        padding: '12px 16px',
                        fontSize: 12,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      خذف پیام </div>
                  </div>
                </div>

              </div>
            </div>

          </Modal>


          <Modal visible={this.state.delete_button_modal} width="70%" height="20%" effect="fadeInDown" onClickAway={() => { this.setState({ delete_button_modal: false }) }}>
            <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><CloseRoundedIcon onClick={() => { this.setState({ delete_button_modal: false }) }} style={{ color: 'red' }} /></div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>

                <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                  آیا قصد پاک کردن این پیام را دارید؟
                                  </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                  <div
                    onClick={this.msg_deletion}
                    style={{
                      width: '40%',
                      height: '70%',
                      boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                      backgroundColor: '#0857c3',
                      border: '1px solid rgb(3, 77, 179)',
                      borderRadius: 40,
                      color: 'white',
                      padding: '12px 16px',
                      fontSize: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    بله </div>


                  <div
                    onClick={() => { this.setState({ delete_button_modal: false }) }} style={{ color: 'red' }}
                    style={{
                      width: '40%',
                      height: '70%',
                      boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                      backgroundColor: '#0857c3',
                      border: '1px solid rgb(3, 77, 179)',
                      borderRadius: 40,
                      color: 'white',
                      padding: '12px 16px',
                      fontSize: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    خیر </div>
                </div>


              </div>


            </div>

          </Modal>

        </div>

      );
                  }
                  else{
                    return (
                      <div style={{ marginBottom: '15vh', marginTop: '3vh', padding: '16px', display: 'flex', justifyContent: 'center' }}>
                        <div className='loader-container' style={{ marginTop: 0 }}>
                          <div className='loader-spinner'>در حال حاضر پیامی وجود ندارد :(</div>
                        </div>
                      </div>);
                  }
    }
  }




}

export default UserNotificatin;