import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import TextInput from '../Fields/TextInput.js';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { CircularProgressbar } from 'react-circular-progressbar';
import Modal from 'react-awesome-modal';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import {sendLog} from '../LogTest.js';

import {
    AwesomeButton,
    AwesomeButtonProgress,
    AwesomeButtonSocial,
  } from 'react-awesome-button';
  import AwesomeButtonStyles from 'react-awesome-button/src/styles/styles.scss'

export default class Sarkhortoomi extends Component {

  constructor(props) {
    super(props);
    this.state = {
      farm_engaged: null,

        form_is_done: false,
        question_text: ' ',
        uploader_volume: number,
        image_selected: false,
        farms_name : [],
        show_newAddressChosen: 'none',
        newAddressChosen: false,

        pest_engaged_visible: 'none',
        pest_not_engaged_visible: 'none', 

    };
}


scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }


    componentDidMount() {
        this.scrollToBottom();
  }


  questionSubmit = (element, next) => {

    const axios = require('axios');
    axios.post('https://api.pestekaran.com/api/pest',
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        },

        api_token: localStorage.getItem('userTOKEN'),
        farm_id: localStorage.getItem('pested_farm_id'),
        msg_id: parseInt(localStorage.getItem('pest_modal_msg_id')),
        data: {
          sarkhortoomi: {
            farm_engaged: this.state.farm_engaged,
        },
      }


      })
      .then((response) => {
        next();
        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'success-notifier-toast',
        });

        toast("درخواست شما ثبت شد. تا زمان پاسخ کارشناسان شکیبا باشید.");

        this.setState({ form_is_done: true })
      })
      .catch((error) => {
                next(false, 'مجددا تلاش نمایید!');
        sendLog(error.response);
      })
      .finally(function () {
        // always executed
      });

  }


  render() {


    if (!this.state.form_is_done) {


    return (


      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>


        
        <div style={{ overflow: 'hidden', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 32, height: '70vh', marginTop: '30vh' }} ref={(el) => { this.messagesEnd = el; }}>
        <div style={{width: '100%'}} ref={(el) => { this.messagesEnd = el; }}>



        <div style={{ marginBottom: 32, textAlign: 'right' }}>
                    <div>
                        آیا سابقه خسارت (خورده شدن جوانه ها) در مراحل اولیه رشد در باغ وجود داشته است؟
                    </div>
                    <Select
                        className=""
                        onChange={ 
                            (value) => { this.setState({ farm_engaged: value.label }) 
                        }
                    }
                        style={{ textAlign: 'center', height: 35 }}
                        options={[
                            { value: '1', label: 'بله' },
                            { value: '2', label: 'خیر' },
                        ]}
                        placeholder='انتخاب کنید'
                    />
                    </div>



<div className="frame" style={{zIndex: 0, marginTop: 64, marginBottom: 64}}>
<AwesomeButtonProgress
      size='large'
      type='primary'
      disabled={false}
      fakePress={false}
      releaseDelay={2000}
      loadingLabel='در حال پردازش'
      resultLabel='ثبت شد!'
      onPress={this.questionSubmit}
    >
      ثبت 
    </AwesomeButtonProgress>
</div>
</div>
</div>



      </div>
    );
}

if (this.state.form_is_done) {
    return (<Redirect to='/Home' />);
}

  }
}



