
import React, { Component } from 'react';
import './styles/Correction.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';


class Correction extends Component {
  constructor() {
    super();
    
  }


  render() {


    return (


<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

<div style={{ // ***************************************** header ***********************************************************************************
  position: 'absolute',
  backgroundColor: '#007bffa8',
  boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
  width: '160vw',
  height: '80vw',
  borderRadius: '80vw / 40vw',
  boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
  left: '-32%',
  top: '-20%'
}}
>
</div>


<div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
  <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
    اصلاح خاک
  </div>
</div>

<Link to="/Home" style={{ color: '#FFF' }}>
  <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
  </div>
</Link>

<div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
  <Slide down>
    <div style={{ // ***************************************** Top card *****************************************************************************
      boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
      display: 'flex',
      backgroundColor: '#1D75FA',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 8,
      width: '80vw',
      height: '20vh',
      marginTop: '9vh',
      borderRadius: 40,
      position: 'relative'
    }}>

      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <div>
            <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
          </div>
        </div>

      </div>


      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

      <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: '#dee2e6' }}>
                توصیه مورد نیاز مربوط به اصلاح خاک باغ خود را انتخاب کنید.
                </div>
      </div>
    </div>
  </Slide>
</div>

<div style={{ padding: 0, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>

<Link to={{ pathname: '/QuestionForm', state: { cat_id: 11 } }}>
<div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#6B2D5C' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#6B2D5C' }}>برنامه اصلاح خاک در زمستان</h6>
                </Slide>
              </div>
            </div>
          </div>
          </Link>

          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 12 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#26547C' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#26547C' }}>برنامه و چگونگی استفاده از اسید سولفوریک</h6>
                </Slide>
              </div>
            </div>
          </div>
          </Link>







      </div>
      </div>
    );
  }
}

export default Correction;