import React, {Component} from 'react';
import './styles/ImageView.css';
import { Chat } from '@progress/kendo-react-conversational-ui';
import TextInput from './Fields/TextInput.js'; 
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';

export default class ImageView extends Component {

render () {
    const {imgSrc} = this.props.location.state;
  return (
    
    <div>
        <AppBar position="sticky" style={{backgroundColor: 'white'}}>
          <Toolbar className="headerBackButton">
            <Typography variant="h6">
            </Typography>
            <div>
              <Link to="/ChatBox" style={{ color: '#FFF' }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <CloseIcon style={{color: 'black'}} />
                </IconButton>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        <div className='img-container'>
            <img className='my-img' src={imgSrc}/>
        </div>
    </div>
  );
}
}