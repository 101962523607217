
import React, { Component } from 'react';
import 'react-chat-widget/lib/styles.css';
import { Launcher } from 'react-chat-window';
import './styles/ChatBox.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MagicSpinner } from "react-spinners-kit";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { fontFamily } from '@material-ui/system';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Input } from 'react-chat-elements';
import { Button } from 'react-chat-elements';
import Modal from 'react-awesome-modal';
import {sendLog} from './LogTest.js';

class ChatBox extends Component {
  constructor() {
    super();
    this.state = {
      fileReady: true,
      fileSize: '',
      percent: number,
      loading: 1,
      uploader_volume: 0,
      messageList: [
        {
          author: 'them',
          type: 'text',
          data: {
            text: '.به سیستم پشتیبان پسته کاران خوش آمدید'
          }
        }
        ,
        {
          author: 'them',
          type: 'file',
          data: {
            url: 'https://google.com',
            fileName: 'فیلم ضمیمه شده برای'
          }
        },
        {
          author: 'them',
          type: 'text',
          data: {
            text: ReactHtmlParser('<img class="chatimg" src="https://api.pestekaran.com/storage/G4iRKPTtna.jpg" />'),
          }
        },
        
      ],
      isOpen: false
    };
  }

  componentDidMount = () => {

    //////console.log("Chat id is:", this.props.location.chat_id);
    var request_chat_messages = 'https://api.pestekaran.com/api/messages/' + (this.props.location.chat_id);
    ////console.log(request_chat_messages);
    const axios = require('axios');
    axios.get(request_chat_messages,
      {
        params: {
          api_token: localStorage.getItem('userTOKEN')
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        //console.log(response.data);
        var msg_owner = '';
        var msg_text = '';
        var msg;
        for (let i = 0; i < response.data.length; i++) {

          if (response.data[i].by_admin == 0) { msg_owner = 'right' } else if (response.data[i].by_admin == 1) { msg_owner = 'left' };

          msg_text = ReactHtmlParser(response.data[i].text);
          //console.log(typeof (response.data[i].text));

          msg = { author: msg_owner, type: 'text', text:  msg_text }

          this.setState({
            messageList: [...this.state.messageList, msg]
          })



        }


      })
      .catch(function (error) {
        //console.log(error);
      })
      .finally(function () {
        // always executed
      });
    setTimeout(() => {
      this.setState({ loading: 0 });

    }, 2000);

  }


  _onMessageWasSent(message) {

    const axios = require('axios');
    axios.post('https://api.pestekaran.com/api/messages',
      {

        api_token: localStorage.getItem('userTOKEN'),
        text: message,
        farm_id: '',
        reply_to: this.props.location.chat_id
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then(function (response) {
        //console.log(response);

        if (response.data.error == 1) {
          toast.configure({
            position: "top-left",
            rtl: true,
            hideProgressBar: true,
            toastClassName: 'error-notifier-toast',
          });

          toast(response.data.message[0]);
        }
        else if (response.data.error == 0) { this.setState({ messageList: [...this.state.messageList, message] }) }

      })
      .catch(function (error) {
        sendLog(error.response);
      })
      .finally(function () {
        // always executed
      });


  }


  _onFileSeleceted = (event) => {
    this.setState({ loading: 3 });                                       /// 3= file upload

    localStorage.setItem('file_size', (event[0].size) / 1024);
    //console.log((event[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("file", event[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    formData.append('reply_to', this.props.location.chat_id);

    const config = {
      onUploadProgress: progressEvent => {

        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }
    axios.post('https://api.pestekaran.com/api/file/send', formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        //console.log(response);
        //console.log(response.data.data);
        this.setState({
          loading: 0,
          messageList: [...this.state.messageList, {
            author: 'me',
            type: 'file',
            data: {
              url: response.data.data,
              fileName: 'فایل شما ضمیمه شد. تا زمان پاسخ کارشناس شکیبا باشید.'
            }
          }]
        })


      })
  }
  render() {
    var chat_box_content;
    if (this.state.loading == 1) {

      chat_box_content = <div className='loader-container'>
        <div className='loader-spinner'><MagicSpinner size={50} color="#686769" loading={true} /></div>
        <div className='loader-text'>در حال پردازش ...</div>
      </div>;
    }
    else if (this.state.loading == 0) {
      chat_box_content = <Launcher
        
        agentProfile={{
          teamName: 'پاسخ شما',
          imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
        }}
        onMessageWasSent={this._onMessageWasSent.bind(this)}
        messageList={this.state.messageList}
        newMessagesCount={5}
        showEmoji
        onFilesSelected={this._onFileSeleceted.bind(this)}
      />;
    }
    else if (this.state.loading == 3) {
      chat_box_content =


        <div className='loader-container'>
          <div className='loader-spinner'><MagicSpinner size={50} color="#686769" loading={true} /></div>
          <div className='loader-text'>در حال بارگذاری ...</div>
          <div className='circularWaitChat'><CircularProgressbar value={this.state.uploader_volume} text={`${this.state.uploader_volume}%`} /></div>
        </div>;
    }







    return (
      <div className='chat-box-container'>

        {chat_box_content}
      </div>
    );
  }
}

export default ChatBox;