
import React, { Component } from 'react';
import './styles/Fertilizer.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';


export default class Fertilizer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fertilizer_problems: false,
      fertilizer_in_growth_season: false
    }

  }


  render() {


    return (
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

        <div style={{ // ***************************************** header ***********************************************************************************
          position: 'absolute',
          backgroundColor: '#007bffa8',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          width: '160vw',
          height: '80vw',
          borderRadius: '80vw / 40vw',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          left: '-32%',
          top: '-20%'
        }}
        >
        </div>


        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
            تغذیه و کوددهی
          </div>
        </div>

        <Link to="/Home" style={{ color: '#FFF' }}>
          <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
          </div>
        </Link>

        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Slide down>
            <div style={{ // ***************************************** Top card *****************************************************************************
              boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
              display: 'flex',
              backgroundColor: '#1D75FA',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 8,
              width: '80vw',
              height: '20vh',
              marginTop: '9vh',
              borderRadius: 40,
              position: 'relative'
            }}>

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                  </div>
                  <div style={{ marginRight: 8 }}>
                    {this.state.farms_count}
                  </div>
                </div>

              </div>


              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

              <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: '#dee2e6' }}>
                توصیه مورد نیاز مربوط به تغذیه و کوددهی باغ خود را انتخاب کنید.
                </div>
              </div>
            </div>
          </Slide>
        </div>

        <div style={{ padding: 0, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>

        <Link to={{ pathname: '/QuestionForm', state: { cat_id: 15 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#6B2D5C' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#6B2D5C' }}>چگونگی نمونه برداری خاک</h6>
                </Slide>
              </div>
            </div>
          </div>
          </Link>

          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 16 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#26547C' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#26547C' }}>چگونگی نمونه برداری برگ</h6>
                </Slide>
              </div>
            </div>
          </div>
          </Link>

          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 17 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#EF476F' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#EF476F' }}>درخواست اعزام کارشناس برای نمونه برداری خاک</h6>
                </Slide>
              </div>
            </div>
          </div>
          </Link>

          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 18 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#E03616' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#E03616' }}>برنامه کوددهی زمستانه</h6>
                </Slide>
              </div>
            </div>
          </div>
          </Link>


          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 19 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '20%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#48392A' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#48392A' }}>برنامه کوددهی در طول فصل رشد</h6>
                  <h6 style={{ fontSize: 13, color: 'black' }}>کودآبیاری یا کوددهی همراه آب یاری در آبیاری غرقابی یا کرتی</h6>
                </Slide>
              </div>
            </div>
          </div>
          </Link>

          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 20 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '20%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#48392A' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#48392A' }}>برنامه کوددهی در طول فصل رشد</h6>
                  <h6 style={{ fontSize: 13, color: 'black' }}>کودآبیاری یا کوددهی همراه آب یاری در آبیاری تحت فشار؛ قطره ای و کم فشار</h6>
                </Slide>
              </div>
            </div>
          </div>
          </Link>


          <Link to={{ pathname: '/QuestionForm', state: { cat_id: 21 } }}>
          <div className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%" }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#561F37' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#561F37' }}>برنامه محلول پاشی</h6>
                </Slide>
              </div>
            </div>
          </div>
          </Link>




          <div onClick={() => this.setState({ fertilizer_problems: true })} className='list-border-bottom' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '15%', width: "100%", marginBottom: 64 }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
              <RemoveCircleOutlineIcon style={{ width: 25, height: 25, color: '#A52422' }} />
            </div>
            <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 20 }}>
              <div>
                <Slide up>
                  <h6 style={{ fontSize: 14, color: '#A52422' }}>بررسی و ارائه راه حل مشکلات و عارضه های خاص مرتبط با تغذیه</h6>
                </Slide>
              </div>
            </div>
          </div>

          <Modal visible={this.state.fertilizer_problems} width="80%" height="50%" effect="fadeInUp" onClickAway={() => this.setState({ fertilizer_problems: false })}>
            <div style={{ padding: 10, textAlign: 'center' }}>
              <div style={{ marginTop: 15 }}>
                <Link to={{ pathname: '/QuestionForm', state: { cat_id: 22 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>سرخشکیدگی</div></Link>
              </div>

              <div style={{ marginTop: 15 }}>
                <Link to={{ pathname: '/QuestionForm', state: { cat_id: 23 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>ریزبرگی یا قرمزو</div></Link>
              </div>

              <div style={{ marginTop: 15 }}>
                <Link to={{ pathname: '/QuestionForm', state: { cat_id: 24 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>زردی</div></Link>
              </div>

              <div style={{ marginTop: 15 }}>
                <Link to={{ pathname: '/QuestionForm', state: { cat_id: 7 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>لکه پوست</div></Link>
              </div>

              <div style={{ marginTop: 15 }}>
                <Link to={{ pathname: '/QuestionForm', state: { cat_id: 10 } }}><div style={{ marginBottom: 20, textAlign: "center" }}>سایر</div></Link>
              </div>
              <div style={{ marginTop: 20, color: 'red' }}><a style={{ color: 'red' }} href="javascript:void(0);" onClick={() => this.setState({ fertilizer_problems: false })}>بازگشت</a></div>
            </div>
          </Modal>

        </div>



      </div>
    );
  }
}

