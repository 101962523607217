import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import { initializeFirebase } from './init-fcm.js';
import CodeConfirmation from './components/CodeConfirmation.js';

initializeFirebase();
ReactDOM.render((<Router><App /></Router>), document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
serviceWorker.forceSWupdate();
