import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { CircularProgressbar } from 'react-circular-progressbar';
import Modal from 'react-awesome-modal';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { ArrowRight, Flare } from '@material-ui/icons';
import Shake from 'react-reveal/Shake';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import { MetroSpinner } from "react-spinners-kit";
import ReactHtmlParser from 'react-html-parser';
import Loading from './Loading.js';
import RegPart1 from './RegPart1';
import {sendLog} from './LogTest.js';

export default class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            feed: []
        };
    }

    componentDidMount() {

        const axios = require('axios');
        axios.get('https://pestekaran.ir/index.php?rest_route=%2Fwp%2Fv2%2Fposts&categories=45',)
          .then((response) => {
            let array = [];
            for (let i = 0; i < response.data.length; i++) {
                let title = response.data[i].title.rendered;
                let text = (ReactHtmlParser(response.data[i].excerpt.rendered)[0].props.children[0]).slice(0,100) + "  " + "...";
// console.log(ReactHtmlParser(response.data[i].excerpt.rendered))
                array.push({
                    title: title,
                    text: text,
                    link: response.data[i].link
                })
            }

            this.setState({ feed: array }, () => {
                this.setState({ ready: true })
            })


            
    
    
          })
          .catch(function (error) {
            sendLog(error.response);
          })
          .finally(function () {
            // always executed
          });
    
    }


    render() {

        const Notifications = ({ my_title, my_text, my_link }) => (
            <div className='generalMSGContainer' style={{ height: '20vh' }} >
      
              <div className='generalItemContainer'  style={{ height: '20vh' }} onClick={() => { window.open(my_link) }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '60%' }}>
      
                  <EventNoteRoundedIcon style={{ width: '50%', height: '50%', color: 'white' }} />
                </div>
                <div className='msgContent' style={{ padding: '8px 8px 8px 32px', textAlign: 'right' }}>
                  <div className='notificationTitle' style={{ color: 'white', fontSize: 14 }}>{my_title}</div>
                  <div className='notificationSubTitle' style={{ color: '#e8e8e8' }} >{my_text}</div>
                </div>
              </div>
            </div>
      
      
          )

          if (this.state.ready == false) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Loading />
                </div>);
          }

          else {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
                    <div style={{ // ***************************************** header ***********************************************************************************
                      position: 'absolute',
                      backgroundColor: '#007bffa8',
                      boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                      width: '160vw',
                      height: '80vw',
                      borderRadius: '80vw / 40vw',
                      boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                      left: '-32%',
                      top: '-20%'
                    }}
                    >
                    </div>
            
            
                    <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                        مطالب عمومی
                            </div>
                    </div>
            
                    <Link to="/Home" style={{ color: '#FFF' }}>
                      <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                      </div>
                    </Link>
            
                    <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Slide down>
                        <div style={{ // ***************************************** Top card *****************************************************************************
                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                            display: 'flex',
                            backgroundColor: '#1D75FA',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: 8,
                            width: '80vw',
                            height: '20vh',
                            marginTop: '9vh',
                            borderRadius: 40,
                            position: 'relative'
                        }}>


                            <div style={{ display: 'flex', marginTop: 16, flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: '#dee2e6' }}>
                                    آخرین مقالات و مطالب آموزشی مجموعه پسته کاران را ببینید!
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: 8, flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                    <div>
                                        <div
                                            onClick={() => { window.open('https://pestekaran.ir/?page_id=17') }}
                                            style={{
                                                width: '100%',
                                                height: '70%',
                                                boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                                                backgroundColor: '#0857c3',
                                                border: '1px solid rgb(3, 77, 179)',
                                                borderRadius: 40,
                                                color: 'white',
                                                padding: '12px 16px',
                                                fontSize: 12,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            مشاهده در سایت </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Slide>
                    </div>
            
                    <div style={{ padding: 0, overflow: 'hidden', overflowY: 'auto', height: '70vh', marginTop: '30vh' }}>


                    <div key={this.state.refresh_bool} style={{ marginBottom: '15vh', marginTop: '3vh', padding: '16px' }}>
          <ul className='farmULs'>
            {this.state.feed.map(({ title, text, link }) => (
              <li key={title, text, link}>
                <Notifications my_title={title} my_text={text} my_link={link} />
              </li>
            ))}
          </ul>
          </div>
            
            </div>
            </div>
            );
                    }
    }
} 