import React, { Component } from 'react';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/creatable';
import Loading from '../Loading.js';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TextInput from '../Fields/TextInput.js';
import {sendLog} from '../LogTest.js';

export default class CFOAFlastTwoYearEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {

            modal_1_color: 'gray',
            modal_2_color: 'gray',
            modal_3_color: 'gray',
            modal_4_color: 'gray',
            modal_5_color: 'gray',
            modal_6_color: 'gray',
            modal_7_color: 'gray',
            modal_8_color: 'gray',
            modal_9_color: 'gray',
            modal_10_color: 'gray',
            modal_11_color: 'gray',
            modal_12_color: 'gray',
            modal_13_color: 'gray',

            loaded: false,

            urea_amount: '',
            ammonium_sulfate_amount: '',
            triple_super_phosphate_amount: '',
            simple_super_phosphate_amount: '',
            mono_ammonium_phosphate_amount: '',
            D_ammonium_phosphate_amount: '',
            potassium_phosphate_amount: '',
            iron_sulfate_amount: '',
            zinc_sulfate_amount: '',
            manganese_sulfate_amount: '',
            copper_sulfate_amount: '',
            boric_acid_amount: '',
            extra_chemical_fertilizer_name: '',
            extra_chemical_fertilizer_amount: '',

            form_is_done: false,


            uploader_volume: number,
            image_selected: false,
        }
    }

    componentDidMount() {
        const axios = require('axios');
        axios.get('https://api.pestekaran.com/api/farms',
            {

                params: {
                    api_token: localStorage.getItem('userTOKEN'),
                    id: parseInt(localStorage.getItem('current_editting_farm')),
                }
                ,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json'
                }
            })
            .then((response) => {
                console.log(response.data.length);

                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].id === parseInt(localStorage.getItem('current_editting_farm'))) {
                        console.log(response.data[i]);
                        this.setState({ primary_farm_data: response.data[i].fertilizerHistory.animalOnChemical.last_two_year });
                        console.log(this.state.primary_farm_data.urea_amount)
                        this.setState({
                            urea_amount: this.state.primary_farm_data.urea_amount,
                            ammonium_sulfate_amount: this.state.primary_farm_data.ammonium_sulfate_amount,
                            triple_super_phosphate_amount: this.state.primary_farm_data.triple_super_phosphate_amount,
                            simple_super_phosphate_amount: this.state.primary_farm_data.simple_super_phosphate_amount,
                            mono_ammonium_phosphate_amount: this.state.primary_farm_data.mono_ammonium_phosphate_amount,
                            D_ammonium_phosphate_amount: this.state.primary_farm_data.D_ammonium_phosphate_amount,
                            potassium_phosphate_amount: this.state.primary_farm_data.potassium_phosphate_amount,
                            iron_sulfate_amount: this.state.primary_farm_data.iron_sulfate_amount,
                            zinc_sulfate_amount: this.state.primary_farm_data.zinc_sulfate_amount,
                            manganese_sulfate_amount: this.state.primary_farm_data.manganese_sulfate_amount,
                            copper_sulfate_amount: this.state.primary_farm_data.copper_sulfate_amount,
                            boric_acid_amount: this.state.primary_farm_data.boric_acid_amount,
                            extra_chemical_fertilizer_name: this.state.primary_farm_data.extra_chemical_fertilizer_name,
                            extra_chemical_fertilizer_amount: this.state.primary_farm_data.extra_chemical_fertilizer_amount,
                        })
                            
                        
                        console.log(this.state.primary_farm_data);
                    }
                }

                // Subitmes Completion Status:
                //modal 1:
                if (this.state.urea_amount != null) {
                    this.setState({ modal_1_color: 'rgb(29, 117, 250)' })
                }
                //modal 2:
                if (this.state.ammonium_sulfate_amount != null) {
                    this.setState({ modal_2_color: 'rgb(29, 117, 250)' })
                }
                //modal 3:
                if (this.state.triple_super_phosphate_amount != null) {
                    this.setState({ modal_3_color: 'rgb(29, 117, 250)' })
                }
                //modal 4:
                if (this.state.simple_super_phosphate_amount != null) {
                    this.setState({ modal_4_color: 'rgb(29, 117, 250)' })
                }
                //modal 5:
                if (this.state.mono_ammonium_phosphate_amount != null) {
                    this.setState({ modal_5_color: 'rgb(29, 117, 250)' })
                }
                //modal 6:
                if (this.state.D_ammonium_phosphate_amount != null) {
                    this.setState({ modal_6_color: 'rgb(29, 117, 250)' })
                }
                //modal 7:
                if (this.state.potassium_phosphate_amount != null) {
                    this.setState({ modal_7_color: 'rgb(29, 117, 250)' })
                }
                //modal 8:
                if (this.state.iron_sulfate_amount != null) {
                    this.setState({ modal_8_color: 'rgb(29, 117, 250)' })
                }
                //modal 9:
                if (this.state.zinc_sulfate_amount != null) {
                    this.setState({ modal_9_color: 'rgb(29, 117, 250)' })
                }
                //modal 10:
                if (this.state.manganese_sulfate_amount != null) {
                    this.setState({ modal_10_color: 'rgb(29, 117, 250)' })
                }
                //modal 11:
                if (this.state.copper_sulfate_amount != null) {
                    this.setState({ modal_11_color: 'rgb(29, 117, 250)' })
                }
                //modal 12:
                if (this.state.boric_acid_amount != null) {
                    this.setState({ modal_12_color: 'rgb(29, 117, 250)' })
                }
                //modal 13:
                if (this.state.extra_chemical_fertilizer_name != null || this.state.extra_chemical_fertilizer_amount != null) {
                    this.setState({ modal_13_color: 'rgb(29, 117, 250)' })
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
            this.setState({ loaded: true });
    }

    on_farm_fertilizer_submit = (element, next) => {

        var animal_fertilizer = {

            urea_amount: this.state.urea_amount,
            ammonium_sulfate_amount: this.state.ammonium_sulfate_amount,
            triple_super_phosphate_amount: this.state.triple_super_phosphate_amount,
            simple_super_phosphate_amount: this.state.simple_super_phosphate_amount,
            mono_ammonium_phosphate_amount: this.state.mono_ammonium_phosphate_amount,
            D_ammonium_phosphate_amount: this.state.D_ammonium_phosphate_amount,
            potassium_phosphate_amount: this.state.potassium_phosphate_amount,
            iron_sulfate_amount: this.state.iron_sulfate_amount,
            zinc_sulfate_amount: this.state.zinc_sulfate_amount,
            manganese_sulfate_amount: this.state.manganese_sulfate_amount,
            copper_sulfate_amount: this.state.copper_sulfate_amount,
            boric_acid_amount: this.state.boric_acid_amount,
            extra_chemical_fertilizer_name: this.state.extra_chemical_fertilizer_name,
            extra_chemical_fertilizer_amount: this.state.extra_chemical_fertilizer_amount,

        };




        if (localStorage.getItem('current_editting_farm') == undefined || localStorage.getItem('current_editting_farm') == '') {
            toast.configure({
                position: "top-left",
                rtl: true,
                hideProgressBar: true,
                toastClassName: 'error-notifier-toast',
            });

            toast("ابتدا اطلاعات عمومی باغتان را پر نمایید.");
        }
        else {
            const axios = require('axios');
            axios.put('https://api.pestekaran.com/api/fertilizer/animalOnChemicalTwo/' + localStorage.getItem('current_editting_farm'),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json'
                    },

                    api_token: localStorage.getItem('userTOKEN'),
                    farm_id: localStorage.getItem('current_editting_farm'),
                    data: animal_fertilizer,


                })
                .then((response) => {
                    console.log(response);
                    next();
                    toast.configure({
                        position: "top-left",
                        rtl: true,
                        hideProgressBar: true,
                        toastClassName: 'success-notifier-toast',
                    });

                    toast("اصلاعات کوددهی باغ شما با موفقیت ثبت شد.");
                    this.setState({ form_is_done: true })

                })
                .catch((error) => {
                    next(false, 'مجددا تلاش نمایید!');
                    sendLog(error.response);

                })
                .finally(function () {
                    // always executed
                });
        }
    }


    open_urea_modal = () => {
        this.setState({ urea_modal: true })
    }
    close_urea_modal = () => {
        this.setState({ urea_modal: false })
    }
    open_ammonium_sulfate_modal = () => {
        this.setState({ ammonium_sulfate_modal: true })
    }
    close_ammonium_sulfate_modal = () => {
        this.setState({ ammonium_sulfate_modal: false })
    }
    open_triple_super_phosphate_modal = () => {
        this.setState({ triple_super_phosphate_modal: true })
    }
    close_triple_super_phosphate_modal = () => {
        this.setState({ triple_super_phosphate_modal: false })
    }
    open_simple_super_phosphate_modal = () => {
        this.setState({ simple_super_phosphate_modal: true })
    }
    close_simple_super_phosphate_modal = () => {
        this.setState({ simple_super_phosphate_modal: false })
    }
    open_mono_ammonium_phosphate_modal = () => {
        this.setState({ mono_ammonium_phosphate_modal: true })
    }
    close_mono_ammonium_phosphate_modal = () => {
        this.setState({ mono_ammonium_phosphate_modal: false })
    }
    open_D_ammonium_phosphate_modal = () => {
        this.setState({ D_ammonium_phosphate_modal: true })
    }
    close_D_ammonium_phosphate_modal = () => {
        this.setState({ D_ammonium_phosphate_modal: false })
    }
    open_potassium_phosphate_modal = () => {
        this.setState({ potassium_phosphate_modal: true })
    }
    close_potassium_phosphate_modal = () => {
        this.setState({ potassium_phosphate_modal: false })
    }
    open_iron_sulfate_modal = () => {
        this.setState({ iron_sulfate_modal: true })
    }
    close_iron_sulfate_modal = () => {
        this.setState({ iron_sulfate_modal: false })
    }
    open_zinc_sulfate_modal = () => {
        this.setState({ zinc_sulfate_modal: true })
    }
    close_zinc_sulfate_modal = () => {
        this.setState({ zinc_sulfate_modal: false })
    }
    open_manganese_sulfate_modal = () => {
        this.setState({ manganese_sulfate_modal: true })
    }
    close_manganese_sulfate_modal = () => {
        this.setState({ manganese_sulfate_modal: false })
    }
    open_copper_sulfate_modal = () => {
        this.setState({ copper_sulfate_modal: true })
    }
    close_copper_sulfate_modal = () => {
        this.setState({ copper_sulfate_modal: false })
    }
    open_boric_acid_modal = () => {
        this.setState({ boric_acid_modal: true })
    }
    close_boric_acid_modal = () => {
        this.setState({ boric_acid_modal: false })
    }
    open_extra_chemical_fertilizer_modal = () => {
        this.setState({ extra_chemical_fertilizer_modal: true })
    }
    close_extra_chemical_fertilizer_modal = () => {
        this.setState({ extra_chemical_fertilizer_modal: false })
    }



    closeModal = () => {
        this.setState({
            urea_modal: false,
            ammonium_sulfate_modal: false,
            triple_super_phosphate_modal: false,
            simple_super_phosphate_modal: false,
            mono_ammonium_phosphate_modal: false,
            D_ammonium_phosphate_modal: false,
            potassium_phosphate_modal: false,
            iron_sulfate_modal: false,
            zinc_sulfate_modal: false,
            manganese_sulfate_modal: false,
            copper_sulfate_modal: false,
            boric_acid_modal: false,
            extra_chemical_fertilizer_modal: false

        })
    }


    render() {


        const JDate = require('jalali-date');
        const jdate = new JDate;

        if (!this.state.form_is_done) {

            if (!this.state.loaded) {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loading />
                    </div>);
            }
            else {
                return (


                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

                        <div style={{ // ***************************************** header ***********************************************************************************
                            position: 'absolute',
                            backgroundColor: '#007bffa8',
                            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                            width: '160vw',
                            height: '80vw',
                            borderRadius: '80vw / 40vw',
                            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                            left: '-32%',
                            top: '-20%'
                        }}
                        >
                        </div>


                        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                کود شیمیایی در دو سال گذشته
</div>
                        </div>

                        <Link style={{ color: 'white' }} to={{
                            pathname: '/ChemicalFertilizerOAF',
                            state: {
                                name: localStorage.getItem('current_editting_farm_name'),
                                farm_id: localStorage.getItem('current_editting_farm')
                            }
                        }}>
                            <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                            </div>
                        </Link>

                        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Slide down>
                                <div style={{ // ***************************************** Top card *****************************************************************************
                                    boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                    display: 'flex',
                                    backgroundColor: '#1D75FA',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 8,
                                    width: '80vw',
                                    height: '20vh',
                                    marginTop: '9vh',
                                    borderRadius: 40,
                                    position: 'relative'
                                }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                            <div>
                                                <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                            </div>
                                            <div style={{ marginRight: 8 }}>
                                                {this.state.farms_count}
                                            </div>
                                        </div>

                                    </div>


                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                                        <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                            در این بخش می توانید اطلاعات کوددهی باغ خود را مشاهده یا ویرایش کنید.
</div>
                                    </div>
                                </div>
                            </Slide>
                        </div>


                        <div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>

                            <div className="diseaseItems" onClick={this.open_urea_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_1_color }} />اوره</div>
                            <Modal visible={this.state.urea_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_urea_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>اوره:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>

                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ urea_amount: value })
                                            }}
                                            initialValue={this.state.urea_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />


                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_1_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_ammonium_sulfate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_2_color }} />سولفات آمونیوم</div>
                            <Modal visible={this.state.ammonium_sulfate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_ammonium_sulfate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>سولفات آمونیوم:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>

                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ ammonium_sulfate_amount: value })
                                            }}
                                            initialValue={this.state.ammonium_sulfate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />


                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_2_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_triple_super_phosphate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_3_color }} />سوپر فسفات تریپل (کود سیاه ریشه ای)</div>
                            <Modal visible={this.state.triple_super_phosphate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_triple_super_phosphate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>سوپر فسفات تریپل (کود سیاه ریشه ای):</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>

                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ triple_super_phosphate_amount: value })
                                            }}
                                            initialValue={this.state.triple_super_phosphate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />


                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_3_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_simple_super_phosphate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_4_color }} />سوپر فسفات ساده</div>
                            <Modal visible={this.state.simple_super_phosphate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_simple_super_phosphate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>سوپر فسفات ساده:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>

                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ simple_super_phosphate_amount: value })
                                            }}
                                            initialValue={this.state.simple_super_phosphate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />
                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_4_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_mono_ammonium_phosphate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_5_color }} />  منو آمونیوم فسفات</div>
                            <Modal visible={this.state.mono_ammonium_phosphate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_mono_ammonium_phosphate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}> منو آمونیوم فسفات:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>

                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ mono_ammonium_phosphate_amount: value })
                                            }}
                                            initialValue={this.state.mono_ammonium_phosphate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />
                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_5_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_D_ammonium_phosphate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_6_color }} />دی آمونیوم فسفات</div>
                            <Modal visible={this.state.D_ammonium_phosphate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_D_ammonium_phosphate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>دی آمونیوم فسفات:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>

                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ D_ammonium_phosphate_amount: value })
                                            }}
                                            initialValue={this.state.D_ammonium_phosphate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />

                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_6_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_potassium_phosphate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_7_color }} />سولفات پتاسیم (پتاس)</div>
                            <Modal visible={this.state.potassium_phosphate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_potassium_phosphate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>سولفات پتاسیم (پتاس):</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>

                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ potassium_phosphate_amount: value })
                                            }}
                                            initialValue={this.state.potassium_phosphate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />
                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_7_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_iron_sulfate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_8_color }} />سولفات آهن</div>
                            <Modal visible={this.state.iron_sulfate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_iron_sulfate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>سولفات آهن:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>

                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ iron_sulfate_amount: value })
                                            }}
                                            initialValue={this.state.iron_sulfate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />

                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_8_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_zinc_sulfate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_9_color }} />سولفات روی</div>
                            <Modal visible={this.state.zinc_sulfate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_zinc_sulfate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>سولفات روی:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>


                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ zinc_sulfate_amount: value })
                                            }}
                                            initialValue={this.state.zinc_sulfate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />

                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_9_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_manganese_sulfate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_10_color }} />سولفات منگنز</div>
                            <Modal visible={this.state.manganese_sulfate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_manganese_sulfate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>سولفات منگنز:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>


                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ manganese_sulfate_amount: value })
                                            }}
                                            initialValue={this.state.manganese_sulfate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />

                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_10_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_copper_sulfate_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_11_color }} />سولفات مس</div>
                            <Modal visible={this.state.copper_sulfate_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_copper_sulfate_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>سولفات مس:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>
                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ copper_sulfate_amount: value })
                                            }}
                                            initialValue={this.state.copper_sulfate_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />
                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_11_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_boric_acid_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_12_color }} /> بوریک اسید</div>
                            <Modal visible={this.state.boric_acid_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_boric_acid_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>بوریک اسید:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>
                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ boric_acid_amount: value })
                                            }}
                                            initialValue={this.state.boric_acid_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />
                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_12_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>

                            <div className="diseaseItems" onClick={this.open_extra_chemical_fertilizer_modal}><CheckBoxIcon style={{ marginLeft: 4, color: this.state.modal_13_color }} /> سایر</div>
                            <Modal visible={this.state.extra_chemical_fertilizer_modal} width="95%" heighheight="50%" effect="fadeInUp" onClickAway={this.close_extra_chemical_fertilizer_modal}>
                                <div className="modal-overlay">

                                    <div className="modal-title-container">
                                        <div style={{ width: '10%' }}><CloseRoundedIcon onClick={() => this.closeModal()} /></div>
                                        <div style={{ width: '80%', marginTop: 8 }}>سایر:</div>
                                        <div style={{ width: '10%' }}></div>
                                    </div>

                                    <div style={{ height: '80%', padding: 16 }}>
                                        <TextInput inputName='نام کود:'
                                            onChange={value => {
                                                this.setState({ extra_chemical_fertilizer_name: value })
                                            }}
                                            initialValue={this.state.extra_chemical_fertilizer_name} InputPlaceHolder='نام کود ...' />


                                        <TextInput inputName='مقدار کود:'
                                            onChange={value => {
                                                this.setState({ extra_chemical_fertilizer_amount: value })
                                            }}
                                            initialValue={this.state.extra_chemical_fertilizer_amount} InputPlaceHolder='بر حسب کیلو گرم بر هکتار' />

                                    </div>
                                    <div style={{ height: '10%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}
                                        onClick={() => {
                                            this.closeModal();
                                            this.setState({ modal_13_color: 'rgb(29, 117, 250)' })
                                        }}>
                                        <div className="modal-confirm-button"><a style={{ color: 'white' }}>ثبت</a></div>
                                    </div>
                                </div>
                            </Modal>




                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                zIndex: 0,
                                marginTop: '32px',
                                marginBottom: '64px',
                            }}>
                                <AwesomeButtonProgress
                                    size='large'
                                    type='primary'
                                    disabled={false}
                                    fakePress={false}
                                    releaseDelay={2000}
                                    loadingLabel='در حال پردازش'
                                    resultLabel='ثبت شد!'
                                    onPress={this.on_farm_fertilizer_submit}
                                >
                                    ثبت
</AwesomeButtonProgress>
                            </div>

                        </div>


                    </div>
                );
            }

        }
        else if (this.state.form_is_done) {
            return (<Redirect to={{
                pathname: '/ChemicalFertilizerOAF',
                state: {
                    name: localStorage.getItem('current_editting_farm_name'),
                    farm_id: localStorage.getItem('current_editting_farm')
                }
            }} />);
        }
    }
}