import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { CircularProgressbar } from 'react-circular-progressbar';
import Modal from 'react-awesome-modal';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { ArrowRight, Flare } from '@material-ui/icons';
import Shake from 'react-reveal/Shake';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';


export default class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slide_right: false,
            slide_left: false,
            slide_down: false,
            current_img: 'url(' + require('./introIMGs/1.png') + ')',
            img_urls: [
                'url(' + require('./introIMGs/1.png') + ')',
                'url(' + require('./introIMGs/2.png') + ')',
                'url(' + require('./introIMGs/3.png') + ')',
                'url(' + require('./introIMGs/4.png') + ')',
                'url(' + require('./introIMGs/5.png') + ')',
                'url(' + require('./introIMGs/6.png') + ')',
                'url(' + require('./introIMGs/7.png') + ')',
                'url(' + require('./introIMGs/8.png') + ')',
            ]
        };
    }

    componentDidMount() {
        this.setState({ slide_down: true })
    }

    onClickNext = () => {
        this.setState({ slide_right: true, slide_down: false, slide_left: false })
        for (let i = 0; i < this.state.img_urls.length; i++) {
            if (this.state.img_urls[i] == this.state.current_img && i < 7) {
                this.setState({ current_img: this.state.img_urls[i + 1] })
            }
        }
    }

    onClickPrevious = () => {
        this.setState({ slide_right: false, slide_down: false, slide_left: true })


        for (let i = 0; i < this.state.img_urls.length; i++) {
            if (this.state.img_urls[i] == this.state.current_img && i > 0) {
                this.setState({ current_img: this.state.img_urls[i - 1] })
            }
        }

    }
    render() {

        ////console.log(this.state.img_urls, this.state.current_img)
        return (
            <div style={{ position: 'relative', width: '100%', height: '100%', }}>
                <div style={{
                    width: '100%',
                    position: 'absolute',
                    fontSize: 16,
                    color: 'white',
                    zIndex: 9000000001,
                    backgroundColor: 'rgb(0 4 11 / 66%)',
                    width: '160vw',
                    height: '51vw',
                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                    left: '-32%',
                    top: '-20%'
                }}>


                </div>
                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%', zIndex: 9000000002, }}
                    onClick={this.props.sendBoolToHomePage}>
                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                        خروج از راهنما
</div>
                </div>

                {this.state.slide_right &&
                    <div>
                        <Slide right key={this.state.current_img}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                backgroundImage: this.state.current_img,
                                backgroundSize: 'contain'
                            }}>


                            </div>
                        </Slide></div>}


                {this.state.slide_down &&
                    <div>
                        <Slide down key={this.state.current_img}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                backgroundImage: this.state.current_img,
                                backgroundSize: 'contain'
                            }}>


                            </div>
                        </Slide></div>}

                {this.state.slide_left &&
                    <div>
                        <Slide left key={this.state.current_img}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                backgroundImage: this.state.current_img,
                                backgroundSize: 'contain'
                            }}>


                            </div>
                        </Slide></div>}


                <div key={this.state.current_img} style={{ zIndex: 9000000000, display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                    <div onClick={() => { this.onClickNext(); }}
                        style={{
                            backgroundColor: 'transparent',
                            opacity: 0.8,
                            height: '100%',
                            width: '50%',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        { (this.state.current_img != 'url(' + require('./introIMGs/8.png') + ')') &&
                        <Shake delay={3000} duration={2000}>
                            <ArrowForwardIosRoundedIcon style={{ width: '6vh', height: '6vh', color: 'white' }} />
                        </Shake>
                        }
                    </div>
                    <div onClick={() => { this.onClickPrevious(); }}
                        style={{
                            backgroundColor: 'transparent',
                            opacity: 0.8,
                            height: '100%',
                            width: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                        { (this.state.current_img != 'url(' + require('./introIMGs/1.png') + ')') &&
                        <Shake delay={3000} duration={2000}>
                            <ArrowBackIosRoundedIcon style={{ width: '6vh', height: '6vh', color: 'white' }} />
                        </Shake>
                         }
                    </div>
                </div>
            </div>
        )
    }
} 