
import React, { Component } from 'react';
import './styles/Wallet.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { BrowserRouter as Route, Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { green } from '@material-ui/core/colors';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import {Timeline, TimelineEvent, TimelineBlip} from 'react-event-timeline'
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Slide from 'react-reveal/Slide';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Loading from './Loading.js';
import Modal from 'react-awesome-modal';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Slider from '@material-ui/core/Slider';


const FarmList = ({ updated_at1, verfied1, amount1, status_color1, status_icon1 }) => (
    <div>
    <TimelineEvent
    title={verfied1}
    createdAt={updated_at1}
    icon={status_icon1}
    iconColor="#757575"
    buttons={<i />}
    container="card"
    style={{
        boxShadow: '0px 0px 6px 1px #d0d0d0', 
        border: '1px solid rgb(186, 186, 186)',
        borderRadius: 40,
        padding: 0.5,
        textAlign: 'right',
        direction: 'rtl',
        width: '90%'
    }}
    cardHeaderStyle={{ borderTopLeftRadius: 40, borderTopRightRadius: 40, backgroundColor: '#f8f9fa', color: '#503331', paddingRight: '2em' }}
>
    <div style={{color: status_color1 }}>
        مقدار تراکنش: {amount1} هزار تومان
    </div>

</TimelineEvent>
</div>
      )


export default class Wallet extends Component {
  constructor() {
    super();
    this.state ={
        buttonState: '',
         posts: [],
         loaded: false,
         add_charege_modal: false,
         current_charging_value: 60,
         payment_link: '',
         no_payment_history: 'none',
         account_balance: number,
    }
    
  }

  componentDidMount () {   
      this.setState({loading: true});
      const axios = require('axios');
      axios.get('https://api.pestekaran.com/api/profile', 
      {
      
      params: {
      api_token: localStorage.getItem('userTOKEN'),
      }
      ,
      headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json'
      }
      })
        .then((response) => {
            this.setState({ account_balance: response.data.charge });
  
        })
      .catch((error) => {
      //console.log(error);
      })
      .finally(function () {
      // always executed
      }); 

      
    axios.get('https://api.pestekaran.com/api/payments',
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json'
      },
      params: {
          api_token: localStorage.getItem('userTOKEN')
      }
    })
    .then((response) => {

        if ( response.data == null ) {

            this.setState({ 
                no_payment_history: 'block',
                loaded: true })
        }
        else {
        //console.log(response);

        
        var verfied_status = '';
        var status_color_changed = '';
        var status_icon_changed;

        for (let i = 0; i < response.data.length; i++) {

             status_icon_changed = <CheckRoundedIcon style={{color: 'green'}} />
             verfied_status = 'تراکنش با موفقیت';
             status_color_changed = 'green';

             if ( response.data[i].is_verified == 0 ) { 
                 verfied_status= 'تراکنش با خطا'; 
                 status_color_changed = 'red'; 
                 status_icon_changed = <ClearRoundedIcon style={{color: 'red'}} /> 
                }

            this.setState({ posts: [...this.state.posts, 
            {
                updated_at: response.data[i].updated_at,
                verfied: verfied_status,
                amount: Math.floor((response.data[i].amount/1000)),
                status_color: status_color_changed,
                status_icon: status_icon_changed
            },
            ] })
        }
        this.setState({ loaded: true,
            no_payment_history: 'none' })
    }
    })
    .catch(function (response) {
      //console.log(response);
    })
    .finally(function () {
      // always executed
    });

  }


  render() {
    if (!this.state.loaded) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Loading />
          </div>
        );
      }

      else {
    return (

<div class="walletBG" style={{display: 'flex', flexDirection: 'column'}}>
    <div style={{
        position: 'absolute',
        backgroundColor: '#007bffa8',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        width: '160vw', 
        height: '80vw', 
        borderRadius: '80vw / 40vw',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        left: '-32%',
        top: '-20%'
    }}
        >
        </div>
    
    
    <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%'}}>
        <div style={{fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white'}}>
        کیف پول
        </div>
    </div>

    <Link to="/Home" style={{ color: '#FFF' }}>
                <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                </div>
            </Link>

    <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%'}}>
        <Slide down>
                <div style={{
                    boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)", 
                    display: 'flex',
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'space-around',
                    padding: 8, 
                    width: '40vh', 
                    height: '35vh', 
                    marginTop: '10vh', 
                    backgroundColor: '#1D75FA', 
                    borderRadius: 40
                }}>
                    <MonetizationOnRoundedIcon style={{ color: 'white', width: '8vh', height: '8vh' }} />
            <div style={{fontSize: '1.2rem', fontWeight: 'bold', marginTop: 8, color: 'white'}}>
        موجودی  
        </div>
        <div style={{fontSize: '1.2rem', fontWeight: 'bold', marginTop: 8, color: 'white', paddingBottom: 16}}>
        {(this.state.account_balance)/1000} هزار تومان  
        </div>
                    <div onClick={() => {this.setState({ add_charege_modal: true })} } style={{}}>
                        <button style={{
                            boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                            backgroundColor: '#0857c3',
                            border: '1px solid rgb(3, 77, 179)',
                            borderRadius: 40,
                            color: 'white',
                            padding: '12px 16px',
                            fontSize: 16,
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center'
                        }}>
                            <AddCircleRoundedIcon style={{marginLeft: 8}} />افزایش اعتبار</button>
                    </div>
        </div>
</Slide>
            </div>

            <Modal visible={this.state.add_charege_modal} width="90%" height="50%" effect="fadeInDown" onClickAway={() => { this.setState({ add_charege_modal: false }) }}>
                  <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                        <CloseRoundedIcon onClick={() => { this.setState({ add_charege_modal: false }) }} style={{ color: 'red' }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>

                      <div style={{ marginBottom: '5vh', fontSize: 14, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
                       میزان افزایش حساب:
                      </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', padding: 8 }}>
                            <Slider
                                defaultValue={60}
                                aria-labelledby="discrete-slider-always"
                                onChange={ (event, value) => { 
                                    this.setState({ 
                                        current_charging_value: value,
                                        payment_link: 'https://api.pestekaran.com/api/charge/'+ value*1000 + '/?api_token=' + localStorage.getItem('userTOKEN')
                                    });

                                                             }}
                                valueLabelDisplay="on"
                                step={20}
                                marks
                                min={20}
                                max={200}
                            />
                        </div>

                    <div style={{marginBottom: 64}}>{this.state.current_charging_value} هزار تومان</div>
                    
                    
                        <a href={this.state.payment_link}><button style={{
                            boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                            backgroundColor: '#0857c3',
                            border: '1px solid rgb(3, 77, 179)',
                            borderRadius: 40,
                            color: 'white',
                            padding: '12px 16px',
                            fontSize: 14,
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center'
                        }}>
                            <CheckRoundedIcon style={{marginLeft: 8}} />افزایش اعتبار</button></a>
                    
                    

                    </div>


                  </div>

                </Modal>

<div style={{fontSize: 16, textAlign: 'right', marginTop: '50vh', marginRight: 16}}>تاریخچه حساب:</div>
            <div style={{ padding: 10, overflow: 'auto', height: '40vh'}}>
                <div style={{display: this.state.no_payment_history, textAlign: 'center'}}> شما تا به حال تراکنشی انجام نداده اید.</div>
                <Timeline orientation='right'>
                {this.state.posts.map(({ updated_at, verfied, amount, status_color, status_icon}) => (
							
								<FarmList updated_at1={updated_at} verfied1={verfied} amount1={amount} status_color1={status_color} status_icon1={status_icon} />
							
						))}

                </Timeline>
            </div>

</div>
    );
   }
  }
}
