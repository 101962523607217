import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './styles/AddNewQuestion.css';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import DatePicker from './Fields/DatePicker.js';
import TextInput from './Fields/TextInput.js';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import {sendLog} from './LogTest.js';

import {
    AwesomeButton,
    AwesomeButtonProgress,
    AwesomeButtonSocial,
  } from 'react-awesome-button';
  import AwesomeButtonStyles from 'react-awesome-button/src/styles/styles.scss'

export default class FarmVisit extends Component {

  constructor(props) {
    super(props);
    this.state = {
        question_text: ' ',
        uploader_volume: number,
        image_selected: false,
        farms_name : [],
        show_newAddressChosen: 'none',
        newAddressChosen: false,

    };
}

scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }
  /*
  componentDidUpdate() {
    this.scrollToBottom();
  }
  */

    componentDidMount() {
      localStorage.setItem('back_from_requests', true)
        this.scrollToBottom();
        const axios = require('axios');
        axios.get('https://api.pestekaran.com/api/farms',
            {

                params: {
                    api_token: localStorage.getItem('userTOKEN'),
                }
                ,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json'
                }
            })
            .then((response) => {
                //console.log(response);
                localStorage.setItem('farm_list', JSON.stringify(response.data));
                var farms = JSON.parse(localStorage.getItem('farm_list'));
                for (let i = 0; i < farms.length; i++) {
                    this.setState({
                        farms_name: [...this.state.farms_name, {
                            value: (i+1).toString(),
                            label: farms[i].name,
                        }]
                    })
    }
    //console.log(this.state.farms_name);


})
.catch((error) => {
  //console.log(error);
  //console.log("code ghalate")
})
.finally(function () {
  // always executed
}); 

  }


  questionSubmit = (element, next) => {

    const axios = require('axios');

    axios.post('https://api.pestekaran.com/api/messages',
      {

        category_id: 62,
        api_token: localStorage.getItem('userTOKEN'),
        text: 'شرح درخواست:' + this.state.reason + '<br>' + 'تاریخ درخواست:' + this.state.suggested_date + '<br>' + 'زمان پیشنهادی:' + this.state.suggested_time + '<br>' + 'آدرس:' + this.state.address,
        farm_id: ''
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {

        //console.log(response);
        next();
        this.setState({
          question_asked: true,
          log_text: 'درخواست شما ثبت شد. تا زمان پاسخ کارشناسان شکیبا باشید.'
        })



      })
      .catch((error) => {
        next(false, 'مجددا تلاش نمایید!');
        sendLog(error.response);
        this.setState({
          question_asked: true,
          log_text: 'اتصال خود به اینترنت را بررسی کنید و مجددا تلاش نمایید.'
        })

      })
      .finally(function () {
        // always executed
      });
  }


  render() {

if (this.state.newAddressChosen) {
    var AddressOrFarm = (
        <div style={{ marginTop: 32 }}>
            <TextInput inputName='آدرس:' onChange={ (value) => { this.setState({ address: value }) }}/>
        </div>
    );}
    else if (!this.state.newAddressChosen) {
        var AddressOrFarm = (
            <div style={{ textAlign: 'right' }}>
                <div style={{ marginTop: 32 }}>
                    نام باغ:
                    </div>
                <Select
                    className=""
                    onChange={ (value) => { this.setState({ address: 'باغ ثبت شده:' + value.label }) } }
                    style={{ textAlign: 'center', height: 35 }}
                    options={this.state.farms_name}
                    placeholder='باغ'
                />
            </div>
        );}



    return (


      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
        <div style={{ // ***************************************** header ***********************************************************************************
          position: 'absolute',
          backgroundColor: '#007bffa8',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          width: '160vw',
          height: '80vw',
          borderRadius: '80vw / 40vw',
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
          left: '-32%',
          top: '-20%'
        }}
        >
        </div>


        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
             بازدید از باغ
                </div>
        </div>

        <Link to="/Home" style={{ color: '#FFF' }}>
          <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
          </div>
        </Link>

        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Slide down>
            <div style={{ // ***************************************** Top card *****************************************************************************
              boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
              display: 'flex',
              backgroundColor: '#1D75FA',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 8,
              width: '80vw',
              height: '20vh',
              marginTop: '9vh',
              borderRadius: 40,
              position: 'relative'
            }}>

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                  </div>
                  <div style={{ marginRight: 8 }}>
                    {this.state.farms_count}
                  </div>
                </div>

              </div>


              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                  برای بازدید از باغ توسط کارشناسان، لطفا دلیل، زمان و تاریخ آن را در فرم زیر پر بفرمایید.
                      </div>
              </div>
            </div>
          </Slide>
        </div>

        
        <div style={{ overflow: 'hidden', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 32, height: '70vh', marginTop: '30vh' }} ref={(el) => { this.messagesEnd = el; }}>
        <div style={{width: '100%'}} ref={(el) => { this.messagesEnd = el; }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 16}}>
                <textarea
                    onChange={ (e) => { this.setState({ reason: e.target.value }) } }
                    style={{ borderRadius: 5 }}
                    placeholder="دلیل درخواست بازدید حضوری از باغ توسط کارشناسان مجموعه پسته کاران را به طور خلاصه در اینجا بنویسید ...."
                    rows="5"
                    cols="100">
                </textarea>
</div>



                    <DatePicker inputName='روز پیشنهادی:' onChange={value => { this.setState({ suggested_date: value }) }}/>


                    <div style={{ marginBottom: 32, textAlign: 'right' }}>
                    <div>
                        زمان پیشنهادی:
                    </div>
                    <Select
                        className=""
                        onChange={ (value) => { this.setState({ suggested_time: value.label }) }}
                        style={{ textAlign: 'center', height: 35 }}
                        options={[
                            { value: '1', label: 'صبح' },
                            { value: '2', label: 'بعد از ظهر' },
                        ]}
                        placeholder='زمان'
                    />
                    </div>

                    <div style={{ marginBottom: 32, textAlign: 'right' }}>
                    <div>
                        محل بازدید:
                    </div>
                    <Select
                        className=""
                        onChange={ value => {
                            
                            if (value.value == '1') { this.setState({ newAddressChosen: false }) }
                            if (value.value == '2') { this.setState({ newAddressChosen: true }) }
                            this.setState({ show_newAddressChosen: 'block' })
                            
                        }}
                        style={{ textAlign: 'center', height: 35 }}
                        options={[
                            { value: '1', label: 'انتخاب از لیست باغ های موجود' },
                            { value: '2', label: 'وارد کردن آدرس محل بازدید' },
                        ]}
                        placeholder='محل'
                    />
                    </div>
                    
                    <div style={{display: this.state.show_newAddressChosen}}>
                        {AddressOrFarm}
                    </div>


<div className="frame" style={{zIndex: 0, marginTop: 64, marginBottom: 64}}>
<AwesomeButtonProgress
      size='large'
      type='primary'
      disabled={false}
      fakePress={false}
      releaseDelay={2000}
      loadingLabel='در حال پردازش'
      resultLabel='ثبت شد!'
      onPress={this.questionSubmit}
    >
      ثبت 
    </AwesomeButtonProgress>
</div>
</div>
</div>



      </div>
    );
  }
}



