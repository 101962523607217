
import React, { Component } from 'react';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Select from 'react-select';
import DatePicker from '../Fields/DatePicker.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/creatable';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TextInput from '../Fields/TextInput.js';
import AcUnitRoundedIcon from '@material-ui/icons/AcUnitRounded';


export default class ChemicalFertilizerOAF extends Component {
    constructor(props) {
        super(props);
        this.state = {

form_is_Ready: false,        }
    }

    onSubmit = () => {
        toast.configure({
            position: "top-left",
            rtl: true,
            hideProgressBar: true,
            toastClassName: 'error-notifier-toast',
            });
               
           toast("ثبت شد");   

      this.setState({form_is_Ready: true})
    }


    render() {

if (!this.state.form_is_Ready) {
        const JDate = require('jalali-date');
        const jdate = new JDate;

        return(
<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

<div style={{ // ***************************************** header ***********************************************************************************
    position: 'absolute',
    backgroundColor: '#007bffa8',
    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
    width: '160vw',
    height: '80vw',
    borderRadius: '80vw / 40vw',
    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
    left: '-32%',
    top: '-20%'
}}
>
</div>


<div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
    کود حیوانی
</div>
</div>

<Link style={{ color: 'white' }} to={{
    pathname: '/FertilizerBackground',
    state: {
        name: localStorage.getItem('current_editting_farm_name'),
        farm_id: localStorage.getItem('current_editting_farm')
    }
}}>
    <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
    </div>
</Link>

<div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
    <Slide down>
        <div style={{ // ***************************************** Top card *****************************************************************************
            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
            display: 'flex',
            backgroundColor: '#1D75FA',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 8,
            width: '80vw',
            height: '20vh',
            marginTop: '9vh',
            borderRadius: 40,
            position: 'relative'
        }}>

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                    </div>
                    <div style={{ marginRight: 8 }}>
                        {this.state.farms_count}
                    </div>
                </div>

            </div>


            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center' }}>
                دقت نمایید این قسمت را تنها در صورت کاربرد کود شیمیایی روی کود حیوانی در دو سال اخیر کامل نمایید.
      </div>
            </div>
        </div>
    </Slide>
</div>


<div style={{ padding: 32, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>


<div className="frame">

    <Link to='/CFOAFlastYearEdit' style={{color: 'black'}}><div className="general-farm-info"><div className="correctionFormNotation">کاربرد کود شیمیایی روی کود حیوانی در سال{(jdate.getFullYear())-1}</div></div></Link>

</div>

<div className="frame">

    <Link to='/CFOAFlastTwoYearEdit' style={{color: 'black'}}><div className="general-farm-info"><div className="correctionFormNotation">کاربرد کود شیمیایی روی کود حیوانی در سال{(jdate.getFullYear())-2}</div></div></Link>

</div>






                    <div className="frame">
                        <button className="regSubmitButton" onClick={this.onSubmit}>ثبت</button>
                    </div>

        </div>


            </div>
        );
}
else if (this.state.form_is_Ready) {
    return(<Redirect to='/FertilizerBackground' />);
}
    }
}