import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { CircularProgressbar } from 'react-circular-progressbar';
import Modal from 'react-awesome-modal';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { ArrowRight, Flare } from '@material-ui/icons';
import Shake from 'react-reveal/Shake';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import { MetroSpinner } from "react-spinners-kit";
import ReactHtmlParser from 'react-html-parser';
import Loading from './Loading.js';
import RegPart1 from './RegPart1';
import { sendLog } from './LogTest.js';

export default class TermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
        };
    }

    componentDidMount() {

        const axios = require('axios');
        axios.get('https://pestekaran.ir/?rest_route=/wp/v2/pages/10653',)
            .then((response) => {
                console.log(response.data);

                this.setState({ text: ReactHtmlParser(response.data.content.rendered) }, () => this.setState({ ready: true }))


            })
            .catch(function (error) {
                sendLog(error.response);
            })
            .finally(function () {
                // always executed
            });

    }




    render() {


        if (this.state.ready == false) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loading />
                </div>);
        }

        else {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', position: 'relative' }}>
                    <div style={{ width: '100%', height: '85%', textAlign: 'right', padding: 16 }}>
                        {this.state.text}
                    </div>

                    <div style={{ width: '100%', height: '15%', justifyContent: 'center', backgroundColor: 'rgb(0 0 0 / 56%)', textAlign: 'center', position: 'sticky', bottom: 0 }} onClick={this.props.sendBoolToRegPage}>

                        <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white', height: '100%', marginBottom: 15 }}>
                            خروج
</div>
                    </div>

                </div>

            );
        }
    }
} 