import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import DatePicker from './Fields/DatePicker.js';
import TextInput from './Fields/TextInput.js';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { CircularProgressbar } from 'react-circular-progressbar';
import Modal from 'react-awesome-modal';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Loading from './Loading.js';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import {
  AwesomeButton,
  AwesomeButtonProgress,
  AwesomeButtonSocial,
} from 'react-awesome-button';
import AwesomeButtonStyles from 'react-awesome-button/src/styles/styles.scss'
import { Block } from '@material-ui/icons';

export default class PaymentPolicy extends Component {

  constructor(props) {
    super(props);
    this.state = {

      account_balance: 0,
      price: 0,
      payment_URL: '',

      engagement_quantity_identified_by_user: null,
      pest_percentage: null,

      form_is_done: false,
      question_text: ' ',
      uploader_volume: number,
      farms_name: [],
      show_newAddressChosen: 'none',
      newAddressChosen: false,

      pest_engaged_visible: 'none',
      pest_not_engaged_visible: 'none',
      image_selected: false,
      upload_modal: false,
      uploader_volume: 0,

      loading: true,
      payment_popup: false

    };
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }
  /*
  componentDidUpdate() {
    this.scrollToBottom();
  }
  */

  componentDidMount() {
      let account_balance = parseInt(JSON.parse(localStorage.getItem('payment_object')).account_balance);
      let price = parseInt(JSON.parse(localStorage.getItem('payment_object')).price);
      let payment_url = JSON.parse(localStorage.getItem('payment_object')).Payment_url;

      this.setState({ account_balance: account_balance,
                      price: price,
                      payment_URL: payment_url}, () => {
                          this.setState({ loading: false })
                      })


    this.scrollToBottom();
  }


  questionSubmit = (element, next) => {

    const axios = require('axios');
    axios.post('https://api.pestekaran.com/api/pest',
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        },

        api_token: localStorage.getItem('userTOKEN'),
        farm_id: localStorage.getItem('pested_farm_id'),
        msg_id: parseInt(localStorage.getItem('pest_modal_msg_id')),
        data: {
          choobkhar: {
            engagement_quantity_identified_by_user: this.state.engagement_quantity_udentified_by_user,
            pest_percentage: this.state.pest_percentage,
          }
        },


      })
      .then((response) => {
        //console.log(response)
        next();
        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'success-notifier-toast',
        });

        toast("درخواست شما ثبت شد. تا زمان پاسخ کارشناسان شکیبا باشید.");

        this.setState({ form_is_done: true })
      })
      .catch((error) => {
        next(false, 'مجددا تلاش نمایید!');
      })
      .finally(function () {
        // always executed
      });

  }




  render() {

if (this.state.loaidng) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading />
        </div>
      );
}

else {

    if (!this.state.form_is_done) {
      return (


        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>



          <div style={{ overflow: 'hidden', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 32, height: '70vh', marginTop: '30vh' }} ref={(el) => { this.messagesEnd = el; }}>
            <div style={{ width: '100%' }} ref={(el) => { this.messagesEnd = el; }}>


            <Modal visible={this.state.payment_popup} width="80%" height="50%" effect="fadeInDown" onClickAway={() => { this.setState({ payment_popup: false }) }}>
                <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><CloseRoundedIcon onClick={() => { this.setState({ payment_popup: false }) }} style={{ color: 'red' }} /></div>

                  <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>

                    <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                      توجه:
                                  </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                      <ul style={{ textAlign: 'right' }}>
                        <li>
                            آیا مایل هستید مبلغ پرداختی از کیف پول شما کم شود؟
                        </li>
                      </ul>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                      <div
                        onClick={() => { window.location.replace(this.state.payment_URL);
                        }} style={{ color: 'red' }}
                        style={{
                          width: '40%',
                          height: '50%',
                          boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                          backgroundColor: '#0857c3',
                          border: '1px solid rgb(3, 77, 179)',
                          borderRadius: 40,
                          color: 'white',
                          padding: '12px 16px',
                          fontSize: 12,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        بله </div>
                    </div>


                  </div>


                </div>

              </Modal>



            <div style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
        <Slide down>
                <div style={{
                    boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)", 
                    display: 'flex',
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'space-around',
                    padding: 8, 
                    width: '40vh', 
                    height: '35vh', 
                    marginTop: '10vh', 
                    backgroundColor: '#1D75FA', 
                    borderRadius: 40
                }}>
                    <MonetizationOnRoundedIcon style={{ color: 'white', width: '8vh', height: '8vh' }} />
            <div style={{fontSize: '1rem', fontWeight: 'bold', marginTop: 8, color: 'white'}}>
        موجودی کیف پول: {(this.state.account_balance)/1000} هزار تومان  
        </div>
        <div style={{fontSize: '1rem', fontWeight: 'bold', marginTop: 8, color: 'white', paddingBottom: 16}}>
        مبلغ درخواستی: {(this.state.price)/1000} هزار تومان 
        </div>
                    <div onClick={() => {
                                          
                                          if (this.state.account_balance >= this.state.price) {
                                              this.setState({ payment_popup: true })
                                          }

                                          else {
                                            window.location.replace(this.state.payment_URL);
                                          }
                    }}>
                        <button style={{
                            boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                            backgroundColor: '#0857c3',
                            border: '1px solid rgb(3, 77, 179)',
                            borderRadius: 40,
                            color: 'white',
                            padding: '12px 16px',
                            fontSize: 16,
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'center'
                        }}>
                            پرداخت</button>
                    </div>
        </div>
</Slide>
            </div>

            </div>
          </div>


        </div>
      );
    }

    if (this.state.form_is_done) {
      return (<Redirect to='/Message' />);
    }
  }
}
}



