
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import './styles/Home.css';
import './styles/Profile.css';
import Home from './Home.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LabelBottomNavigation from './BottomTab';
import TextInput from './Fields/TextInput.js';
import DatPickerEditable from './Fields/DatPickerEditable.js';
import PersianDatePicker from './Fields/PersianDatePicker.js';
import Select from 'react-select';
import DatePicker from './Fields/DatePicker.js';
import axios from 'axios';
import Slide from 'react-reveal/Slide';
import { CircularProgressbar } from 'react-circular-progressbar';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { AwesomeButtonProgress } from 'react-awesome-button';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Modal from 'react-awesome-modal';
import Loading from './Loading.js';
import {sendLog} from './LogTest.js';


const customStyles = {

  overlay: {zIndex: 20000}
};


class Profile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      file: "",
      ready: false,
      name: '',
      lName: '',
      finalDate: '',
      education_lvl_value: '',
      major: '',
      job_type: '',
      farming_history: '',
      location: '',
      extra_explaination: '',
      user_exist: false,
      loaded: false,
      uploader_volume: 0,
      image_selected: false,
      isOpen: false,
      upload_modal: false,
    }
    this.upload = this.upload.bind(this)
    this._open_profile_image = this._open_profile_image.bind(this);
  }
  _open_profile_image = () => { this.setState({isOpen: true})}
  upload(event) {
    this.setState({ image_selected: true, upload_modal: true });
    //console.log((event.target.files[0].size) / 1024);
    localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("photo", event.target.files[0]);
    formData.append('api_token', localStorage.getItem('userTOKEN'));
    formData.append('id', parseInt(localStorage.getItem('userID')));

    const config = {
      onUploadProgress: progressEvent => {
        var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
        //console.log(x);
        this.setState({ uploader_volume: x })
      }
    }



    axios.post('https://api.pestekaran.com/api/profile/photo', formData, config, {


      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      },
      api_token: localStorage.getItem('userTOKEN'),
      id: parseInt(localStorage.getItem('userID'))

    })
      .then((response) => {
        this.setState({ file: response.data.photo });
        //console.log(response);

        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });

        toast("تصویر شما با موفقیت بارگذاری شد!");
        this.setState({ image_selected: false, upload_modal: false });


      })
  }
  componentDidMount() {
    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/profile',
      {

        params: {
          api_token: localStorage.getItem('userTOKEN'),
        }
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        //console.log(response);
        if (response.data.photo == null) { this.setState({ file: 'https://cdn.imgbin.com/6/25/24/imgbin-user-profile-computer-icons-user-interface-mystique-aBhn3R8cmqmP4ECky4DA3V88y.jpg' }) }
        else if (response.data.photo != null) { this.setState({ file: response.data.photo }) }
        localStorage.setItem('user_profile_credentials', JSON.stringify(response.data));
        this.setState({ ready: true });
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(function () {
        // always executed
      });

  }





  onNameInputChage = value => {
    this.setState({
      name: value
    });
  };


  onLnameInputChage = value => {
    this.setState({
      lName: value
    });
  };


  onFarmingHistoryChange = value => {
    this.setState({
      farming_history: value
    });
  };


  onExtraExplainationChange = value => {
    this.setState({
      extra_explaination: value
    });

  };


  onLocationChange = value => {
    this.setState({
      location: value
    });

  };


  onFinalDateChange = value => {
    this.setState({
      finalDate: value
    });
  };


  jobTypeChange = (newValue) => {
    //console.log(typeof (newValue.label));
    this.setState({
      job_type: newValue.label,
    })
  };


  handleEducationLevelChange = (newValue) => {
    //console.log(newValue.label);
    this.setState({
      education_lvl_value: newValue.label,
    })
  };


  onMajorInputChage = value => {
    this.setState({
      major: value
    });
  };


  profileUpdate = (element, next) => {
    const axios = require('axios');
    axios.put('https://api.pestekaran.com/api/profile',
      {

        id: parseInt(localStorage.getItem('userID')),
        name: this.state.name,
        birth_day: localStorage.getItem('first_date'),
        education: this.state.education_lvl_value,
        profession: this.state.job_type,
        major: this.state.major,
        farming_background: this.state.farming_history,
        resident: this.state.location,
        farm_location: this.state.extra_explaination,
        api_token: localStorage.getItem('userTOKEN')
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
        }
      })
      .then(function (response) {
        //console.log(response);
        next();
        localStorage.setItem('user_profile_credentials', JSON.stringify(response.data));
        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'img-uploaded-success-notifier-toast',
        });
        

        toast("اطلاعات شما ویرایش شد.");
      })
      .catch(function (error) {
        next(false, 'مجددا تلاش نمایید!');
        sendLog(error.response);
        toast.configure({
          position: "top-left",
          rtl: true,
          hideProgressBar: true,
          toastClassName: 'error-notifier-toast',
        });

        toast("اتصال خود به اینترنت را بررسی نمایید، در غیر اینصورت بعدا تلاش نمایید. با تشکر!");
      })
      .finally(function () {
        // always executed
      });
  }



  render() {

    var UploadIndicator;
    if (this.state.image_selected == true) {

      UploadIndicator = <div className='circularWait' style={{width: '15vw', height: '15vw', marginRight: 0}}><CircularProgressbar value={this.state.uploader_volume} text={`${this.state.uploader_volume}%`} /></div>;

    } else if (this.state.image_selected == false) {

      UploadIndicator = <div></div>;

    }

    if (this.state.ready == false) {
      return(
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Loading />
        </div>);
    }
    else {
      var user_profile = JSON.parse(localStorage.getItem('user_profile_credentials'));
      return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

          <div style={{
            position: 'absolute',
            backgroundColor: '#007bffa8',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            width: '160vw',
            height: '80vw',
            borderRadius: '80vw / 40vw',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            left: '-32%',
            top: '-20%'
          }}
          >
          </div>


          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
              پروفایل
        </div>
          </div>

          <Link to="/Home" style={{ color: '#FFF' }}>
            <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
            </div>
          </Link>


          <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-start', width: '50%' }}>
            <label for="files"><EditRoundedIcon style={{ margin: 16, color: 'white' }} /></label>
            <input id="files" className="openCameraFile" type="file" accept="image/x-png,image/jpeg,image/gif"
              onChange={this.upload} />
          </div>


          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <img
              onClick={this._open_profile_image}
              src={this.state.file}
              alt="Avatar"
              style={{
                zIndex: 100,
                width: '35vw',
                height: '35vw',
                marginTop: '15VH',
                borderRadius: '50%',
                boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                border: '5px solid rgb(45, 148, 255)',
              }} />
            


            <div>
              {this.state.isOpen && (
                <Lightbox
                  reactModalStyle={customStyles}
                  mainSrc={this.state.file}

                  onCloseRequest={() => this.setState({ isOpen: false })}

                />
              )}
            </div>

          </div>




          <Slide up>
            <div style={{ padding: 32, overflow: 'auto', height: '50vh', marginTop: '40vh' }}>

              <TextInput inputName='نام:' onChange={this.onNameInputChage} initialValue={user_profile.name} />
              <DatPickerEditable inputName='تاریخ تولد:' on_date_edit={this.onLnameInputChage} onChange={this.onLnameInputChage} initialValue={user_profile.birth_day} />
              <TextInput inputName='مدرک تحصیلی:' onChange={this.onMajorInputChage} initialValue={user_profile.education} />
              <TextInput inputName='رشته تحصیلی:' onChange={this.onMajorInputChage} initialValue={user_profile.major} />
              <TextInput inputName='شغل' onChange={this.onMajorInputChage} initialValue={user_profile.profession} />
              <TextInput inputName='سابقه فعالیت (سال):' onChange={this.onFarmingHistoryChange} initialValue={user_profile.farming_background} />
              <TextInput inputName='محل سکونت:' onChange={this.onLocationChange} initialValue={user_profile.resident} />
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <AwesomeButtonProgress
                  size='large'
                  type='primary'
                  disabled={false}
                  fakePress={false}
                  releaseDelay={2000}
                  loadingLabel='در حال پردازش'
                  resultLabel='ثبت شد!'
                  onPress={this.profileUpdate}
                >
                  ویرایش اطلاعات
            </AwesomeButtonProgress>
              </div>
            </div>

          </Slide>





          <Modal visible={this.state.upload_modal} width="50%" height="20%" effect="fadeInDown">
            <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {UploadIndicator}
            </div>
          </Modal>




        </div>

      );
    }
  }
}

export default Profile;

/*


*/