
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import './styles/AboutUs.css';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './styles/AddNewQuestion.css';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import DatePicker from './Fields/DatePicker.js';
import Slide from 'react-reveal/Slide';
import Intro from './Intro.js';
import Modal from 'react-awesome-modal';

export default class AboutUs extends Component {

  constructor(props) {
    super(props);
    this.state = {
        intro_modal: false,
        question_text: ' ',
        uploader_volume: number,
        image_selected: false,

    };
}

setIntroModalState = () => {
    this.setState({ intro_modal: false })
  }




  render() {




    return (
<div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'white'}}>
    <div style={{
        position: 'absolute',
        backgroundColor: '#007bffa8',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        width: '160vw', 
        height: '80vw', 
        borderRadius: '80vw / 40vw',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        left: '-32%',
        top: '-20%'
    }}
        >
        </div>
    
    
    <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%'}}>
        <div style={{fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white'}}>
        درباره ما 
        </div>
    </div>

    <Link to="/Home" style={{ color: '#FFF' }}>
                <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                </div>
            </Link>

    <div onClick={ () => { this.setState({ intro_modal: true })} } style={{position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%'}}>
        <Slide down>
        <img src={require('./styles/aboutus-logo.jpg')} style={{
                    boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)", 
                    display: 'flex',
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'space-around',
                    padding: 8, 
                    width: '40vh', 
                    height: '35vh', 
                    marginTop: '10vh', 
                    borderRadius: 40
                }} />
</Slide>
            </div>

            <Slide up>
            <div style={{ padding: 35, overflow: 'auto', height: '40vh', marginTop: '50vh'}}>
<p style={{textAlign: 'center', textAlign: 'justify', textAlignLast: 'center',}}>

اين اپليكيشن جهت مشاوره احداث و مديريت باغ پسته طراحي شده است. به طوري كه باغدار بتواند در هر
زمان درخواست ها و سوالات خود را با متخصصين مطرح نموده و توصيه يا پاسخ لازم را دريافت نمايد.
</p>
<p style={{textAlign: 'center', textAlign: 'justify', textAlignLast: 'center',}}>
مشاوره در زمينه هاي مختلف مربوط به يك باغ پسته مانند اصلاح خاك، تغذيه و كوددهي، آبياري،
باغباني، آفات و بيماري هاي درختان پسته ارائه مي شود. همچنين مي توانيد جهت احداث باغ در
مناطق جديد سوالات خود را با مشاورين و متخصصين ما در ميان بگذاريد.
</p>
<p style={{textAlign: 'center', textAlign: 'justify', textAlignLast: 'center',}}>
مشاوره و توصيه ها براساس شرايط و خصوصيات هر باغ به طور جداگانه صورت مي گيرد. امكان طرح هر
گونه سوال در مورد باغ با امكانات ارسال فايل آزمايشها، عكس و فيلم در هر زمان امكان پذير است.

</p>

<p style={{textAlign: 'center', textAlign: 'justify', textAlignLast: 'center',}}>
اين اپ توسط جمعي از اعضاء هيات علمي و متخصصين پسته كه اكثر آنها داراي حداقل  20سال سابقه
تحقيق، آموزش و ترويج در مناطق پسته كاري ايران هستند، اداره مي شود. سعي خواهد شد توصيه هاي
علمي و كاربردي، مناسب شرايط هر باغ با بررسي گروهي متخصصين ارائه گردد.

</p>
            </div>
            </Slide>

            <Modal visible={this.state.intro_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ intro_modal: false }) }}>

<div style={{ height: '100%', width: '100%', zIndex: 90000}}>
<Intro sendBoolToHomePage={this.setIntroModalState} />
</div>
                  </Modal>


</div>
    );
  }
}


