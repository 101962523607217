
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import './styles/Home.css';
import './styles/Message.css';
import Profile from './Profile.js';
import LabelBottomNavigation from './BottomTab';
import SimpleList from './SimpleList.js';
import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import QuestionForm from './QuestionForm.js';
import UserNotificatin from './UserNotificatin';
import './styles/Pest.css';
import Loading from './Loading.js';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import TelegramIcon from '@material-ui/icons/Telegram';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'


class Message extends Component {



  constructor(props) {
    super(props);
    this.changeGeneralStyle = this.changeGeneralStyle.bind(this);
    this.changePrivateStyle = this.changePrivateStyle.bind(this);
    this.state = {
      bgColor: 'white',
      txtColor: '#3f51b5',
      chat_box_open: false,
      ready: false,
      public_bg_color: 'transparent',
      public_font_size: 13,
      private_bg_color: '#1d75fa',
      private_font_size: 15
    };
  }

  componentDidMount() {

    localStorage.setItem('payment_object', '{"price":"32000.00","Payment_url":"https://api.pestekaran.com/api/payment/161?api_token=rz375IdYYzQY6ZObeRL5MvgCy9Gdxwlnb2qV5nnqHjQ5sS0TySxuxi2LF1W1","account_balance":"0"}')
    const axios = require('axios');

    let msg_request = axios.get('https://api.pestekaran.com/api/messages',
    {
      params: {
        api_token: localStorage.getItem('userTOKEN')
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json'
      }
    });

    let farms_request = axios.get('https://api.pestekaran.com/api/farms',
    {

      params: {
        api_token: localStorage.getItem('userTOKEN'),
      }
      ,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json'
      }
    });

    axios.all([msg_request, farms_request]).then(axios.spread((...responses) => {
      const msg_response = responses[0];
      const farms_response = responses[1];

      let msg_list = [];
      let farm_list = [];

      for (let i = 0; i < (farms_response.data.length); i++) {
        farm_list.push(farms_response.data[i]);
      }

      //console.log(farm_list)

      for (let i = 0; i < (msg_response.data.length); i++) {
        msg_list.push(msg_response.data[i]);
      }

      for (let i = 0; i < (msg_response.data.length); i++) {
        for (let j = 0; j < (farms_response.data.length); j++) {
          if ( msg_list[i].farm_id == null) {
            //console.log('emptyyyyyyyyyyyyyyyyy')
            msg_list[i].farm_name = 'نام باغ توسط کاربر اعلام نشده است.'
          }
        if (msg_list[i].farm_id == farm_list[j].id) {
          msg_list[i].farm_name = farm_list[j].name;
        }
      }
      }


      //console.log(farm_list)
      //console.log(msg_list)
      localStorage.setItem('user_message_list', JSON.stringify(msg_list));
      this.setState({ ready: true })
    })
    ).catch( error => {
      //console.log(error)
    })

  }

  changeGeneralStyle = () => {
    this.setState({ bgColor: 'white', txtColor: '#3f51b5' });
  }
  changePrivateStyle = () => {
    this.setState({ bgColor: '#3f51b5', txtColor: 'white' });
  }

  render() {
    if (this.state.ready == false) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading />
        </div>);
    }


    else {

      var msgs = JSON.parse(localStorage.getItem("user_message_list"));
      //console.log(msgs.length)
      for (let i = 0; i <= (msgs.length) - 1; i++) {
        if (msgs[i].category_id == '8') {
          msgs[i].status = 'عمومی';
        }
        if (msgs[i].category_id == '61') {
          msgs[i].status = 'درخواست ملاقات حضوری';
        }

        if (msgs[i].category_id == '62') {
          msgs[i].status = 'درخواست بازدید از باغ';
        }
        if (msgs[i].category_id == '59') {
          msgs[i].status = 'بررسی خاک';
        }
        if (msgs[i].category_id == '58') {
          msgs[i].status = 'بررسی آب';
        }
        if (msgs[i].category_id == '57') {
          msgs[i].status = 'بررسی اقلیمی یا آب‌ و هوایی';
        }

        if (msgs[i].category_id == '38' || msgs[i].category_id == '39' || msgs[i].category_id == '40' || msgs[i].category_id == '41' || msgs[i].category_id == '42' ||
          msgs[i].category_id == '33' || msgs[i].category_id == '34' || msgs[i].category_id == '35' || msgs[i].category_id == '36') {
          msgs[i].status = 'آبیاری';
        }

        if (msgs[i].category_id == '15' || msgs[i].category_id == '16' || msgs[i].category_id == '17' || msgs[i].category_id == '18' || msgs[i].category_id == '19' ||
          msgs[i].category_id == '20' || msgs[i].category_id == '21' || msgs[i].category_id == '22' || msgs[i].category_id == '23' || msgs[i].category_id == '24' ||
          msgs[i].category_id == '7' || msgs[i].category_id == '10') {
          msgs[i].status = 'تغذیه و کوددهی';
        }

        if (msgs[i].category_id == '11' || msgs[i].category_id == '12') {
          msgs[i].status = 'اصلاح خاک';
        }

        if (msgs[i].category_id == '13' || msgs[i].category_id == '26' || msgs[i].category_id == '27' || msgs[i].category_id == '14' || msgs[i].category_id == '29' ||
          msgs[i].category_id == '30' || msgs[i].category_id == '31' || msgs[i].category_id == '32' || msgs[i].category_id == '60' || msgs[i].category_id == '65') {
          msgs[i].status = 'باغبانی';
        }

        if (msgs[i].category_id == '54' || msgs[i].category_id == '55' || msgs[i].category_id == '56' || msgs[i].category_id == '64') {
          msgs[i].status = 'بیماری ها';
        }

        if (msgs[i].category_id == '63' || msgs[i].category_id == '44' || msgs[i].category_id == '45' || msgs[i].category_id == '46' || msgs[i].category_id == '47'
          || msgs[i].category_id == '48' || msgs[i].category_id == '49' || msgs[i].category_id == '50' || msgs[i].category_id == '51' || msgs[i].category_id == '52' || msgs[i].category_id == '53') {
          msgs[i].status = 'راهنمای کنترل آفات';
        }

        if (msgs[i].category_id == '43') {
          msgs[i].status = 'توصیه‌های قبل از شروع فصل';
        }

      }

      const MessageList = ({ my_category_id, my_created_at, my_id, my_price, my_status, my_farm_name }) => (
        <Link style={{ color: 'black' }} to={{ pathname: "/ChatDemo", chat_id: my_id }}>
          <div className='generalMSGContainer'>

            <div className='generalItemContainer'>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '20%' }}>
                <ContactSupportIcon style={{ width: '50%', height: '50%', color: 'white' }} />
              </div>
              <div className='msgContent'>
                <div className='notificationTitle' style={{ fontSize: 14, color: '#e8e8e8' }}>پاسخ سوال یا دریافت توصیه باغ</div>
                <div className='notificationSubTitle' style={{ color: 'white' }}>دسته بندی: {my_status}</div>
                <div className='notificationSubTitle' style={{ color: 'white' }}>نام باغ: {my_farm_name}</div>

              </div>
            </div>
          </div>
        </Link>

      )


      var messageRender;
      var card_icon;
      if (this.state.public_font_size == 13) {

        card_icon = <TelegramIcon style={{ color: 'white', width: '7vh', height: '7vh', marginTop: 16 }} />;
        messageRender =
          <div style={{ marginBottom: '15vh', marginTop: '3vh', padding: '16px' }}>
            <ul className='farmULs'>
              {msgs.map(({ category_id, created_at, id, price, status, farm_name }) => (
                <li key={category_id, created_at, id, price, status, farm_name}>
                  <MessageList my_category_id={category_id} my_created_at={created_at} my_id={id} my_price={price} my_status={status} my_farm_name={farm_name} />
                </li>
              ))}
            </ul>
          </div>;
      }
      if (this.state.public_font_size == 15) {

        card_icon = <NotificationsActiveRoundedIcon style={{ color: 'white', width: '7vh', height: '7vh', marginTop: 16 }} />;
        messageRender = <UserNotificatin />
      }



      return (

        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>

          <div style={{ // ***************************************** header ***********************************************************************************
            position: 'absolute',
            backgroundColor: '#007bffa8',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            width: '160vw',
            height: '80vw',
            borderRadius: '80vw / 40vw',
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
            left: '-32%',
            top: '-20%'
          }}
          >
          </div>


          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
              پیام ها
        </div>
          </div>

          <Link to="/Home" style={{ color: '#FFF' }}>
            <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
            </div>
          </Link>

          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Slide down>
              <div style={{ // ***************************************** Top card *****************************************************************************
                boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                display: 'flex',
                backgroundColor: '#1D75FA',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 3,
                width: '80vw',
                height: '20vh',
                marginTop: '9vh',
                borderRadius: 40,
                position: 'relative'
              }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                      {card_icon}
                    </div>
                    <div style={{ marginRight: 8 }}>
                      {this.state.farms_count}
                    </div>
                  </div>

                </div>


                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '98%',
                  height: '30%',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  position: 'relative',
                  backgroundColor: '#0051cc',
                  borderRadius: '5px 5px 40px 42px',
                  padding: 3
                }}>

                  <div onClick={() => { this.setState({ public_bg_color: 'transparent', public_font_size: 13, private_bg_color: '#1d75fa', private_font_size: 15 }) }}
                    style={{
                      borderRadius: '20px 5px 40px 20px',
                      backgroundColor: this.state.private_bg_color,
                      width: '50%',
                      height: '100%',
                      position: 'relative',
                      fontSize: this.state.private_font_size,
                      margin: 4,
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white'
                    }}>
                    خصوصی
              </div>
                  <div onClick={() => { this.setState({ public_bg_color: '#1d75fa', public_font_size: 15, private_bg_color: 'transparent', private_font_size: 13 }) }}
                    style={{
                      borderRadius: '5px 20px 20px 40px',
                      backgroundColor: this.state.public_bg_color,
                      width: '50%',
                      height: '100%',
                      position: 'relative',
                      fontSize: this.state.public_font_size,
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white'
                    }}>
                    عمومی
              </div>


                </div>


              </div>
            </Slide>
          </div>

          <div style={{ padding: 0, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>

            {messageRender}




          </div>

        </div>
      );
    }
  }




}

export default Message;

/*

<button onClick={this.changeGeneralStyle} className="dividerButtons" style={{backgroundColor: this.state.txtColor, color: this.state.bgColor,}}>عمومی</button>
<button onClick={this.changePrivateStyle} className="dividerButtons" style={{backgroundColor: this.state.bgColor, color: this.state.txtColor,}}>خصوصی</button>
</div>
<div>

*/