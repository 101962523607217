
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, histoy, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import './styles/AddNewQuestion.css';
import Home from './Home.js';
import LabelBottomNavigation from './BottomTab';
import TextInput from './Fields/TextInput.js';
import PersianDatePicker from './Fields/PersianDatePicker.js';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { number } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {sendLog} from './LogTest.js';

export default class AddNewQuestion extends Component {

  constructor(props) {
    super(props);
    this.state = {
        question_text: ' ',
        uploader_volume: number,
        image_selected: false,

    };
}
    componentDidMount() {
        localStorage.setItem('current_farm_id', this.props.location.state.c_farm_id);
      }
      handleTextChange = (e) => {
        this.setState({
          question_text: e.target.value
        })
      };
      questionSubmit = () => {
        const axios = require('axios');
        const { c_farm_id } = this.props.location.state;
    axios.post('https://api.pestekaran.com/api/messages', 
    {
      
        category_id: 8,
        api_token: localStorage.getItem('userTOKEN'),
        text: this.state.question_text,
        farm_id: c_farm_id
      ,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      toast.configure({
        position: "top-left",
        rtl: true,
        hideProgressBar: true, 
      });
 toast("سوال شما ثبت شد.");   

    })
    .catch(function (error) {
      sendLog(error.response);
    })
    .finally(function () {
      // always executed
    });  
      }






  render() {




    return (
      <div>


<AppBar position="sticky">
          <Toolbar className="headerBackButton">
            <Typography variant="h6">
               ثبت پرسش
            </Typography>
            <div>
              <Link to="/QuestionForm" style={{ color: '#FFF' }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
            </div>
          </Toolbar>
        </AppBar>


<div className='question-form-container'>
<textarea onChange={e => this.handleTextChange(e)} placeholder="سوال و توضیحات خود را در اینجا بنویسید ..." className='question-text' rows="5" cols="100">

</textarea>



<div className="frame">
<button style={{marginBottom: 80}} className="regSubmitButton" onClick={this.questionSubmit}>ثبت پرسش</button>
</div>
</div>



      </div>
    );
  }
}