
import React, { Component } from 'react';
import './FarmsPreview.css';
import { Launcher } from 'react-chat-window'
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import { MagicSpinner } from "react-spinners-kit";


class FarmsPreview extends Component {
  constructor() {
    super();
    this.state ={
      form_is_ready: false,
    }
  }

  onSubmit = () => {
    if (localStorage.getItem('current_farm_id') == '') {
      toast.configure({
        position: "top-left",
        rtl: true,
        hideProgressBar: true,
        toastClassName: 'error-notifier-toast',
        });
           
       toast("ابتدا اطلاعات عمومی باغتان را پر نمایید."); 
    }
    else {
    toast.configure({
      position: "top-left",
      rtl: true,
      hideProgressBar: true,
      toastClassName: 'error-notifier-toast',
      });
         
     toast("باغ شما با موفقیت ثبت شد");   

this.setState({form_is_ready: true})
    }
  }
  render() {


    if (!this.state.form_is_ready) {
    return (
      <div>


<AppBar position="sticky">
          <Toolbar className="headerBackButton">
            <Typography variant="h6">
            وضعیت باغ {this.props.location.state.name} 
            </Typography>
            <div>
              <Link to="/Farms" style={{ color: '#FFF' }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
            </div>
          </Toolbar>
        </AppBar>

        <div className='submitNewFarmContainer'>



<div className="frame">


  <div className='farmOverallStatus'><CircularProgressbar value={20} text={`${20}%`} /></div>

</div>

<div style={{marginTop: 20}}>وضعیت تکمیل باغ:</div>

<div style={{display: 'flex', width:'100%', marginTop: 30, marginRight: 30, flexDirection: 'column', justifyContent: 'flex-start'}}>

  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>

      <div style={{marginLeft: 20}}>اطلاعات عمومی:</div>
      <div style={{marginLeft: 30, color: 'green'}}>تکمیل شده</div>

  </div>

  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>

<div style={{marginLeft: 20}}>اطلاعات عارضه های موجود در باغ:</div>
<div style={{marginLeft: 30, color: 'red'}}>تکمیل نشده</div>

</div>

<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>

<div style={{marginLeft: 20}}>اطلاعات آبیاری:</div>
<div style={{marginLeft: 30, color: 'red'}}>تکمیل نشده</div>

</div>

<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>

<div style={{marginLeft: 20}}>اطلاعات عملیات اصلاحی:</div>
<div style={{marginLeft: 30, color: 'red'}}>تکمیل نشده</div>

</div>

<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>

<div style={{marginLeft: 20}}>اطلاعات سابقه کوددهی:</div>
<div style={{marginLeft: 30, color: 'red'}}>تکمیل نشده</div>

</div>

</div>
<div className="frame">

    <div className="centerAdd" style={{textAlign: 'center', padding: 10}}>
جهت تکمیل اطلاعات باغ خود آن را ویرایش کنید تا بهترین پاسخ را از کارشناسان دریافت کنید.
  </div>

</div>



</div>



      </div>
    );
    }
    else if(this.state.form_is_ready) {
      return(<Redirect to='/Farms' />);
    }
  }
}

export default FarmsPreview;