
import React, { Component } from 'react';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './styles/Farms.css'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Loading from './Loading.js';
import Slide from 'react-reveal/Slide';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import ReportRoundedIcon from '@material-ui/icons/ReportRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const FarmList = ({city1, subCity1, area1, treeCount1, treesAge1, farmID, completion_status1, farm_status_color1, farm_watering_duration1}) => (
<div className="frame">
  <div className="center">
		<div className="profile">
			<div className="image">
				<div className="circle-1"></div>
				<div className="circle-2"></div>
				<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQibYcL5cFX_UtSTpQRgaWhNx22tU2efawfwVvhOVCkAxPHmZWO" width="70" height="70" alt="Jessica Potter"></img>
			</div>
			
			<div className="name">{city1}</div>
			<div className="job">{subCity1}</div>
			
				<div className="actions">
				<div className="job" style={{color: farm_status_color1}} > {completion_status1} </div>
				<div></div>
					<Link style={{ color: 'black' }} to={{
						pathname: '/FarmsEdit',
						state: {
							name: city1,
							farm_id: farmID
						}}}><button className="btn" onClick={ () => { 
							////console.log("The editting farm ID is:" + farmID);
							localStorage.setItem('current_editting_farm', farmID)
							localStorage.setItem('current_editting_farm_name', city1);
							//console.log(typeof(parseInt(localStorage.getItem('current_editting_farm'))));
							}}>ویرایش</button></Link>
				</div>
		</div>
		
		<div className="stats">
			<div className="box">
				<span className="parameter">مساحت:</span>
				<span className="value">{area1} هکتار</span>
			</div>
			<div className="box">
				<span className="parameter">دوره آبیاری:</span>
						<span className="value">{farm_watering_duration1}</span>
			</div>
			<div className="box">
				<span className="parameter"> سن درخت: </span>
				<span className="value">{treesAge1} سال</span>
			</div>
		</div>
  </div>
</div>
  )


class Farms extends Component {
	state = {
		posts: [],
		loading: true,
		farms_count: 0,
		
	};

	componentDidMount() {
		this.setState({loading: true})
		const axios = require('axios');
		axios.get('https://api.pestekaran.com/api/farms',
			{

				params: {
					api_token: localStorage.getItem('userTOKEN'),
				}
				,
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Accept': 'application/json'
				}
			})
			.then((response) => {
				//console.log(response);
				this.setState({ farms_count: response.data.length });
				var status = 'تکمیل نشده';
				var status_color = 'red';
				var watering_duration= 'معین نشده';
				var farm_age= 'معین نشده';
				const JDate = require('jalali-date');
				const jdate = new JDate;
				for (let i = 0; i < response.data.length; i++) {

					 status = 'تکمیل نشده';
					 status_color = 'red';
					 watering_duration= 'معین نشده';
					 farm_age= 'معین نشده';
					 
					if ( response.data[i].general_details != null &&
						 response.data[i].correctiveOperation != null &&
					     response.data[i].disease != null &&
						 response.data[i].fertilizerHistory.animalOnChemical.last_two_year != null &&
						 response.data[i].fertilizerHistory.animalOnChemical.last_year != null &&
						 response.data[i].fertilizerHistory.animal_fertilizer != null &&
						 response.data[i].fertilizerHistory.chemical != null &&
						 response.data[i].fertilizerHistory.spraying != null &&						 
						 response.data[i].irrigation != null ) {
							status = 'تکمیل شده';
							status_color = 'green';
						 }

						 
						 if (response.data[i].general_details.plantig_date != null && response.data[i].general_details.plantig_date != '') {
							farm_age = jdate.getFullYear() - parseInt(response.data[i].general_details.plantig_date) /*.match(/\d{4}/)[0]*/;
						 }

						 if (response.data[i].irrigation != null && response.data[i].irrigation != ''){
							 //console.log('wateriiiiiiiiiiiiiiing not empty')
							  
							if( response.data[i].irrigation.gharghabi_method.gharghabi_watering_duration_in_days != '' && response.data[i].irrigation.gharghabi_method.gharghabi_watering_duration_in_days != null){
								watering_duration = response.data[i].irrigation.gharghabi_method.gharghabi_watering_duration_in_days;
								//console.log(response.data[i].irrigation.gharghabi_method.gharghabi_watering_duration_in_days)
							 }
	
							 if( response.data[i].irrigation.method_234.watering_duration_in_days_234 != '' && response.data[i].irrigation.method_234.watering_duration_in_days_234 != null){
								watering_duration = response.data[i].irrigation.method_234.watering_duration_in_days_234;
							 }
	
							 if( response.data[i].irrigation.pvc_method.watering_duration_in_days_PVC != '' && response.data[i].irrigation.pvc_method.watering_duration_in_days_PVC != null){
								watering_duration = response.data[i].irrigation.pvc_method.watering_duration_in_days_PVC;
							 }

						 }


					this.setState({
						posts: [...this.state.posts, {
							city: response.data[i].name,
							subCity: response.data[i].general_details.farm_address,
							area: response.data[i].general_details.area,
							treeCount: response.data[i].general_details.overall_pistachio_type,
							treesAge: farm_age,
							farm_ID: response.data[i].id,
							completion_status: status, 
							farm_status_color: status_color,
							farm_watering_duration: watering_duration

						}]
					})
				}
				this.setState({loading: false})


			})
			.catch((error) => {
				//console.log(error);
				//console.log("code ghalate")
			})
			.finally(function () {
				// always executed
			});

	}
  render() {
	
if (!this.state.loading) {
    return (
      <div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'white'}}>
		      <div style={{
        position: 'absolute',
        backgroundColor: '#007bffa8',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        width: '160vw', 
        height: '80vw', 
        borderRadius: '80vw / 40vw',
        boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
        left: '-32%',
        top: '-20%'
    }}
        >
        </div>
    
    
    <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%'}}>
        <div style={{fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white'}}>
        باغ ها 
        </div>
    </div>

    <Link to="/Home" style={{ color: '#FFF' }}>
                <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                </div>
            </Link>

			<div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
				<Slide down>
					<div style={{
						boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
						display: 'flex',
						backgroundColor: '#1D75FA',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-around',
						padding: 8,
						width: '80vw',
						height: '20vh',
						marginTop: '9vh',
						borderRadius: 40,
						position: 'relative'
					}}>

						<div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
							<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
								<div>
									تعداد باغ ها:
							</div>
								<div style={{ marginRight: 8 }}>
									{this.state.farms_count}
								</div>
							</div>

						</div>


						<div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

							<div style={{ width: '50%', height: '100%', position: 'relative' }}>
								<Link to="/AddNewFarm"
								><div style={{
									width: '100%',
									height: '100%',
									boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
									backgroundColor: '#0857c3',
									border: '1px solid rgb(3, 77, 179)',
									borderRadius: 40,
									color: 'white',
									padding: '12px 16px',
									fontSize: 12,
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'center'
								}}>
									<AddCircleOutlineRoundedIcon style={{ marginLeft: 8 }} />افزودن باغ</div></Link>
							</div>
						</div>
					</div>
				</Slide>
			</div>


			<Slide up>
				<div style={{ padding: 0, overflow: 'auto', height: '70vh', marginTop: '30vh' }}>




					<ul className='farmULs'>
						{this.state.posts.map(({ city, subCity, area, treeCount, treesAge, farm_ID, completion_status, farm_status_color, farm_watering_duration}) => (
							<li key={city, subCity, area, treeCount, treesAge, completion_status, farm_status_color, farm_watering_duration}>
								<FarmList 
									city1={city} 
									subCity1={subCity} 
									area1={area} 
									treeCount1={treeCount} 
									treesAge1={treesAge} 
									farmID={farm_ID} 
									completion_status1={completion_status} 
									farm_status_color1={farm_status_color}
									farm_watering_duration1={farm_watering_duration} />
							</li>
						))}
					</ul>


				</div>
			</Slide>

      </div>
	);
}
else {
	return(
		<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
		  <Loading />
		</div>);
	  }

  }
}

export default Farms;
