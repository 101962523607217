
import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import 'react-chat-widget/lib/styles.css';
import { Launcher } from 'react-chat-window';
import './styles/ChatBox.css';
import { number } from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MagicSpinner } from "react-spinners-kit";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { fontFamily } from '@material-ui/system';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import 'react-chat-elements/dist/main.css';
import Loading from './Loading.js';
import { MessageBox } from 'react-chat-elements';
import { ChatItem } from 'react-chat-elements'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BrowserRouter as Router, Redirect, Link, histoy, withRouter } from 'react-router-dom';
import { Input } from 'react-chat-elements';
import { Button } from 'react-chat-elements';
import Modal from 'react-awesome-modal';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Sanghermez from './PestCategories/Sanghermez';
import Sooskoo from './PestCategories/Sooskoo';
import Zanjare from './PestCategories/Zanjare';
import Choobkhar from './PestCategories/Choobkhar';
import Sansabz from './PestCategories/Sansabz';
import Pasil from './PestCategories/Pasil';
import Sang from './PestCategories/Sang';
import Mivekhar from './PestCategories/Mivekhar';
import Sarkhortoomi from './PestCategories/Sarkhortoomi';
import Shepeshak from './PestCategories/Shepeshak';
import Slide from 'react-reveal/Slide';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import PaymentPolicy from './PaymentPolicy';
import {sendLog} from './LogTest.js';

const customStyles = {

    overlay: { zIndex: 20000 }
};



class ChatDemo extends Component {
    constructor() {
        super();
        this.myRef = React.createRef();
        this.state = {
            payment_modal: false,
            sooskoo_modal: false,
            shire_tar_modal: false,
            shepeshak_modal: false,
            mive_khar_modal: false,
            sar_khortoomi_modal: false,
            sang_modal: false,
            san_ghermez_modal: false,
            san_sabz_modal: false,
            choob_khar_modal: false,
            pasil_modal: false,
            fileReady: true,
            final_image_url: '',
            fileSize: '',
            uploaderModal: false,
            percent: number,
            loading: 1,
            uploader_volume: 0,
            chatBoxModal: true,
            msg_failure: false,
            msg_log: 'تا زمانی که مدیران پاسخ شما را نداده‌اند، امکان ثبت پیام را ندارید.',
            messageList: [
                {
                    author: 'left',
                    type: 'text',

                    text: 'به سیستم پشتیبان پسته کاران خوش آمدید.',
                    Lightbox_able: '0',
                    Lightbox_img_url: null

                }
                ,


            ],
            isOpen: false
        };






        this._onMessageWasSent.bind(this);

    }


    scrollToBottom = () => {
        const { messageList } = this.refs;
        messageList.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }



    componentDidMount = () => {


        localStorage.setItem('typed_msg', '')
        var request_chat_messages = 'https://api.pestekaran.com/api/messages/' + (this.props.location.chat_id);
        const axios = require('axios');
        axios.get(request_chat_messages,
            {
                params: {
                    api_token: localStorage.getItem('userTOKEN')
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json'
                }
            })
            .then((response) => {
                var msg_owner = '';
                var msg_text = '';
                var msg;
                var clickable;
                var img_url;
            var final_array = [];
            var pest_msg_patern = new RegExp("pest_type")
            var payment_msg_patern = new RegExp("Payment_url")
                for (let i = 0; i < response.data.length; i++) {

                    if (response.data[i].farm_id != null) { localStorage.setItem('pested_farm_id', parseInt(response.data[i].farm_id))}
                    if (response.data[i].text.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/g) == null) { clickable = '1'; img_url = null; } else {
                        clickable = '0';
                        img_url = response.data[i].text.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/g)[0];
                        response.data[i].text = '<a href=' + img_url + '>یک فایل برای شما ارسال شده است. کلیک کنید.</a>'
                    };
                    if (response.data[i].by_admin == 0) { msg_owner = 'right' } else if (response.data[i].by_admin == 1) { msg_owner = 'left' };

                    msg_text = ReactHtmlParser(response.data[i].text);


                    if (pest_msg_patern.test(JSON.stringify(msg_text))) {
                        var pest_msg = JSON.parse(msg_text[0]);
                        if (pest_msg.text == null) { pest_msg.text = 'لطفا تا زمان پاسخ دهی کارشناسان شکیبا باشید.' }
                        clickable = pest_msg.pest_type;

                        msg = {
                            author: msg_owner,
                            type: 'text',
                            text: pest_msg.text,
                            Lightbox_able: clickable,
                            Lightbox_img_url: img_url,
                        }
                        final_array.push(msg);

                        var pest_html = '<div style="width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center"><div>جهت دریافت پاسخ، فرم زیر را پر فرمایید.</div><br><button style="border-radius: 30px; background-color: #4CAF50; border: none; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; font-size: 14px;">فرم آفات</button></div>';
                       
                        msg = {
                            author: msg_owner,
                            type: 'text',
                            text: ReactHtmlParser(pest_html),
                            Lightbox_able: clickable,
                            Lightbox_img_url: img_url
                        }
                        final_array.push(msg);

                    }

                    if (payment_msg_patern.test(JSON.stringify(msg_text))) {
                        var payment_msg = JSON.parse(msg_text[0]);
                        clickable = '1000';


                        var pest_html = '<div style="width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center"><div>هزینه درخواستی توسط کارشناسان:</div><br><button style="border-radius: 30px; background-color: rgb(29, 117, 250); border: none; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; font-size: 14px;"> پرداخت</button></div>';
                       
                        msg = {
                            author: msg_owner,
                            type: 'text',
                            text: ReactHtmlParser(pest_html),
                            Lightbox_able: clickable,
                            Lightbox_img_url: payment_msg
                        }
                        final_array.push(msg);


                    }


                    else {
                        if(!pest_msg_patern.test(JSON.stringify(msg_text)) && !payment_msg_patern.test(JSON.stringify(msg_text))) {
                        msg = {
                            author: msg_owner,
                            type: 'text',
                            text: msg_text,
                            Lightbox_able: clickable,
                            Lightbox_img_url: img_url,
                        }
                        final_array.push(msg);
                    }
                    }
                
                }
                this.setState({ messageList: final_array }, () => { this.setState({ loading: 0 }) })


            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(() => {

            });


    }



    _onMessageWasSent = () => {
        const axios = require('axios');
        axios.post('https://api.pestekaran.com/api/messages',
            {

                api_token: localStorage.getItem('userTOKEN'),
                text: localStorage.getItem('typed_msg'),
                farm_id: '',
                reply_to: this.props.location.chat_id
                ,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json'
                }
            })
            .then(response => {

                this.setState({
                    chatBoxModal: false,
                    msg_failure: true,
                    msg_log: 'پیام شما با موفقیت ثبت شد، لطفا تا پاسخ دادن کارشناسان شکیبا باشید.'
                });
                localStorage.setItem('typed_msg', '');
                this.refs.input_text.clear();

            })
            .catch((error) => {
                sendLog(error.response);
                if (error.response.status == 400) {
                    this.setState({
                        chatBoxModal: false,
                        msg_failure: true,
                        msg_log: error.response.data[0]
                    })
                }
                localStorage.setItem('typed_msg', '')

            })
            .finally(function () {
                // always executed
            });

    }


    _onFileSeleceted = (event) => {
        this.setState({ uploaderModal: true });

        localStorage.setItem('file_size', (event.target.files[0].size) / 1024);
        var formData = new FormData();
        var imagefile = document.querySelector('#file');
        formData.append("file", event.target.files[0]);
        formData.append('api_token', localStorage.getItem('userTOKEN'));
        formData.append('reply_to', this.props.location.chat_id);

        const config = {
            onUploadProgress: progressEvent => {

                var x = Math.floor((((progressEvent.loaded) / 1024) / localStorage.getItem('file_size')) * 100);
                this.setState({ uploader_volume: x })
            }
        }
        axios.post('https://api.pestekaran.com/api/file/send', formData, config, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                this.setState({
                    chatBoxModal: false,
                    msg_failure: true,
                    msg_log: 'پیام شما با موفقیت ثبت شد، لطفا تا پاسخ دادن کارشناسان شکیبا باشید.'
                });
                localStorage.setItem('typed_msg', '')


            })
            .catch((error) => {
                sendLog(error.response);
                //console.log(error)
                if (error.response.status == 400) {
                    this.setState({
                        chatBoxModal: false,
                        msg_failure: true,
                        msg_log: error.response.data[0]
                    })
                }
                localStorage.setItem('typed_msg', '')

            })
            .finally(function () {
                // always executed
            });
    }
    render() {



        const MessageList = ({ author1, type1, text1, Lightbox_able1, Lightbox_img_url1 }) => (

            <MessageBox
                position={author1}
                type={type1}
                text={text1}
                id={'cickable'}
                onClick={() => {
                    switch (Lightbox_able1) {
                        // case '0':
                        //     this.setState({ isOpen: true, final_image_url: Lightbox_img_url1 });
                        //     break;
                        case '1000':
                            this.setState({ payment_modal: true });
                            localStorage.setItem('payment_object', JSON.stringify(Lightbox_img_url1));
                            break;
                        case '53':
                            this.setState({ sooskoo_modal: true });
                            localStorage.setItem('pest_cat_id', '53');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);
                            break;
                        case '52':
                            this.setState({ shire_tar_modal: true });
                            localStorage.setItem('pest_cat_id', '52');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);  
                            break;
                        case '51':
                            this.setState({ shepeshak_modal: true });
                            localStorage.setItem('pest_cat_id', '51');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);  
                            break;
                        case '50':
                            this.setState({ mive_khar_modal: true });
                            localStorage.setItem('pest_cat_id', '50');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);  
                            break;
                        case '49':
                            this.setState({ sar_khortoomi_modal: true });
                            localStorage.setItem('pest_cat_id', '49');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);  
                            break;
                        case '48':
                            this.setState({ sang_modal: true });
                            localStorage.setItem('pest_cat_id', '48');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);  
                            break;
                        case '47':
                            this.setState({ san_ghermez_modal: true });
                            localStorage.setItem('pest_cat_id', '47');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);  
                            break;
                        case '46':
                            this.setState({ san_sabz_modal: true });
                            localStorage.setItem('pest_cat_id', '46');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);  
                            break;
                        case '45':
                            this.setState({ choob_khar_modal: true });
                            localStorage.setItem('pest_cat_id', '45');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);  
                            break;
                        case '44':
                            this.setState({ pasil_modal: true });
                            localStorage.setItem('pest_cat_id', '44');
                            localStorage.setItem('pest_modal_msg_id', this.props.location.chat_id);  
                            break;
                    }
                }
                }


            />


        )
        if (this.state.loading == 1) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loading />
                </div>);
        }
        if (this.state.loading == 0) {
            return (
                <div>

                    <div className='farmsContainer'>


                        <Modal visible={this.state.chatBoxModal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ chatBoxModal: false }) }}>

                            <div style={{ position: 'relative', backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'space-between' }}>

                                <AppBar position="sticky">
                                    <Toolbar className="headerBackButton">
                                        <Typography variant="h6">
                                            پاسخ شما
            </Typography>
                                        <div>

                                            <Link to="/Message" style={{ color: 'white' }}><IconButton
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                color="inherit"
                                            >
                                                <ArrowBackIosIcon />
                                            </IconButton></Link>

                                        </div>
                                    </Toolbar>
                                </AppBar>
                                <div style={{ overflow: 'hidden', overflowY: 'auto', position: 'relative', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '75%', width: '95%' }}>

                                    <div style={{ position: 'absolute', width: '100%', }}>
                                        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>

                                            {this.state.messageList.map(({ author, type, text, Lightbox_able, Lightbox_img_url }) => (
                                                <li key={author, type, text, Lightbox_able, Lightbox_img_url}>
                                                    <MessageList author1={author} type1={type} text1={text} Lightbox_able1={Lightbox_able} Lightbox_img_url1={Lightbox_img_url} />
                                                </li>
                                            ))
                                            }






                                        </ul>

                                        <Modal visible={this.state.uploaderModal} width="50%" height="20%" effect="fadeInDown">
                                            <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                <div className='circularWait' style={{ width: '15vw', height: '15vw', marginRight: 0 }}>
                                                    <CircularProgressbar value={this.state.uploader_volume} text={`${this.state.uploader_volume}%`} />
                                                </div>
                                            </div>
                                        </Modal>




                                        <div ref="messageList"></div>

                                    </div>


                                </div>
                                <div style={{ width: '100%', maxHeight: '10%', display: 'flex', backgroundColor: 'rgb(249, 249, 249)', padding: '0px 8px 8px 8px', backgroundColor: '#e5eaef', }}>








                                    <Input
                                        ref="input_text"
                                        onChange={value => {
                                            localStorage.setItem('typed_msg', value.target.value)
                                        }}
                                        placeholder="بنویسید ..."
                                        multiline={true}
                                        rightButtons={
                                            <div>
                                                <div>
                                                    <label style={{ marginBottom: 0 }} for="files"><AttachFileIcon style={{ transform: 'rotate(45deg) scaleX(-1)', color: '#3f51b5', marginLeft: 8 }} /></label>
                                                    <input id="files" className="openCameraFile" style={{ display: 'none' }} type="file" onChange={this._onFileSeleceted} />
                                                </div>

                                                <div>
                                                    <SendIcon onClick={this._onMessageWasSent} style={{ transform: 'rotate(0deg) scaleX(-1)', color: '#3f51b5' }} />
                                                </div>

                                            </div>

                                        } />
                                </div>

                            </div>


                        </Modal>

                        <Modal visible={this.state.msg_failure} width="60%" height="30%" effect="fadeInDown" onClickAway={() => { this.setState({ msg_failure: false }) }}>
                            <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><CloseRoundedIcon onClick={() => { this.setState({ loading: 4 }) }} style={{ color: 'red' }} /></div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                                    <div style={{ textAlign: 'center' }}>{this.state.msg_log}</div>
                                </div>
                            </div>

                        </Modal>


                        {/* <Modal visible={this.state.isOpen} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ isOpen: false }) }}>
                            <div style={{ width: '100%', height: '100%', position: 'relative', zIndex: 999999999999999999 }}>
                            <div style={{
                    width: '100%',
                    position: 'absolute',
                    fontSize: 16,
                    color: 'white',
                    backgroundColor: 'rgb(0 4 11 / 66%)',
                    width: '160vw',
                    height: '51vw',
                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                    left: '-32%',
                    top: '-20%'
                }}>


                </div>
                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%', zIndex: 9000000002, }}
                    onClick={() => { this.setState({ isOpen: false })}}>
                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
بستن
</div>
                </div>


                                        <img style={{ height: 'inherit' }} src={this.state.final_image_url}/>


                            </div>

                        </Modal> */}




                        <Modal visible={this.state.san_ghermez_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ san_ghermez_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ san_ghermez_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                      سن قرمز 
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Sanghermez />
                                </div>


                            </div>

                        </Modal>


                        <Modal visible={this.state.sooskoo_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ sooskoo_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ sooskoo_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                     سوسک سرشاخه خوار (سوسکو)
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Sooskoo />
                                </div>


                            </div>

                        </Modal>

                        <Modal visible={this.state.shire_tar_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ shire_tar_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ shire_tar_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                     زنجره (شیره تر)
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Zanjare />
                                </div>


                            </div>

                        </Modal>


                        <Modal visible={this.state.choob_khar_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ choob_khar_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ choob_khar_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                     چوبخوار یا کرمانیا
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Choobkhar />
                                </div>


                            </div>

                        </Modal>

                        <Modal visible={this.state.san_sabz_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ san_sabz_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ san_sabz_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                     سن سبز
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Sansabz />
                                </div>


                            </div>

                        </Modal>

                        <Modal visible={this.state.pasil_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ pasil_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ pasil_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                     پسیل یا شیره خشک 
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Pasil />
                                </div>


                            </div>

                        </Modal>

                        <Modal visible={this.state.sang_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ sang_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ sang_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                       سنک  
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Sang />
                                </div>


                            </div>

                        </Modal>

                        <Modal visible={this.state.sar_khortoomi_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ sar_khortoomi_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ sar_khortoomi_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                       سرخرطومی  
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Sarkhortoomi />
                                </div>


                            </div>

                        </Modal>

                        <Modal visible={this.state.mive_khar_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ mive_khar_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ mive_khar_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                       میوه خوار  
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Mivekhar />
                                </div>


                            </div>

                        </Modal>

                        <Modal visible={this.state.shepeshak_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ shepeshak_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        فرم آفات
                </div>
                                </div>


                                <div onClick={() => { this.setState({ shepeshak_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 14, textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                        شپشک  
                      </div>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای دریافت توصیه در خصوص آفات موجود در باغ، فرم زیر را پر بفرمایید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <Shepeshak />
                                </div>


                            </div>

                        </Modal>


                        <Modal visible={this.state.payment_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ payment_modal: false }) }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative', flexDirection: 'column' }}>

                                <div style={{ // ***************************************** header ***********************************************************************************
                                    position: 'absolute',
                                    backgroundColor: '#007bffa8',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    width: '160vw',
                                    height: '80vw',
                                    borderRadius: '80vw / 40vw',
                                    boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
                                    left: '-32%',
                                    top: '-20%'
                                }}
                                >
                                </div>


                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                                        پرداخت هزینه
                </div>
                                </div>


                                <div onClick={() => { this.setState({ payment_modal: false }) }} style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <ArrowBackIosIcon style={{ margin: 16, color: 'white' }} />
                                </div>

                                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Slide down>
                                        <div style={{ // ***************************************** Top card *****************************************************************************
                                            boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                                            display: 'flex',
                                            backgroundColor: '#1D75FA',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 8,
                                            width: '80vw',
                                            height: '20vh',
                                            marginTop: '9vh',
                                            borderRadius: 40,
                                            position: 'relative'
                                        }}>

                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div>
                                                        <NotificationsActiveRoundedIcon style={{ color: 'white' }} />
                                                    </div>
                                                    <div style={{ marginRight: 8 }}>
                                                        {this.state.farms_count}
                                                    </div>
                                                </div>

                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                                                <div style={{ width: '90%', height: '100%', position: 'relative', fontSize: 13, textAlign: 'center', color: 'white' }}>
                                                    برای پرداخت می توانید کیف پول خود را شارژ کنید و یا به طور مستقیم هزینه را بپردازید.
                      </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                                <div>
                                    <PaymentPolicy key={this.state.payment_modal}/>
                                </div>


                            </div>

                        </Modal>
                    </div>
                </div>
            );
        }
        if (this.state.loading == 4) {
            return (<Redirect to='/Message' />);
        }
    }
}

export default ChatDemo;
