
import React, { Component } from 'react';
import { BrowserRouter as Redirect, Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import './styles/Home.css';
import Profile from './Profile.js';
import SimpleList from './SimpleList.js';
import 'react-chat-widget/lib/styles.css';
import Pest from './Pest.js';
import Loading from './Loading.js';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Slide from 'react-reveal/Slide';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PetsRoundedIcon from '@material-ui/icons/PetsRounded';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import BugReportRoundedIcon from '@material-ui/icons/BugReportRounded';
import PolicyRoundedIcon from '@material-ui/icons/PolicyRounded';
import GrainRoundedIcon from '@material-ui/icons/GrainRounded';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import ReportRoundedIcon from '@material-ui/icons/ReportRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Intro from './Intro.js';
import Modal from 'react-awesome-modal';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flex: 1,
    textAlign: "right",
  },
}));


class Home extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      intro_modal: false,
      loading: true,
      posts: [],
      MainMenu: 'block',
      SecondMenu: 'none',
      activeTabColor: 'black',
      deactiveTabcolor: '#a5a5a5',
      activeTabBorder: '1px solid #a5a5a5',
      decativeTabBorder: '0px',
      name: '',
      location: '',
      user_profile_photo: '',
      more_button_modal: false,
      exit_button_modal: false,
      logOut: false,
    }
  }

  setIntroModalState = () => {
    this.setState({ intro_modal: false })
  }

  fetch_stuff = () => {
    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/profile',
      {

        params: {
          api_token: localStorage.getItem('userTOKEN'),
        }
        ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        localStorage.setItem('userID', response.data.id);

        if (response.data.photo == null) {
           this.setState({ user_profile_photo: 'https://cdn.imgbin.com/6/25/24/imgbin-user-profile-computer-icons-user-interface-mystique-aBhn3R8cmqmP4ECky4DA3V88y.jpg' })
        }

        else { this.setState({ user_profile_photo: response.data.photo }) }

        this.setState({ name: response.data.name, location: response.data.resident })

      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(function () {
        // always executed
      });



    axios.put('https://api.pestekaran.com/api/user/fcm',
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        },
        api_token: localStorage.getItem('userTOKEN'),
        fcm_token: localStorage.getItem('fcm_token'),
      })
      .then((response) => {
        //console.log(response);
        this.setState({ loading: false })
      })
      .catch((error) => {
        //console.log(error);
        this.setState({ loading: false })
      })
      .finally(function () {

      });

  }

  componentDidMount() {
    this._isMounted = true;

    if ( localStorage.getItem('show_intro') == undefined || localStorage.getItem('show_intro') == null ) {
      this.setState({ intro_modal: true }, () => {localStorage.setItem('show_intro', 'already seen')} )
      
    }

      this.fetch_stuff();
      if (localStorage.getItem('back_from_requests') == undefined) {
        localStorage.setItem('back_from_requests', false)
        }
       if (localStorage.getItem('back_from_requests') != undefined && localStorage.getItem('back_from_requests') == 'true')
       {
         //console.log('trueeeeeeeeeeeeeee')
        this.setState({
          MainMenu: 'none',
          SecondMenu: 'block',
          activeTabColor: '#a5a5a5',
          deactiveTabcolor: 'black',
          activeTabBorder: '0px',
          decativeTabBorder: '1px solid #a5a5a5',
        })
        localStorage.setItem('back_from_requests', false)
    
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }


  render() {

    if (!this.state.loading) {
      if (!this.state.logOut) {

        return (
          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
            <div style={{
              position: 'absolute',
              backgroundColor: '#007bffa8',
              boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
              width: '160vw',
              height: '80vw',
              borderRadius: '80vw / 40vw',
              boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.25), -5px -5px 30px 1px rgba(0,0,0,0.22)",
              left: '-32%',
              top: '-20%'
            }}
            >
            </div>


            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginTop: 16, color: 'white' }}>
                پسته کاران
        </div>
            </div>
            <Link to="/Wallet" style={{ color: '#FFF' }}>
              <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <AccountBalanceWalletIcon style={{ margin: 16, color: 'white' }} />
              </div>
            </Link>

            <Link to="/Blog" style={{ color: '#FFF' }}>
              <div style={{ zIndex: 100, position: 'absolute', display: 'flex', justifyContent: 'flex-start', width: '50%' }}>
                <EventNoteRoundedIcon style={{ margin: 16, color: 'white' }} />
              </div>
            </Link>


            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100%' }}>

              <div style={{
                boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                display: 'flex',
                backgroundColor: '#1D75FA',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                padding: 8,
                width: '80vw',
                height: '25vh',
                marginTop: '9vh',
                borderRadius: 40,
                position: 'relative'
              }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '70%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '70%', alignItems: 'center' }}>
                    <Link style={{ zIndex: 19999 }} to='/profile'><img src={this.state.user_profile_photo}
                      alt="Avatar"
                      style={{
                        width: '20vw',
                        height: '20vw',
                        borderRadius: '50%',
                        boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                        border: '1px solid rgb(3, 77, 179)',
                      }}
                    />
                    </Link>

                    <Link style={{ color: 'black', zIndex: 19999 }} to='/profile'><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '16px' }}>

                      <div style={{ fontSize: 12 }}>{this.state.name}</div>
                      <div style={{ fontSize: 12 }}>{this.state.location}</div>

                    </div></Link>
                  </div>
                  <div onClick={() => { this.setState({ more_button_modal: true }) }} style={{ zIndex: 19999, cursor: 'pointer', marginRight: '10%', height: 40, width: 40 }}>
                    <MoreVertIcon style={{ color: 'white', marginRight: '15%' }} size="large" />
                  </div>

                  <Modal visible={this.state.more_button_modal} width="60%" height="30%" effect="fadeInDown" onClickAway={() => { this.setState({ more_button_modal: false }) }}>
                    <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><CloseRoundedIcon onClick={() => { this.setState({ more_button_modal: false }) }} style={{ color: 'red' }} /></div>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <ReportRoundedIcon style={{ width: 40, height: 40, }} size="large" />
                          <div style={{ marginTop: 16, fontSize: 12 }}>گزارش اشکال</div>
                        </div>

                        <div onClick={() => { this.setState({ exit_button_modal: true, more_button_modal: false, }) }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <PowerSettingsNewRoundedIcon style={{ width: 40, height: 40, }} size="large" />
                          <div style={{ marginTop: 16, fontSize: 12 }}>خروج</div>
                        </div>
                      </div>
                    </div>

                  </Modal>

                  <Modal visible={this.state.exit_button_modal} width="70%" height="20%" effect="fadeInDown" onClickAway={() => { this.setState({ exit_button_modal: false }) }}>
                    <div style={{ padding: 8, backgroundColor: 'rgba(213, 233, 255, 0.66)', borderRadius: '40px 10px 40px 40px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}><CloseRoundedIcon onClick={() => { this.setState({ exit_button_modal: false }) }} style={{ color: 'red' }} /></div>

                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>

                        <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                          آیا قصد خروج از حساب کاربری خود را دارید؟
                                  </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100%' }}>
                          <div
                            onClick={() => { localStorage.setItem('userTOKEN', ''); window.location.href = 'https://app.pestekaran.com/' }}
                            style={{
                              width: '40%',
                              height: '70%',
                              boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                              backgroundColor: '#0857c3',
                              border: '1px solid rgb(3, 77, 179)',
                              borderRadius: 40,
                              color: 'white',
                              padding: '12px 16px',
                              fontSize: 12,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                            بله </div>


                          <div
                            onClick={() => { this.setState({ exit_button_modal: false }) }} style={{ color: 'red' }}
                            style={{
                              width: '40%',
                              height: '70%',
                              boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                              backgroundColor: '#0857c3',
                              border: '1px solid rgb(3, 77, 179)',
                              borderRadius: 40,
                              color: 'white',
                              padding: '12px 16px',
                              fontSize: 12,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                            خیر </div>
                        </div>


                      </div>


                    </div>

                  </Modal>

                </div>


                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', justifyContent: 'space-around', alignItems: 'center', position: 'relative' }}>

                  <div style={{ width: '45%', height: '100%', position: 'relative', zIndex: 19999 }}>
                    <Link to='/Farms'><div style={{
                      width: '100%',
                      height: '80%',
                      boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                      backgroundColor: '#0857c3',
                      border: '1px solid rgb(3, 77, 179)',
                      borderRadius: 40,
                      color: 'white',
                      padding: '12px 16px',
                      fontSize: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <ClearAllIcon style={{ marginLeft: 8 }} />باغ ها</div></Link>
                  </div>
                  <div style={{ width: '45%', height: '100%', position: 'relative', zIndex: 19999 }}>
                    <Link to='/Wallet'><div style={{
                      width: '100%',
                      height: '80%',
                      boxShadow: "rgba(0, 0, 0, 0.05) 5px 5px 20px 7px, rgba(0, 0, 0, 0.13) -5px -5px 30px 1px",
                      backgroundColor: '#0857c3',
                      border: '1px solid rgb(3, 77, 179)',
                      borderRadius: 40,
                      color: 'white',
                      padding: '12px 16px',
                      fontSize: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <AddCircleOutlineRoundedIcon style={{ marginLeft: 8 }} />اعتبار</div></Link>
                  </div>
                </div>





              </div>

            </div>


            <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginTop: '36vh  ' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '60%', }}>
                <div onClick={() => this.setState({
                  MainMenu: 'block',
                  SecondMenu: 'none',
                  activeTabColor: 'black',
                  deactiveTabcolor: '#a5a5a5',
                  activeTabBorder: '1px solid #a5a5a5',
                  decativeTabBorder: '0px',
                })}
                  style={{
                    zIndex: 19999,
                    width: '50%',
                    textAlign: 'center',
                    borderBottom: this.state.activeTabBorder,
                    boxShadow: 'none',
                    borderTop: '0px',
                    borderRight: '0px',
                    borderLeft: '0px',
                    color: this.state.activeTabColor,
                  }}>
                  توصیه ها
             </div>


                <div onClick={() => this.setState({
                  MainMenu: 'none',
                  SecondMenu: 'block',
                  activeTabColor: '#a5a5a5',
                  deactiveTabcolor: 'black',
                  activeTabBorder: '0px',
                  decativeTabBorder: '1px solid #a5a5a5',
                })}
                  style={{
                    zIndex: 19999,
                    width: '50%',
                    textAlign: 'center',
                    borderBottom: this.state.decativeTabBorder,
                    boxShadow: 'none',
                    borderTop: '0px',
                    borderRight: '0px',
                    borderLeft: '0px',
                    color: this.state.deactiveTabcolor,
                  }}>
                  درخواست ها
             </div>
              </div>
            </div>


            <Slide up>
              <div style={{ display: this.state.MainMenu }}>
                <div style={{ backgroundColor: 'white', overflow: 'auto', width: '100%', height: '100vw', marginTop: '40vh', display: 'flex', flexDirection: 'column', position: 'relative', padding: 10 }}>
                  <div style={{ display: 'flex', flexFlow: 'row', width: '100%', height: '30vw', position: 'relative', justifyContent: 'space-between', alignItems: 'flex-end', marginTop: '20px', }}>

                    <div style={{
                      boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                      borderRadius: 20,
                      width: '30vw',
                      height: '100%',
                      backgroundImage: 'url(' + require('./styles/watering-bg.gif') + ')',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      position: 'relative'
                    }}
                    ><Link to='/WateringAdvice'
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}>

                        <div style={{ color: 'white', marginBottom: '8px', }}>آبیاری</div></Link>
                    </div>
                    <div style={{
                      width: '30vw',
                      height: '100%',
                      boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                      borderRadius: 20,
                      backgroundColor: '#871026',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}><Link to='/Fertilizer'
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}>
                        <div style={{ width: '100%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <PetsRoundedIcon style={{ color: 'white', marginTop: 22 }} />
                        </div>
                        <div style={{ width: '100%', height: '20%', textAlign: 'center', color: 'white', marginBottom: '8px', }}>تغذیه و کوددهی</div></Link>
                    </div>

                    <div style={{
                      width: '30vw',
                      height: '100%',
                      boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                      borderRadius: 20,
                      backgroundColor: '#5b2245',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      <Link to='/FarmingAdvice'
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          position: 'relative',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}>
                        <div style={{ width: '100%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <SpaRoundedIcon style={{ color: 'white', marginTop: 22 }} />
                        </div>
                        <div style={{ width: '100%', height: '20%', textAlign: 'center', color: 'white', marginBottom: '8px', }}>باغبانی</div></Link>

                    </div>

                  </div>



                  <div style={{ display: 'flex', flexFlow: 'row', width: '100%', height: '30vw', position: 'relative', justifyContent: 'space-between', marginTop: '2.5vw', alignItems: 'flex-start' }}>
                    <div style={{
                      width: '30vw',
                      height: '100%',
                      boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                      borderRadius: 20,
                      backgroundColor: '#414288',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      <Link to='/Pest'
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          position: 'relative',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}>
                        <div style={{ width: '100%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <BugReportRoundedIcon style={{ color: 'white', marginTop: 22 }} />
                        </div>
                        <div style={{ width: '100%', height: '20%', textAlign: 'center', color: 'white', marginBottom: '8px', }}>آفات</div></Link>
                    </div>

                    <div style={{
                      width: '30vw',
                      height: '100%',
                      boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                      borderRadius: 20,
                      backgroundColor: '#A80874',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      <Link to='/DiseaseAdvice'
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          position: 'relative',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}>
                        <div style={{ width: '100%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <PolicyRoundedIcon style={{ color: 'white', marginTop: 22 }} />
                        </div>
                        <div style={{ width: '100%', height: '20%', textAlign: 'center', color: 'white', marginBottom: '8px', }}>بیماری ها</div></Link>

                    </div>


                    <div style={{
                      width: '30vw',
                      height: '100%',
                      boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                      borderRadius: 20,
                      backgroundColor: '#077655',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      <Link to='/Correction'
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          position: 'relative',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}>
                        <div style={{ width: '100%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <GrainRoundedIcon style={{ color: 'white', marginTop: 22 }} />
                        </div>
                        <div style={{ width: '100%', height: '20%', textAlign: 'center', color: 'white', marginBottom: '8px', }}>اصلاح خاک</div></Link>

                    </div>

                  </div>




                </div>
              </div>

              <div style={{ display: this.state.SecondMenu }}>
                <div style={{ backgroundColor: 'white', overflow: 'auto', width: '100%', height: '100vw', marginTop: '40vh', display: 'flex', flexDirection: 'column', position: 'relative', padding: 10 }}>

                  <SimpleList />

                </div>
              </div>
            </Slide>

            <Modal visible={this.state.intro_modal} width="100%" height="100%" effect="fadeInRight" onClickAway={() => { this.setState({ intro_modal: false }) }}>

<div style={{ height: '100%', width: '100%', zIndex: 90000}}>
<Intro sendBoolToHomePage={this.setIntroModalState} />
</div>
                  </Modal>


          </div>

        );
      }
      else if (this.state.logOut) {
        return (<Redirect to='/App' />);
      }
    }
    else {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading />
        </div>);
    }
  }
}
export default withRouter(Home);